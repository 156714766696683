import React from "react";

const AvatarImg = ({ selfieUrl, name }) => {
    return (
        <>
            <div className="avatar-upload mb-3">
                <div className="pic selfi_image "></div>
                <div className="avatar-preview rounded">
                    <div id="imagePreview" style={{
                        backgroundImage: 'url('+selfieUrl+')'
                    }}></div>
                    <p className="text-center mt-2">{name}</p>
                </div>
            </div>
        </>
    );
}
export default AvatarImg;