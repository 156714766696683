import React, { useContext, useEffect, useState } from "react";
import { useNavigate,useLocation } from "react-router-dom";
import Heading from "../common-components/Heading";
import Layout from "../common-components/Layout";
import { ToastContainer, toast } from 'react-toastify';
import config from "../../config.json";
import { CustomButton } from "../common-components/Buttons";
import { BureauContext } from "../Context/BureauContext";
import { encryptData } from "../../encrypt";
import axios from "axios";
import locationImage from "../../images/location-new.jpg";
import resetLocation from "../../images/reset-location.jpg";

const BajajPrincipleApproval = () => {
    const api_url = config.API_URL;
    const BAJAJ_API_URL = config.BAJAJ_API_URL;
    const navigate = useNavigate();
    const { search } = useLocation();
    var urls = search;
    var myurls = urls.split("?signature=");
    var q = myurls[1];
    const [userToken, setToken] = useState(sessionStorage.getItem('token'));
    const [approve_amount, setApprovedAmount] = useState(sessionStorage.getItem("approve_amount"));
    const [locate_permission, setLocatePermission] = useState('');
    const [locationInstruction, setLocationInstruction] = useState(false);
    const [locationPopup, setLocationPopup] = useState(false);
    const [fetchLocation, setFetchLocation] = useState(false);
    const [locationError, setLocationError] = useState(false);
    const [locationErrorPopUp, setLocationErrorPopUp] = useState(false);
    const latitude = sessionStorage.getItem("latitude");
    const longitude = sessionStorage.getItem("longitude");
    const [lat,setLatitude] = useState(null);
    const [long,setLongitude] = useState(null);
    const {sanctionHtml, setsanctionHtml, logout, popupData, setPopUpData, checkStateByMiddileware, checkLocationPermission} = useContext(BureauContext);
    
    useEffect(() => {
        navigator.permissions
        .query({ name: "geolocation" })
        .then((permissionStatus) => {
          setLocatePermission(permissionStatus.state);
          permissionStatus.onchange = () => {
            setLocatePermission(permissionStatus.state);
          };
        });
        getUserDetail();
        checkLocationOnRegister();
    },[]);

    const checkLocationOnRegister = async() => {
        navigator.permissions
        .query({ name: "geolocation" })
        .then((permissionStatus) => {
            sessionStorage.setItem("locate_permission" , permissionStatus.state);
            if(permissionStatus.state === 'granted'){
                setLocationInstruction(false);
                setFetchLocation(false);
                setLocationError(false);
                setLocationErrorPopUp(false);
                var timeoutVal = 10 * 1000 * 1000;
                navigator.geolocation.watchPosition(displayPosition, displayError,{ enableHighAccuracy: true, timeout: timeoutVal, maximumAge: 0 });
            }else if(permissionStatus.state === 'denied'){
                setLocationInstruction(false);
                setFetchLocation(false);
                setLocationError(true);
                setLocationErrorPopUp(false);
              }else{
                setLocationInstruction(true);
                setFetchLocation(false);
                setLocationError(false);
                setLocationErrorPopUp(false);
             }
            permissionStatus.onchange = () => {
              sessionStorage.setItem("locate_permission" , permissionStatus.state);
              if(permissionStatus.state === 'granted'){
                setLocationInstruction(false);
                setFetchLocation(false);
                setLocationError(false);
                setLocationErrorPopUp(false);
                var timeoutVal = 10 * 1000 * 1000;
                navigator.geolocation.watchPosition(displayPosition, displayError,{ enableHighAccuracy: true, timeout: timeoutVal, maximumAge: 0 });
              }else if(permissionStatus.state === 'denied'){
                setLocationInstruction(false);
                setFetchLocation(false);
                setLocationError(true);
                setLocationErrorPopUp(false);
              }else{
                setLocationInstruction(true);
                setFetchLocation(false);
                setLocationError(false);
                setLocationErrorPopUp(false);
             }
            };
          }); 
    }

    function allowDeviceLocation(){
        navigator.permissions
        .query({ name: "geolocation" })
        .then((permissionStatus) => {
            if(permissionStatus.state === 'granted'){
                sessionStorage.setItem("locate_permission" , permissionStatus.state);
                var timeoutVal = 10 * 1000 * 1000;
                navigator.geolocation.watchPosition(displayPosition, displayError,{ enableHighAccuracy: true, timeout: timeoutVal, maximumAge: 0 });
            }else if(permissionStatus.state === 'denied'){
                var timeoutVal = 10 * 1000 * 1000;
                navigator.geolocation.watchPosition(displayPosition, displayError, { enableHighAccuracy: true, timeout: timeoutVal, maximumAge: 0 });
                setLocationInstruction(false);
                setFetchLocation(false);
                setLocationError(true);
                setLocationErrorPopUp(false);
            }else{
                var timeoutVal = 10 * 1000 * 1000;
                navigator.geolocation.watchPosition(displayPosition, displayError, { enableHighAccuracy: true, timeout: timeoutVal, maximumAge: 0 });
                setLocationInstruction(true);
                setFetchLocation(false);
                setLocationError(false);
                setLocationErrorPopUp(false);
            }
            permissionStatus.onchange = () => {
                sessionStorage.setItem("locate_permission" , permissionStatus.state);
                if(permissionStatus.state === 'granted'){
                    var timeoutVal = 10 * 1000 * 1000;
                    navigator.geolocation.watchPosition(displayPosition, displayError, { enableHighAccuracy: true, timeout: timeoutVal, maximumAge: 0 });
                }else if(permissionStatus.state === 'denied'){
                    setLocationInstruction(false);
                    setFetchLocation(false);
                    setLocationError(true);
                    setLocationErrorPopUp(false);
                    sessionStorage.setItem('latitude','');
                    sessionStorage.setItem('longitude','');
                }else{
                    var timeoutVal = 10 * 1000 * 1000;
                    navigator.geolocation.watchPosition(displayPosition, displayError, { enableHighAccuracy: true, timeout: timeoutVal, maximumAge: 0 });
                    setLocationInstruction(true);
                    setFetchLocation(false);
                    setLocationError(false);
                    setLocationErrorPopUp(false);
                    sessionStorage.setItem('latitude','');
                    sessionStorage.setItem('longitude','');
                }
            };
          });
          setLocationPopup(false);
    }
    const closeLocationError = async() => {
        setLocationInstruction(false);
        setFetchLocation(false);
        setLocationError(true);
        setLocationErrorPopUp(false)
        setLocationPopup(false);

    }

    function displayPosition(position) {
        if(position.coords.latitude === '' || position.coords.longitude === ''){
            setFetchLocation(true);
        }
        setLatitude(position.coords.latitude);
        setLongitude(position.coords.longitude);
        sessionStorage.setItem("latitude" , position.coords.latitude);
        sessionStorage.setItem("longitude" , position.coords.longitude);
    }
    
    function displayError(error) {
        
        if(error.code === 2 || error.code === 3){
            setFetchLocation(true);
        }
        var errors = {
          1: 'Permission denied',
          2: 'Position unavailable',
          3: 'Request timeout'
        };
    }
    
    const proceedPrincipalApproval = async () => {
        const latitude = sessionStorage.getItem('latitude');
        const longitude = sessionStorage.getItem('longitude');
        if(locationInstruction === true){
            setLocationPopup(true);
        }else{
           if(fetchLocation === false){
             if(locationError === false){
                const headers =  {
                    'Authorization' : `Bearer ${userToken}`,
                    'content-type' : 'application/json',
                    'latlong' : `${latitude},${longitude}`,
                };

                const request_data = {
                    lat_long: `${latitude},${longitude}`,
                    signature : q,
                }
                const encrypted = encryptData(request_data);
                const requestOptions = {
                    method: 'POST',
                    headers: headers,
                    body: JSON.stringify({encrypted : encrypted})
                };
                document.getElementById('waitingLoader').style.display = 'block';
                fetch(BAJAJ_API_URL+`/proceed-bajaj-principal-approval`,requestOptions).then((res) => {
                    return res.json();
                }).then((result) => {
                    document.getElementById('waitingLoader').style.display = 'none';
                    if(result.status === 'token'){
                        sessionStorage.clear();
                        navigate('/login');
                    }else if(result.status === 'expired'){
                        document.getElementById('waitingLoader').style.display = 'none';
                        setPopUpData({...popupData,is_bureau_checked : true});
                    }else if(result.status === "hold") {
                        document.getElementById('waitingLoader').style.display = 'none';
                        setPopUpData({...popupData,is_bank_hold : true});
                    }else if(result.status === 'success'){
                        document.getElementById('waitingLoader').style.display = 'none';
                        navigate('/kyc-document');
                    }else if (result.status === 'fail' && result.is_link === 'expired') {
                        document.getElementById('waitingLoader').style.display = 'none';
                        toast.error(result.errors, {
                            position: toast.POSITION.TOP_CENTER
                        });
                        navigate('/login');
                    }else if (result.status === 'fail' && result.is_rejected === true) {
                        document.getElementById('waitingLoader').style.display = 'none';
                        if(result.hasOwnProperty('data')){
                            sessionStorage.setItem('status_flag', result.data.appStatus.comment)
                        }
                        navigate("/regrate");
                    }else{
                        document.getElementById('waitingLoader').style.display = 'none';
                        toast.error(result.errors, {
                            position: toast.POSITION.TOP_CENTER,
                        });
                    }
                }).catch((err) => {
                    document.getElementById('waitingLoader').style.display = 'none';
                    toast.error(err, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                })
            }else{
                setLocationErrorPopUp(true);
            }
        }else{
            toast.error("Please allow your device location", {
                position: toast.POSITION.TOP_CENTER,
            });
        }
    }
    }

    const getUserDetail = () => {
        document.getElementById('waitingLoader').style.display = 'block';
        axios.get(BAJAJ_API_URL +`/get-user-detail?signature=${q}`).then((response) => {
            if (response.data.status === 'success') {
                document.getElementById('waitingLoader').style.display = 'none';
                sessionStorage.setItem("approve_amount",response.data.data.appData.PreQualifiedAmount);
                setApprovedAmount(response.data.data.appData.PreQualifiedAmount);
                sessionStorage.setItem('token', response.data.data.token);
                setToken(response.data.data.token);
                sessionStorage.setItem('userData', JSON.stringify(response.data.data.user));
                sessionStorage.setItem('lafId', response.data.data.appData.laf_id);
                sessionStorage.setItem("product_code", response.data.data.appData.product_code);
            }else if (response.data.status === 'fail' && response.data.is_rejected === true) {
                document.getElementById('waitingLoader').style.display = 'none';
                if(response.data.hasOwnProperty('data')){
                 sessionStorage.setItem('status_flag', response.data.data.appStatus.comment)
                }
                navigate("/regrate");
            }else if (response.data.status === 'fail' && response.data.is_link === 'expired') {
                document.getElementById('waitingLoader').style.display = 'none';
                toast.error(response.data.errors, {
                    position: toast.POSITION.TOP_CENTER
                });
                navigate('/login');
            }else if (response.data.status === "token") {
                document.getElementById('waitingLoader').style.display = 'none';
                toast.error(response.data.errors);
                sessionStorage.clear();
                navigate('/login');
            }else {
                document.getElementById('waitingLoader').style.display = 'none';
                toast.error(response.data.errors, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
        }).catch((err) => {
            document.getElementById('waitingLoader').style.display = 'none';
            toast.error(err, {
                position: toast.POSITION.TOP_CENTER,
            });
        })
    }

    return (
        <>
            <ToastContainer />
            <div className="mt-2">
                <Heading Description="U GRO Capital can help you with your business finance" BadgeDescription="Let us sign you up" />
            </div>
            <Layout ImageSide="Register">
                <div className="principle">
                    <h4 className="text-center">In Principle Approval</h4>
                    <p className="text-center">Congratulations! You are eligible to get pre approved amount up to <b> ₹ {approve_amount}</b>. Now you can proceed with KYC Verification.</p>
                    <div className="text-center">
                     <CustomButton clickBtn={() => { proceedPrincipalApproval() }} btnname="Continue" btn_col="col-md-4"/>
                    </div>
                </div>
            </Layout>

            {locationPopup &&
            <div className="modal-center">
                <div className={`modal modal-background fade show`} id="exampleModal" tabIndex={-1} style={{ display: "block" }}>
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-body pt-4">
                               <div className="text-center col-md-10 mx-auto"><strong>Please Allow Your Device Location.</strong></div>
                               </div>
                            <div className="modal-footer justify-content-center border-0 pt-0">
                               <img src={locationImage} />
                            </div>
                            <div className="modal-footer justify-content-center border-0 pt-0">
                                <button type="button" className="btn btn-primary" onClick={()=>{ allowDeviceLocation(); }}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        }
        {fetchLocation &&
            <div className="modal-center">
            <div className={`modal modal-background fade show`} id="exampleModal" tabIndex={-1} style={{ display: "block" }}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body pt-4">
                               <div className="text-center col-md-10 mx-auto">Sorry, we are facing a technical issue. Please try again after some time..</div>
                            </div><br/>
                        </div>
                    </div>
                </div>
            </div> 
        }

    {locationErrorPopUp &&
            <div className="modal-center">
            <div className={`modal modal-background fade show`} id="exampleModal" tabIndex={-1} style={{ display: "block" }}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body pt-4">
                        <div className="text-center col-md-10 mx-auto"><strong>Your location seems to be blocked. Please Allow to continue.</strong></div>
                               <div className="text-center col-md-10 mx-auto"><img src={resetLocation} /></div>
                            </div><br/>

                            <div className="modal-footer justify-content-center border-0 pt-0">
                                <button type="button" className="btn btn-primary" onClick={()=>{ closeLocationError(); }}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        }
        </>
    );
}
export default BajajPrincipleApproval;