import React, { useState, useEffect, useContext } from "react";
import AvatarImg from "../../common-components/AvatarImg";
import Input from "../../common-components/Input";
import Textarea from "../../common-components/Textarea";
import DetailsCard from "./DetailsCard";
import config from "../../../config.json";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import PageFooter from "../../common-components/PageFooter";
import { compareAsc, format } from 'date-fns';
import { BureauContext } from "../../Context/BureauContext";
import DownImg from "../../../images/down.gif";
import PDFIcon from "../../../images/pdf_512.png";
import { encryptData } from "../../../encrypt";

const SummaryInput = () => {
    const navigate = useNavigate();
    const [applicationSummary, setApplicationSummary] = useState({});
    const [udyamAssist, setUdyamAssist] = useState({});
    const [udyamInfo, setUdyamInfo] = useState({});
    const [udyamProofInfo, setUdyamProofInfo] = useState({});
    const [businessEntityProofInfo, setbusinessEntityInfo] = useState({});
    const [gstInfo, setGstInfo] = useState('');
    const [businessEntityInfo, setBusinessEntityInfo] = useState({});
    const api_url = config.API_URL;
    const usertoken = sessionStorage.getItem('token');
    const [disablebtn, setDisableBtn] = useState(false);
    const { popupData, setPopUpData, checkStateByMiddileware} = useContext(BureauContext);
    const [scroll, setScroll] = useState(4000);
    const latitude = sessionStorage.getItem("latitude");
    const longitude = sessionStorage.getItem("longitude");

    useEffect(() => {
        getSummeryData();
    }, []);

    const getSummeryData = () => {
        checkStateByMiddileware();
        const headers = {
            'Authorization': `Bearer ${usertoken}`,
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'latlong' : `${latitude},${longitude}`,
        }
        document.getElementById('waitingLoader').style.display = 'block';
        axios.get(api_url + `/summary`, { headers })
            .then((response) => {
                document.getElementById('waitingLoader').style.display = 'none';
                if (response.data.status == 'success') {
                    setDisableBtn(false);
                    setApplicationSummary(response.data.data);
                    setUdyamAssist(response.data.data.udyam_assist);
                    setUdyamProofInfo(response.data.data.udyamProofData);
                    setbusinessEntityInfo(response.data.data.BeProofData);
                    if(response.data.data.owner.is_udyam_skipped === 0){
                        setUdyamInfo(response.data.data.udyam);
                    }
                    if(response.data.data.owner.is_gst_skipped === 0){
                        setGstInfo(response.data.data.appBiz.gstin);
                    }
                    if(response.data.data.owner.is_beproof_skipped === 0){
                        setBusinessEntityInfo(response.data.data.BizEntity);
                    }
                }
                else if (response.data.status === "token") {
                    toast.error(response.data.errors);
                    sessionStorage.clear();
                    navigate('/login');
                }
                else if (response.data.status === "expired") {
                    document.getElementById('waitingLoader').style.display = 'none';
                    setPopUpData({...popupData,is_bureau_checked : true});
                }else if (response.data.status === 'hold') {
                    setPopUpData({...popupData,is_bank_hold : true});
                }else if (response.data.status === 'fail' && response.data.is_rejected === true) {
                    document.getElementById('waitingLoader').style.display = 'none';
                    if(response.data.hasOwnProperty('data')){
                        sessionStorage.setItem('status_flag', response.data.data.appStatus.comment)
                    }
                    navigate("/regrate");
                }else {
                    document.getElementById('waitingLoader').style.display = 'none';
                    toast.error(response.data.errors, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            })
            .catch(error => {
                document.getElementById('waitingLoader').style.display = 'none';
                toast.error(error.message, {
                    position: toast.POSITION.TOP_CENTER
                });
            });
    }

    const review = () => {
        checkStateByMiddileware();
        const headers = {
            'Authorization': `Bearer ${usertoken}`,
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'latlong' : `${latitude},${longitude}`,
        }
        document.getElementById('waitingLoader').style.display = 'block';
        axios.post(api_url + `/review-summary`, '', { headers })
            .then((response) => {
                document.getElementById('waitingLoader').style.display = 'none';
                if (response.data.status == 'success') {
                    navigate("/review-offer");
                }else if (response.data.status === 'fail' && response.data.is_rejected === true) {
                    document.getElementById('waitingLoader').style.display = 'none';
                    if(response.data.hasOwnProperty('data')){
                        sessionStorage.setItem('status_flag', response.data.data.appStatus.comment)
                    }
                    navigate("/regrate");
                }else if (response.data.status === 'hold') {
                    setPopUpData({...popupData,is_bank_hold : true});
                }else if (response.data.status === "token") {
                    toast.error(response.data.errors);
                    sessionStorage.clear();
                    navigate('/login');
                } else if (response.data.status === "expired") {
                    document.getElementById('waitingLoader').style.display = 'none';
                    setPopUpData({...popupData,is_bureau_checked : true});
                } else {
                    document.getElementById('waitingLoader').style.display = 'none';
                    toast.error(response.data.errors, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            })
            .catch(error => {
                document.getElementById('waitingLoader').style.display = 'none';
                toast.error(error.message, {
                    position: toast.POSITION.TOP_CENTER
                });
            });
    }
    const goToDown = (val) => {
        const scrollmodal = document.querySelector('.modal-body');
        scrollmodal.scrollTo({
            top: val,
            left: 0,
            behavior: 'smooth'
        })
        setScroll(val + 4000);
    };
    return (
        <>
            <ToastContainer />
            <div className="shadow-sm downscroll">
             <div className="text-end card-header border-0 bg-transparent mb-3" onClick={() => goToDown(scroll)} > 
             <img src={DownImg} alt="Down arrow" width={25} className="cursor"/></div>
             <div className="card-body modal-body acceptanceheight ">
            <DetailsCard Heading="Contact Details" CollapseId="IdOne" bradius="top-border-radius"> 
                <div className="row summeryData">
                    <div className="col-md-6 col-sm-12">
                        <Input LabelName="Phone No " type="tel" Id="Phone_No" inputval={(Object.keys(applicationSummary).length > 0 ? applicationSummary.owner.mobile_no : '')} placeholder="Phone No" disabled />
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <Input LabelName="E-mail ID" type="email" Id="E-mail_ID" inputval={(Object.keys(applicationSummary).length > 0 ? applicationSummary.owner.email : '')} placeholder="E-mail ID" disabled />
                    </div>
                </div>
            </DetailsCard>
            {/* <p className="my-2 border-dashed-orange" /> */}
            <DetailsCard Heading="PAN Details" CollapseId="IdTwo" bradius="rounded-0 border-top-0">
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <Input LabelName="PAN " type="text" Id="PAN" inputval={(Object.keys(applicationSummary).length > 0 ? applicationSummary.owner.pan_no : '')} placeholder="PAN" disabled />
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <Input LabelName="Name" type="text" Id="Name" inputval={(Object.keys(applicationSummary).length > 0 ? applicationSummary.owner.f_name + ' ' + applicationSummary.owner.l_name : '')} placeholder="Name" disabled />
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <Input LabelName="DOB" type="text" Id="DOB" inputval={(Object.keys(applicationSummary).length > 0 ? format(new Date(applicationSummary.owner.dob), 'dd-MM-yyyy') : '')} placeholder="DOB" disabled />
                    </div>
                </div>
            </DetailsCard>
            {/* <p className="my-2 border-dashed-orange" /> */}
            <DetailsCard Heading="Aadhaar Details" CollapseId="IdThree" bradius="rounded-0 border-top-0">
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <Textarea Placeholder="Address" Label="Address" inputval={(Object.keys(applicationSummary).length > 0 ? applicationSummary.aadhaarInfo.address_aadhaar : '')} disabled />
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <Input LabelName="Masked Aadhaar Number" type="text" Id="Masked_Aadhaar_number" inputval={(Object.keys(applicationSummary).length > 0 ? applicationSummary.aadhaarInfo.aadhaar_number : '')} placeholder="Masked Aadhaar Number" disabled />
                    </div>
                </div>
            </DetailsCard>
            {/* <p className="my-2 border-dashed-orange" /> */}
            <DetailsCard Heading="Individual Photo" CollapseId="IdFour" bradius="rounded-0 border-top-0">
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <AvatarImg selfieUrl={(Object.keys(applicationSummary).length > 0 ? applicationSummary.selfieUrl : '')} name={(Object.keys(applicationSummary).length > 0 ? applicationSummary.selfieName : '')} />
                    </div>
                </div>
            </DetailsCard>
          {
            (Object.keys(udyamInfo).length > 0) && 
                <DetailsCard Heading="UDYAM Details" CollapseId="IdSix" bradius="rounded-0 border-top-0">
                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <Input LabelName="UDYAM Number" type="text" Id="udyam_number" placeholder="UDYAM Number" inputval={(Object.keys(udyamInfo).length > 0  ? udyamInfo.udyog_arn : '')} disabled />
                        </div>
                        {
                        Object.keys(udyamProofInfo).length > 0 &&
                            <div className="col-md-6 col-sm-12">
                                <AvatarImg selfieUrl={udyamProofInfo.ext !== 'pdf' ? udyamProofInfo.udyamCertificateUrl : PDFIcon} name={udyamProofInfo.Name} />
                            </div>
                        }
                        <div className="col-md-6 col-sm-12">
                            <Input LabelName="Industry type (NIC)" type="text" Id="Industry_type_(NIC)" inputval={(Object.keys(applicationSummary).length > 0 && applicationSummary.industryType != null ? applicationSummary.industryType.name : '')} placeholder="Industry type (NIC)" disabled />
                        </div>
                    </div>
                </DetailsCard>
          }
           
            <DetailsCard Heading="General Information" CollapseId="IdSeven" bradius="rounded-0 border-top-0">
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <Input LabelName="Number of years in the Current Address " type="text" Id="years_Current_Address" placeholder="Number of years in the Current Address" disabled inputval={(Object.keys(applicationSummary).length > 0 ? applicationSummary.individualInfo.no_of_years_in_curr_address : '')} />
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <Input LabelName="Status of Current Address" type="text" Id="Status_of_Current_Address" placeholder="Status of Current Address" disabled inputval={(Object.keys(applicationSummary).length > 0 ? applicationSummary.individualInfo.status_of_curr_address : '')} />
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <Input LabelName="Mother's Maiden Name" type="text" Id="Mother_Maiden_Name" placeholder="Mother's Maiden Name" disabled inputval={(Object.keys(applicationSummary).length > 0 ? applicationSummary.individualInfo.mother_maiden_name : '')} />
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <Input LabelName="Marital Status" type="text" Id="Marital_Status" placeholder="Marital Status" disabled inputval={(Object.keys(applicationSummary).length > 0 ? applicationSummary.individualInfo.marital_status : '')} />
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <Input LabelName="Gender" type="text" Id="Gender" placeholder="Gender" disabled inputval={(Object.keys(applicationSummary).length > 0 ? applicationSummary.owner.gender : '')} />
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <Input LabelName="Phone Number" type="text" Id="Phone_Number" placeholder="Phone Number" disabled inputval={(Object.keys(applicationSummary).length > 0 ? applicationSummary.user.mobile_no : '')} />
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <Input LabelName="Eduction Qualification" type="text" Id="Eduction_Qualification" placeholder="Eduction Qualification" disabled inputval={(Object.keys(applicationSummary).length > 0 ? applicationSummary.individualInfo.education_qualification : '')} />
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <Input LabelName="Nature of Business" type="text" Id="Nature_of_Busniess" placeholder="Nature of Business" disabled inputval={(Object.keys(applicationSummary).length > 0 ? applicationSummary.individualInfo.nature_of_business : '')} />
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <Input LabelName="Business type" type="text" Id="Business_type" placeholder="Business type" disabled inputval={(Object.keys(applicationSummary).length > 0 ? applicationSummary.type_of_business : '')} />
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <Input LabelName="Number of years in Business" type="text" Id="Numberofyears_Business" placeholder="Number of years in Business" disabled inputval={(Object.keys(applicationSummary).length > 0 ? applicationSummary.BizEntity.no_of_years_in_business : '')} />
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <Input LabelName="Number of years in Business at Current Address" type="text" Id="Number_years_Current Address" placeholder="Number of years in Business at Current Address" disabled inputval={(Object.keys(applicationSummary).length > 0 ? applicationSummary.BizEntity.business_years_in_curr_location : '')} />
                    </div>
                </div>
            </DetailsCard>
           {
            (gstInfo !== '' && gstInfo !== null && gstInfo !== undefined) && 
                <DetailsCard Heading="GSTIN Details" CollapseId="IdEight" bradius="rounded-0 border-top-0" >
                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <Input LabelName="GSTIN" type="text" Id="GSTIN" placeholder="GSTIN" disabled inputval={gstInfo} />
                        </div>
                    </div>
                </DetailsCard>
           }
            {
               (Object.keys(businessEntityInfo).length > 0) && 
                <DetailsCard Heading="Business Entity Proof" CollapseId="IdNine" bradius="rounded-0 border-top-0">
                    <div className="row">
                        {
                            Object.keys(businessEntityProofInfo).length > 0 &&
                            <div className="col-md-6 col-sm-12">
                                <AvatarImg selfieUrl={businessEntityProofInfo.ext !== 'pdf' ? businessEntityProofInfo.BeProofUrl : PDFIcon} name={businessEntityProofInfo.Name} />
                            </div>
                                                
                        }
                        <div className="col-md-6 col-sm-12">
                            <Input LabelName="Business Registration Number" type="text" Id="Business_registration_number" placeholder="Business Registration Number" disabled inputval={(Object.keys(businessEntityInfo).length > 0 ? businessEntityInfo.be_proof_number : '')} />
                        </div>
                    </div>
                </DetailsCard>
            }
            <DetailsCard Heading="Bank Details" CollapseId="Id11" bradius="bottom-border-radius border-top-0">
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <Input LabelName="Account Number" type="text" Id="Account_Number" placeholder="Account Number" inputval={(Object.keys(applicationSummary).length > 0 ? applicationSummary.bankDetail.bank_account_number : '')} disabled />
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <Input LabelName="Account Holder’s Name" type="text" Id="Account_Holder_Name" placeholder="Account Holder’s Name" inputval={(Object.keys(applicationSummary).length > 0 ? applicationSummary.bankDetail.account_holder_name : '')} disabled />
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <Input LabelName="IFSC" type="text" Id="IFSC" placeholder="IFSC" disabled inputval={(Object.keys(applicationSummary).length > 0 ? applicationSummary.bankDetail.bank_ifcs : '')} />
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <Input LabelName="Account Type" type="text" Id="Account_Type" placeholder="Account Type" disabled inputval={(Object.keys(applicationSummary).length > 0 ? applicationSummary.bankDetail.account_type : '')} />
                    </div>
                </div>
            </DetailsCard>  
            {
                (Object.keys(udyamAssist).length > 0) ? 
               <DetailsCard Heading="Udyam Assist Data" CollapseId="Id11" bradius="bottom-border-radius border-top-0">
                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <Input LabelName="Business Name" type="text" Id="Account_Number" placeholder="Business Name" inputval={(Object.keys(udyamAssist).length > 0 ? udyamAssist.business_name : '')} disabled />
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <Input LabelName="Address 1" type="text" Id="Account_Holder_Name" placeholder="Address 1" inputval={(Object.keys(udyamAssist).length > 0 ? udyamAssist.address_1 : '')} disabled />
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <Input LabelName="Address 2" type="text" Id="IFSC" placeholder="Address 2" disabled inputval={(Object.keys(udyamAssist).length > 0 ? udyamAssist.address_2 : '')} />
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <Input LabelName="Address 3" type="text" Id="Account_Type" placeholder="Address 3" disabled inputval={(Object.keys(udyamAssist).length > 0 ? udyamAssist.address_3 : '')} />
                        </div>
    
                        <div className="col-md-6 col-sm-12">
                            <Input LabelName="Pincode" type="text" Id="Account_Type" placeholder="Pincode" disabled inputval={(Object.keys(udyamAssist).length > 0 ? udyamAssist.pincode : '')} />
                        </div>
    
                        <div className="col-md-6 col-sm-12">
                            <Input LabelName="City" type="text" Id="Account_Type" placeholder="City" disabled inputval={(Object.keys(udyamAssist).length > 0 ? udyamAssist.city : '')} />
                        </div>
    
                        <div className="col-md-6 col-sm-12">
                            <Input LabelName="State" type="text" Id="Account_Type" placeholder="State" disabled inputval={(Object.keys(udyamAssist).length > 0 ? udyamAssist.state : '')} />
                        </div>
    
                        <div className="col-md-6 col-sm-12">
                            <Input LabelName="District" type="text" Id="Account_Type" placeholder="District" disabled inputval={(Object.keys(udyamAssist).length > 0 ? udyamAssist.district : '')} />
                        </div>
    
                        <div className="col-md-6 col-sm-12">
                            <Input LabelName="Date Of Incorporation" type="text" Id="Account_Type" placeholder="Date Of Incorporation" disabled inputval={((Object.keys(udyamAssist).length > 0 && udyamAssist.doi !== '' && udyamAssist.doi !== null && udyamAssist.doi !== undefined) ? format(new Date(udyamAssist.doi), 'dd-MM-yyyy') : '')} />
                        </div>
    
    
                        <div className="col-md-6 col-sm-12">
                            <Input LabelName="Social Category" type="text" Id="Account_Type" placeholder="Social Category" disabled inputval={(Object.keys(udyamAssist).length > 0 ? udyamAssist.social_category : '')} />
                        </div>
    
                        <div className="col-md-6 col-sm-12">
                            <Input LabelName="Type Of Organization" type="text" Id="Account_Type" placeholder="Type Of Organization" disabled inputval={(Object.keys(udyamAssist).length > 0 ? udyamAssist.type_of_organization : '')} />
                        </div>
    
                        <div className="col-md-6 col-sm-12">
                            <Input LabelName="Major of Activity Unit" type="text" Id="Account_Type" placeholder="Major of Activity Unit" disabled inputval={(Object.keys(udyamAssist).length > 0 ? udyamAssist.major_activity_of_unit : '')} />
                        </div>
    
                        <div className="col-md-6 col-sm-12">
                        <Textarea Placeholder="Bsr Code" Label="Bsr Code" inputval={(Object.keys(udyamAssist).length > 0 ? udyamAssist.bsr_code : '')} disabled />
                        </div>
    
                        <div className="col-md-6 col-sm-12">
                            <Input LabelName="No Of Employee" type="text" Id="Account_Type" placeholder="No Of Employee" disabled inputval={(Object.keys(udyamAssist).length > 0 ? udyamAssist.no_of_employees : '')} />
                        </div>
                    </div>
                </DetailsCard> : null
            }
            </div>
            </div>
            <PageFooter setDisabled={disablebtn} BtnName="I SUBMIT" TermsCondition="By submitting, I allow U GRO to assess my application" ButtonClick={() => review()} review />
        </>
    );
}
export default SummaryInput;