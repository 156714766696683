import React, { useState, useEffect, useContext } from "react";
import Heading from "../common-components/Heading";
import Layout from "../common-components/Layout";
import andriodQR from "../../images/dtocweb_images/Android_GRO X_QR.jpeg";
import IphoneQR from "../../images/dtocweb_images/IOS _GRO X_QR.jpeg";
import Rating from "./components/Rating";
import config from "../../config.json";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { BureauContext } from "../Context/BureauContext";

const JourneySuccess = () => {
    const api_url = config.API_URL;
    const usertokenj = sessionStorage.getItem('token');
    const [lan_no, setLanNo] = useState('');
    const [promo_code, setPromocode] = useState('');
    const [isPreApproved, setisPreApproved] = useState(0);
    const [rating, setRating] = useState(0);
    const [hover, setHover] = useState(0);
    const navigate = useNavigate();
    const {popupData, setPopUpData, checkStateByMiddileware, checkLocationPermission} = useContext(BureauContext);
    const latitude = sessionStorage.getItem("latitude");
    const longitude = sessionStorage.getItem("longitude");

    useEffect(() => {
        if(checkLocationPermission()){
            checkStateByMiddileware();
            const headers = {
                'Authorization': `Bearer ${usertokenj}`,
                'latlong' : `${latitude},${longitude}`,
            }
            document.getElementById('waitingLoader').style.display = 'block';
            axios.get(api_url + `/journey-success`,{ headers })
                .then((response) => {
                    if (response.data.status === 'success') {
                        document.getElementById('waitingLoader').style.display = 'none';
                        setLanNo(response.data.data.appData.lan_no ? response.data.data.appData.lan_no : '734280100003745');
                        setHover(response.data.data.appData.rating_onboarding_jour);
                        setRating(response.data.data.appData.rating_onboarding_jour);
                        setisPreApproved(response.data.data.appData.is_pre_approved);
                        setPromocode(response.data.data.appData.product_code ? response.data.data.appData.product_code : 'PROMOCODE')
                    }
                    else if(response.data.status === "token") {
                        document.getElementById('waitingLoader').style.display = 'none';
                        toast.error(response.data.errors);
                        sessionStorage.clear();
                        navigate('/login');
                    }else if(response.data.status === "expired") {
                        document.getElementById('waitingLoader').style.display = 'none';
                        setPopUpData({...popupData,is_bureau_checked : true});
                    }else if(response.data.status === "hold") {
                        document.getElementById('waitingLoader').style.display = 'none';
                        setPopUpData({...popupData,is_bank_hold : true});
                    }else if (response.data.status === 'fail' && response.data.is_rejected === true) {
                        document.getElementById('waitingLoader').style.display = 'none';
                        if(response.data.hasOwnProperty('data')){
                    sessionStorage.setItem('status_flag', response.data.data.appStatus.comment)
                }
                        navigate("/regrate");
                    }else{
                        document.getElementById('waitingLoader').style.display = 'none';
                        toast.error(response.data.errors,{
                            position: toast.POSITION.TOP_CENTER
                        });
                    }
                }).catch(error => {
                    document.getElementById('waitingLoader').style.display = 'none';
                    toast.error(error.message,{
                    position: toast.POSITION.TOP_CENTER
                    });
                });
            }else{
                toast.error("Please allow your device location", {
                    position: toast.POSITION.TOP_CENTER
                });
            }
    }, []);
    
    return (
        <>
            <ToastContainer />
            <div className="pt-3">
                <Heading Description="You are all set now to fuel your business growth" BadgeDescription="U GRO is there with you in your journey" user />
            </div>
            <Layout ImageSide="Product-intro">
                <h4 className="text-center mb-4">Thank you for being our valued customer. We are so grateful and hope we met your expectations.</h4>
                <h6 className="text-center fw-normal">Your LAN No is <strong>{lan_no}</strong>.</h6>
                {
                    (isPreApproved === 1 && promo_code === "DIRECT") ? 
                    <h6 className="text-center fw-normal">Your Promo Code is <strong>DRCT</strong>.</h6>
                    : null
                }
                <div className="col-md-8 col-sm-12 mx-auto mt-4">
                    <p className="text-center">Download GRO X Application to utilize your limit</p>
                </div>
                <div className="col-md-12 col-sm-12">
                    <div className="d-flex justify-content-center gap-3">
                        <div className="col-md-3 text-center">
                            <img src={andriodQR} alt="" height="100" width="100" title="GRO X" />
                            <p><small>Android</small> </p>
                        </div>
                        <div className="col-md-3 text-center">
                            <img src={IphoneQR} alt="" height="100" width="100" title="GRO X" />
                            <p><small>iOS</small> </p>
                        </div>
                    </div>
                </div>
                <Rating ratting={rating} hovers={hover}/>
            </Layout>
        </>
    );
}
export default JourneySuccess;