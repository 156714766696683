import React from "react";

const Select = ({ LabelName, isAstrix, SelectData, selected, name = '', preSelect='', disabled = false }) => {
    const astrix = <span className="required-field text-danger">*</span>;
    const select_value = SelectData.filter((values) => {
         values.id = values.id ? values.id : (values.code) ? values.code : values.Value;
        return values.id.includes(preSelect.toUpperCase());
    })
    return (
        <>
            <div className="mb-0">
                <label htmlFor="floatingSelect" className="form-label mb-0 fontsize-12 font-wt-600 text-orange">{LabelName} {isAstrix && astrix}</label>
                {
                    (select_value.length > 0) ? 
                    <select className="form-select" value={preSelect} onChange={selected} id="floatingSelect" disabled={disabled}>
                     {
                        Object.keys(SelectData).length > 1 && <option value="">--Select--</option>
                    }
                    {SelectData.map((key) => {
                        return (
                            <option value={key.id ? key.id : (key.code? key.code : key.Value)} key={key.id}>{key.Value}</option>
                        );
                    })}
                </select> :
                <select className="form-select" onChange={selected} id="floatingSelect" disabled={disabled}>
                    {
                        Object.keys(SelectData).length > 1 && <option value="">--Select--</option>
                    }
                    {SelectData.map((key) => {
                        return (
                            <option value={key.id ? key.id : (key.code? key.code : key.Value)} key={key.id}>{key.Value}</option>
                        );
                    })}
                </select>
            }
                    
            </div>
        </>
    );
}
export default Select;