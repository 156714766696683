import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Heading from "../common-components/Heading";
import Input from "../common-components/Input";
import Layout from "../common-components/Layout";
import PageFooter from "../common-components/PageFooter";
import Select from "../common-components/Select";
import { ToastContainer, toast } from 'react-toastify';
import config from "../../config.json";
import axios from "axios";
import { BureauContext } from "../Context/BureauContext";
import { encryptData } from "../../encrypt";

const BusinessQuestions = () => {

    const navigate = useNavigate();
    const [isDisabled, setDisabled] = useState(true);
    const [businessaddress, setBusinessAddress] = useState('');
    const [numberyearsbusiness, setNumberYearsBusiness] = useState('');
    const [numberyearscurrentaddress, setNumberYearsCurrentAddress] = useState('');
    const [preNatureOfBusiness, setpreNatureOfBusiness] = useState('');
    const api_url = config.API_URL;
    const [StatusofCurrentAddress, setStatusofCurrentAddress] = useState([]);
    const usertoken = sessionStorage.getItem('token');
    const [validation, setValidationError] = useState({businessAddressError: '', yearBusinessError: '',  CurrentAddressError: ''});
    const {popupData, setPopUpData, checkStateByMiddileware} = useContext(BureauContext);
    const latitude = sessionStorage.getItem("latitude");
    const longitude = sessionStorage.getItem("longitude");

    const onChangeNoOfYear = (e) => {
        const value = e.target.value.replace(/\D/g, "");
        const limit = 2;
        setNumberYearsBusiness(value.slice(0, limit));
        removeErrorSms(e,'yearBusinessError');
    }
    const onChnagesCurrentYear = (e) => {
        const value = e.target.value.replace(/\D/g, "");
        const limit = 2;
        setNumberYearsCurrentAddress(value.slice(0, limit));
        removeErrorSms(e,'CurrentAddressError');
    }
    const onChangeHandler = (e) => {
        setDisabled(false);
        setBusinessAddress(e.target.value);
        setpreNatureOfBusiness(e.target.value);
        removeErrorSms(e,'businessAddressError');
    }
    useEffect(() => {
        checkStateByMiddileware();
        const headers = {
            'Authorization': `Bearer ${usertoken}`,
           'latlong' : `${latitude},${longitude}`,
        }
        document.getElementById('waitingLoader').style.display = 'block';
        axios.get(api_url + `/business-question`, { headers })
            .then((response) => {
                document.getElementById('waitingLoader').style.display = 'none';
                if (response.data.status === 'success') {
                    setStatusofCurrentAddress(response.data.data);
                } 
                else if(response.data.status === "token") {
                    toast.error(response.data.errors);
                    sessionStorage.clear();
                    navigate('/login');
                }else if(response.data.status === "expired") {
                    document.getElementById('waitingLoader').style.display = 'none';
                    setPopUpData({...popupData,is_bureau_checked : true});
                }else if (response.data.status === 'fail' && response.data.is_rejected === true) {
                    document.getElementById('waitingLoader').style.display = 'none';
                    if(response.data.hasOwnProperty('data')){
                        sessionStorage.setItem('status_flag', response.data.data.appStatus.comment)
                    }
                    navigate("/regrate");
                }else {
                    document.getElementById('waitingLoader').style.display = 'none';
                    toast.error(response.data.errors, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            })
            .catch(error => {
                document.getElementById('waitingLoader').style.display = 'none';
                toast.error(error.message, {
                    position: toast.POSITION.TOP_CENTER
                });
            });
    }, [])


    const businessQuestionsSubmit = () => {
        checkStateByMiddileware();
        let checkValidate = true;
        let checkerror = {}
        if (businessaddress === '' || businessaddress === undefined) {
            checkerror['businessAddressError'] = "Please Select Business Address";
            checkValidate = false;
        }
        if (numberyearsbusiness === '' || numberyearsbusiness === undefined) {
            checkerror['yearBusinessError'] = "Please enter Number of years business";
            checkValidate = false;
        }
        if (numberyearscurrentaddress === '' || numberyearscurrentaddress === undefined) {
            checkerror['CurrentAddressError'] = "Enter Number of years Current Address";
            checkValidate = false;
        }
        setValidationError({ ...validation, ...checkerror });
        if (checkValidate) {
            const payload = {
                type_of_business: businessaddress,
                no_of_year_business: numberyearsbusiness,
                no_of_year_business_at_curr: numberyearscurrentaddress,
            }
            const encrypted = encryptData(payload);
            const headers = {
                'Authorization': `Bearer ${usertoken}`,
                'latlong' : `${latitude},${longitude}`,

            }
            document.getElementById('waitingLoader').style.display = 'block';
            axios.post(api_url + `/store_business-question`, {encrypted : encrypted}, { headers })
                .then((response) => {
                    document.getElementById('waitingLoader').style.display = 'none';
                    if (response.data.status === 'success') {
                        navigate('/address');
                    }
                    else if(response.data.status === "token") {
                        toast.error(response.data.errors);
                        sessionStorage.clear();
                        navigate('/login');
                    } else if(response.data.status === 'expired'){
                        document.getElementById('waitingLoader').style.display = 'none';
                        setPopUpData({...popupData,is_bureau_checked : true});
                    }else if (response.data.status === 'fail' && response.data.is_rejected === true) {
                        document.getElementById('waitingLoader').style.display = 'none';
                        if(response.data.hasOwnProperty('data')){
                            sessionStorage.setItem('status_flag', response.data.data.appStatus.comment)
                        }
                        navigate("/regrate");
                    }else {
                        document.getElementById('waitingLoader').style.display = 'none';
                        toast.error(response.data.errors, {
                            position: toast.POSITION.TOP_CENTER
                        });
                    }
                })
                .catch(error => {
                    document.getElementById('waitingLoader').style.display = 'none';
                    toast.error(error.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                });
        }
    }

    function removeErrorSms(e, fielName) {
        let checkerror = {}
        if (e.target.value.length > 0) {
            checkerror[fielName] = "";
            setValidationError({ ...validation, ...checkerror });
        }
    }
    return (
        <>
            <ToastContainer />
            <div className="pt-3">
                <Heading Description="U GRO Capital can help you with your business finance" BadgeDescription="Let us know a bit more about your business" user />
            </div>
            <Layout ImageSide="Login">
                <h4 className="text-center mb-4">Business Question</h4>
                <div className="col-md-9 mx-auto">
                    <Select LabelName="Nature of Business Address" preSelect={preNatureOfBusiness} isAstrix selected={(e) => onChangeHandler(e)} SelectData={StatusofCurrentAddress} />
                    {(validation.businessAddressError !== '') ?
                        <span className="text-danger fontsize-13">{validation.businessAddressError}</span>
                        : null
                    }
                    <Input LabelName="Number of years in Business" type="text" Id="Number_years_Business" placeholder="Number of years in Business" inputval={numberyearsbusiness} changeInput={(e) => onChangeNoOfYear(e)} />
                    {(validation.yearBusinessError !== '') ?
                        <span className="text-danger fontsize-13">{validation.yearBusinessError}</span>
                        : null
                    }
                    <Input LabelName="Number of years in Business at Current Address" type="text" Id="Number_years_Current_Address" placeholder="Number of years in Business at Current Address" inputval={numberyearscurrentaddress} changeInput={(e) => onChnagesCurrentYear(e)} />
                    {(validation.CurrentAddressError !== '') ?
                        <span className="text-danger fontsize-13">{validation.CurrentAddressError}</span>
                        : null
                    }
                </div>
                <PageFooter setDisabled={isDisabled} BtnName="I CONFIRM" TermsCondition="By confirming, I declare that these are my correct details" ButtonClick={() => { businessQuestionsSubmit() }} />
            </Layout>
        </>
    );
}
export default BusinessQuestions;