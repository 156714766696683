export const StatusofCurrentAddress = [
    {
        id:"1",
        Value:"SELF OWNED",
    },
    {
        id:"2",
        Value:"RENTED",
    },

     {
        id:"3",
        Value:"GOVT. ACCOMODATION",
    },
    {
        id:"4",
        Value:"COMPANY LEASE",
    },
    {
        id:"5",
        Value:"PARENTAL",
    },
    {
        id:"6",
        Value:"IN LAWS",
    },
    {
        id:"7",
        Value:"OTHERS",
    },
];

export const MaritalStatus = [
    {
        id:"1",
        Value:"MARRIED",
    },
    {
        id:"2",
        Value:"SINGLE",
    },{
        id:"3",
        Value:"SEPERATED",
    },
    {
        id:"4",
        Value:"DIVORCED",
    },
    {
        id:"5",
        Value:"WIDOWED",
    },
    {
        id:"6",
        Value:"OTHERS",
    }
];

export const Natureofbusiness=[
    {
        id:"1",
        Value:"MANUFACTURING",
    },
    {
        id:"2",
        Value:"SERVICES",
    },
    {
        id:"3",
        Value:"TRADING",
    }
];

export const EducationQualification = [
    {
        id:"1",
        Value:"ENGINEER",
    },
    {
        id:"2",
        Value:"MBA",
    },
    {
        id:"3",
        Value:"CA",
    },
];

export const AccountType = [
    {
        id:"CURRENT",
        Value:"Current Account",
    },
    {
        id:"SAVINGS",
        Value:"Savings Account",
    }
];

export const BankNameList = [
    {
        id:"1",
        Value:"STATE BANK OF INDIA",
    },
    {
        id:"2",
        Value:"HDFC BANK LTD",
    },
];

export const PaymentMethod = [
    {
        id:"all",
        Value:"NET BANKING",
    },
    {
        id:"all",
        Value:"DEBIT CARD",
    },
];


export const Salutation = [
    {
        id:"MESSR",
        Value:"MESSR",
    },
    {
        id:"MR",
        Value:"MR",
    },
    {
        id:"MS",
        Value:"MS",
    },
    {
        id:"MRS",
        Value:"MRS",
    },
];

export const Gender = [
    {
        id:"M",
        Value:"Male",
    },
    {
        id:"F",
        Value:"Female",
    },
    {
        id:"T",
        Value:"Others"
    }
];

export const Language = [
    {
        id:"https://groxd2cweb.ugrocapital.com",
        Value:"English",
    },
    {
        id:"https://hindi-groxd2cweb.ugrocapital.com",
        Value:"Hindi",
    },
    {
        id:"https://tamil-groxd2cweb.ugrocapital.com",
        Value:"Tamil"
    },
    {
        id:"https://telugu-groxd2cweb.ugrocapital.com",
        Value:"Telugu"
    }
];

export const socialCategory = [
    {
        id : "GENERAL",
        Value : "GENERAL"
    },
    {
        id : "OBC",
        Value : "OBC"
    },
    {
        id : "SC",
        Value : "SC"
    },
    {
        id : "ST",
        Value : "ST"
    },
];

export const activity = [
    {
        id : "MANUFACTURING",
        Value : "MANUFACTURING"
    },
    {
        id : "SERVICES",
        Value : "SERVICES"
    },
    {
        id : "TRADING",
        Value : "TRADING"
    }
];