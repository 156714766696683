import React from "react";

import { CustomButton } from "./Buttons";

const PageFooter = ({ setDisabled, BtnName, TermsCondition,ButtonClick,type }) => {


    return (
        <>
            <div className="text-center mt-4">
                <CustomButton type={type} isDisabled={setDisabled} btnname={BtnName} btn_col="col-md-4 mb-3" clickBtn={ButtonClick} />
                <p className="text-center fontsize-12 fw-bold col-md-8 mx-auto mb-0" >{TermsCondition}</p>
            </div>
        </>
    );
}
export default PageFooter;