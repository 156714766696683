import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CustomButton } from "../common-components/Buttons";
import Heading from "../common-components/Heading";
import Input from "../common-components/Input";
import Layout from "../common-components/Layout";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from "../../config.json";
import axios from "axios";
import { LoginButton } from "../common-components/Buttons";
import { encryptData } from "../../encrypt";
import locationImage from "../../images/location-new.jpg";
import resetLocation from "../../images/reset-location.jpg";
const Login = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams()
    const api_url = config.API_URL;
    const bajaj_api_url = config.BAJAJ_API_URL;
    const [otp, setOtp] = useState(false);
    const [mobile, setMobile] = useState('');
    const [mobileOtp, setMobileOtp] = useState('');
    const [isDisabled, setDisabled] = useState(false);
    const [jwtToken, setToken] = useState('');
    const [seconds, setSeconds] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [isActive, setIsActive] = useState(false);
    const [isOtpActive, setIsotpActive] = useState(false);
    const [disabled, setDisabledBtn] = useState(true);
    const [counterTime, setCounterTime] = useState(0);
    const [validation, setValidationError] = useState({ mobileNoError: '', otpError: '' });
    const [locate_permission, setLocatePermission] = useState('');
    const [locationInstruction, setLocationInstruction] = useState(false);
    const [locationPopup, setLocationPopup] = useState(false);
    const [fetchLocation, setFetchLocation] = useState(false);
    const [locationError, setLocationError] = useState(false);
    const [locationErrorPopUp, setLocationErrorPopUp] = useState(false);
    const latitude = sessionStorage.getItem("latitude");
    const longitude = sessionStorage.getItem("longitude");
    useEffect(() => {
        navigator.permissions
        .query({ name: "geolocation" })
        .then((permissionStatus) => {
          setLocatePermission(permissionStatus.state);
          permissionStatus.onchange = () => {
            setLocatePermission(permissionStatus.state);
          };
        });

        if(searchParams.get('singnature') !== null && searchParams.get('singnature') !== '' && searchParams.get('singnature') !== undefined){
            const signature = searchParams.get('singnature');
            applyBajaj(signature);
        }
        checkLocationOnRegister();
    }, []);

    const checkLocationOnRegister = async() => {
        navigator.permissions
        .query({ name: "geolocation" })
        .then((permissionStatus) => {
            sessionStorage.setItem("locate_permission" , permissionStatus.state);
            if(permissionStatus.state === 'granted'){
                setLocationInstruction(false);
                setFetchLocation(false);
                setLocationError(false);
                setLocationErrorPopUp(false);
                var timeoutVal = 10 * 1000 * 1000;
                navigator.geolocation.watchPosition(displayPosition, displayError,{ enableHighAccuracy: true, timeout: timeoutVal, maximumAge: 0 });
            }else if(permissionStatus.state === 'denied'){
                setLocationInstruction(false);
                setFetchLocation(false);
                setLocationError(true);
                setLocationErrorPopUp(false);
              }else{
                setLocationInstruction(true);
                setFetchLocation(false);
                setLocationError(false);
                setLocationErrorPopUp(false);
              }
            permissionStatus.onchange = () => {
              sessionStorage.setItem("locate_permission" , permissionStatus.state);
              if(permissionStatus.state === 'granted'){
                setLocationInstruction(false);
                setFetchLocation(false);
                setLocationError(false);
                setLocationErrorPopUp(false);
                var timeoutVal = 10 * 1000 * 1000;
                navigator.geolocation.watchPosition(displayPosition, displayError,{ enableHighAccuracy: true, timeout: timeoutVal, maximumAge: 0 });
              }else if(permissionStatus.state === 'denied'){
                setLocationInstruction(false);
                setFetchLocation(false);
                setLocationError(true);
                setLocationErrorPopUp(false);
              }else{
                setLocationInstruction(true);
                setFetchLocation(false);
                setLocationError(false);
                setLocationErrorPopUp(false);
              }
            };
          }); 
    }

    function allowDeviceLocation(){
        navigator.permissions
        .query({ name: "geolocation" })
        .then((permissionStatus) => {
            if(permissionStatus.state === 'granted'){
                sessionStorage.setItem("locate_permission" , permissionStatus.state);
                var timeoutVal = 10 * 1000 * 1000;
                navigator.geolocation.watchPosition(displayPosition, displayError,{ enableHighAccuracy: true, timeout: timeoutVal, maximumAge: 0 });
            }else if(permissionStatus.state === 'denied'){
                var timeoutVal = 10 * 1000 * 1000;
                navigator.geolocation.watchPosition(displayPosition, displayError, { enableHighAccuracy: true, timeout: timeoutVal, maximumAge: 0 });
                setLocationInstruction(false);
                setFetchLocation(false);
                setLocationError(true);
                setLocationErrorPopUp(false);
            }else{
                var timeoutVal = 10 * 1000 * 1000;
                navigator.geolocation.watchPosition(displayPosition, displayError, { enableHighAccuracy: true, timeout: timeoutVal, maximumAge: 0 });
                setLocationInstruction(true);
                setFetchLocation(false);
                setLocationError(false);
                setLocationErrorPopUp(false);
            }
            permissionStatus.onchange = () => {
                sessionStorage.setItem("locate_permission" , permissionStatus.state);
                if(permissionStatus.state === 'granted'){
                    var timeoutVal = 10 * 1000 * 1000;
                    navigator.geolocation.watchPosition(displayPosition, displayError, { enableHighAccuracy: true, timeout: timeoutVal, maximumAge: 0 });
                }else if(permissionStatus.state === 'denied'){
                    setLocationInstruction(false);
                    setFetchLocation(false);
                    setLocationError(true);
                    setLocationErrorPopUp(false);
                    sessionStorage.setItem('latitude','');
                    sessionStorage.setItem('longitude','');
                }else{
                    setLocationInstruction(true);
                    setFetchLocation(false);
                    setLocationError(false);
                    setLocationErrorPopUp(false);
                    sessionStorage.setItem('latitude','');
                    sessionStorage.setItem('longitude','');
                }
            };
          });
          setLocationPopup(false);
    }
    const closeLocationError = async() => {
        setLocationInstruction(false);
        setFetchLocation(false);
        setLocationError(true);
        setLocationErrorPopUp(false)
        setLocationPopup(false);

    }

    function displayPosition(position) {
        if(position.coords.latitude === '' || position.coords.longitude === ''){
            setFetchLocation(true);
        }
        sessionStorage.setItem("latitude" , position.coords.latitude);
        sessionStorage.setItem("longitude" , position.coords.longitude);
      }
    
      function displayError(error) {
        
        if(error.code === 2 || error.code === 3){
            setFetchLocation(true);
        }
        var errors = {
          1: 'Permission denied',
          2: 'Position unavailable',
          3: 'Request timeout'
        };
      }

    const applyBajaj = (search_params) => {
        document.getElementById('waitingLoader').style.display = 'block';
        axios.get(bajaj_api_url + `?signature=${search_params}`).then((response) => {
            if (response.data.status === 'success') {
                document.getElementById('waitingLoader').style.display = 'none';
                sessionStorage.setItem('token', response.data.data.token);
                sessionStorage.setItem('approve_amount', response.data.data.appData.PreQualifiedAmount);
                sessionStorage.setItem('termContent', response.data.data.content);
                sessionStorage.setItem('lafId', response.data.data.appData.laf_id);
                sessionStorage.setItem('userData', JSON.stringify(response.data.data.userData));
                navigate(response.data.data.stage.lastActiveStage);
                
            }
        }).catch(error => {
            document.getElementById('waitingLoader').style.display = 'none';
            toast.error(error.message, {
                position: toast.POSITION.TOP_CENTER
            }
            );
            setOtp(false);
        });
    }
    const onChangeMobile = (e) => {
        setIsActive(false);
        const value = e.target.value.replace(/\D/g, "");
        const limit = 10;
        setMobile(value.slice(0, limit));
        if (e.target.value.length >= limit) {
            setDisabledBtn(false);
        } else {
            setDisabledBtn(true);
        }
        removeErrorSms(e,'mobileNoError');
    }

    const onChangeOTP = (e) => {
        const value = e.target.value.replace(/\D/g, "");
        const limit = 6;
        setMobileOtp(value.slice(0, limit));
        if (e.target.value.length >= 6) {
            setDisabledBtn(false);
        } else {
            setDisabledBtn(true);
        }
        removeErrorSms(e,'otpError');
    }

    const sendOpt = () => {        
        let checkValidate = true;
        if (mobile === '' || mobile === null) {
            toast.error('Enter Mobile Number');
            checkValidate = false;
        }
        const headers = { 'Content-Type': 'application/json', 'latlong' : `${latitude},${longitude}` };
        if(locationInstruction === true){
            setLocationPopup(true);
        }else{
            if(fetchLocation === false){
                if(locationError === false){
                    if (checkValidate) {
                        document.getElementById('waitingLoader').style.display = 'block';
                        const encrypted = encryptData({
                            mobile_no: mobile,
                        });
                        axios.post(api_url + `/send-otp-for-register`,{encrypted : encrypted} , {headers}).then((response) => {
                            if (response.data.status === 'success') {
                                toast.success(response.data.message,
                                    {
                                        position: toast.POSITION.TOP_CENTER,
                                        delay: 1000
                                    });
                                document.getElementById('waitingLoader').style.display = 'none';
                                sessionStorage.setItem('userData', JSON.stringify(response.data.data.userData));
                                sessionStorage.setItem('lafId', response.data.data.lafId);
                                var countDownDate = new Date(response.data.data.date).getTime();
                                setCounterTime(countDownDate);
                                var currentDate = new Date().getTime();
                                var distance = counterTime - currentDate;
                                var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                                var seconds = Math.floor((distance % (1000 * 60)) / 1000);
                                setMinutes(minutes);
                                setSeconds(seconds);
                                setOtp(true);
                                setDisabled(true);
                                setDisabledBtn(true);
                            }
                            else if(response.data.status === "token") {
                                toast.error(response.data.errors);
                                sessionStorage.clear();
                                navigate('/login');
                            }
                            else if(response.data.status === "regrate") {
                                sessionStorage.clear();
                                navigate('/regrate');
                            }else if(response.data.status === "fail" && response.data.is_rejected === true) {
                                document.getElementById('waitingLoader').style.display = 'none';
                                sessionStorage.setItem('status_flag', response.data.data.status_flag);
                                navigate('/regrate');
                            }else if(response.data.status === "fail" && response.data.statusCode !== 102) {
                                document.getElementById('waitingLoader').style.display = 'none';
                                toast.error(response.data.errors,{
                                    position: toast.POSITION.TOP_CENTER,
                                    delay: 1000
                                });
                            } else {
                                let checkerror = {}
                                document.getElementById('waitingLoader').style.display = 'none';
                                checkerror['mobileNoError'] = response.data.errors;
                                setValidationError({ ...validation, ...checkerror });
                                setOtp(false);
                            }
                        }).catch(error => {
                            document.getElementById('waitingLoader').style.display = 'none';
                            toast.error(error.message, {
                                position: toast.POSITION.TOP_CENTER
                            }
                            );
                            setOtp(false);
                        });
                    }
                }else{
                    setLocationErrorPopUp(true);
                }
            }
        }
    }

    useEffect(() => {
        const interval = setInterval(() => {
            var currentDate = new Date().getTime();
            if(counterTime > 0 && currentDate < counterTime){
                var distance = counterTime - currentDate;
                var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                var seconds = Math.floor((distance % (1000 * 60)) / 1000);
                setMinutes(minutes);
                setSeconds(seconds);
            }else{
                setMinutes(0);
                setSeconds(0);
            }
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }

            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(interval);
                } else {
                    const seconds = 59;
                    setSeconds(seconds);
                    setMinutes(minutes - 1);
                }
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [seconds]);

    const goToRegister = () => {
        navigate('/');
    }

    function proceedLogin() {
        if(locate_permission === 'denied' || locate_permission === ''){
            toast.error("Please allow device location", {
              position: toast.POSITION.TOP_CENTER,
           });
           return false;
        }

        let checkValidate = true;
        if (mobileOtp === '' || mobileOtp === null) {
            toast.error('Enter Mobile OTP');
            checkValidate = false;
        }
        if (checkValidate) {
            document.getElementById('waitingLoader').style.display = 'block';
            const payload = {
                mobile_no: mobile,
                otp: mobileOtp
            };
            const encrypted = encryptData(payload);
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${jwtToken}`,
                'latlong' : `${latitude},${longitude}`,
            };

            const requestOptions = {
                method: "POST",
                headers: headers,
                body: JSON.stringify({encrypted : encrypted}),
            };

            fetch(api_url + `/submit-otp-mobile-verify`, requestOptions).then((res) => {
                return res.json();
            }).then((result) => {
                if (result.status === 'success') {
                    toast.success(result.message,
                        {
                            position: toast.POSITION.TOP_CENTER
                        });
                    sessionStorage.setItem('token', result.data.token);
                    sessionStorage.setItem('approve_amount', result.data.appData.PreQualifiedAmount);
                    sessionStorage.setItem('termContent', result.data.content);
                    sessionStorage.setItem('lafId', result.data.appData.laf_id);
                    sessionStorage.setItem('product_code', result.data.appData.product_code);
                    setToken(result.data.token);
                    setValidationError({ mobileNoError: '', otpError: '' })
                    document.getElementById('waitingLoader').style.display = 'none';
                    navigate(result.data.stage.lastActiveStage);
                }else if(result.status === 'fail' && result.statusCode === 102){
                    let checkerror = {}
                    document.getElementById('waitingLoader').style.display = 'none';
                    checkerror['otpError'] = result.errors;
                    setValidationError({ ...validation, ...checkerror });
                }else {
                    document.getElementById('waitingLoader').style.display = 'none';
                    toast.error(result.errors,
                        {
                            position: toast.POSITION.TOP_CENTER
                        });
                }
            }).catch((err) => {
                document.getElementById('waitingLoader').style.display = 'none';
                toast.error(err, {
                    position: toast.POSITION.TOP_CENTER
                });
                setOtp(false);
            })
        }
    }

    function resend() {
        document.getElementById('waitingLoader').style.display = 'block';
        axios.post(api_url + `/resend-otp-for-register`, {encrypted : encryptData({
            mobile_no: mobile,
        })}).then((response) => {
            if (response.data.status == 'success') {
                document.getElementById('waitingLoader').style.display = 'none';
                setValidationError({ mobileNoError: '', otpError: '' });
                toast.success(response.data.message,
                    {
                        position: toast.POSITION.TOP_CENTER
                    });
                var countDownDate = new Date(response.data.data.date).getTime();
                setCounterTime(countDownDate);
                var currentDate = new Date().getTime();
                var distance = counterTime - currentDate;
                var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                var seconds = Math.floor((distance % (1000 * 60)) / 1000);
                setMinutes(minutes);
                setSeconds(seconds);
                setOtp(true);
            } else {
                document.getElementById('waitingLoader').style.display = 'none';
                toast.error(response.data.errors, {
                    position: toast.POSITION.TOP_CENTER
                });
                setOtp(false);
            }
        })
            .catch(error => {
                document.getElementById('waitingLoader').style.display = 'none';
                toast.error(error.message, {
                    position: toast.POSITION.TOP_CENTER
                });
                setOtp(false);
            });
    }

    function modifyEmailPlaceHolder(e) {
        if (e.type === 'focus') {
            setIsActive(true);
        } else {
            setIsActive(false);
            if (e.target.value.length === 10) {
                setDisabledBtn(false);
            } else {
                setDisabledBtn(true);
            }
        }
    }

    function modifyOtpPlaceHolder(e) {
        if (e.type === 'focus') {
            setIsotpActive(true);
        } else {
            setIsotpActive(false);
            if (e.target.value.length === 6) {
                setDisabledBtn(false);
            } else {
                setDisabledBtn(true);
            }
        }
    }
    function removeErrorSms(e, fielName) {
        let checkerror = {}
        if (e.target.value.length > 0) {
            checkerror[fielName] = "";
            setValidationError({ ...validation, ...checkerror });
        }
    }
    return (
        <>
            <ToastContainer />
            <div className="pt-3">
                <Heading Description="U GRO Capital can help you with your business finance" BadgeDescription="Let us sign you back" />
            </div>
            <Layout ImageSide="Login">
                <h4 className="text-center mb-4">Sign In {otp}</h4>
                <div className="col-md-8 col-sm-12 mx-auto">
                    {!otp &&
                        <>
                            <Input disabled={isDisabled} LabelName="Mobile Number" type="text" Id="mobile_num" inputval={mobile} changeInput={(e) => onChangeMobile(e)} placeholder={isActive ? "Please enter your 10 digits mobile number" : ""} isAstrix keyFocus={(e) => modifyEmailPlaceHolder(e)} blurEvent={(e) => modifyEmailPlaceHolder(e)} />
                            {(validation.mobileNoError !== '') ?
                                <span className="text-danger fontsize-13">{validation.mobileNoError}</span> : null
                            }
                            <div className="text-center mt-3"> <CustomButton isDisabled={disabled} btnname="Send OTP" btn_col="col-md-4" clickBtn={() => { sendOpt(); }} />
                            </div>
                        </>
                    }
                    {otp &&
                        <>
                            <Input LabelName="OTP" type="password" Id="mobileOtp" inputval={mobileOtp} changeInput={(e) => onChangeOTP(e)} placeholder={isOtpActive ? "Please enter the OTP" : ""} keyFocus={(e) => modifyOtpPlaceHolder(e)} blurEvent={(e) => modifyOtpPlaceHolder(e)} isAstrix />
                            {(validation.otpError !== '') ?
                                <span className="text-danger fontsize-13">{validation.otpError}</span> : null
                            }
                            <div className="text-end text-orange">
                                {seconds > 0 || minutes > 0 ? (
                                    <p>
                                        {minutes < 10 ? `0${minutes}` : minutes}:
                                        {seconds < 10 ? `0${seconds}` : seconds}
                                    </p>
                                ) : (
                                    <p className="text-center"><span className="text-black">Didn't recieve OTP?</span>
                                        &nbsp;<a href="#" onClick={() => resend()}>Resend</a>
                                    </p>
                                )}
                            </div>
                            <div className="text-center mt-3">
                                <CustomButton btnname="Verify OTP" isDisabled={disabled} btn_col="col-md-4" clickBtn={() => proceedLogin()} />
                            </div>
                        </>
                    }
                    {!otp &&
                        <p className="mt-3 text-center">Don't have an account? <LoginButton btnname="Register" clickBtn={() => { goToRegister() }} className="text-decoration-none text-orange">Login</LoginButton></p>}
                </div>
            </Layout>

            {locationPopup &&
            <div className="modal-center">
                <div className={`modal modal-background fade show`} id="exampleModal" tabIndex={-1} style={{ display: "block" }}>
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-body pt-4">
                               <div className="text-center col-md-10 mx-auto"><strong>Please Allow Your Device Location.</strong></div>
                               </div>
                            <div className="modal-footer justify-content-center border-0 pt-0">
                               <img src={locationImage} />
                            </div>
                            <div className="modal-footer justify-content-center border-0 pt-0">
                                <button type="button" className="btn btn-primary" onClick={()=>{ allowDeviceLocation(); }}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        }
        {fetchLocation &&
            <div className="modal-center">
            <div className={`modal modal-background fade show`} id="exampleModal" tabIndex={-1} style={{ display: "block" }}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body pt-4">
                               <div className="text-center col-md-10 mx-auto">Sorry, we are facing a technical issue. Please try again after some time..</div>
                            </div><br/>
                        </div>
                    </div>
                </div>
            </div> 
        }

         {locationErrorPopUp &&
            <div className="modal-center">
            <div className={`modal modal-background fade show`} id="exampleModal" tabIndex={-1} style={{ display: "block" }}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body pt-4">
                        <div className="text-center col-md-10 mx-auto"><strong>Your location seems to be blocked. Please Allow to continue.</strong></div>
                               <div className="text-center col-md-10 mx-auto"><img src={resetLocation} /></div>
                            </div><br/>

                            <div className="modal-footer justify-content-center border-0 pt-0">
                                <button type="button" className="btn btn-primary" onClick={()=>{ closeLocationError(); }}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        }
        </>
    );
}

export default Login;