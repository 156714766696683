import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Heading from "../common-components/Heading";
import Input from "../common-components/Input";
import Layout from "../common-components/Layout";
import PageFooter from "../common-components/PageFooter";
import Select from "../common-components/Select";
import config from "../../config.json";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import { BureauContext } from "../Context/BureauContext";
import { encryptData } from "../../encrypt";

const GeneralQuestions = () => {
    const navigate = useNavigate();
    const [isDisabled, setDisabled] = useState(false);
    const [noOfYears, setNoOfYears] = useState('');
    const [statusofCurrentAddress, setStatusofCurrentAddress] = useState('');
    const [motherName, setMotherName] = useState('');
    const [martialStatus, setMartialStatus] = useState('');
    const [altPhone, setAltPhone] = useState('');
    const [educationQualification, setEducationQualification] = useState('');
    const [natureofbusiness, setNatureofbusiness] = useState('');
    const api_url = config.API_URL;
    const [StatusofCurrentAddress1, setStatusofCurrentAddress1] = useState([]);
    const [martialStatus1, setMartialStatus1] = useState([]);
    const [educationQualification1, setEducationQualification1] = useState([]);
    const [natureofbusiness1, setNatureofbusiness1] = useState([]);
    const [businessaddress, setBusinessAddress] = useState('');
    const [numberyearsbusiness, setNumberYearsBusiness] = useState('');
    const [numberyearscurrentaddress, setNumberYearsCurrentAddress] = useState('');
    const [StatusofCurrentAddressForBusiness, setStatusofCurrentAddressForBusiness] = useState([]);
    const [preStatusOfCurrentAddress, setpreStatusOfCurrentAddress] = useState('');
    const [preNatureOfBusinessAddress, setpreNatureOfBusinessAddress] = useState('');
    const [preNatureOfBusiness, setpreNatureOfBusiness] = useState('');
    const [preEducation, setpreEducation] = useState('');
    const [preMaritalStatus, setpreMaritalStatus] = useState('');
    const [validation, setValidationError] = useState({ motherError: '', phoneError: '', currentAddressError: '', statusCurrentAddressError: '', natureofbusinessError: '', motherNameError: '', martialStatusError: '', qualificationError: '', yearBusinessError: '', businessAddressError: '', CurrentAddressErrors: ''});
    const usertoken = sessionStorage.getItem('token');
    const { popupData, setPopUpData, checkStateByMiddileware} = useContext(BureauContext);
    const latitude = sessionStorage.getItem("latitude");
    const longitude = sessionStorage.getItem("longitude");

    const onChangeNoOfYear = (e) => {
        const value = e.target.value.replace(/\D/g, "");
        const limit = 2;
        setNumberYearsBusiness(value.slice(0, limit));
        removeErrorSms(e, 'yearBusinessError');
    }
    const onChnagesCurrentYear = (e) => {
        const value = e.target.value.replace(/\D/g, "");
        const limit = 2;
        setNumberYearsCurrentAddress(value.slice(0, limit));
        removeErrorSms(e, 'CurrentAddressError');
    }
    const onChangeHandler4 = (e) => {
        setDisabled(false);
        setBusinessAddress(e.target.value);
        setpreNatureOfBusinessAddress(e.target.value);
        removeErrorSms(e, 'businessAddressError');
    }

    useEffect(() => {
        checkStateByMiddileware();
        getBusinessAddress();
        const headers = {
            'Authorization': `Bearer ${usertoken}`,
           'latlong' : `${latitude},${longitude}`,
        }
        axios.get(api_url + `/get-general-questions`, { headers })
            .then((response) => {
                document.getElementById('waitingLoader').style.display = 'none';
                if (response.data.status == 'success') {
                    setStatusofCurrentAddress1(response.data.data.STATUS_OF_CURRENT_ADDRESS);
                    setMartialStatus1(response.data.data.MARITAL_STATUS);
                    setEducationQualification1(response.data.data.QUALIFICATION);
                    setNatureofbusiness1(response.data.data.NATURE_OF_BUSINESS);
                } else if (response.data.status === "token") {
                    sessionStorage.clear();
                    navigate("/login");
                }else if(response.data.status === 'expired'){
                    document.getElementById('waitingLoader').style.display = 'none';
                    setPopUpData({...popupData,is_bureau_checked : true});
                }else if (response.data.status === 'fail' && response.data.is_rejected === true) {
                    document.getElementById('waitingLoader').style.display = 'none';
                    if(response.data.hasOwnProperty('data')){
                        sessionStorage.setItem('status_flag', response.data.data.appStatus.comment)
                    }
                    navigate("/regrate");
                } else {
                    toast.error(response.data.errors, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            })
            .catch(error => {
                toast.error(error.message, {
                    position: toast.POSITION.TOP_CENTER
                });
            });
    }, []);
    const onChangeHandler = (e) => {
        setDisabled(false);
        setStatusofCurrentAddress(e.target.value);
        setpreStatusOfCurrentAddress(e.target.value);
        removeErrorSms(e, 'statusCurrentAddressError');
    }
    const onChangeHandler1 = (e) => {
        setDisabled(false);
        setMartialStatus(e.target.value);
        setpreMaritalStatus(e.target.value);
        removeErrorSms(e, 'martialStatusError');
    }
    const onChangeHandler2 = (e) => {
        setDisabled(false);
        setEducationQualification(e.target.value);
        setpreEducation(e.target.value);
        removeErrorSms(e, 'qualificationError');
    }
    const onChangeHandler3 = (e) => {
        setDisabled(false);
        setNatureofbusiness(e.target.value);
        setpreNatureOfBusiness(e.target.value);
        removeErrorSms(e, 'natureofbusinessError');
    }
    const generalQuestionsSubmit = (e) => {
        checkStateByMiddileware();
        let checkValidate = true;
        let checkerror = {}
        if (noOfYears === '' || noOfYears === undefined) {
            checkerror['currentAddressError'] = "Please enter Number Of years in the current address";
            checkValidate = false;
            setValidationError({ ...validation, ...checkerror });
            return 0;
        }
        if (statusofCurrentAddress === '' || statusofCurrentAddress === undefined) {
            checkerror['statusCurrentAddressError'] = "Please select Status Of Current Address";
            checkValidate = false;
            setValidationError({ ...validation, ...checkerror });
            return 0;
        }
        if (motherName === '' || motherName === undefined) {
            checkerror['motherNameError'] = "Please enter your mother's maiden name";
            checkValidate = false;
            setValidationError({ ...validation, ...checkerror });
            return 0;
        }
        if (motherName.length < 3) {
            checkerror['motherNameError'] = "Mother's Maiden name must be atleast three letters";
            checkValidate = false;
            setValidationError({ ...validation, ...checkerror });
            return 0;
        }
        if (martialStatus === '' || martialStatus === undefined) {
            checkerror['martialStatusError'] = "Please select Martial Status";
            checkValidate = false;
            setValidationError({ ...validation, ...checkerror });
            return 0;
        }
        if (educationQualification === '' || educationQualification === undefined) {
            checkerror['qualificationError'] = "Please select Education Qualification";
            checkValidate = false;
            setValidationError({ ...validation, ...checkerror });
            return 0;
        }
        if(natureofbusiness === '' || natureofbusiness === undefined) {
            checkerror['natureofbusinessError'] = "Please select Nature of business";
            checkValidate = false;
            setValidationError({ ...validation, ...checkerror });
            return 0;
        }
        if (businessaddress === '' || businessaddress === undefined) {
            checkerror['businessAddressError'] = "Please Select Business Address";
            setValidationError({ ...validation, ...checkerror });
            checkValidate = false;
            return 0;
        }
        if (numberyearsbusiness === '' || numberyearsbusiness === undefined) {
            checkerror['yearBusinessError'] = "Please enter Number of years business";
            setValidationError({ ...validation, ...checkerror });
            checkValidate = false;
            return 0;
        }
        if (numberyearscurrentaddress === '' || numberyearscurrentaddress === undefined) {
            checkerror['CurrentAddressErrors'] = "Enter Number of years Current Address";
            setValidationError({ ...validation, ...checkerror });
            checkValidate = false;
            return 0;
        }
        if (checkValidate) {
            const payload = {
                mother_maiden_name: motherName,
                alternate_mobile_number: altPhone,
                marital_status: martialStatus,
                education_qualification: educationQualification,
                nature_of_business: natureofbusiness,
                status_of_curr_address: statusofCurrentAddress,
                no_of_years_in_curr_address: noOfYears,
                type_of_business: businessaddress,
                no_of_year_business: numberyearsbusiness,
                no_of_year_business_at_curr: numberyearscurrentaddress,
            }
            const encrypted = encryptData(payload);
            const headers = {
                'Authorization': `Bearer ${usertoken}`,
                'latlong' : `${latitude},${longitude}`,
            }
            document.getElementById('waitingLoader').style.display = 'block';
            axios.post(api_url + `/update-general-questions`, {encrypted : encrypted}, { headers })
                .then((response) => {
                    document.getElementById('waitingLoader').style.display = 'none';
                    if (response.data.status === 'success') {
                        navigate('/kyc-selfie');
                    } else if (response.data.status === 'expired') {
                        document.getElementById('waitingLoader').style.display = 'none';
                        setPopUpData({...popupData,is_bureau_checked : true});
                    }else if (response.data.status === 'hold') {
                        setPopUpData({...popupData,
                          is_bank_hold : 1, 
                          checkStage : false,
                          stepData : response.data.data.stage.parentStage
                          });
                    }else if (response.data.status === "token") {
                        toast.error(response.data.errors);
                        sessionStorage.clear();
                        navigate('/login');
                    }else if (response.data.status === 'fail' && response.data.is_rejected === true) {
                        document.getElementById('waitingLoader').style.display = 'none';
                        if(response.data.hasOwnProperty('data')){
                            sessionStorage.setItem('status_flag', response.data.data.appStatus.comment)
                        }
                        navigate("/regrate");
                    } else {
                        document.getElementById('waitingLoader').style.display = 'none';
                        toast.error(response.data.errors, {
                            position: toast.POSITION.TOP_CENTER
                        });
                    }
                })
                .catch(error => {
                    document.getElementById('waitingLoader').style.display = 'none';
                    toast.error(error.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                });
        }
    }

    const getBusinessAddress = (e) => {
        const headers = {
            'Authorization': `Bearer ${usertoken}`,
           'latlong' : `${latitude},${longitude}`,
        }
        document.getElementById('waitingLoader').style.display = 'block';
        axios.get(api_url + `/business-question`, { headers })
            .then((response) => {
                document.getElementById('waitingLoader').style.display = 'none';
                if (response.data.status === 'success') {
                    setStatusofCurrentAddressForBusiness(response.data.data);
                }
                else if (response.data.status === "token") {
                    toast.error(response.data.errors);
                    sessionStorage.clear();
                    navigate('/login');
                } else if (response.data.status === "expired") {
                    document.getElementById('waitingLoader').style.display = 'none';
                    setPopUpData({...popupData,is_bureau_checked : true});
                }else if (response.data.status === 'fail' && response.data.is_rejected === true) {
                    document.getElementById('waitingLoader').style.display = 'none';
                    if(response.data.hasOwnProperty('data')){
                        sessionStorage.setItem('status_flag', response.data.data.appStatus.comment)
                    }
                    navigate("/regrate");
                } else {
                    document.getElementById('waitingLoader').style.display = 'none';
                    toast.error(response.data.errors, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            })
            .catch(error => {
                document.getElementById('waitingLoader').style.display = 'none';
                toast.error(error.message, {
                    position: toast.POSITION.TOP_CENTER
                });
            });
    }

    const onChangeNoYear = (e) => {
        const value = e.target.value.replace(/\D/g, "");
        const limit = 2;
        setNoOfYears(value.slice(0, limit));
        removeErrorSms(e, 'currentAddressError');
    }
    const onChangeMotherName = (e, blr) => {
        const value = e.target.value.replace(/[^A-Za-z\s]/ig, '')
        let checkerror = {}
        if (e.type == 'blur') {
            let length = e.target.value.length;
            if (length < 3) {
                checkerror['motherNameError'] = "Mother's Maiden name must be atleast three letters";
                setValidationError({ ...validation, ...checkerror });
            }
            else {
                checkerror['motherNameError'] = " ";
                setValidationError({ ...validation, ...checkerror });
            }
        }
        const limit = 60;
        setMotherName(value.slice(0, limit));
    }

    const onChangeAlMobile = (e, blr) => {
        const value = e.target.value.replace(/\D/g, "");
        if (blr) {
            if (value !== '' && value.length < 10) {
                setValidationError({ ...validation, ['phoneError']: "Phone must be atleast 10 digits." });
            } else {
                setValidationError({ ...validation, ['phoneError']: '' });
            }
        }

        if (value !== '' && value.length === 10) {
            setValidationError({ ...validation, ['phoneError']: '' });
        }

        const limit = 10;
        setAltPhone(value.slice(0, limit));
    }

    function removeErrorSms(e, fielName) {
        let checkerror = {}
        if (e.target.value.length > 0) {
            checkerror[fielName] = "";
            setValidationError({ ...validation, ...checkerror });
        }
    }
    return (
        <>
            <ToastContainer />
            <div className="pt-3">
                <Heading Description="Let us help you build a future-ready company" BadgeDescription="Let us know you a bit more" user />
            </div>
            <Layout ImageSide="Product-intro">
                <h4 className="text-center mb-4">General Questions</h4>
                <div className="row">
                    <div className="col-md-6">
                        <Input inputval={noOfYears} changeInput={(e) => onChangeNoYear(e)} LabelName="No. of Years in the Current Address" isAstrix type="text" Id="Numberofyears_currentaddress" placeholder="No. of years in the current address" />
                        {(validation.currentAddressError !== '') ?
                            <span className="text-danger fontsize-13">{validation.currentAddressError}</span>
                            : null
                        }
                    </div>

                    <div className="col-md-6">
                        <Select LabelName="Status of Current Address" preSelect={preStatusOfCurrentAddress} isAstrix selected={(e) => onChangeHandler(e)} SelectData={StatusofCurrentAddress1} />
                        {(validation.statusCurrentAddressError !== '') ?
                            <span className="text-danger fontsize-13">{validation.statusCurrentAddressError}</span>
                            : null
                        }
                    </div>
                    <div className="col-md-6">
                        <Input LabelName="Mother’s Maiden Name" blurEvent={(e) => onChangeMotherName(e)} inputval={motherName} changeInput={(e) => onChangeMotherName(e)} isAstrix type="text" Id="Mother_Maiden_Name" placeholder="Please Enter Your Mother’s Maiden Name" />
                        {validation.motherError !== '' &&
                            <span className="text-danger">{validation.motherError}</span>
                        }
                        {validation.motherNameError !== '' &&
                            <span className="text-danger">{validation.motherNameError}</span>
                        }
                    </div>
                    <div className="col-md-6">
                        <Select LabelName="Marital Status" preSelect={preMaritalStatus} isAstrix selected={(e) => onChangeHandler1(e)} SelectData={martialStatus1} />
                        {validation.martialStatusError !== '' &&
                            <span className="text-danger">{validation.martialStatusError}</span>
                        }
                    </div>
                    <div className="col-md-6">
                        <Input LabelName="Alternate Phone Number" inputval={altPhone} changeInput={(e) => onChangeAlMobile(e, false)} blurEvent={(e) => onChangeAlMobile(e, true)} type="text" Id="Alternate_phone_number" placeholder="Please Enter Your Alternate Phone Number" />
                        {validation.phoneError !== '' &&
                            <span className="text-danger">{validation.phoneError}</span>
                        }
                    </div>
                    <div className="col-md-6">
                        <Select LabelName="Education Qualification" preSelect={preEducation} isAstrix selected={(e) => onChangeHandler2(e)} SelectData={educationQualification1} />
                        {validation.qualificationError !== '' &&
                            <span className="text-danger">{validation.qualificationError}</span>
                        }
                    </div>
                    <div className="col-md-6">
                        <Select LabelName="Nature of Business" preSelect={preNatureOfBusiness} isAstrix selected={(e) => onChangeHandler3(e)} SelectData={natureofbusiness1} />
                        {validation.natureofbusinessError !== '' &&
                            <span className="text-danger">{validation.natureofbusinessError}</span>
                        }
                    </div>
                    <div className="col-md-6">
                        <Select LabelName="Nature of Business Address" preSelect={preNatureOfBusinessAddress} isAstrix selected={(e) => onChangeHandler4(e)} SelectData={StatusofCurrentAddressForBusiness} />
                        {(validation.businessAddressError !== '') ?
                            <span className="text-danger fontsize-13">{validation.businessAddressError}</span>
                            : null
                        }
                    </div>
                    <div className="col-md-6">
                        <Input LabelName="Number of Years in Business" type="text" Id="Number_years_Business" placeholder="Number of years in Business" inputval={numberyearsbusiness} changeInput={(e) => onChangeNoOfYear(e)} />
                        {(validation.yearBusinessError !== '') ?
                            <span className="text-danger fontsize-13">{validation.yearBusinessError}</span>
                            : null
                        }
                    </div>
                    <div className="col-md-6">
                        <Input LabelName="Number of Years in Business at Current Address" type="text" Id="Number_years_Current_Address" placeholder="Number of years in Business at Current Address" inputval={numberyearscurrentaddress} changeInput={(e) => onChnagesCurrentYear(e)} />
                        {(validation.CurrentAddressErrors !== '') ?
                            <span className="text-danger fontsize-13">{validation.CurrentAddressErrors}</span>
                            : null
                        }
                    </div>
                </div>
                <PageFooter setDisabled={isDisabled} BtnName="I SUBMIT" TermsCondition="By confirming, I declare that these are my correct details" ButtonClick={(e) => generalQuestionsSubmit(e)} />
            </Layout>
        </>
    );
}
export default GeneralQuestions;