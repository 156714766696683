import React from "react";

const Select = ({LabelName,isAstrix,SelectData,selected, name='', preSelect='', disabled = false}) => {
    const astrix = <span className="required-field text-danger">*</span>;
    const select_value = SelectData.filter((value) => {
        return value.bank_code.includes(preSelect);
    })
    return (
        <>
            <div className="mb-0">
                <label htmlFor="floatingSelect" className="form-label mb-0 fontsize-12 font-wt-600 text-orange">{LabelName} {isAstrix && astrix}</label>
                {
                    (select_value.length > 0) ? 
                    <select value={preSelect} className="form-select" onChange={selected} id="floatingSelect" disabled={disabled}>
                        {
                            Object.keys(SelectData).length > 1 && <option value="">--Select--</option>
                        }
                        {SelectData.map((key)=>{
                            return(
                                <option value={key.bank_code?key.bank_code:key.bank_code} key={key.bank_code}>{key.name}</option>
                            );
                        }) }
                    </select> : <select className="form-select" onChange={selected} id="floatingSelect" disabled={disabled}>
                        {
                            Object.keys(SelectData).length > 1 && <option value="">--Select--</option>
                        }
                        {SelectData.map((key)=>{
                            return(
                                <option value={key.bank_code?key.bank_code:key.bank_code} key={key.bank_code}>{key.name}</option>
                            );
                        }) }
                    </select>
                    
                }
               
            </div>
        </>
    );
}
export default Select;