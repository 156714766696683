import React, { useState, useEffect, useContext } from "react";
import { compareAsc, format } from 'date-fns'
import Heading from "../common-components/Heading";
import Layout from "../common-components/Layout";
import { useNavigate } from "react-router-dom";
import { CheckBoxInput } from "../common-components/CheckInput";
import PageFooter from "../common-components/PageFooter";
import config from "../../config.json";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BureauContext } from "../Context/BureauContext";
import { encryptData } from "../../encrypt";
const usertoken = sessionStorage.getItem('token');
const api_url = config.API_URL;

const KycAadhaar = () => {
    const navigate = useNavigate();
    const api_url = config.API_URL;
    const [agreement, setAgreement] = useState(false);
    const [jwtToken, setToken] = useState('');
    const [aadhar, setaadhar] = useState('');
    const [name, setname] = useState('');
    const [dob, setDob] = useState('');
    const [address, setaddress] = useState('');
    const {popupData, setPopUpData, checkStateByMiddileware} = useContext(BureauContext);
    const latitude = sessionStorage.getItem("latitude");
    const longitude = sessionStorage.getItem("longitude");

    useEffect(() => {
        checkStateByMiddileware();
        const usertoken = sessionStorage.getItem('token');
        setToken(usertoken);
        const headers = {
            'Authorization': `Bearer ${usertoken}`,
            'latlong' : `${latitude},${longitude}`,
        }
        document.getElementById('waitingLoader').style.display = 'block';
        axios.get(api_url + `/get_aadhar_details`, { headers })
            .then((response) => {
                document.getElementById('waitingLoader').style.display = 'none';
                if (response.data.status == 'success') {
                    setaadhar(response.data.data.aadhaar_number);
                    setname(response.data.data.name_on_aadhaar);
                    setDob(format(new Date(response.data.data.dob_aadhaar), 'dd-MM-yyyy'));
                    setaddress(response.data.data.address_aadhaar);
                }else if(response.data.status === "expired") {
                    document.getElementById('waitingLoader').style.display = 'none';
                    setPopUpData({...popupData,is_bureau_checked : true});
                }else if (response.data.status === 'fail' && response.data.is_rejected === true) {
                    document.getElementById('waitingLoader').style.display = 'none';
                    if(response.data.hasOwnProperty('data')){
                    sessionStorage.setItem('status_flag', response.data.data.appStatus.comment)
                }
                    navigate("/regrate");
                } else {
                    document.getElementById('waitingLoader').style.display = 'none';
                    toast.error(response.data.errors,{
                      position: toast.POSITION.TOP_CENTER
                    });
                }
            })
            .catch(error => {
                document.getElementById('waitingLoader').style.display = 'none';
                toast.error(error.message,{
                    position: toast.POSITION.TOP_CENTER
                });
            });

    }, []);

    const handleChange = (event) => {
        setAgreement(event.target.checked);
    }

    function AadharSubmit() {
        checkStateByMiddileware();
        let checkValidate = true;
        if (checkValidate) {
            const headers = {
                'Authorization': `Bearer ${jwtToken}`,
                'latlong' : `${latitude},${longitude}`,
            }
            document.getElementById('waitingLoader').style.display = 'block';
            axios.post(api_url + `/aadhar-consent`, '', { headers })
                .then((response) => {
                    document.getElementById('waitingLoader').style.display = 'none';
                    if (response.data.status == 'success') {
                        toast.success(response.data.message,{
                            position: toast.POSITION.TOP_CENTER
                        });
                        navigate('/kyc-selfi');
                    }else if(response.data.status === "expired") {
                        document.getElementById('waitingLoader').style.display = 'none';
                        setPopUpData({...popupData,is_bureau_checked : true});
                    }else if (response.data.status === 'fail' && response.data.is_rejected === true) {
                        document.getElementById('waitingLoader').style.display = 'none';
                        if(response.data.hasOwnProperty('data')){
                    sessionStorage.setItem('status_flag', response.data.data.appStatus.comment)
                }
                        navigate("/regrate");
                    } else {
                        document.getElementById('waitingLoader').style.display = 'none';
                        toast.error(response.data.errors,{
                            position: toast.POSITION.TOP_CENTER
                        });
                    }
                })
                .catch(error => {
                    document.getElementById('waitingLoader').style.display = 'none';
                    toast.error(error.message,{
                        position: toast.POSITION.TOP_CENTER
                    });
                });
        }
    }

    return (
        <>
            <ToastContainer />
            <div className="pt-3">
                <Heading Description="Let us help you build a future-ready company" BadgeDescription="Let us complete your KYC" user />
            </div>
            <Layout ImageSide="Product-intro">
                <h4 className="text-center mb-2">AADHAAR Details</h4>
                <h6 className="text-center mb-4" >{aadhar}</h6>
                <div className="col-md-9 mx-auto">
                    <div className="d-flex mb-2 pb-1">
                        <div className="text-end fw-bold" style={{ width: '45%' }}>Name</div>
                        <span className="text-center fw-bold" style={{ width: '10%' }}>:</span>
                        <div className="" style={{ width: '45%' }}>{name}</div>
                    </div>
                    <div className="d-flex mb-2 pb-1">
                        <div className="text-end fw-bold" style={{ width: '45%' }}>Date of Birth</div>
                        <span className="text-center fw-bold" style={{ width: '10%' }}>:</span>
                        <div className="" style={{ width: '45%' }}>{dob}</div>
                    </div>
                    <div className="d-flex mb-2 pb-1">
                        <div className="text-end fw-bold" style={{ width: '45%' }}>Address</div>
                        <span className="text-center fw-bold" style={{ width: '10%' }}>:</span>
                        <div className="" style={{ width: '45%' }}>{address}</div>
                    </div>
                    <CheckBoxInput name="agreement" ChangeCheck={handleChange}>
                        I agree the AADHAAR information displayed above belongs to me. The above address will be my default Permanent Residential Address
                    </CheckBoxInput>
                    <PageFooter BtnName="I SUBMIT" ButtonClick={() => { navigate('/general-questions') }} setDisabled={!agreement} TermsCondition="By submitting, I confirm sharing my AADHAAR details with U GRO" />
                </div>
            </Layout>
        </>
    );
}

export default KycAadhaar;
