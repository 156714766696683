/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from "react";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { RadioInput } from "../common-components/CheckInput";
import Heading from "../common-components/Heading";
import Input from "../common-components/Input";
import Layout from "../common-components/Layout";
import Modal from "../common-components/Modal";
import PageFooter from "../common-components/PageFooter";
import config from "../../config.json";
import { BureauContext } from "../Context/BureauContext";
import { encryptData } from "../../encrypt";

const OfferDetails = () => {
    // const formatter = new Intl.NumberFormat({ maximumSignificantDigits: 3 })
    const navigate = useNavigate();
    const [offer, setOffer] = useState({});
    const [chooseOffer, setOfferRejected] = useState('');
    const [modal, setModal] = useState(false);
    const [program_id, setProgram] = useState('');
    const [rejectReason, setReason] = useState('');
    const userToken = sessionStorage.getItem('token');
    const { popupData, setPopUpData, checkStateByMiddileware, logout } = useContext(BureauContext);
    const api_url = config.API_URL;
    const latitude = sessionStorage.getItem("latitude");
    const longitude = sessionStorage.getItem("longitude");

    const OfferData = async () => {
        checkStateByMiddileware();
        const headers = {
            'Authorization': `Bearer ${userToken}`,
            'content-type': 'application/json',
           'latlong' : `${latitude},${longitude}`,
        }
        document.getElementById('waitingLoader').style.display = 'block';
        await fetch(api_url + `/review-offer`, { headers }).then((res) => {
            return res.json();
        }).then((result) => {
            document.getElementById('waitingLoader').style.display = 'none';
            if (result.status === "token") {
                sessionStorage.clear();
                navigate('/login');
                toast.error(result.errors);
            } else if (result.status === "expired") {
                document.getElementById('waitingLoader').style.display = 'none';
                setPopUpData({...popupData,is_bureau_checked : true});
            }else if(result.status === "hold") {
                document.getElementById('waitingLoader').style.display = 'none';
                setPopUpData({...popupData,is_bank_hold : true});
            } else if (result.status === "success") {
                setOffer(result.data);
                setProgram(result.data.program.program_id);
                setReason(result.data.rejectReason);
            }else if (result.status === 'fail' && result.is_rejected === true) {
                document.getElementById('waitingLoader').style.display = 'none';
                if(result.hasOwnProperty('data')){
                    sessionStorage.setItem('status_flag', result.data.appStatus.comment)
                }
                navigate("/regrate");
            } else if (result.status === "fail") {
                toast.error(result.errors);
            }
        }).catch((err) => {
            document.getElementById('waitingLoader').style.display = 'none';
        })
    }

    const acceptOffer = async (e) => {
        checkStateByMiddileware();
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userToken}`,
                'latlong' : `${latitude},${longitude}`,
            },
            body: JSON.stringify({encrypted : encryptData({ 'offer': program_id })})
        };
        document.getElementById('waitingLoader').style.display = 'block';
        await fetch(api_url + `/accept-offer`, requestOptions).then((res) => {
            return res.json();
        }).then((result) => {
            document.getElementById('waitingLoader').style.display = 'none';
            if (result.status === "token") {
                sessionStorage.clear();
                navigate('/login');
                toast.error(result.errors);
            } else if (result.status === "expired") {
                setPopUpData({...popupData,is_bureau_checked : true});
            }else if(result.status === "hold") {
                document.getElementById('waitingLoader').style.display = 'none';
                setPopUpData({...popupData,is_bank_hold : true});
            } else if (result.status === "success") {
                navigate('/sanction-acceptance');
            }else if (result.status === 'fail' && result.is_rejected === true) {
                document.getElementById('waitingLoader').style.display = 'none';
                if(result.hasOwnProperty('data')){
                    sessionStorage.setItem('status_flag', result.data.appStatus.comment)
                }
                navigate("/regrate");
            } else if (result.status === "fail") {
                toast.error(result.errors);
            }
        }).catch((err) => {
            
        })
    }

    const rejectOffer = async () => {
        checkStateByMiddileware();
        if (chooseOffer === '') {
            toast.error("Please choose reject reason.");
            return false;
        }
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userToken}`,
                'latlong' : `${latitude},${longitude}`,
            },
            body: JSON.stringify({encrypted : encryptData({ 'rejectId': chooseOffer })})
        };
        document.getElementById('waitingLoader').style.display = 'block';
        await fetch(api_url + `/reject-offer`, requestOptions).then((res) => {
            return res.json();
        }).then((result) => {
            document.getElementById('waitingLoader').style.display = 'none';
            if (result.status === "token") {
                sessionStorage.clear();
                navigate('/login');
                toast.error(result.errors);
            } else if (result.status === "expired") {
                setPopUpData({...popupData,is_bureau_checked : true});
            }else if(result.status === "hold") {
                document.getElementById('waitingLoader').style.display = 'none';
                setPopUpData({...popupData,is_bank_hold : true});
            } else if (result.status === "success") {
                logout();
            }else if (result.status === 'fail' && result.is_rejected === true) {
                document.getElementById('waitingLoader').style.display = 'none';
                if(result.hasOwnProperty('data')){
                    sessionStorage.setItem('status_flag', result.data.appStatus.comment)
                }
                navigate("/regrate");
            } else if (result.status === "fail") {
                toast.error(result.errors);
            }
        }).catch((err) => {
            
        })
    }
    const setOfferRadio = (e) => {
        setOfferRejected(e.target.value);
    }
    useEffect(() => {
        OfferData();
    }, []);
    return (
        <>
            <div className="pt-3">
                <Heading Description="You have the support from U GRO in prospering your business" BadgeDescription="Offer Details" user />
            </div>
            <Layout ImageSide="Product-intro">
                <h4 className="text-center mb-4 ">WOW! Congratulations! Your limit of {Object.keys(offer).length > 0 ? offer.loanAmount : 0.00} has been approved.</h4>
                <div className="card shadow-sm p-4 border-0 bg-f1f1f1 ">
                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <Input read="true" LabelName="Allocated line of credit (INR) " type="text" inputval={Object.keys(offer).length > 0 ? offer.loanAmount : 0} Id="Allocated_line_credit_(INR)" placeholder="Allocated line of credit (INR)" />
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <Input read="true" LabelName="Tenure (Days)" type="text" Id="Tenure_(Days)" inputval={Object.keys(offer).length > 0 ? offer.program.tenor : 0} placeholder="Tenure (Days)" />
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <Input read="true" LabelName="Rate of Interest (%)" type="text" Id="Rate_ofInterest" inputval={Object.keys(offer).length > 0 ? offer.program.roi : 0} placeholder="Rate of Interest"/>
                        </div>
                    </div>
                </div>
                <PageFooter BtnName="I SUBMIT" TermsCondition="By accepting the offer, I give my consent to generate Sanction Letter" ButtonClick={() => acceptOffer()} />
                <div className="text-center mt-4 mx-auto">
                    <a onClick={() => setModal(true)} style={{"color":"#0000FF"}} className="text-decoration-none cursor fw-bold fontsize-12">I'm not Interested</a>
                </div>
            </Layout>
            {modal &&
                <Modal Modal={Modal} setModal={setModal} modalSize="modal-md" Heading="" Footer ButtonSize="Two" RightBtn=" Accept the Offer" LeftBtn="Reject the Offer" RightBtnClick={() => acceptOffer()} LeftBtnClick={() => rejectOffer()} >
                    <p className="text-center">I Confirm that I own this Business, and this is my Business set up photo</p>
                    <div className="mx-5">
                        {rejectReason.length > 0 && rejectReason.map((re) => (
                            <RadioInput inputVal={re.offer_reject_id} radioname="offer_reject" redioClick={(e) => { setOfferRadio(e) }}> {re.reason}</RadioInput>
                        ))}
                    </div>
                </Modal>}
        </>
    );
}
export default OfferDetails;