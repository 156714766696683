const Spacify = (str, after, c) => {
    if (!str) {
        return false;
    }
    after = after || 4;
    c = c || " ";
    var v = str.replace(/[^\dA-Z]/g, ''),
        reg = new RegExp(".{" + after + "}", "g");
    return v.replace(reg, function (a) {
        return a + c;
    }).replace(/[^0-9]+$/, "");
}
export default Spacify;