import React from "react";
import { BiInfoCircle } from "react-icons/bi";
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';


const Tooltip = ({type}) =>{

    const popover = (
        <Popover id="popover-basic" >
            <Popover.Body  >
                { (type === "UDYAM") ? <div>
                Your application will be rejected if you dont have any of the two documents - Udyam or GSTN.
                </div> : <div>
                    <ol className=" custom-popover p-1 border-0 mb-0 lh-lg">
                        <li>Pharmacy / Drug store – FDA License Number</li>
                        <li>Doctor – Medical council Registration Number</li>
                        <li>Grocery / General / Supermarket / Departmental Stores – Shop & Establishment Registration Number</li>
                        <li>Dentist / Path labs / Opticians / Diagnostic centre – Shop & Establishment Registration Number / Degree Certificate Number</li>
                        <li>Hardware stores / Home furnishing stores – Shop & Establishment Registration Number</li>
                        <li>Ready-made clothing store / Family clothing – Shop & Establishment Registration Number</li>
                        <li>Restaurants / Eatery / Bakery – FSSAI License Number</li>
                        <li>Others – Business Registration Number</li>
                    </ol>
                </div>}                
            </Popover.Body>
        </Popover>
    );

    return(
        <>
          <OverlayTrigger trigger="click" rootClose placement="auto" overlay={popover}><Button className="bg-transparent border-0 mb-2 py-0 px-0"><BiInfoCircle className="text-orange fs-5 cursor  " /></Button></OverlayTrigger>  
        </>
    );
}
export default Tooltip;