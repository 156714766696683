import React from "react";

const Textarea = ({Placeholder,Label,disabled,inputval}) => {
    return (
        <>
            <div className="mb-0">
                <label htmlFor="floatingTextarea" className="form-label mb-0 fontsize-12 font-wt-600 text-orange">{Label}</label>
                <textarea className="form-control" placeholder={Placeholder} id="floatingTextarea" defaultValue={inputval} disabled={disabled} rows="3"></textarea>
                
            </div>
        </>
    );
}
export default Textarea;