import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import ChooseInput from "../common-components/ChooseInput";
import Heading from "../common-components/Heading";
import Input from "../common-components/Input";
import Layout from "../common-components/Layout";
import PageFooter from "../common-components/PageFooter";
import Tooltip from "../common-components/Tooltip";
import config from "../../config.json";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import imageThumb from "../../images/image-thumb.png";
import { BureauContext } from "../Context/BureauContext";
import Select from "../common-components/Select";
import { CheckBoxInput } from "../common-components/CheckInput";
import { encryptData } from "../../encrypt";
const BusinessData = () => {
    const navigate = useNavigate();
    const api_url = config.API_URL;
    const [isDisabled, setDisabled] = useState(true);
    const [src, setSrc] = useState(imageThumb);
    const [isSelectedImg, setSelectedImg] = useState(false);
    const [businessSrc, setBusinessImage] = useState("");
    const [businessImageBase64, setBase64BusinessImage] = useState("");
    const [busniess_number, setBusniessNumber] = useState("");
    const [isReUpload, setReUpload] = useState(false);
    const inputRef = useRef(null);
    const jwtToken = sessionStorage.getItem('token');
    const [isregActive, setIsregActive] = useState(false);
    const [isregYearActive, setIsregYearActive] = useState(false);
    const { popupData, setPopUpData, checkStateByMiddileware, checkLocationPermission } = useContext(BureauContext);
    const [validation, setValidationError] = useState({ regError: '', beProofError: '', stateError: "", areaError: "", medicalError: "", registrationError: "", registrationYearError: "", consentError: "", industriesError: "" });
    const [regHide, setregHide] = useState(false);
    const usertoken = sessionStorage.getItem('token');
    const [industries, setIndustries] = useState([]);
    const [beproofmst, setbeMSt] = useState([]);
    const [regLevel, setregLevel] = useState("Business Registration Number");
    const [stateHide, setstateHide] = useState(false);
    const [areaHide, setareaHide] = useState(false);
    const [council, setCouncil] = useState(false);
    const [regYear, setregYear] = useState(false);
    const [consent, setconsent] = useState(false);
    const [beproofTitle, setbeproofTitle] = useState('Business Entity Proof');
    const [state_code, setstateCode] = useState('');
    const [preSelectState, setPreSelectState] = useState('');
    const [areaCode, setareaCode] = useState('');
    const [preSelectAreaCode, setPreSelectAreaCode] = useState('');
    const [yearOfReg, setyearOfReg] = useState('');
    const [medicalCouncil, setmedicalCouncil] = useState('');
    const [preSelectMedicalCouncil, setPreSelectMedicalCouncil] = useState('');
    const [lov_code, setlov_code] = useState('');
    const [consentvalue, setConsentValue] = useState('N');
    const [isImage, setisImage] = useState(false);
    const [consentChecked, setconsentChecked] = useState("");
    const [business_code, setBusinessCode] = useState('');
    const [isSkipped, setSkipped] = useState("N");
    const [skip, setSkip] = useState("N");
    const [ext, setExt] = useState('');
    const [frontpdf, setFrontPDF] = useState('');
    const latitude = sessionStorage.getItem("latitude");
    const longitude = sessionStorage.getItem("longitude");

    useEffect(() => {
        getbeproofindustry();
    }, []);
    const onChangeReg = (e) => {
        const value = e.target.value.replace(/(?!-)[^0-9A-Za-z]/g, "");
        const limit = 21;
        setBusniessNumber(value.slice(0, limit));
        if (e.target.value.length >= limit) {
            removeErrorSms(e, 'regError');
        }
    }
   
    const onFileChangeHandler = (e) => {
        var reader = new FileReader();
        if(e.target.files[0] !== undefined && e.target.files[0] !== '' && e.target.files[0] !== null){
            reader.readAsDataURL(e.target.files[0]);
            var extenstion = getExt(e.target.files[0].name);
            const array = ['pdf', 'jpg', 'png', 'jpeg']; // Your array here
            const doesExist = array.includes(extenstion);
            const checkerror = {};
            if(doesExist !== true){
                checkerror['beProofError'] = "Be proof must be jpg,png,jpeg,pdf";
                setValidationError({ ...validation, ...checkerror });
                return false;
            }
        }
        setSelectedImg(true);
        reader.onloadend = function(){
            setSrc(URL.createObjectURL(e.target.files[0]));
            setBusinessImage(e.target.files[0])
            var file_name = e.target.files[0].name;
            var value = e.target.files[0].size;
            var file_size = value / 1024;
            if (file_size > 1024 * 4) {
                toast.error("Please try with an acceptable file size", {
                    position: toast.POSITION.TOP_CENTER,
                })
            }
            if (extenstion !== "pdf") {
                setSrc(URL.createObjectURL(e.target.files[0]));
            }else{
                setSrc(imageThumb);
            }
            const img_arr = reader.result.split(";base64,");
            const image = img_arr[1];
            setFrontPDF(file_name);  
            setBase64BusinessImage(image);
            removeErrorSms(e, 'beProofError');
            setReUpload(true);
        }
    }
    function getExt(filename) {
        var fileExt = '';
        if(skip === "N"){
            var fileSplit = filename.split('.');
            if (fileSplit.length > 1) {
                fileExt = fileSplit[fileSplit.length - 1];
            }
        }
        return fileExt;
    }
    const proceedBusinessProof = (e) => {
        checkStateByMiddileware();
        let checkValidate = true;
        let checkerror = {};
        if(skip === "N"){
            if (businessSrc === '' || businessSrc === undefined) {
                checkerror['beProofError'] = "Please select Document";
                checkValidate = false;
                setValidationError({ ...validation, ...checkerror });
            }
            if (lov_code === '' || lov_code === undefined) {
                checkerror['industriesError'] = "Please select Industry";
                checkValidate = false;
                setValidationError({ ...validation, ...checkerror });
            } 

            if(lov_code === 'DOC'){
                if(busniess_number === '' || busniess_number === null || busniess_number === undefined){
                    checkerror['regError'] = "Please enter registration number";
                    checkValidate = false;
                    setValidationError({ ...validation, ...checkerror });
                }

                if(yearOfReg === '' || yearOfReg === null || yearOfReg === undefined){
                    checkerror['registrationYearError'] = "Please enter register year";
                    checkValidate = false;
                    setValidationError({ ...validation, ...checkerror });
                }
                const currentYear = new Date().getFullYear();
                if(yearOfReg > currentYear){
                    checkerror['registrationYearError'] = "Register year must be under current year";
                    checkValidate = false;
                    setValidationError({ ...validation, ...checkerror });
                }

                if(medicalCouncil === '' || medicalCouncil === null || medicalCouncil === undefined){
                    checkerror['medicalError'] = "Please select Medical Council";
                    checkValidate = false;
                    setValidationError({ ...validation, ...checkerror });
                }
            }
        }
        if (checkValidate) {
            let ext = getExt(businessSrc.name);
            const request_data = {
                'busniess_doc': businessImageBase64,
                'be_proof_number': busniess_number,
                'lov_code': lov_code,
                'state_code': state_code,
                'areaCode': areaCode,
                'yearOfReg': yearOfReg,
                'medicalCouncil': medicalCouncil,
                'be_proof_consent': consentvalue,
                'doc_type': ext,
                'business_code': business_code,
                'is_skip' : "N",
            }            
            const headers = {
                'Authorization': `Bearer ${jwtToken}`,
                'latlong' : `${latitude},${longitude}`,
            }
            const encrypted = encryptData(request_data);
            document.getElementById('waitingLoader').style.display = 'block';
            axios.post(api_url + `/store-business-proof`, {encrypted : encrypted}, { headers })
                .then((response) => {
                    if (response.data.status === 'success') {
                        document.getElementById('waitingLoader').style.display = 'none';
                        toast.success(response.data.message, {
                            position: toast.POSITION.TOP_CENTER
                        });
                        //if(response.data.data.appData.udyam_lov_code == '' || response.data.data.appData.udyam_lov_code == null || response.data.data.appData.udyam_lov_code == undefined){
                        if(response.data.data.udyamAssit === 1) {
                            navigate("/udyam-assit");
                        } else {
                            navigate('/bank-detail');
                        }
                    }
                    else if (response.data.status === "token") {
                        document.getElementById('waitingLoader').style.display = 'none';
                        toast.error(response.data.errors, {
                            position: toast.POSITION.TOP_CENTER,
                        });
                        sessionStorage.clear();
                        navigate('/login')
                    }else if (response.data.status === 'hold') {
                        document.getElementById('waitingLoader').style.display = 'none';
                        setPopUpData({...popupData,is_bank_hold : true});
                    } else if (response.data.status === 'expired') {
                        document.getElementById('waitingLoader').style.display = 'none';
                        setPopUpData({...popupData,is_bureau_checked : true});
                        reUpload();
                    }else if(response.data.status === 'fail' && response.data.is_rejected === true){
                        document.getElementById('waitingLoader').style.display = 'none';
                        if(response.data.hasOwnProperty('data')){
                            sessionStorage.setItem('status_flag', response.data.data.appStatus.comment)
                        }
                        navigate("/regrate");
                    }else {
                        document.getElementById('waitingLoader').style.display = 'none';
                        toast.error(response.data.errors, {
                            position: toast.POSITION.TOP_CENTER,
                        });
                    }
                }).catch(error => {
                    document.getElementById('waitingLoader').style.display = 'none';
                    toast.error(error.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                });
        }
    }

    const skipBusinessData = (e) => {
        checkStateByMiddileware();
        let checkValidate = true;
        let checkerror = {};
        if (checkValidate) {
            const request_data = {
                'busniess_doc': businessImageBase64,
                'be_proof_number': busniess_number,
                'lov_code': lov_code,
                'state_code': state_code,
                'areaCode': areaCode,
                'yearOfReg': yearOfReg,
                'medicalCouncil': medicalCouncil,
                'be_proof_consent': consentvalue,
                'doc_type': ext,
                'business_code': business_code,
                'is_skip' : "Y",
            }            
            const headers = {
                'Authorization': `Bearer ${jwtToken}`,
                'latlong' : `${latitude},${longitude}`,
            }
            const encrypted = encryptData(request_data);
            document.getElementById('waitingLoader').style.display = 'block';
            axios.post(api_url + `/store-business-proof`, {encrypted : encrypted}, { headers })
                .then((response) => {
                    if (response.data.status === 'success') {
                        document.getElementById('waitingLoader').style.display = 'none';
                        toast.success(response.data.message, {
                            position: toast.POSITION.TOP_CENTER
                        });
                        if(response.data.data.udyamAssit === 1) {
                            navigate("/udyam-assit");
                        } else {
                            navigate('/bank-detail');
                        }
                    }
                    else if (response.data.status === "token") {
                        document.getElementById('waitingLoader').style.display = 'none';
                        toast.error(response.data.errors, {
                            position: toast.POSITION.TOP_CENTER,
                        });
                        sessionStorage.clear();
                        navigate('/login')
                    }else if (response.data.status === 'hold') {
                        document.getElementById('waitingLoader').style.display = 'none';
                        setPopUpData({...popupData,is_bank_hold : true});
                    } else if (response.data.status === 'expired') {
                        document.getElementById('waitingLoader').style.display = 'none';
                        setPopUpData({...popupData,is_bureau_checked : true});
                        reUpload();
                    }else if(response.data.status === 'fail' && response.data.is_rejected === true){
                        document.getElementById('waitingLoader').style.display = 'none';
                        if(response.data.hasOwnProperty('data')){
                            sessionStorage.setItem('status_flag', response.data.data.appStatus.comment)
                        }
                        navigate("/regrate");
                    }else {
                        document.getElementById('waitingLoader').style.display = 'none';
                        toast.error(response.data.errors, {
                            position: toast.POSITION.TOP_CENTER,
                        });
                    }
                }).catch(error => {
                    document.getElementById('waitingLoader').style.display = 'none';
                    toast.error(error.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                });
        }
    }
    function reUpload() {
        if(inputRef.current.value)
        {
            inputRef.current.value = null;
        }
        setBusinessImage('');
        setDisabled(true);
        setSrc(imageThumb);
        setSelectedImg(false);
        setReUpload(false);
        setFrontPDF('');
    }
    function removeErrorSms(e, fielName) {
        let checkerror = {}
        if (e.target.value.length > 0) {
            checkerror[fielName] = "";
            setValidationError({ ...validation, ...checkerror });
        }
    }
    function modifyRegistrationPlaceHolder(e) {
        if (e.type === 'focus') {
            setIsregActive(true);
        } else {
            setIsregActive(false);
        }
    }
    function modifyRegistrationyearPlaceHolder(e) {
        if (e.type === 'focus') {
            setIsregYearActive(true);
        } else {
            setIsregYearActive(false);
        }

        const typeYear = e.target.value.replace(/\D/g, "");
        const currentYear = new Date().getFullYear();
        if(typeYear !== '' && typeYear > currentYear){
            let checkerror = {}
            checkerror['registrationYearError'] = "Registration year must be under current year.";
            setValidationError({ ...validation, ...checkerror });
        }else{
            let checkerror = {}
            checkerror['registrationYearError'] = "";
            setValidationError({ ...validation, ...checkerror });
        }
    }
  

    function setUdyamIndustry(code) {
        setBusinessCode(code);
        let checkerror = {}
        setDisabled(true);
        setisImage(true);
        setBusniessNumber('');
        setBusinessImage('');
        setSrc(imageThumb);
        setconsentChecked("");
        setstateCode('');
        setareaCode('');
        setReUpload(false);
        setmedicalCouncil('');
        setyearOfReg('');
        checkerror['beProofError'] = "";
        setValidationError({ ...validation, ...checkerror });
        setbeproofTitle('Business Entity Proof');
        if(isSelectedImg==true)
        {
          setSelectedImg(true);
        }else{
           setSelectedImg(false);
        }
        
        if (code === 'OTH' || code === '') {
            setregHide(false);
            setstateHide(false);
            setareaHide(false);
            setCouncil(false);
            setregYear(false);
            setconsent(false);
            setisImage(false);
            setReUpload(false);
            setbeproofTitle('Business Entity Proof');
            if (code === 'OTH') {
                setDisabled(true);
                setisImage(true);
                setconsent(true);
            }
        }
        else if (code === "GRO" || code === "DEN" || code === "REST" || code === "HARD" || code === "CLOTH") {
            setareaHide(true);
            setCouncil(false);
            setregYear(false);
            setconsent(true);
            setstateHide(false);
            setregHide(true);
            setregLevel("Business Registration Number");
            if (code === "GRO") {
                setbeproofTitle('Shops and establishment certificate');
            }
            if (code === "DEN") {
                setbeproofTitle('Shops and establishment certificate / qualification certificate');
            }
            if (code === "HARD" || code === "CLOTH") {
                setbeproofTitle('Shops and establishment certificate');
            }
            if (code === "REST") {
                setbeproofTitle('FSSAI License ');
            }
        }
        else if (code === "DOC") {
            setCouncil(true);
            setregYear(true);
            setconsent(true);
            setregHide(false);
            setstateHide(false);
            setregHide(true);
            setareaHide(false);
            setregLevel("Business Registration Number");
            setbeproofTitle('MIC/NMC certificate');
        }
        else if (code === "PHA") {
            setregHide(true);
            setstateHide(true);
            setareaHide(false);
            setCouncil(false);
            setregYear(false);
            setconsent(true);
            setregLevel("License No");
            setbeproofTitle('Drug License');
        }
        else {
            setregLevel("Business Registration Number");
            setregHide(true);
            setareaHide(false);
            setCouncil(false);
            setstateHide(false);
            setregYear(false);
            setconsent(true);
            setbeproofTitle('Business Entity Proof');
        }
    }

    function getbeproofindustry() {
        const headers = {
            'Authorization': `Bearer ${usertoken}`,
            'latlong' : `${latitude},${longitude}`,
        }
        document.getElementById('waitingLoader').style.display = 'block';
        axios.get(api_url + `/get-selected-industry`, { headers })
            .then((response) => {
                document.getElementById('waitingLoader').style.display = 'none';
                if (response.data.status == 'success') {
                    document.getElementById('waitingLoader').style.display = 'none';
                    setlov_code(response.data.data.selectedIndustry.code);
                    setUdyamIndustry(response.data.data.selectedIndustry.code);
                    setbeMSt(response.data.data.getbeProof);
                    if((response.data.data.appData.udyam_lov_code !== '' && response.data.data.appData.udyam_lov_code !== null && response.data.data.appData.udyam_lov_code !== undefined) || response.data.data.appBizData.gst_acknowledged === 1){
                        setSkipped("Y");
                    }
                } else if (response.data.status === "token") {
                    document.getElementById('waitingLoader').style.display = 'none';
                    toast.error(response.data.errors);
                    sessionStorage.clear();
                    navigate('/login');
                }else if (response.data.status === "fail" && response.data.is_rejected === true) {
                    document.getElementById('waitingLoader').style.display = 'none';
                    toast.error(response.data.errors);
                    sessionStorage.clear();
                    navigate('/login');
                } else if (response.data.status === 'expired') {
                    document.getElementById('waitingLoader').style.display = 'none';
                    setPopUpData({...popupData,is_bureau_checked : true});
                }else {
                    document.getElementById('waitingLoader').style.display = 'none';
                    toast.error(response.data.errors, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            })
            .catch(error => {
                toast.error(error.message, {
                    position: toast.POSITION.TOP_CENTER
                });
            });
    }
  
    function onChangeHandlerState(e) {
        setstateCode(e.target.value);
        setPreSelectState(e.target.value);
    }
    function onChangeHandlerArea(e) {
        setareaCode(e.target.value);
        setPreSelectAreaCode(e.target.value);
    }
    function onChangeHandlerMedical(e) {
        setmedicalCouncil(e.target.value);
        setPreSelectMedicalCouncil(e.target.value);
    }
    function onChangeYearReg(e) {
        const value = e.target.value.replace(/\D/g, "");
        const limit = 4;
        const typeYear = value.slice(0, limit);
        setyearOfReg(typeYear);
    }

    const handleChange = (e) => {
        if (e.target.checked == true) {
            setconsentChecked('checked');
            setConsentValue('Y');
            setDisabled(false);
        } else {
            setconsentChecked('');
            setConsentValue('N');
            setDisabled(true);
        }
    }
    return (
        <>
            <ToastContainer />
            <div className="pt-3">
                <Heading Description="Let us help you build a future-ready company" BadgeDescription="Let us complete your Business KYC" user />
            </div>
            <Layout ImageSide="Product-intro">
                <h4 className="text-center mb-4">Please provide credible information about your business</h4>
                <div className="col-md-9 col-sm-12 mx-auto">
                    {regHide &&
                        <Input changeInput={(e) => onChangeReg(e)} isAstrix LabelName={regLevel} type="text" Id="Business_Registration_Number" inputval={busniess_number} placeholder={isregActive ? `Please enter ${regLevel}` : ""} keyFocus={(e) => modifyRegistrationPlaceHolder(e)} blurEvent={(e) => modifyRegistrationPlaceHolder(e)} />
                    }
                    {(validation.regError !== '') ?
                        <span className="text-danger fontsize-13">{validation.regError}</span>
                        : null
                    }
                    {stateHide &&
                        <div className="col-md-12">
                            <Select LabelName="Select State/ UT" preSelect={preSelectState} isAstrix selected={(e) => onChangeHandlerState(e)} SelectData={beproofmst} />
                            {(validation.stateError !== '') ?
                                <span className="text-danger fontsize-13">{validation.stateError}</span>
                                : null
                            }
                        </div>}
                    {areaHide &&
                        <div className="col-md-12">
                            <Select LabelName="Select Area Code" preSelect={preSelectAreaCode} isAstrix selected={(e) => onChangeHandlerArea(e)} SelectData={beproofmst} />
                            {(validation.areaError !== '') ?
                                <span className="text-danger fontsize-13">{validation.areaError}</span>
                                : null
                            }
                        </div>
                    }
                    {council &&
                        <div className="col-md-12">
                            <Select LabelName="Select Medical Council" preSelect={preSelectMedicalCouncil} isAstrix selected={(e) => onChangeHandlerMedical(e)} SelectData={beproofmst} />
                            {(validation.medicalError !== '') ?
                                <span className="text-danger fontsize-13">{validation.medicalError}</span>
                                : null
                            }
                        </div>
                    }
                    {regYear &&
                        <div className="col-md-12">
                            <Input changeInput={(e) => onChangeYearReg(e)} isAstrix LabelName="Year of Registration" type="text" Id="year_of_registration" inputval={yearOfReg} placeholder={isregYearActive ? "Please enter Year of Registration" : ""} keyFocus={(e) => modifyRegistrationyearPlaceHolder(e)} blurEvent={(e) => modifyRegistrationyearPlaceHolder(e)} maxlength="4" max="2024" min="1924"/>
                            {(validation.registrationYearError !== '') ?
                                <span className="text-danger fontsize-13">{validation.registrationYearError}</span>
                                : null
                            }
                        </div>
                    }
                    {isImage &&
                        <div className="col-md-12 mt-2">
                            <ChooseInput isAstrix ispdf={frontpdf} isIndividual="true" isFourMB="BE-PROOF" src={src} inputRef={inputRef} title={beproofTitle} isAccept={'.png, .jpg, .jpeg, .pdf'} selectFile={onFileChangeHandler} />
                            {(validation.beProofError !== '') ?
                                <span className="text-danger fontsize-13">{validation.beProofError}</span>
                                : null
                            }
                        </div>
                    }
                    {consent &&
                        <div className="col-md-12">
                            <CheckBoxInput isChecked={consentChecked} isAstrix ChangeCheck={(e) => handleChange(e)}>I give my consent to validate my information towards my loan application through reliable sources.</CheckBoxInput>
                            {(validation.consentError !== '') ?
                                <span className="text-danger fontsize-13">{validation.consentError}</span>
                                : null
                            }
                        </div>
                    }
                </div>
                {isReUpload &&
                    <div className="text-center">
                        <a href="javascript:;" onClick={() => { reUpload() }} className="text-center fontsize-12 fw-bold">Re-upload</a>
                    </div>
                }
                <PageFooter setDisabled={isDisabled} BtnName="I SUBMIT" TermsCondition="I acknowledge that by submitting, U GRO may access the file uploaded from this device." ButtonClick={(e) => proceedBusinessProof(e)} />
                <div className="text-center">
                    {
                        (isSkipped === 'Y') && <><a className='mt-0 text-center fontsize-14 fw-bold' href='javascript:;' onClick={(e) => skipBusinessData(e)}><span className="fa fa-home">Skip</span></a> <br/></>
                    }
                </div>
            </Layout>
        </>
    );
}

export default BusinessData;