import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import ChooseInput from "../common-components/ChooseInput";
import Heading from "../common-components/Heading";
import Layout from "../common-components/Layout";
import Modal from "../common-components/Modal";
import PageFooter from "../common-components/PageFooter";
import config from "../../config.json";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import imageThumb from "../../images/image-thumb.png";
import { BureauContext } from "../Context/BureauContext";

const KycEntity = () => {

    const navigate = useNavigate();
    const [modal, setModal] = useState(false);
    const api_url = config.API_URL;
    const [disablebtn, setDisableBtn] = useState(true);
    const [src, setSrc] = useState(imageThumb);
    const [panSrc, setPanImage] = useState("");
    const [isReupload, setReUpload] = useState(false);
    const inputRef = useRef(null);
    const usertoken = sessionStorage.getItem('token');
    const {popupData, setPopUpData, checkStateByMiddileware} = useContext(BureauContext);
    const onFileChangeHandler = (e) => {
        setSrc(URL.createObjectURL(e.target.files[0]));
        setPanImage(e.target.files[0]);
        setDisableBtn(false);
        setReUpload(true);
    }

    const proceedEntity = (e) => {
        checkStateByMiddileware();
        const form_data = new FormData();
        form_data.append('entity', panSrc);
        const headers = {
            'Authorization': `Bearer ${usertoken}`,
        }
        setModal(false);
        document.getElementById('waitingLoader').style.display = 'block';
        axios.post(api_url + `/upload-kyc-entity`, form_data, { headers })
            .then((response) => {
                document.getElementById('waitingLoader').style.display = 'none';
                if (response.data.status == 'success') {
                    toast.success(response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                    setModal(false);
                    navigate("/udyam-detail")
                }else if(response.data.status === "expired") {
                    document.getElementById('waitingLoader').style.display = 'none';
                    setPopUpData({...popupData,is_bureau_checked : true});
                }else if (response.data.status === "token") {
                    toast.error(response.data.errors, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                    sessionStorage.clear();
                    navigate('/login')
                }else if (response.data.status === 'fail' && response.data.is_rejected === true) {
                    document.getElementById('waitingLoader').style.display = 'none';
                    if(response.data.hasOwnProperty('data')){
                    sessionStorage.setItem('status_flag', response.data.data.appStatus.comment)
                }
                    navigate("/regrate");
                }else {
                    document.getElementById('waitingLoader').style.display = 'none';
                    toast.error(response.data.errors, {
                        position: toast.POSITION.TOP_CENTER
                    });
                    setModal(false);
                }
            }).catch(error => {
                document.getElementById('waitingLoader').style.display = 'none';
                toast.error(error.message, {
                    position: toast.POSITION.TOP_CENTER
                });
            });
    }

    function reUpload() {
        inputRef.current.value = null;
        setPanImage('');
        setDisableBtn(true);
        setSrc(imageThumb);
        setReUpload(false);
    }

    return (
        <>
            <ToastContainer />
            <div className="pt-3">
                <Heading Description="Let us help you build a future-ready company" BadgeDescription="Let us complete your Business KYC" user />
            </div>
            <Layout ImageSide="Product-intro">
                <h4 className="text-center mb-4">Please upload clear copy of your Business photo with Business name clearly visible</h4>
                <div className="col-md-8 col-sm-12 mx-auto">
                    <ChooseInput title="Business Photo" src={src} inputRef={inputRef} isAccept={'.png, .jpg, .jpeg'} selectFile={onFileChangeHandler} isIndividual="true"/>
                </div>
                <PageFooter setDisabled={disablebtn} BtnName="I CONFIRM" TermsCondition="By confirming, I understand U GRO will have access to the file I will upload from this device and the access to camera and location could be in use" ButtonClick={() => setModal(true)} />
                {isReupload &&
                    <div className="text-center">
                        <a href="javascript:void();" className="text-center fontsize-12 fw-bold" onClick={() => reUpload()}>Re-upload</a>
                    </div>
                }
            </Layout>
            {modal &&
                <Modal Modal={Modal} setModal={setModal} modalSize="modal-sm" Heading="" Footer ButtonSize="Two" RightBtn="Yes" LeftBtn="No" RightBtnClick={() => { proceedEntity() }} LeftBtnClick={() => setModal(false)}  >
                    <p className="text-center">I Confirm that I own this Business, and this is my Business set up photo</p>
                </Modal>}
        </>
    );
}
export default KycEntity;