import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Heading from "../common-components/Heading";
import Input from "../common-components/Input";
import Layout from "../common-components/Layout";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from "../../config.json";
import axios from "axios";
import { CustomButton } from "../common-components/Buttons";
import { BureauContext } from "../Context/BureauContext";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns';
import { Gender } from "../general-questions/SelectStaticData";
import Select from "../common-components/Select";
import ApprovedModal from "../common-components/modal/ApprovedModal";
import { encryptData } from "../../encrypt";

const PanDetail = () => {
    const api_url = config.API_URL;
    const bajaj_promocode = config.PROMOCODE;
    const BAJAJ_API_URL = config.BAJAJ_API_URL;
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [pan, setPan] = useState('');
    const [pinCode, setPinCode] = useState('');
    const [gender, setGender] = useState('');
    const [pregender, setpreGender] = useState('');
    const [disabled, setDisabledBtn] = useState(false);
    const [isDisabled, setDisabled] = useState(false);
    const token = sessionStorage.getItem('token');
    const [isActive, setIsActive] = useState(false);
    const [isdobActive, setisdobActive] = useState(false);
    const [ispanActive, setPanActive] = useState(false);
    const [ispinCodeActive, setPinCodeActive] = useState(false)
    const [validation, setValidationError] = useState({ nameError: '', dobError: '', panError: '', pinCodeError: '', genderError : '' });
    const { sanctionHtml, setsanctionHtml, logout, approvalAmount, setApprovalAmount, popupData, setPopUpData, checkStateByMiddileware} = useContext(BureauContext);
    const [dob, setDob] = useState('');
    const locate_permission = sessionStorage.getItem('locate_permission');
    const [modal, setModal] = useState(false);
    const [lan, setLan] = useState('');
    const [isApiType, setIsAPItype] = useState('');
    const [loanAmount, setloanAmount] = useState('');
    const [nextDate, setNextDate] = useState('');
    const [stageSMS, setstageSMS] = useState('');
    const [mobileapp, setmobileapp] = useState('');
    const [crn, setCRN] = useState('');
    const [laf, setLAF] = useState('');
    const usertoken = sessionStorage.getItem('token');
    const latitude = sessionStorage.getItem("latitude");
    const longitude = sessionStorage.getItem("longitude");
    const promocode = sessionStorage.getItem("product_code");

    useEffect(() => {
        getUserDetail();
    }, []);

    const getUserDetail = () => {
        const headers = {
            'Authorization': `Bearer ${usertoken}`,
            'latlong' : `${latitude},${longitude}`,
          }
        document.getElementById('waitingLoader').style.display = 'block';
        axios.get(BAJAJ_API_URL +`/get-pan-detail`,{headers}).then((response) => {
            if (response.data.status === 'success') {
                document.getElementById('waitingLoader').style.display = 'none';
                if(response.data.data.promocode === bajaj_promocode){
                    setDob(new Date(response.data.data.dob));
                    setName(response.data.data.user_input_name);
                    setPan(response.data.data.pan_no);
                    setGender(response.data.data.gender);
                    setpreGender(response.data.data.gender);
                    setPinCode(response.data.data.pin_code);
                    setDisabled(true);
                }
            }else if (response.data.status === "token") {
                toast.error(response.data.errors);
                sessionStorage.clear();
                navigate('/login');
                } else {
                    document.getElementById('waitingLoader').style.display = 'none';
                    toast.error(response.data.errors, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
        }).catch((erro) => {

        })
    }

    const onChangeName = (e) => {
        setIsActive(false);
        const value = e.target.value.replace(/[^A-Za-z\s]/ig, '')
        const limit = 50;
        setName(value.slice(0, limit));
        if (e.target.value.length >= 50) {
            setDisabledBtn(false);
        } else {
            setDisabledBtn(true);
        }
        removeErrorSms(e, 'nameError');
    }
    const onChangeDob = (e) => {
        setIsActive(false);
        setDob(e);
        let checkerror = {}
        checkerror['dobError'] = '';
        setValidationError({ ...validation, ...checkerror });
    }
    const onChangePan = (e) => {
        setIsActive(false);
        const value = e.target.value.toUpperCase();
        const limit = 10;
        setPan(value.slice(0, limit));
        if (e.target.value.length >= 10) {
            setDisabledBtn(false);
        } else {
            setDisabledBtn(true);
        }
        removeErrorSms(e, 'panError');
    }

    const onChangePinCode = (e) => {
        setPinCodeActive(false);
        const value = e.target.value.replace(/\D/g, "");
        const limit = 6;
        setPinCode(value.slice(0, limit));
        if (e.target.value.length >= 6) {
            setDisabledBtn(false);
        } else {
            setDisabledBtn(true);
        }
        removeErrorSms(e, 'pinCodeError');
    }

    const submitPanDetail = async () => {
        checkStateByMiddileware();
        if (validate()) {
            const headers = {
                'Authorization' : `Bearer ${token}`,
                'Content-Type': 'application/json',
                'latlong' : `${latitude},${longitude}`,
            };
            console.log("dob is here",dob);
            const payload = {
                name : name,
                dob : format(new Date(dob), 'dd-MM-yyyy'),
                pan_no : pan,
                pincode : pinCode,
                gender : gender,
            }
            const encrypted = encryptData(payload);
            const requestOptions = {
                method : 'post',
                headers : headers,
                body : JSON.stringify({encrypted : encrypted}),
            };
            document.getElementById('waitingLoader').style.display = 'block';
            await fetch(api_url+`/submit-pan-by-input`,requestOptions).then((res) => {
                return res.json();
            }).then((result) => {
                document.getElementById('waitingLoader').style.display = 'none';
                if(result.status === 'token'){
                    sessionStorage.clear();
                    navigate('/login');
                }else if(result.status === "hold") {
                    document.getElementById('waitingLoader').style.display = 'none';
                    setPopUpData({...popupData, is_bank_hold : true});
                }else if(result.status === 'expired'){
                    document.getElementById('waitingLoader').style.display = 'none';
                    setPopUpData({...popupData, is_bureau_checked : true});
                }else if (result.status === 'APPROVED') {
                    setModal(true);
                    setLan(result.data.approved.lan_no);
                    setIsAPItype('approved');
                    setloanAmount(result.data.approved.approved_amount);
                    setNextDate();
                    setstageSMS('Your Application Is Already Approved With Mobile Number');
                    setmobileapp(result.data.approved.mobile_no);
                    setCRN(result.data.approved.crn);
                    setLAF(result.data.approved.laf);
                }else if (result.status === 'REJECTED') {
                    setModal(true);
                    setIsAPItype('rejected');
                    setNextDate(result.data.rejected.next_date);
                    setstageSMS(result.data.rejected.message);
                    setmobileapp(result.data.rejected.mobile_no);
                }else if(result.status === 'success'){
                    document.getElementById('waitingLoader').style.display = 'none';
                    sessionStorage.setItem("userData",JSON.stringify(result.data.user));
                    sessionStorage.setItem("approve_amount",result.data.PreQualifiedAmount);
                    navigate('/principal-approval');
                }else if (result.status === 'fail' && result.is_rejected === true) {
                    document.getElementById('waitingLoader').style.display = 'none';
                    if(result.hasOwnProperty('data')){
                    sessionStorage.setItem('status_flag', result.data.appStatus.comment)
                }
                    navigate("/regrate");
                }else{
                    document.getElementById('waitingLoader').style.display = 'none';
                    toast.error(result.errors, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                }
            }).catch((err) => {
                document.getElementById('waitingLoader').style.display = 'none';
                toast.error(err, {
                    position: toast.POSITION.TOP_CENTER,
                });
            })
        }
    }

    const validate = () => {
        let checkValidate = true;
        let checkerror = {}
        if(name === '' || name === null) {
            checkerror['nameError'] = "Please enter Name";
            setValidationError({ ...validation, ...checkerror });
            checkValidate = false;
        }
        if(dob === null || dob === ''){
            checkerror['dobError'] = "Please enter DOB";
            checkValidate = false;
            setValidationError({ ...validation, ...checkerror });
        }

        if(gender === null || gender === ''){
            checkerror['genderError'] = "Please select gender";
            checkValidate = false;
            setValidationError({ ...validation, ...checkerror });
        }

        if(locate_permission === "denied"){
            toast.error('Please allow device location', {
                position: toast.POSITION.TOP_CENTER,
            });
            checkValidate = false;
        }

        return checkValidate;
    }

    function modifyNamePlaceHolder(e) {
        if (e.type === 'focus') {
            setIsActive(true);
        } else {
            setIsActive(false);
            if (e.target.value.length === 10) {
                setDisabledBtn(false);
            } else {
                setDisabledBtn(true);
            }
        }
    }

    function modifydobPlaceHolder(e) {
        if (e.type === 'focus') {
            setisdobActive(true);
        } else {
            setisdobActive(false);
            if (e.target.value.length === 10) {
                setDisabledBtn(false);
            } else {
                setDisabledBtn(true);
            }
        }
    }

    function modifyPanPlaceHolder(e) {
        if (e.type === 'focus') {
            setPanActive(true);
        } else {
            setPanActive(false);
            if (e.target.value.length === 10) {
                setDisabledBtn(false);
            } else {
                setDisabledBtn(true);
            }
        }
    }

    function modifypinCodePlaceHolder(e) {
        if (e.type === 'focus') {
            setPinCodeActive(true);
        } else {
            setPinCodeActive(false);
            if (e.target.value.length === 6) {
                setDisabledBtn(false);
            } else {
                setDisabledBtn(true);
            }
        }
    }
    function removeErrorSms(e, fielName) {
        let checkerror = {}
        if (e.target.value.length > 0) {
            checkerror[fielName] = "";
            setValidationError({ ...validation, ...checkerror });
        }
    }
    const onChangeGender = (e) => {
        setGender(e.target.value);
        setpreGender(e.target.value);
    }
    function dateofBirth(e){
        let checkerror = {}
        if(e.keyCode !== 8) {
            e.preventDefault();
        }
        checkerror['dobError'] = "Please select calendar";
        setValidationError({ ...validation, ...checkerror });
        setDob('');
        return false;
    }
    return (
        <>
            <ToastContainer />
            <div className="mt-2">
                <Heading Description="U GRO Capital can help you with your business finance" BadgeDescription="Let us sign you up" />
            </div>
            <Layout ImageSide="Register">
                <h4 className="text-center mb-4"> USER DETAILS</h4>
                <div className="col-md-8 col-sm-12 mx-auto">
                    <Input LabelName="NAME" disabled={isDisabled} keyFocus={(e) => modifyNamePlaceHolder(e)} type="text" blurEvent={(e) => modifyNamePlaceHolder(e)} Id="name" inputval={name} changeInput={(e) => onChangeName(e)} placeholder={isActive ? "Please enter your Name" : ""} isAstrix />
                    {(validation.nameError !== '') ?
                        <span className="text-danger fontsize-13">{validation.nameError}</span> : null
                    }
                    <label className="form-label mb-0 fontsize-12 font-wt-600 text-orange">DOB<span className="required-field text-danger">*</span></label>
                    <DatePicker placeholder="DD-MM-YYYY" disabled={isDisabled} showIcon dateFormat="dd MMM yyyy" className="form-control" onKeyDown={(e)=>dateofBirth(e)} value={dob} selected={dob} onChange={(e) => onChangeDob(e) } peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" />
                    {(validation.dobError !== '') ?
                        <span className="text-danger fontsize-13">{validation.dobError}</span> : null
                    }
                    <Input LabelName="PAN" disabled={isDisabled} keyFocus={(e) => modifyPanPlaceHolder(e)} type="text" blurEvent={(e) => modifyPanPlaceHolder(e)} inputval={pan} changeInput={(e) => onChangePan(e)} placeholder={ispanActive ? "Please enter PAN" : ""} isAstrix />
                    {(validation.panError !== '') ?
                        <span className="text-danger fontsize-13">{validation.panError}</span> : null
                    }
                    <Input LabelName="PIN CODE" disabled={isDisabled} keyFocus={(e) => modifypinCodePlaceHolder(e)} type="text" blurEvent={(e) => modifypinCodePlaceHolder(e)} inputval={pinCode} changeInput={(e) => onChangePinCode(e)} placeholder={ispinCodeActive ? "Please Enter Pin Code" : ""} isAstrix />
                    {(validation.pinCodeError !== '') ?
                        <span className="text-danger fontsize-13">{validation.pinCodeError}</span> : null
                    }
                    <Select LabelName="GENDER" preSelect={pregender} disabled={isDisabled} selected={(e) => onChangeGender(e)} SelectData={Gender} isAstrix/>
                    {(validation.genderError !== '') ?
                        <span className="text-danger fontsize-13">{validation.genderError}</span> : null
                    }
                </div>
                <div className="text-center mt-3">
                    <CustomButton isDisabled={disabled} clickBtn={() => { submitPanDetail() }} btnname="I SUBMIT" btn_col="col-md-4" />
                </div>
            </Layout>

            {modal && <ApprovedModal isApiType={isApiType} lan={lan} crn={crn} loanAmount={loanAmount} stageSMS={stageSMS} mobileapp={mobileapp} />
            }
        </>
    );
}
export default PanDetail;