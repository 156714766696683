import React from "react";


const Input = ({LabelName , isAstrix, disabled, type, Id,placeholder, changeInput, inputval, constVal, Value, udyam, blurEvent, autoComplete,keyFocus, read}) =>{
    const astrix = <span className="required-field text-danger">*</span>;

    return (
        <>
            {!constVal ? <div className="mb-0">
                <label htmlFor={Id} className="form-label mb-0 fontsize-12 font-wt-600 text-orange">{LabelName} {isAstrix && astrix}</label>
                <input type={type} pattern="[0-9]*" className="form-control" id={Id} onFocus={keyFocus} onChange={changeInput} onBlur={blurEvent} value={inputval} placeholder={placeholder} disabled={disabled}  readonly={read}/>

            </div> :
                <div className="mb-0" id="udyam-input">
                    <label htmlFor={Id} className="form-label mb-0 fontsize-12 font-wt-600 text-orange">{LabelName} {isAstrix && astrix}</label>
                    <input type={type} className="form-control" id={Id} placeholder={placeholder} onChange={changeInput} onBlur={blurEvent} disabled={disabled} value={inputval} readonly={read}/>
                    <span className={`udyam-span ${inputval !== undefined && "udyam-span2"}`}>{udyam}</span>

                </div>}       
             </>
    );
}
export default Input;