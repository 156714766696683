import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { CustomButton } from "../common-components/Buttons";
import Heading from "../common-components/Heading";
import Layout from "../common-components/Layout";
import config from "../../config.json";
import { toast } from "react-toastify";
import { BureauContext } from "../Context/BureauContext";

const ProductIntro = () => {

    const navigate = useNavigate();
    const api_url = config.API_URL;
    const userToken = sessionStorage.getItem('token');
    var user = sessionStorage.getItem('userData');
    const {popupData, setPopUpData, checkStateByMiddileware} = useContext(BureauContext);
    const proceedProductIntro = async () => {
        checkStateByMiddileware();
        const headers = {
            'Authorization' : `Bearer ${userToken}`
        };

        const requestOptions = {
            method : "POST",
            headers : headers
        }
        document.getElementById('waitingLoader').style.display = 'block';
        await fetch(api_url+`/store-intro`, requestOptions).then((res) => {
            return res.json();
        }).then((result) => {
            document.getElementById('waitingLoader').style.display = 'none';
            if(result.status === "token"){
                sessionStorage.clear();
                navigate('/login');
            }else if(result.status === "success"){
                navigate('/kyc-document');
            }else if(result.status === "expired"){
                document.getElementById('waitingLoader').style.display = 'none';
                setPopUpData({...popupData,is_bureau_checked : true});
            }else if(result.status === "hold") {
                document.getElementById('waitingLoader').style.display = 'none';
                setPopUpData({...popupData,is_bank_hold : true});
            }else if(result.status === "fail"){
                toast.error(result.errors);
            }
        }).catch((err) => {
            document.getElementById('waitingLoader').style.display = 'none';
            toast.error(err);
        })
    }
    
    return (
        <>
            <div className="pt-3">
                <Heading Description="U GRO is committed to help you unlock world of immense opportunities" BadgeDescription="Allow us to give you a quick product overview" user={user} />
            </div>
            <Layout ImageSide="Product-intro">
                <h5 className="text-center mb-4">It is a unique Line of Credit product, with key features mentioned below:</h5>
                <ol className="col-md-11 mx-auto">
                   
                    {
                    Product.map((key, index) => {
                        return (
                            <>
                                <li key={index}>{key}</li>
                            </>
                        );
                    })}
                </ol>
                <div className="text-center mt-4">
                    <CustomButton btnname="LET'S EXPLORE" btn_col="col-md-4" clickBtn={() => proceedProductIntro()} />
                </div>
            </Layout>
        </>
    );
}
export default ProductIntro;

export const Product = ["Instant and On-tap credit solution", "End to End digital journey with no need of physical paper", "A dedicated GRO X App to help you make and receive Real time, Secure and Safe payments via digital modes like UPI (QR, VPA) and Bank transfers (IMPS, NEFT, RTGS)", "Interest will be charged basis utilisation", "Option to convert the outstanding into affordable EMIs"
];