import React, { useContext, useEffect } from "react";
import Heading from "../common-components/Heading";
import Layout from "../common-components/Layout";
import { ToastContainer, toast } from 'react-toastify';
import PageFooter from "../common-components/PageFooter";
import { BureauContext } from "../Context/BureauContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import confige from "../../config.json";

const Regrate = () => {
    const navigate = useNavigate();
    const apiurl = confige.API_URL;
    const usertoken = sessionStorage.getItem('token');
    const status_flag = sessionStorage.getItem('status_flag');
  
    const {logout} = useContext(BureauContext);
    useEffect(() => {
      if(usertoken !== '' && usertoken !== null && usertoken !== undefined){
        checkAppData();
      }
    }, []);    
    const checkAppData = () => {
        const headers = {
          'Authorization': `Bearer ${usertoken}`,
        }
        axios.post(apiurl + `/check-verify-jwt`, '', { headers })
          .then((response) => {
            if (response.data.status === 'token') {
              sessionStorage.clear();
              navigate('/');
            } else if(response.data.status === 'fail' && response.data.is_rejected === true) {
              if(response.data.data.appStatus.status_id === 69 && response.data.data.appStatus.comment === "AutoReject"){
                sessionStorage.setItem('status_flag', "AutoReject");
              }else if(response.data.data.appStatus.status_id === 69 && response.data.data.appStatus.comment !== "AutoReject"){
                sessionStorage.setItem('status_flag', "NormalReject");
              }
            }else {
              toast.error(response.data.errors, {
                position: toast.POSITION.TOP_CENTER
              });
            }
          })
          .catch(error => {
            toast.error(error.message, {
              position: toast.POSITION.TOP_CENTER
            });
          });
      }
    return (
        <>
            <ToastContainer/>
            <div className="pt-3">
                <Heading Description="You are all set now to fuel your business growth" BadgeDescription="U GRO is there with you in your journey" user />
            </div>
            <Layout ImageSide="Product-intro">
              {
                (status_flag === "AutoReject") ? <>
                <h4 className="text-center mb-4">Dear Customer, Your application has expired. You may reapply to avail GroX Credit Line.</h4>               
                {
                  (usertoken !== '' && usertoken !== null && usertoken !== undefined) ? 
                  <PageFooter BtnName="OK" ButtonClick={() => logout() } /> : <PageFooter BtnName="OK" ButtonClick={() => navigate('/') } />
                }
                
                </> : <>
                <h4 className="text-center mb-4">Uh Oh! We regret to state that your application does not meet our internal policy norms. You may re-apply after 6 months.</h4>               
                {
                  (usertoken !== '' && usertoken !== null && usertoken !== undefined) ? 
                  <PageFooter BtnName="OK" ButtonClick={() => logout() } /> : <PageFooter BtnName="OK" ButtonClick={() => navigate('/') } />
                }
                </>
              }
                
            </Layout>

        </>
    );
}
export default Regrate;