import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import ChooseInput from "../common-components/ChooseInput";
import Heading from "../common-components/Heading";
import Input from "../common-components/Input";
import Layout from "../common-components/Layout";
import PageFooter from "../common-components/PageFooter";
import Tooltip from "../common-components/Tooltip";
import config from "../../config.json";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import imageThumb from "../../images/image-thumb.png";
import { BureauContext } from "../Context/BureauContext";
import Select from "../common-components/Select";
import { CheckBoxInput } from "../common-components/CheckInput";
import { encryptData } from "../../encrypt";

const BeProof = () => {
    const navigate = useNavigate();
    const api_url = config.API_URL;
    const [isDisabled, setDisabled] = useState(true);
    const [isReUpload, setReUpload] = useState(false);
    const inputRef = useRef(null);
    const jwtToken = sessionStorage.getItem('token');
    const { popupData, setPopUpData, checkStateByMiddileware, checkLocationPermission } = useContext(BureauContext);
    const [validation, setValidationError] = useState({ regError: '', beProofError: '', stateError: "", areaError: "", medicalError: "", registrationError: "", consentError: "", industriesError: "" });
    const usertoken = sessionStorage.getItem('token');
    const [industries, setIndustries] = useState([]);
    const [beproofmst, setbeMSt] = useState([]);
    const [consent, setconsent] = useState(false);
    const [lov_code, setlov_code] = useState('');
    const [consentvalue, setConsentValue] = useState('N');
    const [isImage, setisImage] = useState(false);
    const [consentChecked, setconsentChecked] = useState("");
    const [business_code, setBusinessCode] = useState('');
    const [isSkipped, setSkipped] = useState("N");
    const [skip, setSkip] = useState("N");
    const [selectIndustry, setSelectIndustry] = useState('');
    const latitude = sessionStorage.getItem("latitude");
    const longitude = sessionStorage.getItem("longitude");

    useEffect(() => {
        if(checkLocationPermission()){
        getbeproofindustry();
        }else{
            toast.error('Please allow device location', {
                position: toast.POSITION.TOP_CENTER,
            });
        }
    }, []);

 
    const proceedBeProof = (e) => {
        checkStateByMiddileware();
        let checkValidate = true;
        let checkerror = {};
        if ((lov_code === '' || lov_code === undefined)) {
            checkerror['industriesError'] = "Please select Industry";
            checkValidate = false;
            setValidationError({ ...validation, ...checkerror });
        }
        if (checkValidate) {
            const request_data = {
                'lov_code': lov_code,
                'is_industry_skipped': "N",
            }            
            const headers = {
                'Authorization': `Bearer ${jwtToken}`,
                'latlong' : `${latitude},${longitude}`,
            }
            const encrypted = encryptData(request_data);
            document.getElementById('waitingLoader').style.display = 'block';
            axios.post(api_url + `/store-skip-industry`, {encrypted : encrypted}, { headers })
                .then((response) => {
                    if (response.data.status === 'success') {
                        document.getElementById('waitingLoader').style.display = 'none';
                        toast.success(response.data.message, {
                            position: toast.POSITION.TOP_CENTER
                        });
                        navigate("/business-proof");
                    }
                    else if (response.data.status === "token") {
                        document.getElementById('waitingLoader').style.display = 'none';
                        toast.error(response.data.errors, {
                            position: toast.POSITION.TOP_CENTER,
                        });
                        sessionStorage.clear();
                        navigate('/login')
                    }else if (response.data.status === 'hold') {
                        document.getElementById('waitingLoader').style.display = 'none';
                        setPopUpData({...popupData,is_bank_hold : true});
                    } else if (response.data.status === 'expired') {
                        document.getElementById('waitingLoader').style.display = 'none';
                        setPopUpData({...popupData,is_bureau_checked : true});
                    }
                    else {
                        let checkerror = {};
                        document.getElementById('waitingLoader').style.display = 'none';
                        checkerror['beProofError'] = response.data.errors;
                        setValidationError({ ...validation, ...checkerror });
                    }
                }).catch(error => {
                    document.getElementById('waitingLoader').style.display = 'none';
                    toast.error(error.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                });
        }
    }

    const skipIndustry = (e) => {
        checkStateByMiddileware();
        let checkValidate = true;
        let checkerror = {};
        if (checkValidate) {
            const request_data = {
                'lov_code': lov_code,
                'is_industry_skipped': "Y",
            }            
            const headers = {
                'Authorization': `Bearer ${jwtToken}`,
                'latlong' : `${latitude},${longitude}`,
            }
            const encrypted = encryptData(request_data);
            document.getElementById('waitingLoader').style.display = 'block';
            axios.post(api_url + `/store-skip-industry`, {encrypted : encrypted}, { headers })
                .then((response) => {
                    if (response.data.status === 'success') {
                        document.getElementById('waitingLoader').style.display = 'none';
                        toast.success(response.data.message, {
                            position: toast.POSITION.TOP_CENTER
                        });
                        navigate("/business-proof");
                    }
                    else if (response.data.status === "token") {
                        document.getElementById('waitingLoader').style.display = 'none';
                        toast.error(response.data.errors, {
                            position: toast.POSITION.TOP_CENTER,
                        });
                        sessionStorage.clear();
                        navigate('/login')
                    }else if (response.data.status === 'hold') {
                        document.getElementById('waitingLoader').style.display = 'none';
                        setPopUpData({...popupData,is_bank_hold : true});
                    } else if (response.data.status === 'expired') {
                        document.getElementById('waitingLoader').style.display = 'none';
                        setPopUpData({...popupData,is_bureau_checked : true});
                    }else {
                        let checkerror = {};
                        document.getElementById('waitingLoader').style.display = 'none';
                        checkerror['beProofError'] = response.data.errors;
                        setValidationError({ ...validation, ...checkerror });
                    }
                }).catch(error => {
                    document.getElementById('waitingLoader').style.display = 'none';
                    toast.error(error.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                });
        }
    }

    function onChangeHandlerType(e) {
        setlov_code(e.target.value);
        setSelectIndustry(e.target.value);
        setDisabled(false);
    }
    
    function removeErrorSms(e, fielName) {
        let checkerror = {}
        if (e.target.value.length > 0) {
            checkerror[fielName] = "";
            setValidationError({ ...validation, ...checkerror });
        }
    }
    
    function getbeproofindustry() {
        const headers = {
            'Authorization': `Bearer ${usertoken}`,
            'latlong' : `${latitude},${longitude}`,
        }
        document.getElementById('waitingLoader').style.display = 'block';
        axios.get(api_url + `/get_beproof_industries`, { headers })
            .then((response) => {
                document.getElementById('waitingLoader').style.display = 'none';
                if (response.data.status == 'success') {
                    document.getElementById('waitingLoader').style.display = 'none';
                    setIndustries(response.data.data.getindustry);
                    if((response.data.data.appData.udyam_lov_code !== '' && response.data.data.appData.udyam_lov_code !== null && response.data.data.appData.udyam_lov_code !== undefined)){
                        setSkipped("Y");
                    }
                } else if (response.data.status === "token") {
                    document.getElementById('waitingLoader').style.display = 'none';
                    toast.error(response.data.errors);
                    sessionStorage.clear();
                    navigate('/login');
                } else {
                    document.getElementById('waitingLoader').style.display = 'none';
                    toast.error(response.data.errors, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            })
            .catch(error => {
                toast.error(error.message, {
                    position: toast.POSITION.TOP_CENTER
                });
            });
    }
  
    const handleChange = (e) => {
        if (e.target.checked == true) {
            setconsentChecked('checked');
            setConsentValue('Y');
            setDisabled(false);
        } else {
            setconsentChecked('');
            setConsentValue('N');
            setDisabled(true);
        }
    }
    return (
        <>
            <ToastContainer />
            <div className="pt-3">
                <Heading Description="Let us help you build a future-ready company" BadgeDescription="Let us complete your Business KYC" user />
            </div>
            <Layout ImageSide="Product-intro">
                <h4 className="text-center mb-4">Please provide credible information about your business</h4>
                <div className="col-md-9 col-sm-12 mx-auto">
                    <div className="col-md-12">
                        <Select LabelName="Select Industry" preSelect={selectIndustry} isAstrix selected={(e) => onChangeHandlerType(e)} SelectData={industries} />
                    </div>
                    {(validation.industriesError !== '') ?
                        <span className="text-danger fontsize-13">{validation.industriesError}</span>
                        : null
                    }
                </div>
                <PageFooter setDisabled={isDisabled} BtnName="I SUBMIT" TermsCondition="I acknowledge that by submitting, U GRO may access the location, camera and the file uploaded from this device." ButtonClick={(e) => proceedBeProof(e)} />
                <div className="text-center">
                    {
                        (isSkipped === 'Y') && <><a className='mt-0 text-center fontsize-14 fw-bold' href='javascript:;' onClick={(e) => skipIndustry(e)}><span className="fa fa-home">Skip</span></a> <br/></>
                    }
                </div>
            </Layout>
        </>
    );
}
export default BeProof;