import React, { useContext} from "react";
import {useNavigate} from "react-router-dom";
import Heading from "../common-components/Heading";
import Layout from "../common-components/Layout";
import { ToastContainer } from 'react-toastify';
import PageFooter from "../common-components/PageFooter";
import { BureauContext } from "../Context/BureauContext";

const LinkExpired = () => {
    const navigate = useNavigate();
    const {logout} = useContext(BureauContext);    
    return (
        <>
            <ToastContainer/>
            <div className="pt-3">
                <Heading Description="You are all set now to fuel your business growth" BadgeDescription="U GRO is there with you in your journey" user />
            </div>
            <Layout ImageSide="Product-intro">
                <h4 className="text-center mb-4"> Your Pre-approved offer has expired, but you can still avail the loan by completing the journey. </h4>               
                <PageFooter BtnName="OK" ButtonClick={() => navigate('/') } />
            </Layout>

        </>
    );
}
export default LinkExpired;