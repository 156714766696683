import React from "react";
import { RadioInput } from "../../common-components/CheckInput";

const AddressCard = ({ AddressType,RadioLabel,address1,address2,address3,disabled,state,pincode,city,radioname,redioClick,setRedioAttr,changeInputAddress1,changeInputAddress2,changeInputAddress3,changePincode,changeCity,changeState,id,isInput,upPincode,isRead,isRequired,isaddr2Required,isaddr3Required,ispinRequired,iscityRequired,isstateRequired}) => {
    return (
        <div className="mb-3">
            <h6>{AddressType}</h6>
            <div className="card p-2">
                <input type="text" className={`form-control mb-1 bg-ede8e89e ${isRequired ? 'requiredAddr':''}`} disabled={disabled} value={address1} onChange={changeInputAddress1} placeholder="Address Line 1"/>
                <input type="text" className={`form-control mb-1 bg-ede8e89e ${isaddr2Required ? 'requiredAddr':''}`}  disabled={disabled} value={address2} onChange={changeInputAddress2} placeholder="Address Line 2"/>
                <input type="text" className={`form-control mb-1 bg-ede8e89e ${isaddr3Required ? 'requiredAddr':''}`} disabled={disabled} value={address3} onChange={changeInputAddress3} placeholder="Address Line 3"/>
                <div className="d-flex">
                    <div className="col-md-6 pe-1">
                        <input type="text" className={`form-control mb-1 bg-ede8e89e ${ispinRequired ? 'requiredAddr':''}`} disabled={disabled} onChange={changePincode} onKeyUp={upPincode} value={pincode} placeholder="Pincode" />
                    </div>
                    <div className="col-md-6 ps-1">
                        <input type="text" className={`form-control mb-1 bg-ede8e89e ${iscityRequired ? 'requiredAddr':''}`} disabled={(disabled?disabled:isRead)} onChange={changeCity} value={city} placeholder="City"/>
                    </div>
                </div>
                <div className="col-md-6 pe-1">
                    <input type="text" className={`form-control mb-1 bg-ede8e89e ${isstateRequired ? 'requiredAddr':''}`} disabled={(disabled?disabled:isRead)} onChange={changeState} value={state} placeholder="State" />
                </div>
            </div>
            <RadioInput radioname={radioname} id={id} isChecked={isInput} redioClick={redioClick} setRedioAttr={setRedioAttr}>{RadioLabel}</RadioInput>
        </div>
    );
}
export default AddressCard;