import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { CustomButton } from "../common-components/Buttons";
import { LoginButton } from "../common-components/Buttons";
import { CheckBoxInput } from "../common-components/CheckInput";
import Heading from "../common-components/Heading";
import Input from "../common-components/Input";
import Layout from "../common-components/Layout";
import Modal from "../common-components/Modal";
import RoadMap from "./components/RoadMap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from "../../config.json";
import axios from "axios";
import Textarea from "../common-components/Textarea";
import { CustomOtp } from "../common-components/OtpInput";
import DownImg from "../../images/down.gif";
import { GrFormClose } from "react-icons/gr";
import approveModal from "../common-components/Modal";
import locationImage from "../../images/location-new.jpg";
import resetLocation from "../../images/reset-location.jpg";
import Select from "../common-components/Select";
import { useParams } from "react-router-dom/dist";
import { encryptData, decryptData } from "../../encrypt";
//import nodemailer from 'nodemailer';

const Register = () => {
    const api_url = config.API_URL;
    const navigate = useNavigate();
    const { search } = useLocation();
    var urls = search;
    var myurls = urls.split("?q=");
    var q = myurls[1];
    const [otp, setOtp] = useState(false);
    const [mobile, setMobile] = useState('');
    const [termHTML, setHtml] = useState({ __html: "" });
    const [promocode, setPromocode] = useState('');
    const [partnerCode, setPartnerCode] = useState('');
    const [mobileOtp, setMobileOtp] = useState('');
    const [jwtToken, setToken] = useState('');
    const [modal, setModal] = useState(false);
    const [disabled, setDisabledBtn] = useState(true);
    const [isDisabled, setDisabled] = useState(false);
    const [isEmailMobileDisable, setEmailMobileDisable] = useState(false);
    const [seconds, setSeconds] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const token = sessionStorage.getItem('token');
    const [approveModal, setApproveModal] = useState(false);
    const [lan, setLan] = useState('');
    const [crn, setCRN] = useState('');
    const [stageSMS, setstageSMS] = useState('');
    const [loanAmount, setloanAmount] = useState('');
    const [mobileapp, setmobileapp] = useState('');
    const [nextDate, setNextDate] = useState('');
    const [isApiType, setIsAPItype] = useState('');
    const [promocodeArr, setPromocodeArr] = useState({});
    const [isActive, setIsActive] = useState(false);
    const [isActivePro, setIsActivePro] = useState(false);
    const [isAccept, setIsAccept] = useState(false);
    const [scroll, setScroll] = useState(5000);
    const [sms, setSMS] = useState('');
    const [mobileError, setMobileError] = useState('');
    const [isOtpActive, setIsotpActive] = useState(false);
    const [latLong, setlatLong] = useState(true);
    const [validation, setValidationError] = useState({ mobileNoError: '', otpError: '', emailError: '', displayEmailError:'',promoError:'' });
    const [email, setEmail] = useState('');
    const [isemailActive, setisemailActive] = useState(false);
    const locate_permission = sessionStorage.getItem('locate_permission');
    const [preApproved, setPreApproved] = useState(false);
    const [isLink, setIsLink] = useState(false);
    const [isAstrixValue, setIsAstrix] = useState(false);
    const [prePromocode, setprePromocode] = useState('');
    const [counterTime, setCounterTime] = useState(0);
    const [batchNo, setBatchNo] = useState("");
    const [locationInstruction, setLocationInstruction] = useState(false);
    const [locationPopup, setLocationPopup] = useState(false);
    const [fetchLocation, setFetchLocation] = useState(false);
    const [locationError, setLocationError] = useState(false);
    const [locationErrorPopUp, setLocationErrorPopUp] = useState(false);
    const [mailMessage, setMailMessage] = useState("Customer facing to fetch location from google APIs.");
    const latitude = sessionStorage.getItem("latitude");
    const longitude = sessionStorage.getItem("longitude");

    useEffect(() => {
        var myurls = urls.split("?pre=");
        var pre = myurls[1];
        if(pre !== "" && pre !== null && pre !== undefined){
            const decryptStr = decryptData(pre);
            const decryptArr = decryptStr.split("|");
            const currentTimestamp = Math.floor(Date.now() / 1000);
            const expire_time = parseInt(decryptArr[2]);
            const batch = decryptArr[4];
            if(batch !== '' && batch !== null && batch !== undefined){
                setBatchNo(batch);
            }
            setEmail(decryptArr[0]);
            setMobile(decryptArr[1]); 
            setEmailMobileDisable(true);
            setPreApproved(true); 
            setIsLink(true);
            setIsAstrix(true);
            setDisabledBtn(false);
            if(currentTimestamp > expire_time){
                navigate('/link-expired')
            }
        }

        if (q !== '' && q !== null && q !== undefined) {
            getPromocode();
        }
        checkLocationOnRegister();
    }, []);

    const checkLocationOnRegister = async() => {
        navigator.permissions
        .query({ name: "geolocation" })
        .then((permissionStatus) => {
            sessionStorage.setItem("locate_permission" , permissionStatus.state);
            if(permissionStatus.state === 'granted'){
                setLocationInstruction(false);
                setFetchLocation(false);
                setLocationError(false);
                setLocationErrorPopUp(false);
                var timeoutVal = 10 * 1000 * 1000;
                navigator.geolocation.watchPosition(displayPosition, displayError,{ enableHighAccuracy: true, timeout: timeoutVal, maximumAge: 0 });
            }else if(permissionStatus.state === 'denied'){
                setLocationInstruction(false);
                setFetchLocation(false);
                setLocationError(true);
                setLocationErrorPopUp(false);
              }else{
                setLocationInstruction(true);
                setFetchLocation(false);
                setLocationError(false);
                setLocationErrorPopUp(false);
             }
            permissionStatus.onchange = () => {
              sessionStorage.setItem("locate_permission" , permissionStatus.state);
              if(permissionStatus.state === 'granted'){
                setLocationInstruction(false);
                setFetchLocation(false);
                setLocationError(false);
                setLocationErrorPopUp(false);
                var timeoutVal = 10 * 1000 * 1000;
                navigator.geolocation.watchPosition(displayPosition, displayError,{ enableHighAccuracy: true, timeout: timeoutVal, maximumAge: 0 });
              }else if(permissionStatus.state === 'denied'){
                setLocationInstruction(false);
                setFetchLocation(false);
                setLocationError(true);
                setLocationErrorPopUp(false);
              }else{
                setLocationInstruction(true);
                setFetchLocation(false);
                setLocationError(false);
                setLocationErrorPopUp(false);
             }
            };
          }); 
    }

    function allowDeviceLocation(){
        navigator.permissions
        .query({ name: "geolocation" })
        .then((permissionStatus) => {
            if(permissionStatus.state === 'granted'){
                sessionStorage.setItem("locate_permission" , permissionStatus.state);
                var timeoutVal = 10 * 1000 * 1000;
                navigator.geolocation.watchPosition(displayPosition, displayError,{ enableHighAccuracy: true, timeout: timeoutVal, maximumAge: 0 });
            }else if(permissionStatus.state === 'denied'){
                var timeoutVal = 10 * 1000 * 1000;
                navigator.geolocation.watchPosition(displayPosition, displayError, { enableHighAccuracy: true, timeout: timeoutVal, maximumAge: 0 });
                console.log("allow here.......");
                setLocationInstruction(false);
                setFetchLocation(false);
                setLocationError(true);
                setLocationErrorPopUp(false);
            }else{
                var timeoutVal = 10 * 1000 * 1000;
                navigator.geolocation.watchPosition(displayPosition, displayError, { enableHighAccuracy: true, timeout: timeoutVal, maximumAge: 0 });
                console.log("allow here.......");
                setLocationInstruction(true);
                setFetchLocation(false);
                setLocationError(false);
                setLocationErrorPopUp(false);
            }
            permissionStatus.onchange = () => {
                sessionStorage.setItem("locate_permission" , permissionStatus.state);
                if(permissionStatus.state === 'granted'){
                    var timeoutVal = 10 * 1000 * 1000;
                    navigator.geolocation.watchPosition(displayPosition, displayError, { enableHighAccuracy: true, timeout: timeoutVal, maximumAge: 0 });
                }else if(permissionStatus.state === 'denied'){
                    setLocationInstruction(false);
                    setFetchLocation(false);
                    setLocationError(true);
                    setLocationErrorPopUp(false);
                    sessionStorage.setItem('latitude','');
                    sessionStorage.setItem('longitude','');
                }else{
                    var timeoutVal = 10 * 1000 * 1000;
                    navigator.geolocation.watchPosition(displayPosition, displayError, { enableHighAccuracy: true, timeout: timeoutVal, maximumAge: 0 });
                    setLocationInstruction(true);
                    setFetchLocation(false);
                    setLocationError(false);
                    setLocationErrorPopUp(false);
                    sessionStorage.setItem('latitude','');
                    sessionStorage.setItem('longitude','');
                }
            };
          });
          setLocationPopup(false);
    }
    const closeLocationError = async() => {
        setLocationInstruction(false);
        setFetchLocation(false);
        setLocationError(true);
        setLocationErrorPopUp(false)
        setLocationPopup(false);

    }

    function displayPosition(position) {
        if(position.coords.latitude === '' || position.coords.longitude === ''){
            setFetchLocation(true);
        }
        sessionStorage.setItem("latitude" , position.coords.latitude);
        sessionStorage.setItem("longitude" , position.coords.longitude);
      }
    
      function displayError(error) {
        
        if(error.code === 2 || error.code === 3){
            setFetchLocation(true);
        }
        var errors = {
          1: 'Permission denied',
          2: 'Position unavailable',
          3: 'Request timeout'
        };
      }

    useEffect(() => {
        const interval = setInterval(() => {
            var currentDate = new Date().getTime();
            if(counterTime > 0 && currentDate < counterTime){
                var distance = counterTime - currentDate;
                var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                var seconds = Math.floor((distance % (1000 * 60)) / 1000);
                setMinutes(minutes);
                setSeconds(seconds);
            }else{
                setMinutes(0);
                setSeconds(0);
            }
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }

            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(interval);
                } else {
                    const seconds = 59;
                    setSeconds(seconds);
                    setMinutes(minutes - 1);
                }
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [seconds]);

    const getPromocode = async () => {
        const request_data = {
            q: q
        }
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'latlong' : `${latitude},${longitude}`},
            body: JSON.stringify(request_data)
        };
        await fetch(api_url + `/get-promocode`, requestOptions).then((res) => {
            return res.json();
        }).then((result) => {
            if (result.status === 'success') {
                setPromocodeArr(result.data.promo_code);
                setPartnerCode(result.data.partner_code);
                if(Object.keys(result.data.promo_code).length == 1){
                    setPromocode(result.data.promo_code[0].Value);
                }
            }
        }).catch((err) => {
            
        })
    }

    // const sendEmail = async () => {
    //     //const message = "Customer facing to fetch location from google APIs.";
    //     const transporter = nodemailer.createTransport({
    //       host: 'smtp.example.com',
    //       port: 587,
    //       secure: false,
    //       auth: {
    //         user: 'your-email@example.com',
    //         pass: 'your-password',
    //       },
    //     });
    //     const mailOptions = {
    //       from: 'your-email@example.com',
    //       to:  email,
    //       subject: 'Hello from  ReactJS',
    //       text: mailMessage,
    //     };
    //     try {
    //       await transporter. sendMail(mailOptions);
    //       console.log(' Email sent successfully');
    //     } catch (error) {
    //       console.error('Error sending  email:', error);
    //     }
    //   };

    const getRegisterOtp = async (token) => {
        const headers = {
            'Authorization': `Bearer ${token}`,
           'latlong' : `${latitude},${longitude}`,
        }
        document.getElementById('waitingLoader').style.display = 'block';
        await fetch(api_url + `/get-otp-time?otp_type=mobile`, { headers }).then((res) => {
            return res.json();
        }).then((result) => {
            document.getElementById('waitingLoader').style.display = 'none';
            if (result.status === 'success') {
                var countDownDate = new Date(result.data.date).getTime();
                setCounterTime(countDownDate)
                var currentDate = new Date().getTime();
                var distance = counterTime - currentDate;
                var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                var seconds = Math.floor((distance % (1000 * 60)) / 1000);
                setMinutes(minutes);
                setSeconds(seconds);
            }
        }).catch((err) => {
            document.getElementById('waitingLoader').style.display = 'none';
        })
    }

    const termCondition = async (e) => {
        e.preventDefault();
        document.getElementById('waitingLoader').style.display = 'block';
        fetch(api_url + `/term-condition`).then((res) => {
            return res.json();
        }).then((result) => {
            document.getElementById('waitingLoader').style.display = 'none';
            if (result.status === "success") {
                sessionStorage.setItem('termContent', result.data.content);
                setHtml({ __html: result.data.content });
                setModal(true);
            }
            else if (result.status === "token") {
                sessionStorage.clear();
                navigate('/login');
            }
            else if (result.status === "fail") {
                toast.error(result.errors);
            }
        }).catch((err) => {
            
        })
    }

    const onChangeMobile = (e) => {
        setIsActive(false);
        const value = e.target.value.replace(/\D/g, "");
        const limit = 10;
        setMobile(value.slice(0, limit));
        if (e.target.value.length >= 10) {
            setDisabledBtn(false);
        } else {
            setDisabledBtn(true);
        }
        removeErrorSms(e, 'mobileNoError');
    }
    const getLocation = (e) => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(showPosition, showError);
        } else {
            
        }
    }

    const showPosition = (position) => {
        var latitude = position.coords.latitude;
        var longitude = position.coords.longitude;
        setlatLong(latitude + "," + longitude);
        //return ;

    }

    const showError = (error) => {
        if (error.PERMISSION_DENIED) {
            
        }
    }

    const onChangeProMoCode = (e) => {
        const value = e.target.value;
        const limit = 20;
        setPromocode(value.slice(0, limit));
        removeErrorSms(e, 'promoError');
    }

    const onChangeOTP = (e) => {
        const value = e.target.value.replace(/\D/g, "");
        const limit = 6;
        setMobileOtp(value.slice(0, limit));
        if (e.target.value.length >= limit) {
            setDisabledBtn(false);
        } else {
            setDisabledBtn(true);
        }
    }
    
    const proceedRegister =  () => {
        if (validate()) {
            if(locationInstruction === true){
                setLocationPopup(true);
            }else{
                if(fetchLocation === false){
                    if(locationError === false){
                        if (isAccept) {
                            const request_data = {
                                mobile_no: mobile,
                                promocode_id: promocode,
                                partner_code: partnerCode,
                                lat_long: `${latitude},${longitude}`,
                                email:email,
                                location : 'Y',
                                notification : 'N',
                                camera : 'Y',
                                is_link : isLink,
                                unique_ref : batchNo,
                            }
                            const encrypted = encryptData(request_data);
                            const requestOptions = {
                                method: 'POST',
                                headers: { 
                                    'Content-Type': 'application/json',
                                    'latlong' : `${latitude},${longitude}` 
                                },
                                body: JSON.stringify({encrypted : encrypted})
                            };
                            document.getElementById('waitingLoader').style.display = 'block';
                            fetch(api_url + `/store-register`, requestOptions).then((res) => {
                                return res.json();
                            }).then((result) => {
                                document.getElementById('waitingLoader').style.display = 'none';
                                if (result.status === 'fail') {
                                    toast.error(result.errors, {
                                        position: toast.POSITION.TOP_CENTER
                                    });
                                }
                                else if (result.status === 'approved') {
                                    setApproveModal(true);
                                    setLan(result.data.lan_no);
                                    setCRN(result.data.crn);
                                    setloanAmount(result.data.approved_amount);
                                    setmobileapp(result.data.mobile_no);
                                    setIsAPItype('approved');
                                    setstageSMS("Your Application Is Already Approved With Mobile Number ");
                                }
                                else if (result.status === 'rejected') {
                                    setApproveModal(true);
                                    setmobileapp(result.data.mobile_no);
                                    setNextDate(result.data.next_date);
                                    setIsAPItype('rejected');
                                    setstageSMS("Your Application Is Already Rejected With This Mobile Number ");
                                }
                                else if (result.status === "success") {
                                    sessionStorage.setItem('token', result.data.token);
                                    sessionStorage.setItem('userData', JSON.stringify(result.data.user));
                                    sessionStorage.setItem('lafId', JSON.stringify(result.data.lafId));
                                    setToken(result.data.token);
                                    toast.success(result.message, {
                                        position: toast.POSITION.TOP_CENTER
                                    });
                                    setDisabled(true);
                                    var countDownDate = new Date(result.data.date).getTime();
                                    setCounterTime(countDownDate)
                                    var currentDate = new Date().getTime();
                                    var distance = counterTime - currentDate;
                                    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                                    var seconds = Math.floor((distance % (1000 * 60)) / 1000);
                                    setMinutes(minutes);
                                    setSeconds(seconds);
                                    setOtp(true);
                                }
                            }).catch((err) => {
                                document.getElementById('waitingLoader').style.display = 'none';
                                toast.error(err, {
                                    position: toast.POSITION.TOP_CENTER
                                });
                            })
                            setSMS('');
                        } else {
                            setSMS('Please read and accept the Terms and Conditions');
                        }
                    }else{
                        setLocationErrorPopUp(true);
                    }
                }
            }
        }
    }

    const validate = () => {
        // if(locate_permission === 'denied' || locate_permission === ''){
        //     toast.error("Please allow device location", {
        //       position: toast.POSITION.TOP_CENTER,
        //    });
        //    return false;
        // }
        let checkValidate = true;
        let checkerror = {}
        if (disabled === true) {
            toast.error('Please accept the term and condition.');
            return checkValidate = false;
        }
        if (mobile === '' || mobile === null) {
            checkerror['mobileNoError'] = "Please enter mobile number";
            setValidationError({ ...validation, ...checkerror });
            return checkValidate = false;
        }
        
        let regex = new RegExp(/[6-9]{1}[0-9]{9}/);
        if (isLink === false &&  regex.test(mobile) == false) {
            checkerror['mobileNoError'] = "Please enter correct mobile number";
            setValidationError({ ...validation, ...checkerror });
            return checkValidate = false;
        } else {
            setMobileError('');
            checkValidate = true;
        }
        if (email === '' || email === null) {
            checkerror['displayEmailError'] = "Please enter your E-mail ID";
            setValidationError({ ...validation, ...checkerror });
            return checkValidate = false;
        }
        // if (promocode === '' || promocode === null) {
        //     checkerror['promoError'] = "Please enter the Promo code";
        //     setValidationError({ ...validation, ...checkerror });
        //     return checkValidate = false;
        // }
        if (otp && (mobileOtp === null || mobileOtp === '')) {
            checkerror['otpError'] = "Please enter OTP";
            return checkValidate = false;
            setValidationError({ ...validation, ...checkerror });
        }
        /*if(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email) == false){
            checkerror['emailError'] = "Please enter valid email.";
            setValidationError({ ...validation, ...checkerror });
            return checkValidate = false;
        }*/

        return checkValidate;
    }

    const verifyOtp = () => {
        if(locate_permission === 'denied' || locate_permission === ''){
            toast.error("Please allow device location", {
              position: toast.POSITION.TOP_CENTER,
           });
           return false;
        }
        if (validate()) {
            const otp_request = {
                mobile_no: mobile,
                otp: mobileOtp,
                lat_long: latLong,
                email:email,
                is_link : isLink,
                unique_ref : batchNo,
            }
            const encrypted = encryptData(otp_request);
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${jwtToken}`,
                    'Content-Type': 'application/json',
                    'latlong' : `${latitude},${longitude}`,
                },
                body: JSON.stringify({encrypted : encrypted})
            };
            document.getElementById('waitingLoader').style.display = 'block';
            fetch(api_url + `/submit-otp-register`, requestOptions).then((res) => {
                return res.json();
            }).then((result) => {
                document.getElementById('waitingLoader').style.display = 'none';
                let checkerror = {}
                if (result.status === 'fail') {
                    checkerror['otpError'] = result.errors;
                    setValidationError({ ...validation, ...checkerror });
                }
                else if (result.status === "token") {
                    sessionStorage.clear();
                    navigate('/login');
                }
                else if (result.status === "success") {
                    sessionStorage.setItem('lafId', result.data.lafId);
                    sessionStorage.setItem('userData', JSON.stringify(result.data.userData));
                    navigate(result.data.stage.lastActiveStage);
                }
            }).catch((err) => {
                toast.error(err, {
                    position: toast.POSITION.TOP_CENTER
                });
            })
        }
    }

    const acceptCondition = () => {
        setDisabledBtn(false);
        setModal(false);
        setSMS('');
        setIsAccept(true);
    }
    const goToLogin = () => {
        navigate('/login');
    }

    function resend(e) {
        e.preventDefault();
        document.getElementById('waitingLoader').style.display = 'block';
        axios.post(api_url + `/resend-otp-for-register`, {encrypted : encryptData({
            mobile_no: mobile,
            lat_long: latLong,
            is_link : isLink,
            unique_ref : batchNo,
        })}).then((response) => {
            if (response.data.status === 'success') {
                document.getElementById('waitingLoader').style.display = 'none';
                setValidationError({ mobileNoError: '', otpError: '', emailError: '', displayEmailError:'',promoError:'' });
                toast.success(response.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
                setOtp(true);
                var countDownDate = new Date(response.data.data.date).getTime();
                setCounterTime(countDownDate);
                var currentDate = new Date().getTime();
                var distance = counterTime - currentDate;
                var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                var seconds = Math.floor((distance % (1000 * 60)) / 1000);
                setMinutes(minutes);
                setSeconds(seconds);
            }
            else if (response.data.status === "token") {
                toast.error(response.data.errors);
                sessionStorage.clear();
                navigate('/login');
            } else {
                document.getElementById('waitingLoader').style.display = 'none';
                toast.error(response.data.errors, {
                    position: toast.POSITION.TOP_CENTER
                });
                setOtp(false);
            }
        })
            .catch(error => {
                document.getElementById('waitingLoader').style.display = 'none';
                toast.error(error.message, {
                    position: toast.POSITION.TOP_CENTER
                });
                setOtp(false);
            });
    }

    const goToDown = (val) => {
        const scrollmodal = document.querySelector('.modal-body');
        scrollmodal.scrollTo({
            top: val,
            left: 0,
            behavior: 'smooth'
        })
        setScroll(val + 2000);
    };

    function changeHandler(e) {
        setPromocode(e.target.value);
    }

    function modifyNamePlaceHolder(e) {
        if (e.type === 'focus') {
            setIsActive(true);
        } else {
            setIsActive(false);
            if (e.target.value.length === 10) {
                setDisabledBtn(false);
            } else {
                setDisabledBtn(true);
            }
        }
    }
    function modifyPromoPlaceHolder(e) {
        if (e.type === 'focus' && preApproved === false) {
            setIsActivePro(true);
        } else {
            setIsActivePro(false);
        }
    }
    function modifyOtpPlaceHolder(e) {
        if (e.type === 'focus') {
            setIsotpActive(true);
        } else {
            setIsotpActive(false);
            if (e.target.value.length === 6) {
                setDisabledBtn(false);
            } else {
                setDisabledBtn(true);
            }
        }
    }

    function removeErrorSms(e, fielName) {
        let checkerror = {}
        if (e.target.value.length > 0) {
            checkerror[fielName] = "";
            setValidationError({ ...validation, ...checkerror });
        }
    }

    function modifyEmailPlaceHolder(e) {
        if (e.type === 'focus') {
            setisemailActive(true);
            if (e.target.value.length > 4) {
                setDisabledBtn(true);
            }
        } else {
            setisemailActive(false);
            if (e.target.value.length > 4) {
                setDisabledBtn(false);
            }
        }
    }
    const onChangeEmail = (e) => {
        const value = e.target.value;
        const limit = 50;
        setEmail(value.slice(0, limit));
        if (value.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
            setDisabledBtn(false);
            removeErrorSms(e, 'emailError');
            removeErrorSms(e, 'displayEmailError');
        } else {
            setDisabledBtn(false);
            removeErrorSms(e, 'emailError');
            removeErrorSms(e, 'displayEmailError');
        }
    }
    return (
        <>
            <ToastContainer />

            <div className="mt-2">
                <Heading Description="U GRO Capital can help you with your business finance" BadgeDescription="Let us sign you up" />
            </div>
            <Layout ImageSide="Register">
                <h4 className="text-center mb-4"> Create Account</h4>
                
                <div className="col-md-8 col-sm-12 mx-auto">
                {
                    isLink && <p className="text-center">We have partially masked your mobile number and E-mail ID. Please validate and proceed.</p>
                }
                    <Input LabelName="Mobile Number" disabled={isEmailMobileDisable} keyFocus={(e) => modifyNamePlaceHolder(e)} type="text" blurEvent={(e) => modifyNamePlaceHolder(e)} Id="mobile_num" inputval={mobile} changeInput={(e) => onChangeMobile(e)} placeholder={isActive ? "Please enter your 10 digits mobile number" : ""} isAstrix />
                    {(validation.mobileNoError !== '') ?
                        <span className="text-danger fontsize-13">{validation.mobileNoError}</span> : null
                    }
                   
                    <Input LabelName="E-mail ID" disabled={isEmailMobileDisable} type="email" Id="E-mail_ID" isAstrix inputval={email} changeInput={(e) => onChangeEmail(e)} blurEvent={(e) => modifyEmailPlaceHolder(e)} keyFocus={(e) => modifyEmailPlaceHolder(e)} placeholder={isemailActive ? "Please enter your valid E-mail ID" : ""} />
                    {(validation.displayEmailError !== '') ?
                        <span className="text-danger fontsize-13">{validation.displayEmailError}</span> : null
                    }
                     {(validation.emailError !== '') ?
                        <span className="text-danger fontsize-13">{validation.emailError}</span> : null
                    }
                    {Object.keys(promocodeArr).length > 0 ?
                      <Select LabelName="Select Promo Code" preSelect={prePromocode} disabled={isDisabled} selected={(e) => changeHandler(e)} SelectData={promocodeArr} />
                        :
                        (isAstrixValue) ?
                      <Input LabelName="Promo Code" disabled={isDisabled} type="text" Id="promo_code" inputval={promocode} changeInput={(e) => onChangeProMoCode(e)} keyFocus={(e) => modifyPromoPlaceHolder(e)} blurEvent={(e) => modifyPromoPlaceHolder(e)} placeholder={isActivePro ? "This field is mandatory" : ""} />
                        : <Input LabelName="Promo Code" disabled={isDisabled} type="text" Id="promo_code" inputval={promocode} changeInput={(e) => onChangeProMoCode(e)} keyFocus={(e) => modifyPromoPlaceHolder(e)} blurEvent={(e) => modifyPromoPlaceHolder(e)} placeholder={isActivePro ? "This field is mandatory" : ""} isAstrix />
                    }
                    {(validation.promoError !== '') ?
                        <span className="text-danger fontsize-13">{validation.promoError}</span> : null
                    }
                    {!otp && <>
                        <p> I am aware of, and acknowledge the&nbsp;<LoginButton btnname="T&Cs" clickBtn={(e) => termCondition(e)} className="text-decoration-none text-orange"> T&Cs </LoginButton>
                            <div id="term_error" className="text-danger">{sms}</div></p>
                        <div className="text-center mt-3">
                            <CustomButton btnname="I SUBMIT" isDisabled={disabled} btn_col="col-md-4" clickBtn={() => { proceedRegister() }} />
                        </div>
                    </>
                    }
                    {otp &&
                        <>
                            <Input LabelName="Enter OTP sent on Mobile Number" type="password" inputval={mobileOtp} changeInput={(event) => onChangeOTP(event)} Id="promo_code" placeholder={isOtpActive ? "Enter OTP sent on Mobile Number" : ""} keyFocus={(e) => modifyOtpPlaceHolder(e)} blurEvent={(e) => modifyOtpPlaceHolder(e)} isAstrix />
                            {(validation.otpError !== '') ?
                                <span className="text-danger fontsize-13">{validation.otpError}</span> : null
                            }
                            <p className="text-end text-orange">
                                {seconds > 0 || minutes > 0 ? (
                                    <p>
                                        {minutes < 10 ? `0${minutes}` : minutes}:
                                        {seconds < 10 ? `0${seconds}` : seconds}
                                    </p>
                                ) : (
                                    <p className="text-center">Didn’t Receive OTP? <span className="fw-semibold"><Link onClick={(e) => resend(e)}>Resend</Link></span></p>
                                )}
                            </p>
                            <div className="text-center mt-3">
                                <CustomButton isDisabled={disabled} btnname="I SUBMIT" btn_col="col-md-4" clickBtn={() => { verifyOtp() }} />
                            </div>
                        </>
                    }
                    {otp &&
                        <div class="mt-3 reg_consent text-center" style={{ "font-weight": "700", "font-size": "10px" }}>By registering, I am sharing my contact details with U GRO </div>
                    }
                    {!otp &&
                        <p className="mt-3 text-center">Already have an account?&nbsp;<LoginButton btnname="Login" clickBtn={() => { goToLogin() }} className="text-decoration-none text-orange">Login</LoginButton></p>
                    }
                </div>
            </Layout>
            <RoadMap />

            {modal &&
                <div className={`modal modal-background  ${modal && 'fade show'}`} id="exampleModal" tabIndex={-1} style={{ display: "block", zIndex:"99999" }}>
                    <div className="modal-dialog modal-xl">
                        <div className="modal-content">
                            <div className="modal-header border-0">
                                <h2 className="modal-title cursor text-center col-md-12" id="exampleModalLabel">Terms and Conditions</h2>
                                <div className="d-flex align-items-center" style={{ right: '35px', position: 'relative' }}>
                                    <a onClick={() => goToDown(scroll)} > <img src={DownImg} alt="Down arrow" width={25} className="cursor" /></a>
                                    <p className="cursor mb-0 d-flex" onClick={() => setModal(false)} >
                                        <GrFormClose className="fs-5" />
                                    </p>
                                </div>

                            </div>
                            <div className="modal-body termsconditions">
                                <div dangerouslySetInnerHTML={termHTML}></div>
                                <div className="py-3 text-center">
                                    <button type="button" className="btn btn-primary" onClick={() => { acceptCondition() }}>Accept</button>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            }

            {approveModal &&
                <div className="modal-center">
                    <div className={`modal modal-background fade show ${approveModal}`} id="exampleModal" tabIndex={-1} style={{ display: "block" }}>
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-body pt-4">
                                    <div className="text-center col-md-10 mx-auto">{stageSMS}<b>{mobileapp}</b>
                                        {isApiType === 'rejected' && " Please Try After " + nextDate}
                                    </div><br />
                                    {isApiType === 'approved' &&
                                        <div className="col-md-10 mx-auto lh-lg">
                                            <div>LAN - {lan}</div>
                                            <div>CRN - {crn}</div>
                                            <p>Loan Amount - {loanAmount}</p>
                                        </div>
                                    }
                                    <div className="modal-footer justify-content-center border-0 pt-0">
                                        <button type="button" className="btn btn-primary" onClick={() => { setApproveModal(false) }}>CLOSE</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
             {locationPopup &&
            <div className="modal-center">
                <div className={`modal modal-background fade show`} id="exampleModal" tabIndex={-1} style={{ display: "block" }}>
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-body pt-4">
                               <div className="text-center col-md-10 mx-auto"><strong>Please Allow Your Device Location.</strong></div>
                               </div>
                            <div className="modal-footer justify-content-center border-0 pt-0">
                               <img src={locationImage} />
                            </div>
                            <div className="modal-footer justify-content-center border-0 pt-0">
                                <button type="button" className="btn btn-primary" onClick={()=>{ allowDeviceLocation(); }}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        }
        {fetchLocation &&
            <div className="modal-center">
            <div className={`modal modal-background fade show`} id="exampleModal" tabIndex={-1} style={{ display: "block" }}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body pt-4">
                               <div className="text-center col-md-10 mx-auto">Sorry, we are facing a technical issue. Please try again after some time..</div>
                            </div><br/>
                        </div>
                    </div>
                </div>
            </div> 
        }

    {locationErrorPopUp &&
            <div className="modal-center">
            <div className={`modal modal-background fade show`} id="exampleModal" tabIndex={-1} style={{ display: "block" }}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body pt-4">
                        <div className="text-center col-md-10 mx-auto"><strong>Your location seems to be blocked. Please Allow to continue.</strong></div>
                               <div className="text-center col-md-10 mx-auto"><img src={resetLocation} /></div>
                            </div><br/>

                            <div className="modal-footer justify-content-center border-0 pt-0">
                                <button type="button" className="btn btn-primary" onClick={()=>{ closeLocationError(); }}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        }
        </>
    );
}
export default Register;
