import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Heading from "../common-components/Heading";
import Input from "../common-components/Input";
import Layout from "../common-components/Layout";
import PageFooter from "../common-components/PageFooter";
import Table from "../common-components/Table";
import { CongratsTable } from "./StaticTableData";
import {FiDownload} from "react-icons/fi"
import { BiInfoCircle } from "react-icons/bi";
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import config from "../../config.json";
import { toast } from "react-toastify";
import axios from "axios";
import { BureauContext } from "../Context/BureauContext";
import { encryptData } from "../../encrypt";

const Congratulations = () => {
    const api_url = config.API_URL;
    const navigate = useNavigate();
    const [lan, setLan] = useState('');
    const [crn, setCrn] = useState('');
    const [docData, setDoc] = useState({doc:''});
    const userToken = sessionStorage.getItem('token');
    const {popupData, setPopUpData, checkStateByMiddileware} = useContext(BureauContext);
    const latitude = sessionStorage.getItem("latitude");
    const longitude = sessionStorage.getItem("longitude");
    const getCongrat = async () => {
        checkStateByMiddileware();
        const headers = {
            "Authorization" : `Bearer ${userToken}`,
           'latlong' : `${latitude},${longitude}`,
        }
        await fetch(api_url+`/congratulations`, {headers}).then((res) => {
            return res.json();
        }).then((result) => {
            document.getElementById('waitingLoader').style.display = 'none';
            if(result.status === "token"){
                sessionStorage.clear();
                toast.error(result.errors);
                navigate('/login');
            }else if (result.status === 'hold') {
                setPopUpData({...popupData,
                  is_bank_hold : 1, 
                  checkStage : false,
                  stepData : result.data.stage.parentStage
                  });
            }else if(result.status === "expired") {
                setPopUpData({...popupData,is_bureau_checked : true});
            }else if (result.status === 'fail' && result.is_rejected === true) {
                document.getElementById('waitingLoader').style.display = 'none';
                if(result.hasOwnProperty('data')){
                    sessionStorage.setItem('status_flag', result.data.appStatus.comment)
                }
                navigate("/regrate");
            }else if(result.status === "fail"){
                toast.error(result.errors);
            }else if(result.status === "success"){
                setLan(result.data.app_data.lan_no);
                setCrn(result.data.app_data.crn);
                setDoc({doc:result.data.doc_data});
            }
            
        }).catch((err) => {
            
        })
    }

    const proceedCongrat = async () => {
        checkStateByMiddileware();
        const headers = {
            "Authorization" : `Bearer ${userToken}`,
           'latlong' : `${latitude},${longitude}`,
        };

        const requestOptions = {
            "method" : "Post",
            "headers" : headers
        }
        document.getElementById('waitingLoader').style.display = 'block';
       await fetch(api_url+`/congrats-submit`, requestOptions).then((res) => {
            return res.json();
        }).then((result) => {
            document.getElementById('waitingLoader').style.display = 'none';
            if(result.status === "token"){
                sessionStorage.clear();
                navigate("/login");
            }else if (result.status === 'hold') {
                setPopUpData({...popupData,is_bank_hold : true});
            }else if(result.status === "fail"){
                toast.error(result.errors);
            }else if(result.status === "success"){
                navigate('/set-mandate');
            }
        }).catch((err) => {
           
        })
    }

    useEffect(()=>{
        getCongrat();
    }, []);

    const popover = (
        <Popover id="popover-basic" >
            <Popover.Body  >
                <div>
                   <p className="mb-0">Application Kit consists of your Application form, Sanction letter and Most Important Terms and Conditions. We recommend you to download this</p>
                </div>
                
            </Popover.Body>
        </Popover>
    );

    return (
        <>
            <div className="pt-3">
                <Heading Description="You have the support from U GRO in prospering your business" BadgeDescription="Your Application Kit is ready" user />
            </div>
            <Layout ImageSide="Product-intro">
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <Input LabelName="Loan Application Number" type="text" Id="Loan_Application_number" inputval={lan} placeholder="Loan Application number" disabled />
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <Input LabelName="Customer Identification Number" type="text" Id="Customer_Identification_Number" inputval={crn} placeholder="Customer Identification Number" disabled />
                    </div>
                </div>
                <Table TableHeadData={CongratsTable}>
                { 
                Object.keys(docData.doc).length > 0 ?
                    docData.doc.map( (docum) => (
                        <tr>
                        <td>Application Kit <OverlayTrigger trigger="click" rootClose placement="auto" overlay={popover}><Button className="bg-transparent border-0 mb-2 py-0 px-0"><BiInfoCircle className="text-orange fs-5 cursor  " /></Button></OverlayTrigger>  </td>
                        <td><Link to={api_url+`/document/download?file_id=${docum.file_id}`} target="_blank" title="Download" download="mitc.pdf"><FiDownload className="text-orange fw-bold cursor"/></Link></td>
                        </tr>
                    ))
                    : null
                    }
                </Table>
                <PageFooter BtnName="I SUBMIT" TermsCondition="" ButtonClick={() => proceedCongrat()} />
            </Layout>
        </>
    );
}
export default Congratulations;