import React, { useState, useEffect, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import { CustomButton } from "../common-components/Buttons";
import Heading from "../common-components/Heading";
import Input from "../common-components/Input";
import Layout from "../common-components/Layout";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from "../../config.json";
import axios from "axios";
import { BureauContext } from "../Context/BureauContext";

const VerifyEmail = () => {
    const navigate = useNavigate();
    const [seconds, setSeconds] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [otp, setOtp] = useState(false);
    const api_url = config.API_URL;
    const [email, setEmail] = useState('');
    const [emailOtp, setEmailOtp] = useState('');
    const token = sessionStorage.getItem('token');
    const [isDisabled, setDisabled] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [disabledbtn, setDisabledBtn] = useState(true);
    const [displayEmailError, setdisplayEmailError] = useState('');
    const [displayOtpError, setdisplayOtpError] = useState('');
    const {logout, popupData, setPopUpData, checkStateByMiddileware} = useContext(BureauContext);

    const proceedEmailStage = () => {
        checkStateByMiddileware();
        if (validate()) {
            const request_data = {
                email: email
            };
            const headers = {
                "Authorization": `Bearer ${token}`,
                "content-type": "application/json",
            };

            const requestOptions = {
                method: "POST",
                headers: headers,
                body: JSON.stringify(request_data),
            };
            document.getElementById('waitingLoader').style.display = 'block';
            fetch(api_url + `/store-register-email`, requestOptions).then((res) => {
                return res.json();
            }).then((result) => {
                document.getElementById('waitingLoader').style.display = 'none';
                if (result.status === "success") {
                    setOtp(true);
                    getRegisterOtp();
                    toast.success(result.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                    setDisabled(true);
                    setDisabledBtn(true);
                } else if (result.status === "fail") {
                    setdisplayEmailError(result.errors);
                } else if(result.status === "hold") {
                    document.getElementById('waitingLoader').style.display = 'none';
                    setPopUpData({...popupData,is_bank_hold : true});
                }else if (result.status === "token") {
                    toast.error(result.errors, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                    sessionStorage.clear();
                    navigate('/login')
                }
            }).catch((err) => {
                document.getElementById('waitingLoader').style.display = 'none';
                console.log("Error is here ", err);
            })
        }
    }
    const onChangeEmail = (e) => {
        const value = e.target.value;
        const limit = 50;
        setEmail(value.slice(0, limit));
        if (value.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
            setDisabledBtn(false);
        } else {
            setDisabledBtn(true);
            setdisplayEmailError('');
        }
    }
    const changeEmailOtp = (e) => {
        setdisplayOtpError('');
        const value = e.target.value;
        const limit = 6;
        setEmailOtp(value.slice(0, limit));
        if (e.target.value.length >= limit) {
            setDisabledBtn(false);
        } else {
            setDisabledBtn(true);
        }
    }

    const validateEmailOtp = () => {
        checkStateByMiddileware();
        if (validate()) {
            const request_data = {
                email: email,
                otpE: emailOtp,
            };
            const headers = {
                "Authorization": `Bearer ${token}`,
                "content-type": "application/json",
            };

            const requestOptions = {
                method: "POST",
                headers: headers,
                body: JSON.stringify(request_data),
            };
            document.getElementById('waitingLoader').style.display = 'block';
            fetch(api_url + `/submit-otp-email`, requestOptions).then((res) => {
                return res.json();
            }).then((result) => {
                document.getElementById('waitingLoader').style.display = 'none';
                if (result.status === "success") {
                    const steps = JSON.stringify(result.data.stage.parentStage);
                    sessionStorage.setItem('parentStage', steps);
                    toast.success(result.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                    navigate('/pan-detail');
                } else if (result.status === "fail") {
                    setdisplayOtpError(result.errors);
                } else if(result.status === "hold") {
                    document.getElementById('waitingLoader').style.display = 'none';
                    setPopUpData({...popupData,is_bank_hold : true});
                }else if (result.status === "token") {
                    toast.error(result.errors, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                    sessionStorage.clear();
                    navigate('/login')
                }
            }).catch((err) => {
                document.getElementById('waitingLoader').style.display = 'none';
                console.log("Error is here ", err);
            })

        }
    }

    const validate = () => {
        let result = true;
        if (email === '' || email === null) {
            setdisplayEmailError('Email Field is required.');
            result = false;
        } else {
            setdisplayEmailError('');
        }
        if (otp && (emailOtp === '' || emailOtp === null)) {
            setdisplayOtpError('OTP Field is required.');
            result = false;
        } else {
            setdisplayOtpError('');
        }
        return result;
    }

    const resendEmailOtp = () => {
        checkStateByMiddileware();
        if (email === '' || email === null) {
            toast.error("email field is required.", {
                position: toast.POSITION.TOP_CENTER,
            });
            return false;
        }
        const request_data = {
            email: email
        };
        const headers = {
            "Authorization": `Bearer ${token}`,
            "content-type": "application/json",
        };

        const requestOptions = {
            method: "POST",
            headers: headers,
            body: JSON.stringify(request_data),
        };
        document.getElementById('waitingLoader').style.display = 'block';
        fetch(api_url + `/send-otp-for-email`, requestOptions).then((res) => {
            return res.json();
        }).then((result) => {
            document.getElementById('waitingLoader').style.display = 'none';
            if (result.status === "success") {
                setOtp(true);
                getRegisterOtp();
                toast.success(result.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            } else if (result.status === "fail") {
                toast.error(result.errors, {
                    position: toast.POSITION.TOP_CENTER,
                });
            } else if (result.status === "token") {
                toast.error(result.errors, {
                    position: toast.POSITION.TOP_CENTER,
                });
                sessionStorage.clear();
                navigate('/login')
            }
        }).catch((err) => {
            console.log("Error is here ", err);
        })
    }

    const getRegisterOtp = async () => {
        const headers = {
            'Authorization': `Bearer ${token}`
        }
        document.getElementById('waitingLoader').style.display = 'block';
        await fetch(api_url + `/get-otp-time?otp_type=email`, { headers }).then((res) => {
            return res.json();
        }).then((result) => {
            document.getElementById('waitingLoader').style.display = 'none';
            if (result.status === 'success') {
                var countDownDate = new Date(result.data.date).getTime();
                var currentDate = new Date().getTime();
                console.log("result.data.date", countDownDate);
                console.log("result.data.currentDate", currentDate);
                var distance = countDownDate - currentDate;
                console.log("result.data.distance", distance);
                var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                var seconds = Math.floor((distance % (1000 * 60)) / 1000);
                setMinutes(minutes);
                setSeconds(seconds);
            }
        }).catch((err) => {
            document.getElementById('waitingLoader').style.display = 'none';
            console.log("exception Error", err);
        })
    }

    useEffect(() => {
        const interval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }

            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(interval);
                } else {
                    const seconds = 59
                    setSeconds(seconds);
                    setMinutes(minutes - 1);
                }
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [seconds]);

    function modifyEmailPlaceHolder(e) {
        if (e.type === 'focus') {
            setIsActive(true);
            if (e.target.value.length > 4) {
                setDisabledBtn(true);
            }
        } else {
            setIsActive(false);
            if (e.target.value.length > 4) {
                setDisabledBtn(false);
            }
        }
    }
   
    return (
        <>
            <div className="pt-3">
                <Heading Description="U GRO Capital can help you with your business finance" BadgeDescription="Let us sign you up" />
            </div>
            <ToastContainer />
            <Layout ImageSide="Register">
                <h4 className="text-center mb-4">Verify E-mail ID</h4>
                <div className="col-md-8 col-sm-12 mx-auto">
                    <Input LabelName="E-mail ID" disabled={isDisabled} isAstrix type="email" Id="E-mail_ID" inputval={email} changeInput={(e) => onChangeEmail(e)} blurEvent={(e) => modifyEmailPlaceHolder(e)} keyFocus={(e) => modifyEmailPlaceHolder(e)} placeholder={isActive ? "Please enter your valid E-mail ID" : ""} />
                    <span className="text-danger">{displayEmailError}</span>
                    {!otp && <div className="text-center mt-3">
                        <CustomButton isDisabled={disabledbtn} btnname="Register" btn_col="col-md-4" clickBtn={() => proceedEmailStage()} />
                    </div>}
                    {otp &&
                        <>
                            <Input LabelName="Enter OTP sent to Email" type="password" inputval={emailOtp} changeInput={(e) => changeEmailOtp(e)} Id="promo_code" blurEvent={(e) => modifyEmailPlaceHolder(e)} keyFocus={(e) => modifyEmailPlaceHolder(e)} placeholder={isActive ? "Enter OTP sent to Email" : ""} isAstrix />
                            <span className="text-danger">{displayOtpError}</span>
                            <p className="text-end text-orange">
                                {seconds > 0 || minutes > 0 ? (
                                    <p>
                                        {minutes < 10 ? `0${minutes}` : minutes}:
                                        {seconds < 10 ? `0${seconds}` : seconds}
                                    </p>
                                ) : (
                                    <p className="text-center"><span className="text-black">Didn't recieve OTP?</span>
                                        &nbsp;<Link onClick={() => resendEmailOtp()}>Resend</Link>
                                    </p>
                                )}
                            </p>
                            <div className="text-center mt-3">
                                <CustomButton isDisabled={disabledbtn} btnname="Register" btn_col="col-md-4" clickBtn={() => { validateEmailOtp() }} />
                            </div>
                            {otp &&
                              <>
                              <div class="mt-3 reg_consent text-center" style={{ "font-weight": "700", "font-size": "10px" }}>By registering, I am sharing my contact details with U GRO</div>
                              <div className="text-center">
                                <a href="#" onClick={() => logout()} id="back_create_account" className="col-md-4">Back</a>
                              </div>
                              </>
                            }       
                        </>
                    }

                </div>
            </Layout>
        </>
    );
}
export default VerifyEmail;