/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Heading from "../common-components/Heading";
import PageFooter from "../common-components/PageFooter";
import config from "../../config.json";
import { toast } from "react-toastify";
import DownImg from "../../images/down.gif";
import { BureauContext } from "../Context/BureauContext";
import { encryptData } from "../../encrypt";

const MitcAcceptance = () => {

    const navigate = useNavigate();
    const [scroll, setScroll] = useState(4000);
    const [mitcHtml, setData] = useState({ __html: "" });
    const [disable, setButton] = useState(true);
    const [showContent, setContent] = useState(false);
    const userToken = sessionStorage.getItem('token');
    const latitude = sessionStorage.getItem("latitude");
    const longitude = sessionStorage.getItem("longitude");
    const api_url = config.API_URL;
    const {popupData, setPopUpData, checkStateByMiddileware, checkLocationPermission} = useContext(BureauContext);
    const getMitc = async () => {
        checkStateByMiddileware();
        const headers = {
            "Authorization": `Bearer ${userToken}`,
           'latlong' : `${latitude},${longitude}`,
        }
        document.getElementById('waitingLoader').style.display = 'block';
        await fetch(api_url + `/mitc-acceptance`, { headers }).then((res) => {
            return res.json();
        }).then((result) => {
            document.getElementById('waitingLoader').style.display = 'none';
            if (result.status === "token") {
                sessionStorage.clear();
                navigate('/login');
            } else if (result.status === "expired") {
                document.getElementById('waitingLoader').style.display = 'none';
                setPopUpData({...popupData,is_bureau_checked : true});
            }else if(result.status === "hold") {
                document.getElementById('waitingLoader').style.display = 'none';
                setPopUpData({...popupData,is_bank_hold : true});
            } else if (result.status === "success") {
                setContent(true);
                setData({ __html: result.data.mitc });
                setButton(false);
            }else if (result.status === 'fail' && result.is_rejected === true) {
                document.getElementById('waitingLoader').style.display = 'none';
                if(result.hasOwnProperty('data')){
                    sessionStorage.setItem('status_flag', result.data.appStatus.comment)
                }
                navigate("/regrate");
            } else if (result.status === "fail") {
                toast.error(result.errors);
            }
        }).catch((err) => {
           
        })
    }
    const acceptMitc = async () => {
        if(checkLocationPermission()){
            checkStateByMiddileware();
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userToken}`,
                    'latlong' : `${latitude},${longitude}`,
                },
            };
            document.getElementById('waitingLoader').style.display = 'block';
            fetch(api_url + `/accept-mitc`, requestOptions).then((res) => {
                return res.json();
            }).then((result) => {
                if (result.status === "token") {
                    document.getElementById('waitingLoader').style.display = 'none';
                    toast.error(result.errors);
                    sessionStorage.clear();
                    navigate('/login');
                } else if (result.status === "expired") {
                    document.getElementById('waitingLoader').style.display = 'none';
                    setPopUpData({...popupData,is_bureau_checked : true});
                }else if(result.status === "hold") {
                    document.getElementById('waitingLoader').style.display = 'none';
                    setPopUpData({...popupData,is_bank_hold : true});
                } else if (result.status === "success") {
                    //toast.error(result.message);
                    document.getElementById('waitingLoader').style.display = 'none';
                    navigate('/mitc-validate');
                }else if (result.status === 'fail' && result.is_rejected === true) {
                    document.getElementById('waitingLoader').style.display = 'none';
                    if(result.hasOwnProperty('data')){
                    sessionStorage.setItem('status_flag', result.data.appStatus.comment)
                }
                    navigate("/regrate");
                } else if (result.status === "fail") {
                    document.getElementById('waitingLoader').style.display = 'none';
                    toast.error(result.errors,{
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            })
        }else{
            toast.error("Please allow your device location", {
                position: toast.POSITION.TOP_CENTER
            });
        }

    }
    useEffect(() => {
        if(checkLocationPermission()){
            getMitc();
        }else{
            toast.error("Please allow your device location", {
                position: toast.POSITION.TOP_CENTER
            });
        }
    }, []);
    const goToDown = (val) => {
        const scrollmodal = document.querySelector('.modal-body');
        scrollmodal.scrollTo({
            top: val,
            left: 0,
            behavior: 'smooth'
        })
        setScroll(val + 4000);
    };

    return (
        <>

            <div className="pt-3">
                <Heading Description="You have the support from U GRO in prospering your business" BadgeDescription="Please review the Most Important terms and conditions" user />
            </div>
            <div className="container my-4">
                <div className="card shadow-sm downscroll">
                    <a className="text-end card-header border-0 bg-transparent" onClick={() => goToDown(scroll)} > <img src={DownImg} alt="Down arrow" width={25} className="cursor" /></a>
                    <div className="card-body modal-body acceptanceheight ">
                        {showContent &&
                            <div className="acceptancewidth" dangerouslySetInnerHTML={mitcHtml}></div>
                        }
                        <PageFooter setDisabled={disable} BtnName="I SUBMIT" TermsCondition="By accepting, I confirm to have read the most important terms and conditions" ButtonClick={() => acceptMitc()}/>
                    </div>
                </div>
            </div>
        </>
    );
}
export default MitcAcceptance;