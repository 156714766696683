import React from "react";
import register_Image from "../../images/dtocweb_images/delivered/Level__Level-1.png";
import product_Image from "../../images/dtocweb_images/delivered/Level__level2.png";
import Address_Image from "../../images/dtocweb_images/delivered/Level__level3.png"
import Summary_Image from "../../images/dtocweb_images/delivered/Level__level4.png"
import product_gif from "../../images/dtocweb_images/delivered/walk.gif";
import Img404jpg from "../../images/dtocweb_images/404.jpg";

export const LoginImg = () => {
    return (
        <div className="">
            <div className="imgsctiveclass">
                <img src={register_Image} alt="" className="img-fluid" />
                <div className="setgifwidth">
                    <img src={product_gif} id="imagegif2" alt=""
                        className=" img-fluid gifonetime " />
                </div>
            </div>
        </div>
    );
}


export const ProductionImg = () => {
    return (
        <div className="">
            <div className="imgsctiveclass">
                <img src={product_Image} alt="" className="img-fluid" />
                <div className="setgifwidth">
                    <img src={product_gif} id="imagegif2" alt=""
                        className=" img-fluid gifonetime " />
                </div>
            </div>
        </div>
    );
}

export const AddressImg = () => {
    return (
        <div className="">
            <div className="imgsctiveclass">
                <img src={Address_Image} alt="" className="img-fluid" />
                <div className="setgifwidth">
                    <img src={product_gif} id="imagegif2" alt=""
                        className=" img-fluid gifonetime " />
                </div>
            </div>
        </div>
    );
}

export const SummaryImg = () =>{
    return(
        <div className="">
            <div className="imgsctiveclass">
                <img src={Summary_Image} alt="" className="img-fluid" />
                <div className="setgifwidth">
                    <img src={product_gif} id="imagegif2" alt=""
                        className=" img-fluid gifonetime " />
                </div>
            </div>
        </div>
    );
}


export const Img404 = () =>{
    return(
        <div className="">
            <div className="imgsctiveclass">
                <img src={Img404jpg} alt="" className="img-fluid" />
                <div className="setgifwidth">
                    <img src={product_gif} id="imagegif" alt=""
                        className=" img-fluid gifonetime " />
                </div>
            </div>
        </div>
    );
}