import React from "react";
import Layout from "../common-components/Layout";

const NotFound = () => {

    return (
        <>
            <Layout ImageSide="404">
                <h2 className="text-center required">404 - Not Found</h2>
                <h3 className="required">Requested resource does not exist</h3>
           </Layout>
        </>
    );
}
export default NotFound;