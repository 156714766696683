import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Heading from "../common-components/Heading";
import Input from "../common-components/Input";
import Layout from "../common-components/Layout";
import PageFooter from "../common-components/PageFooter";
import { ToastContainer, toast } from 'react-toastify';
import config from "../../config.json";
import axios from "axios";
import { format } from 'date-fns';
import { BureauContext } from "../Context/BureauContext";

const MandateSummary = () => {
    const formatter = new Intl.NumberFormat({ maximumSignificantDigits: 3 })
    const navigate = useNavigate();
    const api_url = config.API_URL;
    const usertoken = sessionStorage.getItem('token');
    const [bankName, setBankName] = useState('');
    const [accountNo, setAccountNo] = useState('');
    const [accountHolderName, setAccountHolderName] = useState('');
    const [accountType, setaccountType] = useState('');
    const [maximumAmount, setMaximumAmount] = useState('');
    const [debitFrequency, setdebitFrequency] = useState('');
    const startDate = format(new Date(),'dd-MM-yyyy');
    const [purpose, setPurpose] = useState('');
    const [endDate, setEnd] = useState('');
    const [ref_no, setRefNo] = useState('');
    const [umrn_no, setumrnNo] = useState('');
    const [isDisabled, setDisabled] = useState(true);
    const {popupData, setPopUpData, checkStateByMiddileware, checkLocationPermission} = useContext(BureauContext);
    const latitude = sessionStorage.getItem("latitude");
    const longitude = sessionStorage.getItem("longitude");
    
    useEffect(() => {
        if(checkLocationPermission()){
            checkStateByMiddileware();
            const headers = {
                'Authorization': `Bearer ${usertoken}`,
                'latlong' : `${latitude},${longitude}`,
            }
            document.getElementById('waitingLoader').style.display = 'block';
            axios.get(api_url + `/mandate-summary`, { headers })
                .then((response) => {
                    document.getElementById('waitingLoader').style.display = 'none';
                    if (response.data.status == 'success') {
                        setDisabled(false);
                        setBankName(response.data.data.appBank.bank_name);
                        setAccountNo(response.data.data.appBank.bank_account_number);
                        setAccountHolderName(response.data.data.appBank.account_holder_name);
                        setaccountType(response.data.data.appBank.account_type);
                        setMaximumAmount(response.data.data.loanSanction.loan_amount);
                        setdebitFrequency(response.data.data.loanSanction.frequency);
                        setPurpose(response.data.data.loanSanction.purpose_of_loan);
                        setEnd(response.data.data.loanSanction.purpose_of_loan);
                        setRefNo(response.data.data.loanSanction.ref_no);
                        setumrnNo(response.data.data.loanSanction.umrn_no ? response.data.data.loanSanction.umrn_no : '202206140727544078');
                    }else if(response.data.status === "expired") {
                        document.getElementById('waitingLoader').style.display = 'none';
                        setPopUpData({...popupData,is_bureau_checked : true});
                    }else if(response.data.status === "hold") {
                        document.getElementById('waitingLoader').style.display = 'none';
                        setPopUpData({...popupData,is_bank_hold : true});
                    }else if(response.data.status === "token") {
                        sessionStorage.clear();
                        navigate('/login');
                    }else {
                        document.getElementById('waitingLoader').style.display = 'none';
                        toast.error(response.data.errors,{
                        position: toast.POSITION.TOP_CENTER
                        });
                    }
                })
                .catch(error => {
                    document.getElementById('waitingLoader').style.display = 'none';
                    toast.error(error.message,{
                        position: toast.POSITION.TOP_CENTER
                    });
                });
        }else{
            toast.error("Please allow your device location", {
                position: toast.POSITION.TOP_CENTER
            });
        }
    }, [])

    const confirmMandate = async () => {
        if(checkLocationPermission()){
            checkStateByMiddileware();
            const headers = {
                "Authorization" : `Bearer ${usertoken}`,
                'latlong' : `${latitude},${longitude}`,
            }

            const requestOptions = {
                method : "POST",
                headers : headers
            }
            document.getElementById('waitingLoader').style.display = 'block';
            fetch(api_url+`/confirm-mandate`,requestOptions).then((res) => {
                return res.json();
            }).then((result) => {
                document.getElementById('waitingLoader').style.display = 'none';
                if(result.status === 'success'){
                    navigate('/journey-success');
                }else if(result.status === "expired") {
                    document.getElementById('waitingLoader').style.display = 'none';
                    setPopUpData({...popupData,is_bureau_checked : true});
                }else if(result.status === "hold") {
                    document.getElementById('waitingLoader').style.display = 'none';
                    setPopUpData({...popupData,is_bank_hold : true});
                } else if(result.status === 'token'){
                    navigate('/login');
                }else if (result.status === 'fail' && result.is_rejected === true) {
                    document.getElementById('waitingLoader').style.display = 'none';
                    if(result.hasOwnProperty('data')){
                    sessionStorage.setItem('status_flag', result.data.appStatus.comment)
                }
                    navigate("/regrate");
                }else{
                    toast.error(result.message);
                }
            }).catch((err) => {
                document.getElementById('waitingLoader').style.display = 'none';
            })
        }else{
            toast.error("Please allow your device location", {
                position: toast.POSITION.TOP_CENTER
            });
        }
    }

    return (
        <>
            <ToastContainer />
            <div className="pt-3">
                <Heading Description="You have the support from U GRO in prospering your business" BadgeDescription="Let us review your mandate set up information" user />
            </div>
            <Layout ImageSide="Product-intro">
                
                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <Input LabelName="Name" type="text" Id="Name" inputval={accountHolderName} placeholder="Name" disabled />
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <Input LabelName="Bank" type="text" Id="Bank" inputval={bankName} placeholder="Bank" disabled />
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <Input LabelName="Account Type" type="text" inputval={accountType} Id="Account_Type" placeholder="Account Type" disabled />
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <Input LabelName="Account Number" type="text" inputval={accountNo} Id="Account_Number" placeholder="Account Number" disabled />
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <Input LabelName="Amount" type="text" Id="Amount" inputval={maximumAmount} placeholder="Amount" disabled />
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <Input LabelName="Start Date" type="text" Id="Start_Date" inputval={startDate} placeholder="Start Date" disabled/>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <Input LabelName="End Date" type="text" Id="End_Date" inputval="Until Cancelled" placeholder="End Date" disabled />
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <Input LabelName="Debit Frequency" type="text" Id="Debit_Frequency" inputval="Monthly" placeholder="Debit Frequency" disabled />
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <Input LabelName="Purpose" type="text" Id="Purpose" inputval={purpose} placeholder="Purpose" disabled />
                        </div>

                        <div className="col-md-6 col-sm-12">
                            <Input LabelName="Utility Number" type="text" Id="Utility_Number" placeholder="Utility Number" disabled />
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <Input LabelName="Reference Number" type="text" Id="Reference_Number" inputval={ref_no} placeholder="Reference Number" disabled />
                        </div>

                        <div className="col-md-6 col-sm-12">
                            <Input LabelName="UMRN Number" type="text" Id="UMRN_Number" inputval={umrn_no} placeholder="UMRN Number" disabled />
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <Input LabelName="NPCI REF Number" type="text" Id="NPCI_REF_Number" placeholder="NPCI REF Number" disabled />
                        </div>

                    </div>
                    <p className="text-center fontsize-14 mb-2 mt-3">Your E-Mandate registration is successfully completed. Your reference ID for E-Mandate registration is </p>
                    <p className="text-center fontsize-14 "><strong className="text-center">{umrn_no}</strong></p>
                    <PageFooter type="submit" setDisabled={isDisabled} ButtonClick={()=> confirmMandate()}  BtnName="I SUBMIT" TermsCondition="" />
            </Layout>
        </>
    );
}
export default MandateSummary;