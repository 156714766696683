import React from "react";
import Image1 from "../../../images/dtocweb_images/banker/banker_graphics__Layer-Comp-1.png";
import Image2 from "../../../images/dtocweb_images/banker/banker_graphics__Layer-Comp-2.png"
import Image3 from "../../../images/dtocweb_images/banker/banker_graphics__Layer-Comp-3.png"
import Image4 from "../../../images/dtocweb_images/banker/banker_graphics__Layer-Comp-4.png"
import Image5 from "../../../images/dtocweb_images/banker/banker_graphics__Layer-Comp-5.png"
import Image6 from "../../../images/dtocweb_images/delivered/inactive/gRAPHICS__Layer-Comp-6.png"
import Walkgif from "../../../images/dtocweb_images/delivered/walk-cycle_banker.gif";

const RoadMap = () => {
    return (
        <>
            <div className="mb-5 pb-5 border-top">
                <div className="title pt-3 pb-5 text-center">
                    <h1 className="text-orange dreamclickroadmap"> The roadmap</h1>
                </div>
                <div className="row justify-content-between mx-auto pt-5 roadmap-img">
                    <div className="col-lg-2 col-md-12 road processyellow">
                        <div className="roadmap1 ">
                            <div className="active-img">
                                <img src={Image1} alt="" className="img-fluid activemyclass" />
                                <div className="mytitle text-orange text-center">
                                    <small className="">Registration</small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-12 road processyellow">
                        <div className="roadmap2 ">
                            <div className="active-img">
                                <img src={Image2} alt="" className="img-fluid activemyclass" />
                                <div className="mytitle text-orange text-center">
                                    <small>KYC</small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-12 road processyellow">
                        <div className="roadmap3 ">
                            <div className="active-img">
                                <div className="mytitle text-orange text-center">
                                    <small className="">Assessment</small>
                                </div>
                                <img src={Image3} alt="" className="img-fluid activemyclass" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-12 road">
                        <div className="roadmap1 ">
                            <div className="active-img">
                                <img src={Image4} alt="" className="img-fluid activemyclass" />
                                <div className="mytitle text-orange text-center">
                                    <small>Sanction Acceptance</small>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-12 road">
                        <div className="roadmap2 ">
                            <div className="active-img">
                                <div className="mytitle text-orange text-center">
                                    <small>Repayment Mandate Set-up</small>
                                </div>
                                <img src={Image5} alt="" className="img-fluid activemyclass" />

                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-12 road">
                        <div className="roadmap3 ">
                            <div className="process ">
                                <img src={Image6} alt="" className="img-fluid activemyclass" />
                                <div className="mytitle text-orange text-center">
                                    <small>Utilization Ready</small>
                                </div>
                                <img src={Walkgif} alt="" className="gifactive" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default RoadMap;