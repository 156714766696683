
import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import { CustomButton } from "../common-components/Buttons";
import ChooseInput from "../common-components/ChooseInput";
import Heading from "../common-components/Heading";
import Input from "../common-components/Input";
import Layout from "../common-components/Layout";
import imageThumb from "../../images/image-thumb.png";
import config from "../../config.json";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import InputMask from 'react-input-mask';
import ApplicationSummary from "../application-summary/ApplicationSummary";
import { CustomOtp } from "../common-components/OtpInput";
import { BureauContext } from "../Context/BureauContext";
import { RadioInput, CheckBoxInput } from "../common-components/CheckInput";
import Tooltip from "../../components/common-components/Tooltip";
import { async } from "q";
import { encryptData } from "../../encrypt";
import PageFooter from "../common-components/PageFooter";

const UdyamDetail = () => {

    const navigate = useNavigate();
    const udyam_url = config.UDYAM_REGISTRATION_URL;
    const [isDisabled, setDisabled] = useState(true);
    const [disabled, updateDisabled] = useState(false)
    const [udyamAadhar, setUdyamAadhar] = useState();
    const api_url = config.API_URL;
    const [isSelectedImg, setSelectedImg] = useState(false);
    const [frontpdf, setFrontPDF] = useState('');
    const [ishide, setHide] = useState(false);
    const [isConsentHide, setConsentHide] = useState(true);
    const [isUdyamUpload, setUdyamUpload] = useState(false);
    const [request_id, setRequestId] = useState();
    const [formatChars, setFormatChars] = useState([0 - 9]);
    const jwtToken = sessionStorage.getItem('token');
    const [validation, setValidationError] = useState({ udyamError: '', mobileError: '', otpError: '',  udyamImageError : '' });
    const [isOtpActive, setIsotpActive] = useState(false);
    const [isUdyamAvailable, SetUdyamAvailability] = useState(false);
    const [agree_concent, setAgreeConcent] = useState(0);
    const {popupData, setPopUpData, checkStateByMiddileware, checkLocationPermission} = useContext(BureauContext);
    const [isGst, setGst] = useState(false);
    const [agree_consent, setAgreeConsent] = useState(0);
    const [modal, setModal] = useState(false);
    const [isReUpload, setReUpload] = useState(false);
    const [src, setSrc] = useState(imageThumb);
    const [udyamImage, setUdyamImage] = useState("");
    const [udyamExt, setUdyamExt] = useState('');
    const [title, setTitle] = useState('Udyam Certificate');
    const inputRef = useRef(null);
    const locate_permission = sessionStorage.getItem('locate_permission');
    const latitude = sessionStorage.getItem("latitude");
    const longitude = sessionStorage.getItem("longitude");

    const headers = {
        'Authorization': `Bearer ${jwtToken}`,
        'latlong' : `${latitude},${longitude}`,
    }
    const checkGst = async () => {
        if(checkLocationPermission()){
            checkStateByMiddileware();
            document.getElementById('waitingLoader').style.display = 'block';
            await fetch(api_url+`/check-gst`,{headers}).then((res) => {
                return res.json();
            }).then((result) => {
                document.getElementById('waitingLoader').style.display = 'none';
                if(result.status === 'success'){
                    if(Object.keys(result.data.gst).length > 0){
                        setGst(true);
                    }else{
                        setGst(false);
                    }
                }else if(result.status === "fail" && result.is_rejected === true){
                    document.getElementById('waitingLoader').style.display = 'none';
                    navigate('/regrate');
                }else if(result.status === "token"){
                    document.getElementById('waitingLoader').style.display = 'none';
                    sessionStorage.clear();
                    navigate('/login');
                }else if(result.status === "hold") {
                    document.getElementById('waitingLoader').style.display = 'none';
                    setPopUpData({...popupData,
                        is_bank_hold : 1
                    });
                }else{
                    document.getElementById('waitingLoader').style.display = 'none';
                    toast.error(result.errors, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            }).catch((err) => {
                toast.error(err, {
                    position: toast.POSITION.TOP_CENTER
                });
            })
        }else{
            toast.error("Please allow your device location", {
                position: toast.POSITION.TOP_CENTER
            });
        }
    }
  
    useEffect((e) => {
       // checkGst();
        var myElement = document.getElementsByClassName('udyam');
        myElement.onpaste = function(e) {
            alert();
        e.preventDefault();
        return false; // Prevent the default handler from running.
        };
        
    },[]);

    const proceedUdyamAadhar = () => {
        checkStateByMiddileware();
        setModal(false);
        let checkValidate = true;
        let checkerror = {}
        if (isUdyamAvailable == true && (udyamAadhar === '' || udyamAadhar === undefined)) {
            checkerror['udyamError'] = "Please Enter UDYAM AADHAAR";
            checkValidate = false;
        } else {
            checkerror['udyamError'] = "";
        }
       
        setValidationError({ ...validation, ...checkerror });
        if(locate_permission === 'denied'){
            toast.error("Please allow your device location",{
                position: toast.POSITION.TOP_CENTER
            });
            checkValidate = false;
        }
        if (checkValidate) {
            const payload = {
                udyam_no: udyamAadhar,
                is_udyam: isUdyamAvailable,
            }
            const encrypted = encryptData(payload);
            const headers = {
                'Authorization': `Bearer ${jwtToken}`,
                'latlong' : `${latitude},${longitude}`,
            }
            document.getElementById('waitingLoader').style.display = 'block';
            axios.post(api_url + `/udyam-verified`, {encrypted : encrypted}, { headers })
                .then((response) => {
                    document.getElementById('waitingLoader').style.display = 'none';
                    if (response.data.status == 'success') {
                        if (response.data.is_udyam == 'YES') {
                            toast.success(response.data.message, {
                                position: toast.POSITION.TOP_CENTER
                            });
                            if (response.data.data.show_gst_screen == true) {
                                navigate('/linked-gst');
                            } else {
                                navigate('/upload-aadhar');
                            }
                        } else {
                            if (response.data.data.show_gst_screen == true) {
                                navigate('/linked-gst');
                            } else {
                                navigate('/upload-aadhar');
                            }
                        }

                    }else if (response.data.status === 'fail' && response.data.is_rejected === true) {
                        document.getElementById('waitingLoader').style.display = 'none';
                        if(response.data.hasOwnProperty('data')){
                    sessionStorage.setItem('status_flag', response.data.data.appStatus.comment)
                }
                        navigate("/regrate");
                    }else if (response.data.status === 'fail' && response.data.apiError === true) {
                        document.getElementById('waitingLoader').style.display = 'none';
                        setUdyamUpload(true);
                        updateDisabled(true);
                    }else if (response.data.status === 'fail') {
                        document.getElementById('waitingLoader').style.display = 'none';
                        toast.error(response.data.errors, {
                            position: toast.POSITION.TOP_CENTER
                        });
                    } else if (response.data.status === "expired") {
                        document.getElementById('waitingLoader').style.display = 'none';
                        setPopUpData({...popupData,is_bureau_checked : true});
                    }else if(response.data.status === "hold") {
                        document.getElementById('waitingLoader').style.display = 'none';
                        setPopUpData({...popupData,is_bank_hold : true});
                    }

                    if (response.data.status === 'token') {
                        document.getElementById('waitingLoader').style.display = 'none';
                        sessionStorage.clear();
                        navigate('/login');
                    }

                }).catch(error => {
                    document.getElementById('waitingLoader').style.display = 'none';
                    toast.error(error.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                });
        }
    }


    function preventPaste()
    {
        //alert();
        
    }
    
    const onChangeUdyam = (e) => {
        preventPaste();
        const value = e.target.value.toUpperCase();
        const limit = 19;
        let checkerror = {}
        setUdyamAadhar(value.slice(0, limit));
        if (e.target.value.length >= limit) {
            setDisabled(false);
            checkerror['udyamError'] = "";
        } else {
            setDisabled(true);
            checkerror['udyamError'] = "Please Enter UDYAM AADHAAR";
        }
        setValidationError({ ...validation, ...checkerror });
    }
  

    function onSelectUdyamAvailability(val) {
        reupload();
        if (val == 'yes') {
            setDisabled(true);
            updateDisabled(false);
            SetUdyamAvailability(true);
            setUdyamUpload(false);
        }

        if (val == 'no') {
            setUdyamUpload(false);
            setUdyamAadhar('');
            setHide(false);
            setDisabled(false);
            updateDisabled(false);
            SetUdyamAvailability(false);
        }
    }

    function onChangeChecked(e) {
        setDisabled(true);
        setAgreeConcent(0);
        if (e.target.checked) {
            setDisabled(false);
            setAgreeConcent(1);
        }
    }

    const onFileChangeHandler = (e) => {
        var reader = new FileReader();
        if(e.target.files[0] !== undefined && e.target.files[0] !== '' && e.target.files[0] !== null){
            reader.readAsDataURL(e.target.files[0]);
            var exten = e.target.files[0].name.split('.').pop();
            const array = ['pdf', 'jpg', 'png', 'jpeg']; 
            const doesExist = array.includes(exten);
            const checkerror = {};
            if(doesExist !== true){
                checkerror['udyamImageError'] = "Udyam Proof must be jpg, png, jpeg, pdf";
                setValidationError({ ...validation, ...checkerror });
                return false;
            }
        }
        reader.onloadend = function(){
            const img_arr = reader.result.split(";base64,");
            var ext = e.target.files[0].name.split('.').pop();
            const image = img_arr[1];
            var extenstion = e.target.files[0].type;
            var value = e.target.files[0].size;
            var file_size = value / 1024;
            var file_name = e.target.files[0].name.replace(/C:\\fakepath\\/i, '');
            if (extenstion !== "application/pdf") {
                setSrc(URL.createObjectURL(e.target.files[0]));
            }else{
                setSrc(imageThumb);  
            }
            let result = true;
            if (file_size > 1024 * 4) {
                toast.error("Please try with an acceptable file size", {
                    position: toast.POSITION.TOP_CENTER,
                })
                result = false;
            }
            if(result){
                setTitle(file_name);
                setReUpload(true);
                let checkerror = {};
                setUdyamImage(image);
                setUdyamExt(ext);
                checkerror['udyamImageError'] = "";           
                setValidationError({ ...validation, ...checkerror });
            }
            
        } 
    }

    const reupload = () => {
        inputRef.current = null;
        setSrc(imageThumb);
        setReUpload(false);
        setSelectedImg(false);
        setFrontPDF('');
        setTitle('');
        setUdyamImage('');
    }

    const changeUdyam = () => {
        setUdyamUpload(false);
        updateDisabled(false);
        reupload();
    }

    const uploadUdyamAadhar = () => {
        checkStateByMiddileware();
        setModal(false);
        let checkValidate = true;
        let checkerror = {}
        if ((udyamImage === '' || udyamImage === undefined || udyamImage === null)) {
            checkerror['udyamImageError'] = "Please Select UDYAM Proof";
            checkValidate = false;
        } else {
            checkerror['udyamImageError'] = "";
        }
       
        setValidationError({ ...validation, ...checkerror });
        if(locate_permission === 'denied'){
            toast.error("Please allow your device location",{
                position: toast.POSITION.TOP_CENTER
            });
            checkValidate = false;
        }
        if (checkValidate) {
            const payload = {
                udyam_proof: udyamImage,
                doc_type : udyamExt,
            }
            const encrypted = encryptData(payload);
            const headers = {
                'Authorization': `Bearer ${jwtToken}`,
                'latlong' : `${latitude},${longitude}`,
            }
            document.getElementById('waitingLoader').style.display = 'block';
            axios.post(api_url + `/upload-udyam-proof`, {encrypted : encrypted}, { headers })
                .then((response) => {
                    document.getElementById('waitingLoader').style.display = 'none';
                    if (response.data.status == 'success') {
                        if (response.data.is_udyam == 'YES') {
                            toast.success(response.data.message, {
                                position: toast.POSITION.TOP_CENTER
                            });
                            updateDisabled(true);
                            setDisabled(false);
                            setRequestId(response.data.data.requestId);
                        } else {
                            if (response.data.data.show_gst_screen == true) {
                                navigate('/linked-gst');
                            } else {
                                navigate('/upload-aadhar');
                            }
                        }

                    }else if (response.data.status === 'fail' && response.data.is_rejected === true) {
                        document.getElementById('waitingLoader').style.display = 'none';
                        if(response.data.hasOwnProperty('data')){
                    sessionStorage.setItem('status_flag', response.data.data.appStatus.comment)
                }
                        navigate("/regrate");
                    }else if (response.data.status === 'fail' && response.data.api_error === true) {
                        document.getElementById('waitingLoader').style.display = 'none';
                        setUdyamUpload(true);
                    }else if (response.data.status === 'fail') {
                        document.getElementById('waitingLoader').style.display = 'none';
                        toast.error(response.data.errors, {
                            position: toast.POSITION.TOP_CENTER
                        });
                    } else if (response.data.status === "expired") {
                        document.getElementById('waitingLoader').style.display = 'none';
                        setPopUpData({...popupData,is_bureau_checked : true});
                    }else if(response.data.status === "hold") {
                        document.getElementById('waitingLoader').style.display = 'none';
                        setPopUpData({...popupData,is_bank_hold : true});
                    }

                    if (response.data.status === 'token') {
                        document.getElementById('waitingLoader').style.display = 'none';
                        sessionStorage.clear();
                        navigate('/login');
                    }

                }).catch(error => {
                    document.getElementById('waitingLoader').style.display = 'none';
                    toast.error(error.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                });
        }
    }

    return (
        <>
            <ToastContainer />
            <div className="pt-3">
                <Heading Description="Let us help you build a future-ready company" BadgeDescription="Let us complete your Business KYC" user />
            </div>
            <Layout ImageSide="Login">
                <h4 className="text-center mb-4"><b>UDYAM Details</b></h4>
                <div className="col-md-10 mx-auto">
                    <p className="fontsize-12 fw-bold col-md-12 mx-auto mb-0">Providing UDYAM will increase the chances of your eligibility and higher limit.</p>
                    <div className="udhamGap">
                     <label for="" className="form-label mb-0 fontsize-12 ml-4 font-wt-600 text-orange">Do You Have UDYAM Number? <span className="required">*</span></label>
                      <div className="udhamcenter d-flex" style={{ gap: '12px' }}>
                        <RadioInput radioname="udyam_yes" id="udyam_yes" redioClick={() => { onSelectUdyamAvailability('yes'); }}>Yes</RadioInput>
                        <RadioInput radioname="udyam_yes" id="udyam_no" redioClick={() => { onSelectUdyamAvailability('no'); }} >No</RadioInput>
                      </div>
                    </div>
                    {isUdyamAvailable ?
                        <div>
                            <label for="" className="form-label mb-0 fontsize-12 font-wt-600 text-orange">Please Enter UDYAM Number<span className="required">*</span></label>
                            <div className="mb-1">
                            <InputMask mask='UDYAM-aa-99-9999999' maskChar={null} className="form-control udyam" placeholder="Please enter your UDYAM Number" value={udyamAadhar} onChange={(e) => onChangeUdyam(e)} onPaste={(e)=>{e.preventDefault() 
                                return false;}} onCopy={(e)=>{e.preventDefault() 
                                return false;}} disabled={disabled}/>
                            </div>
                            {(validation.udyamError !== '') ?
                                <span className="text-danger fontsize-13">{validation.udyamError}</span> : null
                            }
                         { isUdyamUpload &&  
                        <div className="row">
                            <div className="text-end">
                            <a className='mt-0 fontsize-12 fw-bold' href='javascript:;' onClick={() => { changeUdyam(); }}>Change Udyam</a>
                            </div>
                            <div className="col-md-12">
                                <ChooseInput isDisabled={isSelectedImg} ispdf={frontpdf} src={src} inputRef={inputRef} title={title} isAccept={'.png, .jpg, .jpeg, .pdf'} selectFile={onFileChangeHandler} isIndividual="true" isFourMB="UDYAM" LabelName="Please Upload Udyam Certificate" />
                            </div>
                            {(validation.udyamImageError !== '') ?
                                <span className="text-danger fontsize-13">{validation.udyamImageError}</span> : null
                            }
                            <PageFooter BtnName="I SUBMIT" setDisabled={isDisabled}  ButtonClick={() => { uploadUdyamAadhar() }}/>
                            {isReUpload &&
                                <div className="text-center">
                                    <a className='mt-0 text-center fontsize-12 fw-bold' href='javascript:;' onClick={() => { reupload(); }}>Re Upload</a>
                                </div>
                            }
                        </div> }
                        </div> : null
                    }
                </div>
                <div className="text-center mt-4 col-md-10 mx-auto">
                       {/* {isUdyamAvailable && !otp ? <CheckBoxInput setConsentHide={isConsentHide} ChangeCheck={(e) => onChangeChecked(e)}>I hereby confirm that the Entered Mobile Number is linked to the Entered UDYAM Number</CheckBoxInput> : null } */}
                     {!isUdyamUpload && 
                        <PageFooter BtnName="I SUBMIT" setDisabled={isDisabled} ButtonClick={() => { proceedUdyamAadhar() }} />
                    }
                    {/* <p>Don't have UDYAM Number <Link to={udyam_url} target="_blank" className="text-decoration-none fw-bold">Apply Now</Link></p>
                    <p className="text-center fontsize-12 fw-bold col-md-10 mx-auto mb-0">Enter your UDYAM Registration Number(User ID), once received to continue the journey</p> */}
                </div>
            </Layout>

            {modal &&
                <div className="modal-center">
                    <div className={`modal modal-background fade show`} id="exampleModal" tabIndex={-1} style={{ display: "block" }}>
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-body pt-4">
                                    <div className="text-center col-md-10 mx-auto">There is No GSTN linked to your PAN and You have not provided UDYAM also ,If You don't Provide UDYAM or GST then the case will be rejected, Do you want to continue.</div>
                                </div>
                                    <div className="modal-footer justify-content-center border-0 pt-0">
                                        <button type="button" className="btn btn-primary" onClick={()=>proceedUdyamAadhar()} >Yes</button>
                                        <button type="button" className="btn btn-primary" onClick={() => setModal(false)} >No</button>                               </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }

        </>
    );
}
export default UdyamDetail;