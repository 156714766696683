import React from "react";

const DetailsCard = ({Heading,children,CollapseId,bradius}) => {
    return (
        <>
            <div className={`card border-bottom-dashed ${bradius}`}>
                <h6 className="card-header bg-transparent text-orange py-3" data-bs-toggle="collapse" href={`#${CollapseId}`} role="button" aria-expanded="true" aria-controls={CollapseId} >
                   {Heading}
                </h6>
                <div className="card-body   collapse show" id={CollapseId}>
                   {children}
                </div>
            </div>
        </>
    );
}
export default DetailsCard;