import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import Heading from "../common-components/Heading";
import Layout from "../common-components/Layout";
import { ToastContainer, toast } from 'react-toastify';
import config from "../../config.json";
import { CustomButton } from "../common-components/Buttons";
import { BureauContext } from "../Context/BureauContext";
import { encryptData } from "../../encrypt";

const PrincipleApproval = () => {
    const navigate = useNavigate();
    const api_url = config.API_URL;
    const userToken = sessionStorage.getItem('token');
    const approve_amount = sessionStorage.getItem("approve_amount");
    const latitude = sessionStorage.getItem("latitude");
    const longitude = sessionStorage.getItem("longitude");
    const {sanctionHtml, setsanctionHtml, logout, popupData, setPopUpData, checkStateByMiddileware, checkLocationPermission} = useContext(BureauContext);
    
    const proceedPrincipalApproval = async () => {
        if(checkLocationPermission()){
                checkStateByMiddileware();
                const headers =  {
                    'Authorization' : `Bearer ${userToken}`,
                    'content-type' : 'application/json',
                    'latlong' : `${latitude},${longitude}`,
                };
                document.getElementById('waitingLoader').style.display = 'block';
                await fetch(api_url+`/proceed-principal-approval`,{headers}).then((res) => {
                    return res.json();
                }).then((result) => {
                    document.getElementById('waitingLoader').style.display = 'none';
                    if(result.status === 'token'){
                        sessionStorage.clear();
                        navigate('/login');
                    }else if(result.status === 'expired'){
                        document.getElementById('waitingLoader').style.display = 'none';
                        setPopUpData({...popupData,is_bureau_checked : true});
                    }else if(result.status === "hold") {
                        document.getElementById('waitingLoader').style.display = 'none';
                        setPopUpData({...popupData,is_bank_hold : true});
                    }else if(result.status === 'success'){
                        document.getElementById('waitingLoader').style.display = 'none';
                        navigate('/kyc-document');
                    }else if (result.status === 'fail' && result.is_rejected === true) {
                        document.getElementById('waitingLoader').style.display = 'none';
                        if(result.hasOwnProperty('data')){
                    sessionStorage.setItem('status_flag', result.data.appStatus.comment)
                }
                        navigate("/regrate");
                    }else{
                        document.getElementById('waitingLoader').style.display = 'none';
                        toast.error(result.errors, {
                            position: toast.POSITION.TOP_CENTER,
                        });
                    }
                }).catch((err) => {
                    document.getElementById('waitingLoader').style.display = 'none';
                    toast.error(err, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                })
        }else{
            toast.error("Please allow your device location", {
                position: toast.POSITION.TOP_CENTER,
            });
        }
    }

    return (
        <>
            <ToastContainer />
            <div className="mt-2">
                <Heading Description="U GRO Capital can help you with your business finance" BadgeDescription="Let us sign you up" />
            </div>
            <Layout ImageSide="Register">
                <div className="principle">
                    <h4 className="text-center">In Principle Approval</h4>
                    <p className="text-center">Congratulations! You are eligible to get pre approved amount up to <b> ₹ {approve_amount}</b>. Now you can proceed with KYC Verification.</p>
                    <div className="text-center">
                     <CustomButton clickBtn={() => { proceedPrincipalApproval() }} btnname="Continue" btn_col="col-md-4"/>
                    </div>
                </div>
            </Layout>
        </>
    );
}
export default PrincipleApproval;