/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { GrFormClose } from "react-icons/gr"
import { CancelButton, CustomButton } from "./Buttons";

const Modal = ({ Modal, setModal, modalSize, Heading, children, ButtonSize,RightBtn,LeftBtn,RightBtnClick,LeftBtnClick,Footer }) => {
    return (
        <>
            <div className={`modal modal-background  ${Modal && 'fade show'}`} id="exampleModal" tabIndex={-1} style={{ display: "block" }}>
                <div className={`modal-dialog modal-dialog-centered ${modalSize}`}>
                    <div className="modal-content">
                        <div className="modal-header border-0 pb-0">
                            <h1 className="modal-title fs-5 cursor" id="exampleModalLabel">{Heading}</h1>
                            <p className="cursor mb-0" onClick={() => setModal(false)}><GrFormClose className="fs-5" /></p>
                        </div>
                        <div className="modal-body">
                            {children}
                        </div>
                        {Footer && <div className="gap-2 d-flex justify-content-center modal-footer border-0 pt-0">
                            {ButtonSize === "Two" && <>
                                <CustomButton btnname={RightBtn} btn_col="px-3" clickBtn={RightBtnClick} />
                                <CancelButton btnname={LeftBtn} btn_col="px-3" clickBtn={LeftBtnClick} /></>
                            }
                            {ButtonSize === "One" &&
                                <CustomButton btnname={RightBtn} btn_col="px-3" clickBtn={RightBtnClick} />
                            }
                        </div>}
                    </div>
                </div>
            </div>
        </>
    );
}
export default Modal;