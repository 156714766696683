import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { CustomButton } from "../common-components/Buttons";
import { LoginButton } from "../common-components/Buttons";
import { CheckBoxInput } from "../common-components/CheckInput";
import Heading from "../common-components/Heading";
import Input from "../common-components/Input";
import Layout from "../common-components/Layout";
import Modal from "../common-components/Modal";
import RoadMap from "./components/RoadMap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from "../../config.json";
import axios from "axios";
import Textarea from "../common-components/Textarea";
import { CustomOtp } from "../common-components/OtpInput";
import DownImg from "../../images/down.gif";
import { GrFormClose } from "react-icons/gr";
import approveModal from "../common-components/Modal"
import Select from "../common-components/Select";
import { useParams } from "react-router-dom/dist";
import { encryptData } from "../../encrypt";
import locationImage from "../../images/location-new.jpg";
import resetLocation from "../../images/reset-location.jpg";

const BajajRegister = () => {
    const api_url = config.API_URL;
    const BAJAJ_API_URL = config.BAJAJ_API_URL;
    const navigate = useNavigate();
    const { search } = useLocation();
    var urls = search;
    var myurls = urls.split("?signature=");
    var q = myurls[1];
    const [otp, setOtp] = useState(false);
    const [mobile, setMobile] = useState('');
    const [termHTML, setHtml] = useState({ __html: "" });
    const [promocode, setPromocode] = useState('');
    const [jwtToken, setToken] = useState('');
    const [modal, setModal] = useState(false);
    const [disabled, setDisabledBtn] = useState(false);
    const [isDisabled, setDisabled] = useState(true);
    const token = sessionStorage.getItem('token');
    const [isActive, setIsActive] = useState(false);
    const [isActivePro, setIsActivePro] = useState(false);
    const [isAccept, setIsAccept] = useState(true);
    const [scroll, setScroll] = useState(5000);
    const [sms, setSMS] = useState('');
    const [mobileError, setMobileError] = useState('');
    const [latLong, setlatLong] = useState(true);
    const [validation, setValidationError] = useState({ mobileNoError: '', otpError: '', emailError: '', displayEmailError:'',promoError:'' });
    const [email, setEmail] = useState('');
    const [terms_n_conditions, setTermAndCondition] = useState('Y');
    const [isemailActive, setisemailActive] = useState(false);
    const [locate_permission, setLocatePermission] = useState('');
    const [locationInstruction, setLocationInstruction] = useState(false);
    const [locationPopup, setLocationPopup] = useState(false);
    const [fetchLocation, setFetchLocation] = useState(false);
    const [locationError, setLocationError] = useState(false);
    const [locationErrorPopUp, setLocationErrorPopUp] = useState(false);
    const latitude = sessionStorage.getItem("latitude");
    const longitude = sessionStorage.getItem("longitude");
    useEffect(() => {
        navigator.permissions
        .query({ name: "geolocation" })
        .then((permissionStatus) => {
          setLocatePermission(permissionStatus.state);
          permissionStatus.onchange = () => {
            setLocatePermission(permissionStatus.state);
          };
        });
    }, []);

    useEffect(() => {
        getUserDetail();
        checkLocationOnRegister();
    }, []);

    const checkLocationOnRegister = async() => {
        navigator.permissions
        .query({ name: "geolocation" })
        .then((permissionStatus) => {
            sessionStorage.setItem("locate_permission" , permissionStatus.state);
            if(permissionStatus.state === 'granted'){
                setLocationInstruction(false);
                setFetchLocation(false);
                setLocationError(false);
                setLocationErrorPopUp(false);
                var timeoutVal = 10 * 1000 * 1000;
                navigator.geolocation.watchPosition(displayPosition, displayError,{ enableHighAccuracy: true, timeout: timeoutVal, maximumAge: 0 });
            }else if(permissionStatus.state === 'denied'){
                setLocationInstruction(false);
                setFetchLocation(false);
                setLocationError(true);
                setLocationErrorPopUp(false);
              }else{
                setLocationInstruction(true);
                setFetchLocation(false);
                setLocationError(false);
                setLocationErrorPopUp(false);
             }
            permissionStatus.onchange = () => {
              sessionStorage.setItem("locate_permission" , permissionStatus.state);
              if(permissionStatus.state === 'granted'){
                setLocationInstruction(false);
                setFetchLocation(false);
                setLocationError(false);
                setLocationErrorPopUp(false);
                var timeoutVal = 10 * 1000 * 1000;
                navigator.geolocation.watchPosition(displayPosition, displayError,{ enableHighAccuracy: true, timeout: timeoutVal, maximumAge: 0 });
              }else if(permissionStatus.state === 'denied'){
                setLocationInstruction(false);
                setFetchLocation(false);
                setLocationError(true);
                setLocationErrorPopUp(false);
              }else{
                setLocationInstruction(true);
                setFetchLocation(false);
                setLocationError(false);
                setLocationErrorPopUp(false);
             }
            };
          }); 
    }

    function allowDeviceLocation(){
        navigator.permissions
        .query({ name: "geolocation" })
        .then((permissionStatus) => {
            if(permissionStatus.state === 'granted'){
                sessionStorage.setItem("locate_permission" , permissionStatus.state);
                var timeoutVal = 10 * 1000 * 1000;
                navigator.geolocation.watchPosition(displayPosition, displayError,{ enableHighAccuracy: true, timeout: timeoutVal, maximumAge: 0 });
            }else if(permissionStatus.state === 'denied'){
                var timeoutVal = 10 * 1000 * 1000;
                navigator.geolocation.watchPosition(displayPosition, displayError, { enableHighAccuracy: true, timeout: timeoutVal, maximumAge: 0 });
                console.log("allow here.......");
                setLocationInstruction(false);
                setFetchLocation(false);
                setLocationError(true);
                setLocationErrorPopUp(false);
            }else{
                var timeoutVal = 10 * 1000 * 1000;
                navigator.geolocation.watchPosition(displayPosition, displayError, { enableHighAccuracy: true, timeout: timeoutVal, maximumAge: 0 });
                console.log("allow here.......");
                setLocationInstruction(true);
                setFetchLocation(false);
                setLocationError(false);
                setLocationErrorPopUp(false);
            }
            permissionStatus.onchange = () => {
                sessionStorage.setItem("locate_permission" , permissionStatus.state);
                if(permissionStatus.state === 'granted'){
                    var timeoutVal = 10 * 1000 * 1000;
                    navigator.geolocation.watchPosition(displayPosition, displayError, { enableHighAccuracy: true, timeout: timeoutVal, maximumAge: 0 });
                }else if(permissionStatus.state === 'denied'){
                    setLocationInstruction(false);
                    setFetchLocation(false);
                    setLocationError(true);
                    setLocationErrorPopUp(false);
                    sessionStorage.setItem('latitude','');
                    sessionStorage.setItem('longitude','');
                }else{
                    var timeoutVal = 10 * 1000 * 1000;
                    navigator.geolocation.watchPosition(displayPosition, displayError, { enableHighAccuracy: true, timeout: timeoutVal, maximumAge: 0 });
                    setLocationInstruction(true);
                    setFetchLocation(false);
                    setLocationError(false);
                    setLocationErrorPopUp(false);
                    sessionStorage.setItem('latitude','');
                    sessionStorage.setItem('longitude','');
                }
            };
          });
          setLocationPopup(false);
    }
    const closeLocationError = async() => {
        setLocationInstruction(false);
        setFetchLocation(false);
        setLocationError(true);
        setLocationErrorPopUp(false)
        setLocationPopup(false);

    }

    function displayPosition(position) {
        if(position.coords.latitude === '' || position.coords.longitude === ''){
            setFetchLocation(true);
        }
        sessionStorage.setItem("latitude" , position.coords.latitude);
        sessionStorage.setItem("longitude" , position.coords.longitude);
      }
    
      function displayError(error) {
        
        if(error.code === 2 || error.code === 3){
            setFetchLocation(true);
        }
        var errors = {
          1: 'Permission denied',
          2: 'Position unavailable',
          3: 'Request timeout'
        };
      }

    const termCondition = async (e) => {
        e.preventDefault();
        document.getElementById('waitingLoader').style.display = 'block';
        fetch(api_url + `/term-condition`).then((res) => {
            return res.json();
        }).then((result) => {
            document.getElementById('waitingLoader').style.display = 'none';
            if (result.status === "success") {
                sessionStorage.setItem('termContent', result.data.content);
                setHtml({ __html: result.data.content });
                setModal(true);
            }
            else if (result.status === "token") {
                sessionStorage.clear();
                navigate('/login');
            }
            else if (result.status === "fail") {
                toast.error(result.errors);
            }
        }).catch((err) => {
            
        })
    }

    const onChangeMobile = (e) => {
        setIsActive(false);
        const value = e.target.value.replace(/\D/g, "");
        const limit = 10;
        setMobile(value.slice(0, limit));
        if (e.target.value.length >= 10) {
            setDisabledBtn(false);
        } else {
            setDisabledBtn(true);
        }
        removeErrorSms(e, 'mobileNoError');
    }

    const showPosition = (position) => {
        var latitude = position.coords.latitude;
        var longitude = position.coords.longitude;
        setlatLong(latitude + "," + longitude);
        //return ;

    }

    const getUserDetail = () => {
        axios.get(BAJAJ_API_URL +`/get-user-detail?signature=${q}`).then((response) => {
            if (response.data.status === 'success') {
                document.getElementById('waitingLoader').style.display = 'none';
                setMobile(response.data.data.mobile_no);
                setEmail(response.data.data.email);
                setPromocode(response.data.data.product_code);
                sessionStorage.setItem('token', response.data.data.token);
                navigate(response.data.data.stage.lastActiveStage);
            }else if (response.data.status === 'fail' && response.data.is_link === 'expired') {
                toast.error(response.data.errors, {
                    position: toast.POSITION.TOP_CENTER
                });
                navigate('/login');
            }else if (response.data.status === "token") {
                toast.error(response.data.errors);
                sessionStorage.clear();
                navigate('/login');
                } else {
                    document.getElementById('waitingLoader').style.display = 'none';
                    toast.error(response.data.errors, {
                        position: toast.POSITION.TOP_CENTER
                    });
                    setOtp(false);
                }
        }).catch((erro) => {

        })
    }

    const onChangeProMoCode = (e) => {
        const value = e.target.value;
        const limit = 20;
        setPromocode(value.slice(0, limit));
        removeErrorSms(e, 'promoError');
    }
 

    const validate = () => {
        let checkValidate = true;
        let checkerror = {}
        return checkValidate;
        if (disabled === true) {
            toast.error('Please accept the term and condition.');
            return checkValidate = false;
        }
        if (mobile === '' || mobile === null) {
            checkerror['mobileNoError'] = "Please enter mobile number";
            setValidationError({ ...validation, ...checkerror });
            return checkValidate = false;
        }
        
        let regex = new RegExp(/[6-9]{1}[0-9]{9}/);
        if (regex.test(mobile) == false) {
            checkerror['mobileNoError'] = "Please enter correct mobile number";
            setValidationError({ ...validation, ...checkerror });
            return checkValidate = false;
        } else {
            setMobileError('');
            checkValidate = true;
        }
        if (email === '' || email === null) {
            checkerror['displayEmailError'] = "Please enter email";
            setValidationError({ ...validation, ...checkerror });
            checkValidate = false;
        }
        // if (promocode === '' || promocode === null) {
        //     checkerror['promoError'] = "Please enter valid promocode";
        //     setValidationError({ ...validation, ...checkerror });
        //     checkValidate = false;
        // }
        
        if (email === '' || email === null) {
            checkerror['emailError'] = "Please enter email-id";
            setValidationError({ ...validation, ...checkerror });
            return checkValidate = false;
        }

        /*if(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email) == false){
            checkerror['emailError'] = "Please enter valid email.";
            setValidationError({ ...validation, ...checkerror });
            return checkValidate = false;
        }*/

        return checkValidate;
    }

    const acceptCondition = () => {
        setDisabledBtn(false);
        setModal(false);
        setSMS('');
        setIsAccept(true);
        setTermAndCondition("Y");
    }
    const goToLogin = () => {
        navigate('/login');
    }

  

    const goToDown = (val) => {
        const scrollmodal = document.querySelector('.modal-body');
        scrollmodal.scrollTo({
            top: val,
            left: 0,
            behavior: 'smooth'
        })
        setScroll(val + 2000);
    };

    function changeHandler(e) {
        setPromocode(e.target.value);
    }

    function modifyNamePlaceHolder(e) {
        if (e.type === 'focus') {
            setIsActive(true);
        } else {
            setIsActive(false);
            if (e.target.value.length === 10) {
                setDisabledBtn(false);
            } else {
                setDisabledBtn(true);
            }
        }
    }
    function modifyPromoPlaceHolder(e) {
        if (e.type === 'focus') {
            setIsActivePro(true);
        } else {
            setIsActivePro(false);
        }
    }

    function removeErrorSms(e, fielName) {
        let checkerror = {}
        if (e.target.value.length > 0) {
            checkerror[fielName] = "";
            setValidationError({ ...validation, ...checkerror });
        }
    }

    function modifyEmailPlaceHolder(e) {
        if (e.type === 'focus') {
            setisemailActive(true);
            if (e.target.value.length > 4) {
                setDisabledBtn(true);
            }
        } else {
            setisemailActive(false);
            if (e.target.value.length > 4) {
                setDisabledBtn(false);
            }
        }
    }

    const proceedRegister = () => {
    if (validate()) {
        if(locationInstruction === true){
            setLocationPopup(true);
        }else{
            acceptCondition();
           if(fetchLocation === false){
             if(locationError === false){
                if (isAccept) {
                    const request_data = {
                        mobile_no: mobile,
                        promocode_id: promocode,
                        lat_long: `${latitude},${longitude}`,
                        email:email,
                        location : 'Y',
                        terms_n_conditions:terms_n_conditions,
                        agreement_captured:terms_n_conditions,
                        bureau:terms_n_conditions,
                        aml:terms_n_conditions,
                        signature : q,
                    }
                    const encrypted = encryptData(request_data);
                    const requestOptions = {
                        method: 'POST',
                        headers: { 
                            'Content-Type': 'application/json',
                            'latlong' : `${latitude},${longitude}` 
                        },
                        body: JSON.stringify({encrypted : encrypted})
                    };
                    document.getElementById('waitingLoader').style.display = 'block';
                    fetch(BAJAJ_API_URL + `/sign-up`, requestOptions).then((res) => {
                        return res.json();
                    }).then((result) => {
                        document.getElementById('waitingLoader').style.display = 'none';
                        if (result.status === 'fail' && result.is_link == 'expired') {
                            toast.error(result.errors, {
                                position: toast.POSITION.TOP_CENTER
                            });
                            navigate('/login');
                        }else if (result.status === 'fail' && result.is_rejected === true) {
                            document.getElementById('waitingLoader').style.display = 'none';
                            if(result.hasOwnProperty('data')){
                                sessionStorage.setItem('status_flag', result.data.appStatus.comment)
                            }
                            navigate("/regrate");
                        }else if (result.status === 'fail') {
                            toast.error(result.errors, {
                                position: toast.POSITION.TOP_CENTER
                            });
                        }else if (result.status === "success") {
                            sessionStorage.setItem('token', result.data.token);
                            sessionStorage.setItem('userData', JSON.stringify(result.data.user));
                            sessionStorage.setItem('lafId', result.data.appData.laf_id);
                            sessionStorage.setItem("product_code", result.data.appData.product_code);
                            navigate(result.data.stage.lastActiveStage);
                        }
                    }).catch((err) => {
                        document.getElementById('waitingLoader').style.display = 'none';
                        toast.error(err, {
                            position: toast.POSITION.TOP_CENTER
                        });
                    })
                    setSMS('');
                }else {
                    setSMS('Please read and accept the Terms and Conditions');
                }
        }else{
            setLocationErrorPopUp(true);
        }
     }
     }
     } 
    }
    const onChangeEmail = (e) => {
        const value = e.target.value;
        const limit = 50;
        setEmail(value.slice(0, limit));
        if (value.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
            setDisabledBtn(false);
        } else {
            setDisabledBtn(false);
            removeErrorSms(e, 'displayEmailError');
        }
    }
    return (
        <>
            <ToastContainer />
            <div className="mt-2">
                <Heading Description="U GRO Capital can help you with your business finance" BadgeDescription="Let us sign you up" />
            </div>
            <Layout ImageSide="Register">
                <h4 className="text-center mb-4"> Create Account</h4>
                <div className="col-md-8 col-sm-12 mx-auto">
                    <Input LabelName="Mobile Number" disabled={isDisabled} keyFocus={(e) => modifyNamePlaceHolder(e)} type="text" blurEvent={(e) => modifyNamePlaceHolder(e)} Id="mobile_num" inputval={mobile} changeInput={(e) => onChangeMobile(e)} placeholder={isActive ? "Please enter your 10 digits mobile number" : ""} isAstrix  />
                    {(validation.mobileNoError !== '') ?
                        <span className="text-danger fontsize-13">{validation.mobileNoError}</span> : null
                    }
                   
                    <Input LabelName="E-mail ID" disabled={isDisabled} type="email" Id="E-mail_ID" isAstrix inputval={email} changeInput={(e) => onChangeEmail(e)} blurEvent={(e) => modifyEmailPlaceHolder(e)} keyFocus={(e) => modifyEmailPlaceHolder(e)} placeholder={isemailActive ? "Please enter your valid E-mail ID" : ""} />
                    {(validation.displayEmailError !== '') ?
                        <span className="text-danger fontsize-13">{validation.displayEmailError}</span> : null
                    }
                     {(validation.emailError !== '') ?
                        <span className="text-danger fontsize-13">{validation.emailError}</span> : null
                    }

                    <Input LabelName="Promo Code" disabled={isDisabled} type="text" Id="promo_code" inputval={promocode} changeInput={(e) => onChangeProMoCode(e)} keyFocus={(e) => modifyPromoPlaceHolder(e)} blurEvent={(e) => modifyPromoPlaceHolder(e)} placeholder={isActivePro ? "This field is optional" : ""} />
                    {(validation.promoError !== '') ?
                        <span className="text-danger fontsize-13">{validation.promoError}</span> : null
                    }
                    {!otp && <>
                        <p> I am aware of, and acknowledge the&nbsp;<LoginButton btnname="T&Cs" clickBtn={(e) => termCondition(e)} className="text-decoration-none text-orange"> T&Cs </LoginButton>
                        <div id="term_error" className="text-danger">{sms}</div></p>
                        <div className="text-center mt-3">
                            <CustomButton btnname="I SUBMIT" isDisabled={disabled} btn_col="col-md-4" clickBtn={(e) => { proceedRegister() }} />
                        </div>
                    </>
                    }
                  
                    {otp &&
                        <div class="mt-3 reg_consent text-center" style={{ "font-weight": "700", "font-size": "10px" }}>By registering, I am sharing my contact details with U GRO </div>
                    }
                </div>
            </Layout>
            <RoadMap />

            {modal &&
                <div className={`modal modal-background  ${modal && 'fade show'}`} id="exampleModal" tabIndex={-1} style={{ display: "block", zIndex:"99999" }}>
                    <div className="modal-dialog modal-xl">
                        <div className="modal-content">
                            <div className="modal-header border-0">
                                <h2 className="modal-title cursor text-center col-md-12" id="exampleModalLabel">Terms and Conditions</h2>
                                <div className="d-flex align-items-center" style={{ right: '35px', position: 'relative' }}>
                                    <a onClick={() => goToDown(scroll)} > <img src={DownImg} alt="Down arrow" width={25} className="cursor" /></a>
                                    <p className="cursor mb-0 d-flex" onClick={() => setModal(false)} >
                                        <GrFormClose className="fs-5" />
                                    </p>
                                </div>

                            </div>
                            <div className="modal-body termsconditions">
                                <div dangerouslySetInnerHTML={termHTML}></div>
                                <div className="py-3 text-center">
                                    <button type="button" className="btn btn-primary" onClick={() => { acceptCondition() }}>Accept</button>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            }

{locationPopup &&
            <div className="modal-center">
                <div className={`modal modal-background fade show`} id="exampleModal" tabIndex={-1} style={{ display: "block" }}>
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-body pt-4">
                               <div className="text-center col-md-10 mx-auto"><strong>Please Allow Your Device Location.</strong></div>
                               </div>
                            <div className="modal-footer justify-content-center border-0 pt-0">
                               <img src={locationImage} />
                            </div>
                            <div className="modal-footer justify-content-center border-0 pt-0">
                                <button type="button" className="btn btn-primary" onClick={()=>{ allowDeviceLocation(); }}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        }
        {fetchLocation &&
            <div className="modal-center">
            <div className={`modal modal-background fade show`} id="exampleModal" tabIndex={-1} style={{ display: "block" }}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body pt-4">
                               <div className="text-center col-md-10 mx-auto">Sorry, we are facing a technical issue. Please try again after some time..</div>
                            </div><br/>
                        </div>
                    </div>
                </div>
            </div> 
        }

    {locationErrorPopUp &&
            <div className="modal-center">
            <div className={`modal modal-background fade show`} id="exampleModal" tabIndex={-1} style={{ display: "block" }}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body pt-4">
                        <div className="text-center col-md-10 mx-auto"><strong>Your location seems to be blocked. Please Allow to continue.</strong></div>
                               <div className="text-center col-md-10 mx-auto"><img src={resetLocation} /></div>
                            </div><br/>

                            <div className="modal-footer justify-content-center border-0 pt-0">
                                <button type="button" className="btn btn-primary" onClick={()=>{ closeLocationError(); }}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        }
        </>
    );
}
export default BajajRegister;
