import React from 'react';
import { BrowserRouter } from "react-router-dom";
import ReactDOM from 'react-dom/client';
import './index.css';
import './css/custom.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// const googleTranslateElementInit = () => {
//   new window.google.translate.TranslateElement({pageLanguage: 'en', includedLanguages: 'en,ms,ta,hi', layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE}, 'google_translate_element');
// };
// var addScript = document.createElement('script');
//   addScript.setAttribute(
//     'src',
//     'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
//   );
//   document.body.appendChild(addScript);
//   window.googleTranslateElementInit = googleTranslateElementInit;
//   window.addEventListener('load', (event) =>{
//     document.cookie = "googtrans=/en/ta";
//   });
  
reportWebVitals();
