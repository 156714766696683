/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Heading from "../common-components/Heading";
import Layout from "../common-components/Layout";
import { CustomOtp } from "../common-components/OtpInput";
import PageFooter from "../common-components/PageFooter";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from "../../config.json";
import axios from "axios";
import Spacify from "../common-components/Spacify";
import { BureauContext } from "../Context/BureauContext";
import { encryptData } from "../../encrypt";

const AadharOtp = () => {
    const navigate = useNavigate();
    const [otp, setOtp] = useState(false);
    const [aadhaar_no, setAadhaarNo] = useState('');
    const api_url = config.API_URL;
    const [otpNum, setOtpNum] = useState({ fourOtp: '', sixOtp: '' });
    const onChangeFour = (value) => setOtpNum(preState => ({ ...preState, fourOtp: value }));
    const onChangeSix = (value) => setOtpNum(preState => ({ ...preState, sixOtp: value }));
    const [seconds, setSeconds] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const usertoken = sessionStorage.getItem('token');
    const {popupData, setPopUpData,checkStateByMiddileware, checkLocationPermission} = useContext(BureauContext);
    const aadhaar_number = sessionStorage.getItem('aadhaar_number');
    const locate_permission = sessionStorage.getItem('locate_permission');
    const latitude = sessionStorage.getItem("latitude");
    const longitude = sessionStorage.getItem("longitude");
    const getRegisterOtp = async () => {
        const headers = {
            'Authorization': `Bearer ${usertoken}`,
           'latlong' : `${latitude},${longitude}`,
        }
        await fetch(api_url + `/get-otp-time?otp_type=aadhaar`, { headers }).then((res) => {
            return res.json();
        }).then((result) => {
            if (result.status === 'success') {
                var countDownDate = new Date(result.data.date).getTime();
                var currentDate = new Date().getTime();
                var distance = countDownDate - currentDate;
                var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                var seconds = Math.floor((distance % (1000 * 60)) / 1000);
                setMinutes(minutes);
                setSeconds(seconds);
            }
        }).catch((err) => {
            
        })
    }

    const processOtp = () => {
        checkStateByMiddileware();
        let checkValidate = true;
        let fourNum = otpNum.fourOtp;
        if (fourNum === '' || fourNum === null) {
            toast.error('Four digit code is required');
            checkValidate = false;
        }
        if (fourNum.length !== 4 && fourNum != '') {
            toast.error('Please enter four digit code');
            checkValidate = false;
        }

        if(aadhaar_number == '' || aadhaar_number === "undefined" || aadhaar_number === undefined || aadhaar_number === null){
            checkValidate = false;
            alert("Session timeout. So Please Re-login");
            sessionStorage.clear();
            navigate('/login');
        }

        if(locate_permission === "denied"){
            toast.error("Please allow your device location", {
                position: toast.POSITION.TOP_CENTER,
            })
            checkValidate = false;
        }

        if (checkValidate) {
            const payload = {
                aadhaar_consent: 'Y',
                aadhaar_number : aadhaar_number,
            }
            const encrypted = encryptData(payload);
            const headers = {
                'Authorization': `Bearer ${usertoken}`,
                'latlong' : `${latitude},${longitude}`,
            }
            document.getElementById('waitingLoader').style.display = 'block';
            axios.post(api_url + `/send-aadhaar-otp`, {encrypted : encrypted}, { headers })
                .then((response) => {
                    document.getElementById('waitingLoader').style.display = 'none';
                    if (response.data.status == 'success') {
                        toast.success(response.data.message,{
                          position: toast.POSITION.TOP_CENTER
                        });
                        setOtp(true);
                        var countDownDate = new Date(response.data.data.valid_at).getTime();
                        var currentDate = new Date().getTime();
                        var distance = countDownDate - currentDate;
                        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                        var seconds = Math.floor((distance % (1000 * 60)) / 1000);
                        setMinutes(minutes);
                        setSeconds(seconds);
                    }else if (response.data.status === "token") {
                        toast.error(response.data.errors, {
                            position: toast.POSITION.TOP_CENTER,
                        });
                        sessionStorage.clear();
                        navigate('/login')
                    }else if(response.data.status === "expired") {
                        document.getElementById('waitingLoader').style.display = 'none';
                        setPopUpData({...popupData,is_bureau_checked : true});
                    }else if (response.data.status === 'hold') {
                        setPopUpData({...popupData,is_bank_hold : true});
                    } else if (response.data.status === 'fail' && response.data.is_rejected === true) {
                        document.getElementById('waitingLoader').style.display = 'none';
                        if(response.data.hasOwnProperty('data')){
                    sessionStorage.setItem('status_flag', response.data.data.appStatus.comment)
                }
                        navigate("/regrate");
                    } else {
                        document.getElementById('waitingLoader').style.display = 'none';
                        toast.error(response.data.errors,{
                            position: toast.POSITION.TOP_CENTER
                        });
                    }
                })
                .catch(error => {
                    document.getElementById('waitingLoader').style.display = 'none';
                    toast.error(error.message,{
                      position: toast.POSITION.TOP_CENTER
                    });
                });
        }
    }

    const processAdhar = () => {
        checkStateByMiddileware();
        let checkValidate = true;
        let sixNum = otpNum.sixOtp;
        if (sixNum === '' || sixNum === null) {
            toast.error('OTP Aadhar is required');
            checkValidate = false;
        }
        if (sixNum.length !== 6 && sixNum != '') {
            toast.error('Please enter Six digit code');
            checkValidate = false;
        }
        if(aadhaar_number == '' || aadhaar_number === "undefined" || aadhaar_number === undefined || aadhaar_number === null){
            checkValidate = false;
            alert("Session timeout. So Please Re-login");
            sessionStorage.clear();
            navigate('/login');
        }
        if (checkValidate) {
            const payload = {
                aadhaar_otp: sixNum,
                share_code: otpNum.fourOtp,
                aadhaar_number:aadhaar_number 
            }
            const encrypted = encryptData(payload);
            const headers = {
                'Authorization': `Bearer ${usertoken}`,
                'latlong' : `${latitude},${longitude}`,
            }
            document.getElementById('waitingLoader').style.display = 'block';
            axios.post(api_url + `/verify-aadhaar-otp`, {encrypted : encrypted}, { headers })
                .then((response) => {
                    document.getElementById('waitingLoader').style.display = 'none';
                    if (response.data.status === 'success') {
                        toast.success(response.data.message,{
                            position: toast.POSITION.TOP_CENTER
                        });
                        sessionStorage.setItem('aadhar_details', JSON.stringify(response.data.data));
                        navigate('/kyc-aadhar');
                    }else if (response.data.status === 'hold') {
                        setPopUpData({...popupData,is_bank_hold : true});
                    } else if(response.data.status === "token") {
                        toast.error(response.data.errors, {
                            position: toast.POSITION.TOP_CENTER
                        });
                        sessionStorage.clear();
                        navigate('/login')
                    }else if(response.data.status === "expired") {
                        document.getElementById('waitingLoader').style.display = 'none';
                        setPopUpData({...popupData,is_bureau_checked : true});
                    }else if (response.data.status === 'fail' && response.data.is_rejected === true) {
                        document.getElementById('waitingLoader').style.display = 'none';
                        if(response.data.hasOwnProperty('data')){
                    sessionStorage.setItem('status_flag', response.data.data.appStatus.comment)
                }
                        navigate("/regrate");
                    } else {
                        document.getElementById('waitingLoader').style.display = 'none';
                        toast.error(response.data.errors,{
                            position: toast.POSITION.TOP_CENTER
                        });
                    }
                })
                .catch(error => {
                    console.log("error is here",error);
                    document.getElementById('waitingLoader').style.display = 'none';
                    toast.error(error.message,{
                        position: toast.POSITION.TOP_CENTER
                    });
                });
        }
    }

    useEffect(() => {
        const interval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }

            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(interval);
                } else {
                    const seconds = 59;
                    setSeconds(seconds);
                    setMinutes(minutes - 1);
                }
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [seconds]);

    function resend() {
        checkStateByMiddileware();
        let fourNum = otpNum.fourOtp;
        const payload = {
            verification_code: fourNum,
            aadhaar_number : aadhaar_number,
        }
        const encrypted = encryptData(payload);
        const headers = {
            'Authorization': `Bearer ${usertoken}`,
           'latlong' : `${latitude},${longitude}`,
        }
        document.getElementById('waitingLoader').style.display = 'block';
        axios.post(api_url + `/share_aadhar_otp`, {encrypted : encrypted}, { headers }).then((response) => {
            document.getElementById('waitingLoader').style.display = 'none';
            if (response.data.status == 'success') {
                toast.success(response.data.message);
                setOtp(true);
                var countDownDate = new Date(response.data.data.valid_at).getTime();
                var currentDate = new Date().getTime();
                var distance = countDownDate - currentDate;
                var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                var seconds = Math.floor((distance % (1000 * 60)) / 1000);
                setMinutes(minutes);
                setSeconds(seconds);
            }else if (response.data.status === 'hold') {
                setPopUpData({...popupData,is_bank_hold : true});
            }else if(response.data.status === "expired") {
                document.getElementById('waitingLoader').style.display = 'none';
                setPopUpData({...popupData,is_bureau_checked : true});
            }else if (response.data.status === 'fail' && response.data.is_rejected === true) {
                document.getElementById('waitingLoader').style.display = 'none';
                if(response.data.hasOwnProperty('data')){
                    sessionStorage.setItem('status_flag', response.data.data.appStatus.comment)
                }
                navigate("/regrate");
            } else {
                document.getElementById('waitingLoader').style.display = 'none';
                toast.error(response.data.errors,{
                    position: toast.POSITION.TOP_CENTER
                });
                setOtp(true);
            }
        }).catch(error => {
            document.getElementById('waitingLoader').style.display = 'none';
            toast.error(error.message,{
                position: toast.POSITION.TOP_CENTER
            });
            setOtp(true);
        });
    }

    return (
        <>
            <ToastContainer />
            <div className="pt-3">
                <Heading Description="Let us help you build a future-ready company" BadgeDescription="Let us complete your KYC" user />
            </div>
            {!otp && <Layout ImageSide="Product-intro">
                <div className="text-center">
                    <h4>AADHAAR Verification</h4>
                    <p className="mb-0 text-center mx-5"> Below is your AADHAAR Number, just complete the verification and let us fetch your details for you</p>
                    <p className="aaddhar-verify mt-2">{Spacify(aadhaar_no, 4, " ")}</p>
                </div>
                <CustomOtp value={otpNum.fourOtp} valueLength={4} onChange={onChangeFour} shareCode="mask" colwidth="col-md-5 col-sm-8 customwdith" />
                <p className="mb-0 text-center"> <strong>Please create a four digit code of your choice</strong></p>
                <PageFooter BtnName="I SUBMIT" TermsCondition="I acknowledge that by submitting, U GRO may access the location, camera and the file uploaded from this device." ButtonClick={() => { processOtp() }} />
            </Layout>}
            {otp && <Layout ImageSide="Product-intro">
                <div className="text-center">
                    <h4>AADHAAR Verification</h4>
                    <p className="mb-0 text-center"> An OTP has been sent to the Mobile Number associated with your AADHAAR</p>
                    <p className="aaddhar-verify mt-2">{Spacify(aadhaar_no, 4, " ")}</p>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 mx-auto " id="aadhar-otp">
                    <CustomOtp value={otpNum.sixOtp} valueLength={6} onChange={onChangeSix} colwidth="col-md-7 col-sm-12" colwidthtimer="col-md-7 col-sm-12 mx-auto">
                        <p className="col-12 mt-0 mx-auto text-end text-orange mb-2">
                            {seconds > 0 || minutes > 0 ? (
                                <p>
                                    {minutes < 10 ? `0${minutes}` : minutes}:
                                    {seconds < 10 ? `0${seconds}` : seconds}
                                </p>
                            ) : (
                                <p className="text-center"><span className="text-black">Didn't recieve OTP?</span>
                                    &nbsp;<a href="#" onClick={() => resend()}>Resend</a>
                                </p>
                            )}
                        </p>
                    </CustomOtp>

                </div>
                <PageFooter BtnName="I SUBMIT" TermsCondition="By submitting, I confirm to proceed for AADHAAR verification" ButtonClick={() => { processAdhar() }} />
            </Layout>}
        </>
    );
}
export default AadharOtp;