import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Heading from "../common-components/Heading";
import Input from "../common-components/Input";
import Layout from "../common-components/Layout";
import PageFooter from "../common-components/PageFooter";
import config from "../../config.json";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import { BureauContext } from "../Context/BureauContext";
import Select from "../common-components/Select";
import { encryptData } from "../../encrypt";
import DatePicker from "react-datepicker";
import { format } from 'date-fns';
import { socialCategory, activity } from "./SelectStaticData";
import DetailsCard from "../application-summary/components/DetailsCard";
import Select2 from 'react-select';
import "react-datepicker/dist/react-datepicker.css";

const UdyamDoc = () => {
    const navigate = useNavigate();
    const api_url = config.API_URL;
    const [disablebtn, setDisableBtn] = useState(true);
    const [businessName, setBusinessName] = useState('');
    const [address_1, setAddress_1] = useState("");
    const [address_2, setAddress_2] = useState("");
    const [address_3, setAddress_3] = useState("");
    const [pincode, setPincode] = useState("");
    const [dateincorporation, setDoi] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [district, setDistrict] = useState("");
    const [socialcategory, setsocialCategory] = useState("");
    const [activityunit, setactivityunit] = useState("");
    const [bsrcode, setbsrcode] = useState("");
    const [noofemployee, setnoofemployee] = useState("");
    const [bsrCodeArr, setBsrCodeArr] = useState([]);
    const [bsrCode, setBsrCode] = useState("");
    const [preSelectCategory, setPreSelectCategory] = useState('');
    const[preSelectMajorActivity, setPreSelectMajorActivity] = useState('');
    const jwtToken = sessionStorage.getItem('token');
    const { popupData, setPopUpData, checkStateByMiddileware, checkLocationPermission } = useContext(BureauContext);
    const [validation, setValidationError] = useState({ regError: '', beProofError: '', stateError: "", areaError: "", medicalError: "", registrationError: "", consentError: "", industriesError: "" });
    const latitude = sessionStorage.getItem("latitude");
    const longitude = sessionStorage.getItem("longitude");  

    useEffect(() => {
        getBsrCodeArr();
    }, []);
    const getBsrCodeArr = async () => {
        const headers = {
            'Authorization' : `Bearer ${jwtToken}`,
            'Content-Type': 'application/json',
            'latlong' : `${latitude},${longitude}`,
        };
        document.getElementById('waitingLoader').style.display = 'block';
        await axios.get(api_url+`/get-bsr-code`, {headers}).then((res) => {
            if(res.data.status === 'token'){
                document.getElementById('waitingLoader').style.display = 'none';
                sessionStorage.clear();
                navigate('/login');
            }else if(res.data.status === "hold") {
                document.getElementById('waitingLoader').style.display = 'none';
                setPopUpData({...popupData, is_bank_hold : true});
            }else if(res.data.status === 'expired'){
                document.getElementById('waitingLoader').style.display = 'none';
                setPopUpData({...popupData, is_bureau_checked : true});
            }else if(res.data.status === 'success'){
                document.getElementById('waitingLoader').style.display = 'none';
                setBsrCodeArr(res.data.data.code);
            }else if (res.data.status === 'fail' && res.data.is_rejected === true) {
                document.getElementById('waitingLoader').style.display = 'none';
                if(res.data.hasOwnProperty('data')){
                    sessionStorage.setItem('status_flag', res.data.data.appStatus.comment)
                }
                navigate("/regrate");
            }else{
                document.getElementById('waitingLoader').style.display = 'none';
                toast.error(res.data.errors, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        }).catch(err => {
            document.getElementById('waitingLoader').style.display = 'none';
            toast.error(err.message, {
                position: toast.POSITION.TOP_CENTER,
            });
        });
    }

    const changeAddress_1 = (e) =>{
        const checkerror = {};
        const value = e.target.value.replace(/[^0-9a-za-z-_ ]+/ig, "");
        const limit = 200;
        setAddress_1(value.slice(0, limit));
        checkerror['address_1Error'] = "";
        setValidationError({ ...validation, ...checkerror });
    }

    const changeAddress_2 = (e) =>{
        const value = e.target.value.replace(/[^0-9a-za-z-_ ]+/ig, "");
        const limit = 150;
        setAddress_2(value.slice(0, limit));
        const checkerror = {};
        checkerror['address_2Error'] = "";
        setValidationError({ ...validation, ...checkerror });
    }

    const changeAddress_3 = (e) =>{
        const value = e.target.value.replace(/[^0-9a-za-z-_ ]+/ig, "");
        const limit = 150;
        setAddress_3(value.slice(0, limit));
        const checkerror = {};
        checkerror['address_3Error'] = "";
        setValidationError({ ...validation, ...checkerror });
    }

    const changePincode = (e) =>{
        const value = e.target.value.replace(/\D/g, "");
        const limit = 6;
        setPincode(value.slice(0, limit));
        const checkerror = {};
        checkerror['pincodeError'] = "";
        setValidationError({ ...validation, ...checkerror });
    }

    const changeBsrCode = (e) =>{
        setbsrcode(e.value);
        const checkerror = {};
        checkerror['bsrcodeError'] = "";
        setValidationError({ ...validation, ...checkerror });
    }

    const changeNoOfEmployee = (e) =>{
        const value = e.target.value.replace(/\D/g, "");
        const limit = 6;
        setnoofemployee(value.slice(0, limit));
        const checkerror = {};
        checkerror['noofemployeeError'] = "";
        setValidationError({ ...validation, ...checkerror });
    }

    const changeActivityUnit = (e) =>{
        setactivityunit(e.target.value);
        setPreSelectMajorActivity(e.target.value);
        const checkerror = {};
        checkerror['activityunitError'] = "";
        setValidationError({ ...validation, ...checkerror });
    }

    const changeSocialCategory = (e) => {
        setsocialCategory(e.target.value);
        setPreSelectCategory(e.target.value);
        const checkerror = {};
        checkerror['socialcategoryError'] = "";
        setValidationError({ ...validation, ...checkerror });
    }

    const addUdyamAssit = async () => {
        if(validate()){
            const headers = {
                'Authorization' : `Bearer ${jwtToken}`,
                'Content-Type': 'application/json',
                'latlong' : `${latitude},${longitude}`,
            };
            const payload = {
                'business_name' : businessName,
                'address_1' : address_1,
                'address_2' : address_2,
                'address_3' : address_3,
                'pincode' : pincode,
                'state' :state,
                'city' :city,
                'district' : district,
                'doi' : format(new Date(dateincorporation), 'yyyy-MM-dd'),
                'social_category' : socialcategory,
                'type_of_organization' : 'Sole Proprietor',
                'major_activity_of_unit' : activityunit,
                'bsr_code' : bsrcode,
                'no_of_employees' : noofemployee,
            }
            const encrypted = encryptData(payload);
            const requestOptions = {
                method : 'post',
                headers : headers,
                body : JSON.stringify({encrypted : encrypted}),
            };
            document.getElementById('waitingLoader').style.display = 'block';
            await fetch(api_url+`/add-udyam-assit`,requestOptions).then((res) => {
                return res.json();
            }).then((result) => {
                if(result.status === 'token'){
                    document.getElementById('waitingLoader').style.display = 'none';
                    sessionStorage.clear();
                    navigate('/login');
                }else if(result.status === "hold") {
                    document.getElementById('waitingLoader').style.display = 'none';
                    setPopUpData({...popupData, is_bank_hold : true});
                }else if(result.status === 'expired'){
                    document.getElementById('waitingLoader').style.display = 'none';
                    setPopUpData({...popupData, is_bureau_checked : true});
                }else if(result.status === 'success'){
                    document.getElementById('waitingLoader').style.display = 'none';
                    toast.success(result.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                    navigate('/bank-detail');
                }else if (result.status === 'fail' && result.is_rejected === true) {
                    document.getElementById('waitingLoader').style.display = 'none';
                    if(result.hasOwnProperty('data')){
                        sessionStorage.setItem('status_flag', result.data.appStatus.comment)
                    }
                    navigate("/regrate");
                }else{
                    document.getElementById('waitingLoader').style.display = 'none';
                    toast.error(result.errors, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                }
            }).catch((err) => {
                document.getElementById('waitingLoader').style.display = 'none';
                toast.error(err, {
                    position: toast.POSITION.TOP_CENTER,
                });
            })
        }
    }

    const getPincodeData = async (e) => {
        var pincode = e.target.value;
        var checkerror = {};
        if(pincode === '' || pincode === null || pincode === undefined){
            checkerror['pincodeError'] = "Please enter valid Pincode";
            setValidationError({ ...validation, ...checkerror });
            setDistrict('');
            setCity('');
            setState('');
            setDisableBtn(true);
            return false;
        }else{
            checkerror['pincodeError'] = "";
            setValidationError({ ...validation, ...checkerror });
        }
        const headers = {
            'Authorization': `Bearer ${jwtToken}`,
            'Content-Type': 'application/json',
            'latlong' : `${latitude},${longitude}`,
        };
        const payload = {
            pincode: pincode,
        }
        const requestOptions = {
            method : "post",
            headers : headers,
            body : JSON.stringify(payload)
        }
        document.getElementById('waitingLoader').style.display = 'block';
        await fetch(api_url + `/get-pincode-data`, requestOptions).then((res) => {
            return res.json();
        }).then((response) => {
            if (response.status === 'success') {
                document.getElementById('waitingLoader').style.display = 'none';
                setDistrict(response.data.pincodeData.district);
                setCity(response.data.pincodeData.city);
                setState(response.data.pincodeData.state);
                setDisableBtn(false);
            } else if(response.status === "token") {
                document.getElementById('waitingLoader').style.display = 'none';
                setDistrict('');
                setCity('');
                setState('');
                toast.error(response.errors, {
                    position: toast.POSITION.TOP_CENTER,
                });
                sessionStorage.clear();
                navigate('/login')
            }else if(response.status === "expired") {
                document.getElementById('waitingLoader').style.display = 'none';
                setDistrict('');
                setCity('');
                setState('');
                setPopUpData({...popupData,is_bureau_checked : true});
            }else if(response.status === "hold") {
                document.getElementById('waitingLoader').style.display = 'none';
                setDistrict('');
                setCity('');
                setState('');
                setPopUpData({...popupData,is_bank_hold : true});
            }else if (response.status === 'fail' && response.is_rejected === true) {
                document.getElementById('waitingLoader').style.display = 'none';
                setDistrict('');
                setCity('');
                setState('');
                if(response.data.hasOwnProperty('data')){
                  sessionStorage.setItem('status_flag', response.data.data.appStatus.comment)
                }
                navigate("/regrate");
            }else if(response.status === "fail") {
                document.getElementById('waitingLoader').style.display = 'none';
                setDistrict('');
                setCity('');
                setState('');
                toast.error(response.errors,{
                    position: toast.POSITION.TOP_CENTER
                });
            }
        }).catch(error => {
            document.getElementById('waitingLoader').style.display = 'none';
            toast.error(error.message, {
                position: toast.POSITION.TOP_CENTER
            });
        });
    }

    const validate = () => {
        let checkValidate = true;
        let checkerror = {}

        if(businessName === '' || businessName === null) {
            checkerror['businessNameError'] = "Please enter business name";
            setValidationError({ ...validation, ...checkerror });
            checkValidate = false;
        }

        if(address_1 === '' || address_1 === null) {
            checkerror['address_1Error'] = "Please enter Address 1";
            setValidationError({ ...validation, ...checkerror });
            checkValidate = false;
        }

        if(pincode === null || pincode === ''){
            checkerror['pincodeError'] = "Please enter Pincode";
            checkValidate = false;
            setValidationError({ ...validation, ...checkerror });
        }

        if(dateincorporation === null || dateincorporation === ''){
            checkerror['dateincorporationError'] = "Please select date of incorporation";
            checkValidate = false;
            setValidationError({ ...validation, ...checkerror });
        }
    
        if(socialcategory === null || socialcategory === ''){
            checkerror['socialcategoryError'] = "Please select Social Category";
            checkValidate = false;
            setValidationError({ ...validation, ...checkerror });
        }

        if(activityunit === null || activityunit === ''){
            checkerror['activityunitError'] = "Please select Activity unit";
            checkValidate = false;
            setValidationError({ ...validation, ...checkerror });
        }

        if(bsrcode === null || bsrcode === ''){
            checkerror['bsrcodeError'] = "Please select BSR Code";
            checkValidate = false;
            setValidationError({ ...validation, ...checkerror });
        }

        if(noofemployee === null || noofemployee === ''){
            checkerror['noofemployeeError'] = "Please select No of Employee.";
            checkValidate = false;
            setValidationError({ ...validation, ...checkerror });
        }

        if(noofemployee < 1 && !(noofemployee === null || noofemployee === '')){
            checkerror['noofemployeeError'] = "Number of employee should be grater than zero.";
            checkValidate = false;
            setValidationError({ ...validation, ...checkerror });
        }
        return checkValidate;
    }

    function dateofincorporation(e){
        let checkerror = {}
        if(e.keyCode !== 8) {
            e.preventDefault();
        }
        checkerror['dateincorporationError'] = "Please select date of incorporation.";
        setValidationError({ ...validation, ...checkerror });
        setDoi('');
        return false;
    }

    const onChangeDateIncorporation = (e) => {
        setDoi(e);
        const checkerror = {};
        checkerror['dateincorporationError'] = "";
        setValidationError({ ...validation, ...checkerror });
    }

    const changeBusinessName = (e) => {
        const value = e.target.value.replace(/[^0-9a-za-z-_ ]+/ig, "");
        const limit = 200;
        setBusinessName(value.slice(0, limit));
        const checkerror = {};
        checkerror['businessNameError'] = "";
        setValidationError({ ...validation, ...checkerror });
    }
   
    return (
        <>
        <ToastContainer />
        <div className="pt-3">
                <Heading Description="Let us help you build a future-ready company" BadgeDescription="Allow us to get your Udyam Assisted information" user />
            </div>
        <Layout ImageSide="Login">   
        <DetailsCard Heading="Business Detail" bradius="rounded-0 border-top-0">
                <div className="row">
                <div className="col-md-6 col-sm-12">
                    <Input LabelName="Business Name" type="text" name="businessName" Id="businessName" changeInput={(e) => {changeBusinessName(e);}} inputval={businessName} placeholder="Business Name" isAstrix  />
                    {(validation.businessNameError !== '') ?
                        <span className="text-danger fontsize-13">{validation.businessNameError}</span> : null
                    }
                </div>
                <div className="col-md-6 col-sm-12 udyam-doc">
                    <label className="form-label mb-0 fontsize-12 font-wt-600 text-orange">Date Of Incorporation<span className="required-field text-danger">*</span></label>
                    <DatePicker placeholder="DD-MM-YYYY" showIcon dateFormat="dd MMM yyyy" className="form-control" onKeyDown={(e)=>dateofincorporation(e)} value={dateincorporation} selected={dateincorporation} maxDate={new Date()} onChange={(e) => onChangeDateIncorporation(e) } peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" />
                    {(validation.dateincorporationError !== '') ?
                        <span className="text-danger fontsize-13">{validation.dateincorporationError}</span> : null
                    }
                </div>
              
                <div className="col-md-6 col-sm-12">
                <Select LabelName="Select Social Category" preSelect={preSelectCategory} isAstrix selected={(e) => changeSocialCategory(e)} SelectData={socialCategory} />
                    {(validation.socialcategoryError !== '') ?
                        <span className="text-danger fontsize-13">{validation.socialcategoryError}</span> : null
                    }
                </div>
                <div className="col-md-6 col-sm-12">
                    <Input LabelName="Type Of Organization" type="text" name="type_of_organization" Id="type_of_organization" inputval="Sole Proprietor" placeholder="Business Name" isAstrix  disabled/>
                </div>
                <div className="col-md-6 col-sm-12">
                    <Select LabelName="Select Major Activity of Unit" preSelect={preSelectMajorActivity} isAstrix selected={(e) => changeActivityUnit(e)} SelectData={activity} />
                    {(validation.activityunitError !== '') ?
                        <span className="text-danger fontsize-13">{validation.activityunitError}</span> : null
                    }
                </div>
                <div className="col-md-6 col-sm-12">
                <label htmlFor="floatingSelect" className="form-label mb-0 fontsize-12 font-wt-600 text-orange">Bsr Code *</label>
                    <Select2 options={bsrCodeArr}  placeholder="Please select bsr code" onChange={(opt) => changeBsrCode(opt) }  id="floatingSelect"/>
                    {(validation.bsrcodeError !== '') ?
                        <span className="text-danger fontsize-13">{validation.bsrcodeError}</span> : null
                    }
                </div>
                <div className="col-md-6 col-sm-12">
                    <Input isAstrix LabelName="No Of Employee" type="text" Id="Eduction_Qualification" name="no_of_employee" changeInput={(e) => {changeNoOfEmployee(e);}} placeholder="No Of Employee"  inputval={noofemployee} />
                    {(validation.noofemployeeError !== '' ) ?
                        <span className="text-danger fontsize-13">{validation.noofemployeeError}</span> : null
                    }
                </div>
            </div>
            </DetailsCard>             
                <DetailsCard Heading="Business Address" bradius="rounded-0 border-top-0">
                <div className="row">
                <div className="col-md-6 col-sm-12">
                    <Input LabelName="Address Line 1" type="text" name="address_1" Id="Phone_No" changeInput={(e) => {changeAddress_1(e);}} inputval={address_1} placeholder="Address Line 1" isAstrix  />
                    {(validation.address_1Error !== '') ?
                        <span className="text-danger fontsize-13">{validation.address_1Error}</span> : null
                    }
                </div>
                <div className="col-md-6 col-sm-12">
                    <Input LabelName="Address Line 2" type="text" name="address_2" Id="E-mail_ID" changeInput={(e) => {changeAddress_2(e);}} inputval={address_2} placeholder="Address Line 2"  />
                    {(validation.address_2Error !== '') ?
                        <span className="text-danger fontsize-13">{validation.address_2Error}</span> : null
                    }
                </div>
                <div className="col-md-6 col-sm-12">
                    <Input LabelName="Address Line 3 " type="text" name="address_3" Id="PAN" changeInput={(e) => {changeAddress_3(e);}} inputval={address_3} placeholder="Address Line 3"  />
                    {(validation.address_3Error !== '') ?
                        <span className="text-danger fontsize-13">{validation.address_3Error}</span> : null
                    }
                </div>
                
                <div className="col-md-6 col-sm-12">
                    <Input isAstrix LabelName="Pincode" type="text" name="pincode" Id="Name" changeInput={(e) => {changePincode(e);}} blurEvent={(e) => { getPincodeData(e); }} inputval={pincode} placeholder="Pincode"  />
                    {(validation.pincodeError !== '') ?
                        <span className="text-danger fontsize-13">{validation.pincodeError}</span> : null
                    }
                </div>
                <div className="col-md-6 col-sm-12">
                    <Input isAstrix LabelName="City" type="text" name="city" Id="DOB" inputval={city} placeholder="City" disabled />
                </div>
                <div className="col-md-6 col-sm-12">
                    <Input isAstrix LabelName="State" type="text" name="state" placeholder="State" inputval={state} disabled />
                </div>
                <div className="col-md-6 col-sm-12">
                    <Input isAstrix LabelName="District" type="text" name="district" Id="district" inputval={district} placeholder="District" disabled />
                </div>
                </div>
                </DetailsCard>
        <PageFooter setDisabled={disablebtn} BtnName="I SUBMIT" TermsCondition="By submitting, I allow U GRO to assess my application" ButtonClick={() => { addUdyamAssit(); }} />
        </Layout>
    </>
    );
}
export default UdyamDoc;