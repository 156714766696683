import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Heading from "../common-components/Heading";
import Layout from "../common-components/Layout";
import PageFooter from "../common-components/PageFooter";
import config from "../../config.json";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import { BureauContext } from "../Context/BureauContext";
import { encryptData } from "../../encrypt";

const LinkedGst = () => {

    const usertoken = sessionStorage.getItem('token');
    const navigate = useNavigate();
    const [click, setClick] = useState([]);
    const [gst, setGST] = useState('');
    const [GstinData, setGstinData] = useState([]);
    const api_url = config.API_URL;
    const [isSkipped, setSkipped] = useState("N");
    const [disabled, setdisabled] = useState(true);
    const [isGstAgree, setGstAgree] = useState('Y');
    const {popupData, setPopUpData, checkStateByMiddileware} = useContext(BureauContext);
    const locate_permission = sessionStorage.getItem('locate_permission');
    const latitude = sessionStorage.getItem("latitude");
    const longitude = sessionStorage.getItem("longitude");

    useEffect(() => {
        checkStateByMiddileware();
        const headers = {
            'Authorization': `Bearer ${usertoken}`,
           'latlong' : `${latitude},${longitude}`,
        }
        document.getElementById('waitingLoader').style.display = 'block';
        axios.get(api_url + `/linked-gst`, { headers })
            .then((response) => {
                document.getElementById('waitingLoader').style.display = 'none';
                if (response.data.status === 'success') {
                    setGstinData(response.data.data.get_gsts);
                    if((response.data.data.appData.nic_code !== '' && response.data.data.appData.nic_code !== null && response.data.data.appData.nic_code !== undefined) || response.data.data.allGstInactive === true){
                        setSkipped("Y");
                    }
                }else if(response.data.status === "token") {
                    toast.error(response.data.errors);
                    sessionStorage.clear();
                    navigate('/login');
                }else if(response.data.status === "expired") {
                    document.getElementById('waitingLoader').style.display = 'none';
                    setPopUpData({...popupData,is_bureau_checked : true});
                }else if(response.data.status === "hold") {
                    document.getElementById('waitingLoader').style.display = 'none';
                    setPopUpData({...popupData,is_bank_hold : true});
                }else {
                    toast.error(response.data.errors,{
                      position: toast.POSITION.TOP_CENTER
                    });
                }
            })
            .catch(error => {
                document.getElementById('waitingLoader').style.display = 'none';
                toast.error(error.message,{
                    position: toast.POSITION.TOP_CENTER
                });
            });
    }, []);

    const getselectCard = (val, addr) => {
        let newarr;
        setdisabled(true);
        setGST('');
        if ((newarr = click.includes(val))) {
            // newarr = click.includes(val)
            // click.splice(...click.indexOf(val),1);
            newarr = click.filter(e => e !== val);
            setClick([newarr]);
        }
        else {
            setGST(val);
            setClick([...click, val]);
            setdisabled(false);
        }

    }

    const proceedGST = async () => {
        checkStateByMiddileware();
        let checkValidate = true;
        if (gst === '' || gst === undefined) {
            toast.error('Please  select GST Details');
            checkValidate = false;
        }
        
        if(locate_permission === "denied"){
            toast.error("Please allow your device location", {
                position: toast.POSITION.TOP_CENTER,
            })
            checkValidate = false;
        }

        if (checkValidate) {
            const headers = {
                'Authorization': `Bearer ${usertoken}`,
                'Content-Type': 'application/json',
                'latlong' : `${latitude},${longitude}`,
            }
            const payload = {
                gst: gst,
                gst_agree : isGstAgree,
                is_gst_skipped : 'N',
            }
            const encrypted = encryptData(payload);
            const requestOptions = {
                method  : "post",
                headers : headers,
                body : JSON.stringify({encrypted : encrypted})
            }
            document.getElementById('waitingLoader').style.display = 'block';
            await fetch(api_url + `/gst_details`, requestOptions).then((res) => {
                return res.json();
            }).then((response) => {
                document.getElementById('waitingLoader').style.display = 'none';
                    if (response.status === 'success') {
                        toast.success(response.message,{
                            position: toast.POSITION.TOP_CENTER
                        });
                        navigate("/upload-aadhar");
                    } 
                    else if(response.status === "token") {
                        toast.error(response.errors, {
                            position: toast.POSITION.TOP_CENTER,
                        });
                        sessionStorage.clear();
                        navigate('/login')
                    }else if(response.status === "expired") {
                        setPopUpData({...popupData,is_bureau_checked : true});
                    }else if(response.status === "hold") {
                        document.getElementById('waitingLoader').style.display = 'none';
                        setPopUpData({...popupData,is_bank_hold : true});
                    }else if (response.status === 'fail' && response.is_rejected === true) {
                        document.getElementById('waitingLoader').style.display = 'none';
                        sessionStorage.setItem('status_flag', response.data.appStatus.comment)
                        navigate("/regrate");
                    }else if(response.status === "fail") {
                        document.getElementById('waitingLoader').style.display = 'none';
                        toast.error(response.errors,{
                          position: toast.POSITION.TOP_CENTER
                        });
                    }
                })
                .catch(error => {
                    document.getElementById('waitingLoader').style.display = 'none';
                    toast.error(error.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
            });
        }
    }

    const skipGst = async () => {
        checkStateByMiddileware();
        let checkValidate = true;
        if (checkValidate) {
            const headers = {
                'Authorization': `Bearer ${usertoken}`,
                'Content-Type': 'application/json',
                'latlong' : `${latitude},${longitude}`,
            }
            const payload = {
                gst: gst,
                gst_agree : isGstAgree,
                is_gst_skipped : 'Y',
            }
            const encrypted = encryptData(payload);
            const requestOptions = {
                method  : "post",
                headers : headers,
                body : JSON.stringify({encrypted : encrypted})
            }
            document.getElementById('waitingLoader').style.display = 'block';
            await fetch(api_url + `/gst_details`, requestOptions).then((res) => {
                return res.json();
            }).then((response) => {
                document.getElementById('waitingLoader').style.display = 'none';
                    if (response.status === 'success') {
                        toast.success(response.message,{
                            position: toast.POSITION.TOP_CENTER
                        });
                        navigate("/upload-aadhar");
                    } 
                    else if(response.status === "token") {
                        toast.error(response.errors, {
                            position: toast.POSITION.TOP_CENTER,
                        });
                        sessionStorage.clear();
                        navigate('/login')
                    }else if(response.status === "expired") {
                        setPopUpData({...popupData,is_bureau_checked : true});
                    }else if(response.status === "hold") {
                        document.getElementById('waitingLoader').style.display = 'none';
                        setPopUpData({...popupData,is_bank_hold : true});
                    }else if (response.status === 'fail' && response.is_rejected === true) {
                        document.getElementById('waitingLoader').style.display = 'none';
                        sessionStorage.setItem('status_flag', response.data.appStatus.comment)
                        navigate("/regrate");
                    }else if(response.status === "fail") {
                        document.getElementById('waitingLoader').style.display = 'none';
                        toast.error(response.errors,{
                          position: toast.POSITION.TOP_CENTER
                        });
                    }
                })
                .catch(error => {
                    document.getElementById('waitingLoader').style.display = 'none';
                    toast.error(error.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
            });
        }
    }

    const updateGst = async () => {
        checkStateByMiddileware();
        const headers = {
            'Authorization': `Bearer ${usertoken}`,
           'latlong' : `${latitude},${longitude}`,
        }
        const requestOptions = {
            method : "post",
            headers : headers
        }
        
        document.getElementById('waitingLoader').style.display = 'block';
        await fetch(api_url + `/recheck-gst-by-pan`, requestOptions).then((res) => {
                return res.json();
            }).then((response) => {
                document.getElementById('waitingLoader').style.display = 'none';
                if (response.status === 'success') {
                    setGstinData(response.data.gst);
                    if((response.data.appData.nic_code !== '' && response.data.appData.nic_code !== null && response.data.appData.nic_code !== undefined) || response.data.allGstInactive === true){
                        setSkipped("Y");
                    }
                    toast.success(response.message,{
                     position: toast.POSITION.TOP_CENTER
                    });
                } 
                else if(response.status === "token") {
                    toast.error(response.errors);
                    sessionStorage.clear();
                    navigate('/login');
                }else if(response.status === "expired") {
                    document.getElementById('waitingLoader').style.display = 'none';
                    setPopUpData({...popupData,is_bureau_checked : true});
                }else if(response.status === "hold") {
                    document.getElementById('waitingLoader').style.display = 'none';
                    setPopUpData({...popupData,is_bank_hold : true});
                }else if (response.status === 'fail' && response.is_rejected === true) {
                    document.getElementById('waitingLoader').style.display = 'none';
                    sessionStorage.setItem('status_flag', response.data.appStatus.comment)
                    navigate("/regrate");
                }else {
                    toast.error(response.errors,{
                        position: toast.POSITION.TOP_CENTER
                     });
                }
            }).catch(error => {
                document.getElementById('waitingLoader').style.display = 'none';
                toast.error(error.message,{
                   position: toast.POSITION.TOP_CENTER
                });
            });
    }

return (
    <>
        <ToastContainer />
        <div className="pt-3">
            <Heading Description="Let us help you build a future-ready company" BadgeDescription="Let us know about your GSTIN" user />
        </div>
        <Layout ImageSide="Product-intro">
            <h4 className="text-center mb-4">Please select any of the Active GSTIN</h4>
            <div className="plans p-4 pt-0">
                <div className="row">
                    {GstinData.map((key, index) => {
                            return (
                                <>
                                    {key.is_active == 1 ? <label onClick={()=>getselectCard(key.gstno)} className="col-xl-6 col-lg-6 col-md-6 col-sm-12 mt-4 " key ={key.gstno}>
                                        <div className={`card p-3 position-relative gst-card cursor h-100 ${gst === key.gstno ? "border-orange" : "border"}`} id="gst-cardid" style={{height:'127px'}}>
                                            <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-ea652c card-number">
                                                {index + 1}
                                            </span>
                                            <div className="plan-content">
                                                <div className="plan-details">
                                                    <span className="fontsize-14 fw-bold d-flex justify-content-between">
                                                        {key.gstno} <p className="text-success">Active</p>
                                                    </span>
                                                    <p className="mb-0 fontsize-14" style={{height:'60px',overflowY:'auto'}}>
                                                        {key.gst_addr}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </label> : <label className="col-xl-6 col-lg-6 col-md-6 col-sm-12 mt-4 " key ={key.gstno}>
                                        <div className={`card p-3 position-relative gst-card cursor h-100 border carddisabled`} id="" style={{height:'127px'}}>
                                            <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-ea652c card-number">
                                                {index + 1}
                                            </span>
                                            <div className="plan-content">
                                                <div className="plan-details">
                                                    <span className="fontsize-14 fw-bold d-flex justify-content-between">
                                                        {key.gstno} <p className="text-danger">In Active</p>
                                                    </span>
                                                    <p className="mb-0 fontsize-14" style={{height:'60px',overflowY:'auto'}}>
                                                        {key.gst_addr}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </label>}
                                </>
                            );
                    })}
                </div>
            </div>
            <div className="text-center">
            <a className='mt-0 text-center fontsize-12 fw-bold' href='javascript:;' onClick={() => updateGst()}><span className="fa fa-home">Refresh GST</span></a>
            </div>
            <PageFooter setDisabled={disabled} BtnName="I CONFIRM" TermsCondition="By confirming, I declare that these are my correct details" ButtonClick={() => proceedGST()} />
            <div className="text-center">
            <a className='mt-0 text-center fontsize-14 fw-bold' href='javascript:;' onClick={() => skipGst()}><span className="fa fa-home">Skip</span></a> <br/>
            </div>
        </Layout>
    </>
);
}
export default LinkedGst;