import React, { useState, useEffect, Link, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Heading from "../common-components/Heading";
import Layout from "../common-components/Layout";
import { CustomOtp } from "../common-components/OtpInput";
import PageFooter from "../common-components/PageFooter";
import config from "../../config.json";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import { BureauContext } from "../Context/BureauContext";
import { encryptData } from "../../encrypt";
const usertoken = sessionStorage.getItem('token');

const MitcValidate = () => {
    const navigate = useNavigate();
    const api_url = config.API_URL;
    const [mobile_no, setMobile_no] = useState('');
    const [otpNum, setOtpNum] = useState({ fourOtp: '', sixOtp: '' });
    const onChangeFour = (value) => setOtpNum(preState => ({ ...preState, fourOtp: value }));
    const [seconds, setSeconds] = useState(59);
    const [minutes, setMinutes] = useState(1);
    const [ishide, setHide] = useState(true);
    const usertoken = sessionStorage.getItem('token');
    const {popupData, setPopUpData, checkStateByMiddileware} = useContext(BureauContext);
    const locate_permission = sessionStorage.getItem('locate_permission');
    const latitude = sessionStorage.getItem("latitude");
    const longitude = sessionStorage.getItem("longitude");
    useEffect(() => {
        getRegisterOtp();
    }, []);

    const getRegisterOtp = async () => {
        const headers = {
            'Authorization': `Bearer ${usertoken}`,
           'latlong' : `${latitude},${longitude}`,
        }
        await fetch(api_url + `/get-otp-time?otp_type=mitc`, { headers }).then((res) => {
            return res.json();
        }).then((result) => {
            if (result.status === 'success') {
                var countDownDate = new Date(result.data.date).getTime();
                var currentDate = new Date().getTime();
                var distance = countDownDate - currentDate;
                var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                var seconds = Math.floor((distance % (1000 * 60)) / 1000);
                setMinutes(minutes);
                setSeconds(seconds);
            }
        }).catch((err) => {
           
        })
    }

    useEffect(() => {
        const interval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }

            if (seconds === 0) {
                if (minutes === 0) {
                    setHide(false);
                    clearInterval(interval);
                } else {
                    const seconds = 59;
                    setSeconds(seconds);
                    setMinutes(minutes - 1);
                }
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [seconds]);

    const resend = async () => {
        checkStateByMiddileware();
        setOtpNum({ fourOtp : ''});
        const payload = {
            is_resend: 1
        };
        const encrypted = encryptData(payload);
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${usertoken}`,
                'latlong' : `${latitude},${longitude}`,
            },
            body: JSON.stringify({encrypted : encrypted}),
        };
        document.getElementById('waitingLoader').style.display = 'block';
        fetch(api_url + `/accept-mitc`, requestOptions).then((res) => {
            return res.json();
        }).then((result) => {
            document.getElementById('waitingLoader').style.display = 'none';
            if (result.status === "token") {
                toast.error(result.errors);
                sessionStorage.clear();
                navigate('/login');
            } else if (result.status === "success") {
                setHide(true);
                getRegisterOtp();
                toast.success(result.message);
            }else if(result.status === "expired") {
                document.getElementById('waitingLoader').style.display = 'none';
                setPopUpData({...popupData,is_bureau_checked : true});
            }else if(result.status === "hold") {
                document.getElementById('waitingLoader').style.display = 'none';
                setPopUpData({...popupData,is_bank_hold : true});
            } else if (result.status === 'fail' && result.is_rejected === true) {
                document.getElementById('waitingLoader').style.display = 'none';
                if(result.hasOwnProperty('data')){
                    sessionStorage.setItem('status_flag', result.data.appStatus.comment)
                }
                navigate("/regrate");
            }else {
                document.getElementById('waitingLoader').style.display = 'none';
                toast.error(result.errors,{
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        })

    }

    const mitcValidateSubmit = () => {
        checkStateByMiddileware();
        let fourNum = otpNum.fourOtp;
        let checkValidate = true;
        if (fourNum === '' || fourNum === undefined) {
            toast.error('Please enter a valid OTP');
            checkValidate = false;
            return 0;
        }
        if (fourNum.length !== 4) {
            toast.error('OTP should 4 digits');
            checkValidate = false;
            return 0;
        }

        if(locate_permission === "denied"){
            toast.error("Please allow your device location", {
                position: toast.POSITION.TOP_CENTER,
            })
            checkValidate = false;
        }

        if (checkValidate) {
            const payload = { otp: fourNum };
            const encrypted = encryptData(payload);
            document.getElementById('waitingLoader').style.display = 'block';
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${usertoken}`,
                    'latlong' : `${latitude},${longitude}`,
                },
                body: JSON.stringify({encrypted : encrypted}),
            };
            fetch(api_url + `/validate-mitc`, requestOptions).then((res) => {
                return res.json();
            }).then((result) => {
                document.getElementById('waitingLoader').style.display = 'none';
                if (result.status === 'success') {
                    document.getElementById('waitingLoader').style.display = 'none';
                    navigate('/congratulations');

                }else if(result.status === "expired") {
                    document.getElementById('waitingLoader').style.display = 'none';
                    setPopUpData({...popupData,is_bureau_checked : true});
                }else if(result.status === "hold") {
                    document.getElementById('waitingLoader').style.display = 'none';
                    setPopUpData({...popupData,is_bank_hold : true});
                }
                else if(result.status === "token") {
                    document.getElementById('waitingLoader').style.display = 'none';
                    sessionStorage.clear();
                    navigate('/login');
                }else if (result.status === 'fail' && result.is_rejected === true) {
                    document.getElementById('waitingLoader').style.display = 'none';
                    if(result.hasOwnProperty('data')){
                        sessionStorage.setItem('status_flag', result.data.appStatus.comment)
                    }
                    navigate('/regrate');
                }else {
                    document.getElementById('waitingLoader').style.display = 'none';
                    toast.error(result.errors, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            }).catch(error => {
                document.getElementById('waitingLoader').style.display = 'none';
                toast.error(error.message, {
                    position: toast.POSITION.TOP_CENTER
                });
            });
        }
    };
    return (
        <>
            <ToastContainer/>
            <div className="pt-3">
                <Heading Description="You have the support from U GRO in prospering your business" BadgeDescription="Let us execute the agreement for you to get ready" user />
            </div>
            <Layout ImageSide="Product-intro">
                <h4 className="text-center ">An OTP has been sent to your mobile number.</h4>
                <p className="aaddhar-verify mt-2">{mobile_no} </p>

                <CustomOtp value={otpNum.fourOtp} valueLength={4} onChange={onChangeFour} colwidth="col-md-5 col-sm-8" colwidthtimer="col-md-5 col-sm-8 mx-auto" />

                <p className="col-md-5 mx-auto col-sm-12 text-end text-orange mb-2">
                    {
                        (ishide === true) && (seconds > 0 || minutes > 0) ? (
                            <p>
                                {minutes < 10 ? `0${minutes}` : minutes}:
                                {seconds < 10 ? `0${seconds}` : seconds}
                            </p>
                        ) : (
                            <p className="text-center"><span className="text-black">Didn't recieve OTP?</span>
                                &nbsp;<a href="javascript:void(0);" onClick={() => resend()}>Resend</a>
                            </p>
                        )}
                </p>

                <PageFooter BtnName="I SUBMIT" TermsCondition="By submitting, I confirm to execute the agreement with U GRO" ButtonClick={() => mitcValidateSubmit()} />
            </Layout>
        </>
    );
}
export default MitcValidate;