import React from "react";

const ChooseInput = ({title,selectFile,isAccept, src, inputRef, isDisabled,ispdf,isIndividual,LabelName, isFourMB}) => {
    return (
        <div className="image-upload mb-3">
            {LabelName?(
                <label className="form-label mb-0 fontsize-12 font-wt-600 text-orange">{LabelName}*</label>)
                :
                ""
            }
            <input type="file" accept={isAccept} name="" ref={inputRef} disabled={isDisabled} onChange={selectFile} id="pan-ocr" />
            <label htmlFor="logo" className="upload-field" id="file-label">
                <div className="file-thumbnail">
                    <img id="image-preview"
                        src={src} alt="" />
                    <div className="text-danger">{ispdf}</div>
                    <h3 id="filename">
                        {title}
                    </h3>

                    {isIndividual?(
                        (isFourMB === 'UDYAM' || isFourMB === 'BE-PROOF') ?
                       <p>Supports JPG, PNG,JPEG,PDF Size:4MB</p> : <p>Supports JPG, PNG,JPEG Size:4MB</p>)
                       :
                       <p>Supports JPG, PNG,JPEG Size:4MB, PDF Size:2MB</p>
                    }
                </div>
            </label>
            <div className="stage pan-upload"></div>
        </div>
    );
}
export default ChooseInput;