import React from "react";

const Table = ({ TableHeadData,children }) => {
    return (
        <>
            <div className="table-responsive">
                <table className="table mt-2 custom-table table-bordered">
                    <thead className="table-light">
                        <tr>
                            {TableHeadData.map((key) => {
                                return (
                                    <th key={key.Name}>{key.Name}</th>
                                );
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {children}
                    </tbody>
                </table>
            </div>
            
        </>
    );
}
export default Table;