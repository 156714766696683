import React, { createRef, useMemo, useEffect } from "react";

export const CustomOtp = ({ value, valueLength, onChange, children, colwidth, colwidthtimer, shareCode="non-mask" }) => {
  const RE_DIGIT = new RegExp(/^\d+$/);
  const arrLength = valueLength || 0;
  const [elRefs, setElRefs] = React.useState([]);
  const valueItems = useMemo(() => {
    const valueArray = value.split('');
    const items = [];

    for (let i = 0; i < valueLength; i++) {
      const char = valueArray[i];

      if (RE_DIGIT.test(char)) {
        items.push(char);
      } else {
        items.push('');
      }
    }

    return items;
  }, [value, valueLength]);

  const inputOnChange = (e, idx) => {
    const target = e.target;
    const targetValue = target.value;
    if (!RE_DIGIT.test(targetValue)) {
      return;
    }
    const newValue =
      value.substring(0, idx) + targetValue + value.substring(idx + 1);

    onChange(newValue);
  };

  //   function getCodeBoxElement(index) {
  //     console.log( document.getElementById('codeBox' + index),"vinay")
  //     return document.getElementById('codeBox' + index);
  //   }
  //   function onKeyUpEvent(index, event) {
  //     const eventCode = event.which || event.key;
  //     if (getCodeBoxElement(index).value.length === 1) {
  //       if (index !== 6) {
  //         getCodeBoxElement(index + 1).focus();
  //       } else {
  //         getCodeBoxElement(index).blur();
  //       }
  //     }
  //     if (eventCode === "Backspace" && index !== 1) {
  //       document.getElementById('codeBox' + index).value = '';
  //       document.getElementById('codeBox' + index).removeAttribute("value");
  //       document.getElementById('codeBox' + (index - 1)).focus();
  //     }
  //     if (eventCode === "Backspace" && index === 1) {
  //       document.getElementById('codeBox' + index).value = '';
  //       document.getElementById('codeBox' + (index)).focus();
  //     }
  //   }
  //   function onFocusEvent(index) {
  //     for (let item = 1; item < index; item++) {
  //       const currentElement = getCodeBoxElement(item);
  //       if (!currentElement.value) {
  //         currentElement.focus();
  //         break;
  //       }
  //     }
  //   }


  useEffect(() => {
    // add or remove refs
    setElRefs((elRefs) =>
      Array(arrLength)
        .fill()
        .map((_, i) => elRefs[i] || createRef()),
    );
  }, [arrLength]);

  const inputfocus = (elmnt, field, idx) => {

    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {

      const newValue =
        value.substring(0, idx) + " " + value.substring(idx + 1);

      onChange(newValue);

      elmnt.target.value = "";
      if (field.current.previousSibling !== null) {
        field.current.previousSibling.focus();
      }
    }
    else {
      if (field.current.nextSibling !== null) {
        field.current.nextSibling.focus();
      }
    }

  }

  return (

    <>
      <div className={` ${colwidth} mx-auto justify-content-between userInput mb-1 mt-3 d-flex gap-1 `} id="otp">
        {
          (shareCode === "mask" ) ? valueItems.map((digit, idx) => (
            <input className="number" type="password" inputMode="numeric" pattern="\d{1}" maxLength={1}
              name={idx} id={`codeBox` + (idx + 1)} onKeyUp={e => inputfocus(e, elRefs[idx], idx)} ref={elRefs[idx]} value={digit} onChange={(e) => inputOnChange(e, idx)} />
          )) : valueItems.map((digit, idx) => (
            <input className="number" type="text" inputMode="numeric" pattern="\d{1}" maxLength={1}
              name={idx} id={`codeBox` + (idx + 1)} onKeyUp={e => inputfocus(e, elRefs[idx], idx)} ref={elRefs[idx]} value={digit} onChange={(e) => inputOnChange(e, idx)} />
          ))
        }

      </div><div className={`${colwidthtimer} justify-content-end mx-auto d-flex`}>
        {children}
      </div>
    </>

  )
}
