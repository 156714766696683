import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Heading from "../common-components/Heading";
import Layout from "../common-components/Layout";
import SummaryInput from "./components/SummaryInputs";
import { ToastContainer, toast } from 'react-toastify';

const ApplicationSummary = () => {
    const navigate = useNavigate();  
    return (
        <>
            <ToastContainer />
            <div className="pt-3">
                <Heading Description="U GRO Capital can help you with your business finance" BadgeDescription="Let us review your application before you submit" user/>
            </div>
            <Layout ImageSide="Summary">
                <h4 className="text-center mb-4 ">Application Summary</h4>
                <SummaryInput/>
            </Layout>
            
        </>
    );
}
export default ApplicationSummary;