import React, { useState, useEffect, useRef, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import Heading from "../common-components/Heading";
import Layout from "../common-components/Layout";
import Input from "../common-components/Input";
import ChooseInput from "../common-components/ChooseInput";
import PageFooter from "../common-components/PageFooter"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from "../../config.json";
import axios from "axios";
import imageThumb from "../../images/image-thumb.png";
import { BureauContext } from '../Context/BureauContext';
import Select from '../common-components/Select';
import { Salutation } from '../general-questions/SelectStaticData';
import { format } from 'date-fns';
import { encryptData } from '../../encrypt';

const KycDetail = () => {
  const api_url = config.API_URL;
  const navigate = useNavigate();
  const [src, setSrc] = useState(imageThumb);
  const [name, setName] = useState('');
  const [pan, setPan] = useState('');
  const [dob, setDob] = useState(format(new Date(),'dd-MM-yyyy'));
  const [isDisabled, setDisabled] = useState(false);
  const [isDisplay, setDisplay] = useState(false);
  const [disablebtn, setDisableBtn] = useState(true);
  const [isReUpload, setReUpload] = useState(false);
  const inputRef = useRef();
  const [isSelectedImg, setSelectedImg] = useState(false);
  const usertoken = sessionStorage.getItem('token');
  const [pdf, setPDF] = useState('');
  const [salut, setSalutation] = useState('');
  const [selectSalutation, setPreSelectSalutation] = useState('');
  const [imagebase64, setBase64] = useState("");
  const [cemara, setCemara] = useState('N');
  const {popupData, setPopUpData, checkStateByMiddileware, checkLocationPermission} = useContext(BureauContext);
  const locate_permission = sessionStorage.getItem('locate_permission');
  const latitude = sessionStorage.getItem("latitude");
  const longitude = sessionStorage.getItem("longitude");
  
  useEffect(() => {
    navigator.permissions
    .query({ name: "camera" })
    .then((permissionStatus) => {
      if(permissionStatus.state === "granted"){
        setCemara('Y');
      }else{
        setCemara('N');
      }
      permissionStatus.onchange = () => {
        if(permissionStatus.state === "granted"){
          setCemara('Y');
        }else{
          setCemara('N');
        }
      };
    });
  }, []);
  const convertDate =(e)=> {
    var d_arr = e.split("/");
    var newdate = d_arr[0] + '-' + d_arr[1] + '-' + d_arr[2];
    return newdate;
  }
  const onFileChangeHandler = (e) => {
    var reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onloadend = function(){
      const img_arr = reader.result.split(";base64,");
      const image = img_arr[1];
      setBase64(image)
      setSrc(URL.createObjectURL(e.target.files[0]));
      setSelectedImg(true);
      var value = e.target.files[0].size;
      var extenstion = e.target.files[0].type;
      var file_size = value/1024;
      let result = true;
      if(extenstion == "application/pdf" && file_size > 1024*2)
      {
        toast.error("Please try with an acceptable file size",{
          position: toast.POSITION.TOP_CENTER
        });
        setReUpload(true);
        result = false;
      }else if(file_size > 1024*4){
        toast.error("Please try with an acceptable file size",{
          position: toast.POSITION.TOP_CENTER,
        })
        setReUpload(true);
        result = false;
      }
      var extension = e.target.files[0].name.split('.').pop();
      const request_data = {
          'name_of_ocr': 'PAN_OCR',
          'latitude': latitude,
          'longitude': longitude,
          'ocr_doc' : image,
          'doc_type' : extension,
          'lat_long' : `${latitude},${longitude}`,
          'location' : 'Y',
          'camera'   : cemara,
          'notification' : 'N'
        };
      if(result){
        checkStateByMiddileware();
        if(checkLocationPermission()){
          document.getElementById('waitingLoader').style.display = 'block';
          ajaxPanData(request_data, extenstion);
        }else{
          resetPanData();
          toast.error("Please allow your device location",{
            position: toast.POSITION.TOP_CENTER,
          });
      }
      }
    }
  }

  function ajaxPanData (request_data, extenstion) {
    const headers = {
      'Authorization': `Bearer ${usertoken}`,
      'latlong' : `${latitude},${longitude}`,
    }
    const encrypted = encryptData(request_data);
    axios.post(api_url + `/doc-ocr`, {encrypted : encrypted}, { headers })
      .then((response) => {
        document.getElementById('waitingLoader').style.display = 'none';
        if (response.data.status == 'success') {
          console.log("Response is",response);
          toast.success(response.data.message,{
            position: toast.POSITION.TOP_CENTER,
          });
          setDisplay(true);
          setDisabled(true);
          setDisableBtn(false);
          setReUpload(true);
          setName(response.data.data.name);
          setPan(response.data.data.panNo);
          let dateOfdirth= response.data.data.date;
          const brithdate = dateOfdirth.replace(/\//g, "-");
          setDob(brithdate);
          if(extenstion == "application/pdf"){
            setPDF(response.data.data.name);
          }else{
            setPDF('');
          }
        }else if(response.data.status === "expired") {
          document.getElementById('waitingLoader').style.display = 'none';
          setPopUpData({...popupData,is_bureau_checked : true});
          resetPanData();
        }else if(response.data.status === "token") {
          toast.error(response.data.errors, {
              position: toast.POSITION.TOP_CENTER,
          });
          sessionStorage.clear();
          navigate('/login')
        }else if (response.data.status === 'hold') {
          setPopUpData({...popupData,
            is_bank_hold : 1, 
            checkStage : false,
            stepData : response.data.data.stage.parentStage
            });
      }else if (response.data.status === 'fail' && response.data.is_rejected === true) {
          document.getElementById('waitingLoader').style.display = 'none';
          if(response.data.hasOwnProperty('data')){
                    sessionStorage.setItem('status_flag', response.data.data.appStatus.comment)
                }
          navigate("/regrate");
      } else {
          resetPanData();
          document.getElementById('waitingLoader').style.display = 'none';
          toast.error(response.data.errors,{
            position: toast.POSITION.TOP_CENTER
          });
        }
      })
      .catch(error => {
        resetPanData();
        document.getElementById('waitingLoader').style.display = 'none';
        toast.error(error.message,{
          position: toast.POSITION.TOP_CENTER,
        });
      });
  }

  function kycSubmit() {
    checkStateByMiddileware();
    let checkValidate = true;
    if (name === '' || setName === null) {
      toast.error('Enter Name',{
        position: toast.POSITION.TOP_CENTER,
      });
      checkValidate = false;
    }
    if (pan === '' || setPan === null) {
      toast.error('Enter Pan',{
        position: toast.POSITION.TOP_CENTER,
      });
      checkValidate = false;
    }
    if (dob === '' || setDob === null) {
      toast.error('Enter DOB',{
        position: toast.POSITION.TOP_CENTER,
      });
      checkValidate = false;
    }
    if (salut === '' || salut === null) {
      toast.error('Please select salutation',{
        position: toast.POSITION.TOP_CENTER,
      });
      checkValidate = false;
    }

    if(locate_permission !== "granted"){
      toast.error('Please allow your device location',{
        position: toast.POSITION.TOP_CENTER,
      });
      checkValidate = false;
    }

    if (longitude === '' || longitude === null) {
      toast.error('Please allow location',{
        position: toast.POSITION.TOP_CENTER,
      });
      checkValidate = false;
    }

    if (checkValidate) {
      const payload = {
        customer_name : name,
        pan_no : pan,
        dob: dob,
        salutation : salut,
        latitude : latitude,
        longitude : longitude,
        consent_for_pan_collection : 'Y',
        pan_collected : 'Y',
        pan_source : 1
      };
      const encrypted = encryptData(payload);
      const headers = {
        'Authorization': `Bearer ${usertoken}`,
        'latlong' : `${latitude},${longitude}`,
      }
      document.getElementById('waitingLoader').style.display = 'block';
      axios.post(api_url + `/validate-pan`, {encrypted : encrypted}, { headers })
        .then((response) => {
          document.getElementById('waitingLoader').style.display = 'none';
          if (response.data.status == 'success') {
            toast.success(response.data.message,{
              position: toast.POSITION.TOP_CENTER,
            });
            sessionStorage.removeItem('userData')
            sessionStorage.setItem('userData', JSON.stringify(response.data.data.user));
            navigate('/udyam-detail');
          }else if (response.data.status === 'hold') {
            setPopUpData({...popupData,is_bank_hold : true});
        }else if(response.data.status === "token") {
            toast.error(response.data.errors, {
                position: toast.POSITION.TOP_CENTER,
            });
            sessionStorage.clear();
            navigate('/login')
          }else if(response.data.status === "expired") {
            document.getElementById('waitingLoader').style.display = 'none';
            setPopUpData({...popupData,is_bureau_checked : true});
          }else if (response.data.status === 'fail' && response.data.is_rejected === true) {
            document.getElementById('waitingLoader').style.display = 'none';
            if(response.data.hasOwnProperty('data')){
                    sessionStorage.setItem('status_flag', response.data.data.appStatus.comment)
                }
            navigate("/regrate");
        }else {
            document.getElementById('waitingLoader').style.display = 'none';
            toast.error(response.data.errors,{
               position: toast.POSITION.TOP_CENTER,
            });
          }
        })
        .catch(error => {
          document.getElementById('waitingLoader').style.display = 'none';
          toast.error(error.message,{
            position: toast.POSITION.TOP_CENTER,
          });
        });
    }
  }

  const resetPanData = () => {
    inputRef.current.value = null;
    setName('');
    setPan('');
    setDob('');
    setPDF('');
    setSrc(imageThumb);
    setDisplay(false);
    setDisableBtn(true);
    setReUpload(false);
    setSelectedImg(false);
  }

  const onChangeSalutation = (e) => {
    setSalutation(e.target.value);
    setPreSelectSalutation(e.target.value);
  }

  return (
    <>
      <ToastContainer />
      <div className="pt-3">
        <Heading Description="Let us help you build a future-ready company" BadgeDescription="Let us complete your KYC" user />
      </div>
      <Layout ImageSide="Product-intro">
        <form>
          <h4 className="text-center mb-4">Please upload your PAN Card</h4>
          <div className="col-md-8 col-sm-12 mx-auto">
            <ChooseInput isDisabled={isSelectedImg} isAccept={'.png, .jpg, .jpeg, .pdf'} inputRef={inputRef} selectFile={onFileChangeHandler} ispdf={pdf} src={src} />
            {isDisplay && <div>
              <Select LabelName="Salutation" preSelect={selectSalutation} isAstrix SelectData={Salutation} selected={(e) => onChangeSalutation(e)} />
              <Input LabelName="Name" disabled={isDisabled} isAstrix type="text" id="name" placeholder="Name" inputval={name} changeInput={(e) => { setName(e.target.value) }} />
              <Input LabelName="PAN" disabled={isDisabled} name="pan" isAstrix type="text" Id="pan" placeholder="PAN" inputval={pan} changeInput={(e) => { setPan(e.target.value) }} />
              <Input LabelName="DOB" disabled={isDisabled} name="dob" isAstrix type="text" Id="dob" placeholder="DOB" inputval={dob} changeInput={(e) => { setDob(e.target.value) }} />
            </div>
            }
          </div>
          <PageFooter BtnName="I SUBMIT" setDisabled={disablebtn} ButtonClick={() => { kycSubmit() }} TermsCondition="I acknowledge that by submitting, U GRO may access the location, camera and the file uploaded from this device."/>
          {isReUpload && 
          <div className="text-center">
          <a className='m-5 text-center fontsize-12 fw-bold' href='javascript:void(0);' onClick={() =>{ resetPanData();}}>Re Upload</a>
          </div>
          }
        </form>
      </Layout>
    </>
  );
}
export default KycDetail;