import { useNavigate, useRoutes } from "react-router-dom";
import "./css/styles.css";
import './css/custom.css';
//import 'https://maps.googleapis.com/maps/api/js?sensor=false';
import confige from "./config.json";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.min.js";
import Register from "./components/register/Register";
import Login from "./components/register/Login";
import VerifyEmail from "./components/register/VerifyEmail";
import ProductIntro from "./components/product-info/ProductIntro";
import KycDocument from "./components/product-info/KycDocument";
import KycDetail from "./components/kyc-detail/KycDetail";
import KycAadhaar from "./components/kyc-detail/KycAadhaar";
import KycSelfie from "./components/kyc-detail/KycSelfie";
import KycEntity from "./components/kyc-detail/KycEntity";
import UdyamDetail from "./components/kyc-detail/UdyamDetail";
import AadhaarUpload from "./components/kyc-detail/AadhaarUpload";
import AadharOtp from "./components/kyc-detail/AadharOtp";
import GeneralQuestions from "./components/general-questions/GeneralQuestions";
import BeProof from "./components/general-questions/BeProof";
import BusinessQuestions from "./components/general-questions/BusinessQuestions";
import Address from "./components/address/Address";
import BankDetail from "./components/bank-detail/BankDetail";
import ApplicationSummary from "./components/application-summary/ApplicationSummary";
import OfferDetails from "./components/offers-details/OfferDetails";
import SanctionAcceptance from "./components/offers-details/SanctionAcceptance";
import MitcAcceptance from "./components/offers-details/MitcAcceptance";
import MitcValidate from "./components/offers-details/MitcValidate";
import Congratulations from "./components/congratulations/Congratulations";
import SetMandate from "./components/set-mandate/SetMandate";
import MandateSummary from "./components/set-mandate/MandateSummary";
import JourneySuccess from "./components/set-mandate/JourneySuccess";
import Regrate from "./components/set-mandate/Regrate";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import LinkedGst from "./components/linked-gst/LinkedGst";
import NotFound from "./components/common-components/NotFound";
import {BureauContext} from "./components/Context/BureauContext";
import PanDetail from "./components/kyc-detail/PanDetail";
import PrincipleApproval from "./components/kyc-detail/PrincipleApproval";
import LinkExpired from "./components/register/LinkExpired";
import AllowLocation from "./components/common-components/modal/AllowLocation";
import FetchAllowLocation from "./components/common-components/modal/FetchAllowLocation";
import BajajRegister from "./components/register/BajajRegister.js";
import BusinessData from "./components/general-questions/BusinessData.js";
import UdyamDoc from "./components/general-questions/UdyamDoc.js";
const locate_permission = sessionStorage.getItem('locate_permission');

const routes = [
  {
    path: "/",
    search : "?pre=:value",
    element: <Register />
  },
  {
    path: "/",
    search: "?q=:value",
    element: <Register />
  },
  {
    path: "/bajaj/signup",
    search: "?signature=:value",
    element: <BajajRegister />
  },
  {
    path: "/login",
    element: <Login />
  },
  {
    path: "/verify-email",
    element: <VerifyEmail />
  },
  {
    path : "/pan-detail",
    element : <PanDetail />
  },
  {
    path : "principal-approval",
    element : <PrincipleApproval/>
  },
  {
    path: "/product-intro",
    element: <ProductIntro />
  },
  {
    path: "/kyc-document",
    element: <KycDocument />
  },
  {
    path: "/kyc-detail",
    element: <KycDetail />
  },
  {
    path: "/upload-aadhar",
    element: <AadhaarUpload />
  },
  {
    path: "/aadhar-otp",
    element: <AadharOtp />
  },
  {
    path: "/kyc-aadhar",
    element: <KycAadhaar />
  },
  {
    path: "/kyc-selfie",
    element: <KycSelfie />
  },
  {
    path: "/kyc-entity",
    element: <KycEntity />
  },
  {
    path: "/udyam-detail",
    element: <UdyamDetail />
  },
  {
    path: "/general-questions",
    element: <GeneralQuestions />
  },
  {
    path: "/be-proof",
    element: <BeProof />
  },
  {
    path: "/linked-gst",
    element: <LinkedGst />
  },
  {
    path: "/business-question",
    element: <BusinessQuestions />
  },
  {
    path: "/address",
    element: <Address />
  },
  {
    path: "/bank-detail",
    element: <BankDetail />
  },
  {
    path: "/summary",
    element: <ApplicationSummary />
  },
  {
    path: "/review-offer",
    element: <OfferDetails />
  },
  {
    path: "/sanction-acceptance",
    element: <SanctionAcceptance />
  },
  {
    path: "/mitc-acceptance",
    element: <MitcAcceptance />
  },
  {
    path: "/mitc-validate",
    element: <MitcValidate />
  },
  {
    path: "/congratulations",
    element: <Congratulations />
  },
  {
    path: "/set-mandate",
    element: <SetMandate />
  },
  {
    path: "/mandate-summary",
    element: <MandateSummary />
  },
  {
    path: "/business-proof",
    element: <BusinessData />
  },
  {
    path: "/udyam-assit",
    element: <UdyamDoc />
  },
  {
    path: "/journey-success",
    element: <JourneySuccess />
  },
  {
    path: "/regrate",
    element: <Regrate />
  },
  {
    path: "/link-expired",
    element: <LinkExpired />
  },
  {
    path: "*",
    element: <NotFound />
  }
];
function App() {
  const navigate = useNavigate();
  const apiurl = confige.API_URL;
  const google = window.google;
  const usertoken = sessionStorage.getItem('token');
  const locate_permission = sessionStorage.getItem('locate_permission');
  const [location, setLocation] = useState('');
  const currentURL = window.location.pathname;
  const [sanctionHtml, setsanctionHtml] = useState({__html : ""});
  const latitude = sessionStorage.getItem('latitude');
  const longitude = sessionStorage.getItem('longitude');
  const [popupData,  setPopUpData] = useState({checkStage : true, approvalAmount: 0, is_bureau_checked : false, is_bank_hold: 0, stageSMS : '', mobileapp: '', laf: '', modal : '', lan: '', crn: '', isApiType: '', loanAmount : '', nextDate: '', stepData : ''})
  
  window.history.pushState(null, null, window.location.href);
  window.onpopstate = function () {
      window.history.go(1);
  };

  const checkTokens = () => {
    const headers = {
      'Authorization': `Bearer ${usertoken}`,
    }
    axios.post(apiurl + `/check-verify-jwt`, '', { headers })
      .then((response) => {
        if (response.data.status === 'token') {
          sessionStorage.clear();
          navigate('/');
          toast.error(response.data.errors, {
            position: toast.POSITION.TOP_CENTER
          });
        } else if (response.data.status === 'fail' && response.data.is_rejected === true) {
          document.getElementById('waitingLoader').style.display = 'none';
          if(response.data.hasOwnProperty('data')){
            sessionStorage.setItem('status_flag', response.data.data.appStatus.comment)
          }
          navigate("/regrate");
        } else if (response.data.status === 'fail') {
          toast.error(response.data.errors, {
            position: toast.POSITION.TOP_CENTER
          });
        }else if (response.data.status === 'hold') {
          setPopUpData({...popupData, is_bank_hold : true});
      } else {
          sessionStorage.setItem('userData', JSON.stringify(response.data.data.user));
          sessionStorage.setItem('lafId', response.data.data.appData.laf_id);
          if(response.data.hasOwnProperty('data')){
                    sessionStorage.setItem('status_flag', response.data.data.appStatus.comment)
                };
          if (currentURL !== response.data.data.stage.lastActiveStage) {
            navigate(response.data.data.stage.lastActiveStage);
          }
          
        }
      })
      .catch(error => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_CENTER
        });
      });
  }

  const checkStateByMiddileware = async () => {
    let token = '';
    if(usertoken !== '' && usertoken !== null && usertoken !== undefined){
      token = usertoken;
    }
    const headers = {
      'Authorization': `Bearer ${token}`,
    }
   await axios.post(apiurl + `/check-verify-jwt`, '', { headers })
      .then((response) => {
            if (response.data.status === 'token') {
              sessionStorage.clear();
              navigate('/login');
              toast.error(response.data.errors,{
                position: toast.POSITION.TOP_CENTER
              });
              
          }else if(response.data.status === 'fail' && response.data.is_rejected === true){
            if(response.data.data.appStatus.status_id === 69 && response.data.data.appStatus.comment === "AutoReject"){
              sessionStorage.setItem('status_flag', "AutoReject");
            }else if(response.data.data.appStatus.status_id === 69 && response.data.data.appStatus.comment !== "AutoReject"){
              sessionStorage.setItem('status_flag', "NormalReject");
            }
          }else if (response.data.status === 'expired') {
              setPopUpData({...popupData,
                 is_bureau_checked : true,
                 checkStage : false,
                 stepData : response.data.data.stage.parentStage
                });
          }else if (response.data.status === 'hold') {
              setPopUpData({...popupData,
                is_bank_hold : true});
          }else if (response.data.status === 'APPROVED') {
              setPopUpData({...popupData,
                stageSMS : 'Your Application Is Already Approved With Mobile Number',
                mobileapp: response.data.data.approved.mobile_no,
                modal : true,
                lan: response.data.data.approved.lan_no,
                crn: response.data.data.approved.crn,
                isApiType: 'approved',
                loanAmount : response.data.data.approved.approved_amount,
                checkStage : false,
                stepData : response.data.data.stage.parentStage
              });
          }else if (response.data.status === 'REJECTED') {
              setPopUpData({...popupData,
                stageSMS : response.data.data.rejected.message,
                mobileapp: response.data.data.rejected.mobile_no,
                modal : true,
                isApiType: 'rejected',
                nextDate : response.data.data.rejected.next_date,
                checkStage : false,
                stepData : response.data.data.stage.parentStage
              });
          }else if(response.data.status === 'regrate'){
            if(response.data.hasOwnProperty('data')){
              sessionStorage.setItem('status_flag', response.data.data.appStatus.comment)
            }
              navigate('/regrate');
          }else if (response.data.status === 'success') {
              setPopUpData({...popupData,
                laf_id : response.data.data.appData.laf_id,
                stepData : response.data.data.stage.parentStage
              });
              sessionStorage.setItem("approve_amount",response.data.data.appData.PreQualifiedAmount)
            if(response.data.data.appData.is_bureau_expired === 1){
                setPopUpData({...popupData,
                  is_bureau_checked : true,
                  checkStage : false,
                  stepData : response.data.data.stage.parentStage
                });
            }
            if(response.data.data.appData.is_bank_hold === 1){
              setPopUpData({...popupData,
                is_bank_hold : 1,
                checkStage : false,
                stepData : response.data.data.stage.parentStage
              });
            }
            setPopUpData({...popupData,
              checkStage : true,
              stepData : response.data.data.stage.parentStage
            });
            
          }
      })
      .catch(error => {
        
      });
  }

  const checkLocationPermission = () => {
    var checkLocation = true;
    if(locate_permission === 'denied' || locate_permission === '' || locate_permission === null){
      var checkLocation = false;
    }else{
      navigator.permissions
      .query({ name: "geolocation" })
      .then((permissionStatus) => {
        sessionStorage.setItem("locate_permission" , permissionStatus.state);
        if(permissionStatus.state === 'granted'){
                var timeoutVal = 10 * 1000 * 1000;
                navigator.geolocation.watchPosition(displayPosition, displayError,{ enableHighAccuracy: true, timeout: timeoutVal, maximumAge: 0 });
            }
          setLocation(permissionStatus.state);
          permissionStatus.onchange = () => {
            setLocation(permissionStatus.state);
            sessionStorage.setItem("locate_permission" , permissionStatus.state);
            if(permissionStatus.state === 'granted'){
                var timeoutVal = 10 * 1000 * 1000;
                navigator.geolocation.watchPosition(displayPosition, displayError, { enableHighAccuracy: true, timeout: timeoutVal, maximumAge: 0 });
            }else{
              sessionStorage.setItem('latitude','');
              sessionStorage.setItem('longitude','');
            }
          };
        });
    }
    return checkLocation;
  }

  useEffect(() => {
    if(currentURL !== "/" && currentURL !== "/login" && currentURL !== "/bajaj/signup"){
      var timeoutVal = 10 * 1000 * 1000;
      const nav = navigator.geolocation.watchPosition(displayPosition, displayError,{ enableHighAccuracy: true, timeout: timeoutVal, maximumAge: 0 });
      navigator.permissions
      .query({ name: "geolocation" })
      .then((permissionStatus) => {
        sessionStorage.setItem("locate_permission" , permissionStatus.state);
          if(permissionStatus.state === 'granted'){
              var timeoutVal = 10 * 1000 * 1000;
              navigator.geolocation.watchPosition(displayPosition, displayError,{ enableHighAccuracy: true, timeout: timeoutVal, maximumAge: 0 });
          }else{
            sessionStorage.setItem('latitude','');
            sessionStorage.setItem('longitude','');
          }
        setLocation(permissionStatus.state);
        permissionStatus.onchange = () => {
          setLocation(permissionStatus.state);
          sessionStorage.setItem("locate_permission" , permissionStatus.state);
          if(permissionStatus.state === 'granted'){
              var timeoutVal = 10 * 1000 * 1000;
              navigator.geolocation.watchPosition(displayPosition, displayError, { enableHighAccuracy: true, timeout: timeoutVal, maximumAge: 0 });
          }else{
            sessionStorage.setItem('latitude','');
            sessionStorage.setItem('longitude','');
          }
        };
      });
    }

    if (usertoken !== '' && usertoken !== null) {
     checkTokens();
    }
    if ((usertoken === '' || usertoken === null || usertoken === undefined) && (currentURL !== "/login" && currentURL !== '/' && currentURL !== '/link-expired' && currentURL !== '/bajaj/signup')) {
      navigate('/');
    }

  }, []);

  function displayPosition(position) {
    sessionStorage.setItem("latitude" , position.coords.latitude);
    sessionStorage.setItem("longitude" , position.coords.longitude);
  }

  function displayError(error) {
    var errors = {
      1: 'Permission denied',
      2: 'Position unavailable',
      3: 'Request timeout'
    };
  }

  function logout() {
    const headers = {
      'Authorization': `Bearer ${usertoken}`,
    }
    axios.post(apiurl + `/logout`,'', {headers}).then((response) => {
        if (response.data.status == 'success' || response.data.status == 'token') {
            setPopUpData({...popupData, checkStage : true,
            approvalAmount: 0, 
            is_bureau_checked : false, 
            is_bank_hold: 0, 
            stageSMS : '', 
            mobileapp: '', 
            laf: '', 
            modal : '', 
            lan: '', 
            crn: '', 
            isApiType: '', 
            loanAmount : '', 
            nextDate: '', 
            stepData : ''
          });
          sessionStorage.clear();
          toast.success(response.data.message);
          navigate('/');
        }
        else {
            toast.error(response.data.errors);
        }
    })
        .catch(error => {
            toast.error(error);
        });
}
const element = useRoutes(routes);
if((location === 'denied') && currentURL !== '/regrate' && currentURL !== '/' && currentURL !== '/login' && currentURL !== '/bajaj/signup'){
  return <AllowLocation />
}
if(currentURL !== '/regrate' && currentURL !== '/' && currentURL !== '/login' && location === 'denied' && (latitude === '' || latitude === null || longitude === '' || longitude === null)){
    return <FetchAllowLocation />
}
  return <>
  <BureauContext.Provider value={{popupData, setPopUpData, checkStateByMiddileware, logout,sanctionHtml, setsanctionHtml, checkLocationPermission }}>
    {/* <div id="google_translate_element"></div> */}
    {element}
  </BureauContext.Provider>
  </>;
}

export default App;
