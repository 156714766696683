import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { BureauContext } from "../../Context/BureauContext";

const ApprovedModal = ({isApiType, lan, crn, loanAmount, stageSMS, mobileapp}) => {
    const {is_bureau_checked, setCheckBureau, sanctionHtml, setsanctionHtml, logout, is_bank_hold, setBankHold} = useContext(BureauContext);

    return(
            <div className="modal-center">
            <div className={`modal modal-background fade show`} id="exampleModal" tabIndex={-1} style={{ display: "block" }}>
            <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
                <div className="modal-body pt-4">
                        {isApiType === 'rejected' && <div className="text-center col-md-10 mx-auto">{stageSMS}</div>}
                    </div><br />
                    {isApiType === 'approved' &&
                    <div>
                        <div className="text-center col-md-10 mx-auto"><b>{stageSMS} {mobileapp}</b></div>
                        <div className="col-md-10 mx-auto lh-lg">
                            <div>LAN - {lan}</div>
                            <div>CRN - {crn}</div>
                            <p>Loan Amount - {loanAmount}</p>
                        </div>
                        </div>
                    }
                    <div className="modal-footer justify-content-center border-0 pt-0">
                        <button type="button" className="btn btn-primary" onClick={() => logout()}>CLOSE</button>                                </div>
                </div>
            </div>
            </div>
            </div>
    );
}

export default ApprovedModal;