import React from "react";

export const CustomButton = ({btnname, isDisabled, btn_col, clickBtn,type=""}) =>{
    return(
        <>
            <button onClick={clickBtn} disabled={isDisabled} type={(type?type:'button')} className={`btn btn-primary ${btn_col}`}>{btnname}</button>
        </>
    );
}
    

export const CancelButton = ({btnname, btn_col, clickBtn}) =>{
    return(
        <>
             <button onClick={clickBtn} type="button" className={`btn btn-outline-primary ${btn_col}`}>{btnname}</button>
        </>
    );
}

export const LoginButton = ({btnname, btn_col, clickBtn}) =>{
    return(
        <>
             <a onClick={clickBtn} type="button" className={`text-decoration-none text-orange ${btn_col}`}>{btnname}</a>
        </>
    );
}

export const RegisterButton = ({btnname, btn_col, clickBtn}) =>{
    return(
        <>
             <a onClick={clickBtn} type="button" className={`text-orange fw-bold${btn_col}`} >{btnname}</a>
        </>
    );
}