import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { CheckBoxInput } from "../common-components/CheckInput";
import Heading from "../common-components/Heading";
import Input from "../common-components/Input";
import Layout from "../common-components/Layout";
import PageFooter from "../common-components/PageFooter";
import Select from "../common-components/Select";
import SelectBank from "../common-components/selectBank";
import { AccountType } from "../general-questions/SelectStaticData";
import { ToastContainer, toast } from 'react-toastify';
import config from "../../config.json";
import axios from "axios";
import { BureauContext } from "../Context/BureauContext";
import { encryptData } from "../../encrypt";

const BankDetail = () => {
    const navigate = useNavigate();
    const [isDisabled, setDisabled] = useState(true);
    const [isInputDisabled, setInputDisabled] = useState(false);
    const [isHide, setHide] = useState(true);
    const usertoken = sessionStorage.getItem('token');
    const [BankNameList, setBankNameList] = useState([]);
    const api_url = config.API_URL;
    const [preSelectBank, setSelectBank] = useState('');
    const [preSelectAccoutType, setSelectAccountType] = useState('');
    const [bankCode, setBankCode] = useState('');
    const [accountType, setAccountType] = useState('');
    const [accountHolder, setAccountHolder] = useState('');
    const [accountNumber, setAccountNumber] = useState('');
    const [reAccountNumber, setReAccountNumber] = useState('');
    const [ifsc, setIfsc] = useState('');
    const [agree_concent, setAgreeConcent] = useState(0);
    const [mode, setMode] = useState('');
    const [validation, setValidationError] = useState({ accountError: '', misMatchError: '', misMatchSuccess: '', bankNameError: '', accountTypeError: '', accountHolderError: '', accountNumberError: '', reAccountNumberError: '', IfscError: '' });
    const { popupData, setPopUpData, checkStateByMiddileware, checkLocationPermission } = useContext(BureauContext);
    const latitude = sessionStorage.getItem("latitude");
    const longitude = sessionStorage.getItem("longitude");
    useEffect(() => {
        if(checkLocationPermission()){
            checkStateByMiddileware();
            const headers = {
                'Authorization': `Bearer ${usertoken}`,
                'latlong' : `${latitude},${longitude}`,
            }
            document.getElementById('waitingLoader').style.display = 'block';
            axios.get(api_url + `/bank-detail`, { headers })
                .then((response) => {
                    document.getElementById('waitingLoader').style.display = 'none';
                    if (response.data.status === 'success') {
                        setBankNameList(response.data.data.bank);
                        // if(response.data.data.appData.is_pre_approved === 1 && response.data.data.appBankData !== null && response.data.data.appBankData !== '' && response.data.data.appBankData !== undefined){
                        //     setBankCode(response.data.data.appBankData.bank_name);
                        //     setAccountHolder(response.data.data.appBankData.account_holder_name);
                        //     setAccountNumber(response.data.data.appBankData.bank_account_number);
                        //     setAccountType(response.data.data.appBankData.account_type);
                        //     setReAccountNumber(response.data.data.appBankData.bank_account_number);
                        //     setIfsc(response.data.data.appBankData.bank_ifcs);
                        //     setMode(response.data.data.appBankData.mode);
                        //     setSelectBank(response.data.data.appBankData.bank_name);
                        //     setSelectAccountType(response.data.data.appBankData.account_type);
                        //     setHide(false);
                        //     if(response.data.data.appData.is_mandate_setup === 1){
                        //         setInputDisabled(true);
                        //     }
                        // }
                    } else if (response.data.status === "token") {
                        toast.error(response.data.errors);
                        sessionStorage.clear();
                        navigate('/login');
                    } else if (response.data.status === "expired") {
                        document.getElementById('waitingLoader').style.display = 'none';
                        setPopUpData({...popupData, is_bureau_checked : true});
                    }else if (response.data.status === "hold") {
                        document.getElementById('waitingLoader').style.display = 'none';
                        setPopUpData({...popupData, is_bank_hold: true});
                    }else {
                        document.getElementById('waitingLoader').style.display = 'none';
                        toast.error(response.data.errors, {
                            position: toast.POSITION.TOP_CENTER
                        });
                    }
                })
                .catch(error => {
                    document.getElementById('waitingLoader').style.display = 'none';
                    toast.error(error.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                });
             } else{
                    toast.error("Please allow your device location",{
                      position: toast.POSITION.TOP_CENTER,
                    });
                  }
    }, [])

    function onChangeHandlerBank(e) {
        checkStateByMiddileware();
        let checkerror = {}
        const headers = {
            'Authorization': `Bearer ${usertoken}`,
           'latlong' : `${latitude},${longitude}`,
        }
        
        const payload = {
            bank_id: e.target.value
        }
        const encrypted = encryptData(payload);
        document.getElementById('waitingLoader').style.display = 'block';
        axios.post(api_url + `/check-bank-detail`, {encrypted : encrypted}, { headers })
            .then((response) => {
                setBankCode('');
                setMode('');
                if (response.data.status === 'success') {
                    document.getElementById('waitingLoader').style.display = 'none';
                    setBankCode(response.data.data.bank_code);
                    setSelectBank(response.data.data.bank_code)
                    setMode(response.data.data.mode);
                } else if (response.data.status === "token") {
                    toast.error(response.data.errors);
                    sessionStorage.clear();
                    navigate('/login');
                } else if (response.data.status === "expired") {
                    document.getElementById('waitingLoader').style.display = 'none';
                    setPopUpData({...popupData, is_bureau_checked : true});
                }else if (response.data.status === "hold") {
                    document.getElementById('waitingLoader').style.display = 'none';
                    setPopUpData({...popupData, is_bank_hold: true});
                }else if (response.data.status === 'fail' && response.data.is_rejected === true) {
                    document.getElementById('waitingLoader').style.display = 'none';
                    if(response.data.hasOwnProperty('data')){
                        sessionStorage.setItem('status_flag', response.data.data.appStatus.comment)
                    }
                    navigate("/regrate");
                }  else {
                    document.getElementById('waitingLoader').style.display = 'none';
                    toast.error(response.data.errors, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            })
            .catch(error => {
                document.getElementById('waitingLoader').style.display = 'none';
                toast.error(error.message, {
                    position: toast.POSITION.TOP_CENTER
                });
            });

        if (e.target.value.length > 0) {
            checkerror['bankNameError'] = "";
            setValidationError({ ...validation, ...checkerror });
        }
    }
    function onChangeHandlerType(e) {
        setAccountType(e.target.value);
        setSelectAccountType(e.target.value);
        let checkerror = {}
        if (e.target.value === 'CURRENT' || e.target.value === 'SAVINGS') {
            setHide(false);
        } else {
            setHide(true);
        }
        if (e.target.value.length > 0) {
            checkerror['accountTypeError'] = "";
            setValidationError({ ...validation, ...checkerror });
        }
    }

    function onChangeChecked(e) {
        setDisabled(true);
        setAgreeConcent(0);
        if (e.target.checked) {
            setDisabled(false);
            setAgreeConcent(1);
        }
    }

    function save_bank_details(e) {
        checkStateByMiddileware();
        let checkValidate = true;
        let checkerror = {}
        if (bankCode === '' || bankCode === undefined || bankCode === null) {
            checkerror['bankNameError'] = "Please Select Bank Name";
            checkValidate = false;
        }
        if (accountType === '' || accountType === undefined || accountType === null) {
            checkerror['accountTypeError'] = "Please Select Account Type";
            checkValidate = false;
        }
        if (accountHolder === '' || accountHolder === undefined || accountHolder === null) {
            checkerror['accountHolderError'] = "Please enter Account Holder Name";
            checkValidate = false;
        }
        if (accountNumber === '' || accountNumber === undefined || accountNumber === null) {
            checkerror['accountNumberError'] = "Please enter Account Number";
            checkValidate = false;
        }
        if (reAccountNumber === '' || reAccountNumber === undefined || reAccountNumber === null) {
            checkerror['reAccountNumberError'] = "Please re-enter Account Number";
            checkValidate = false;
        }
        if (accountNumber !== reAccountNumber) {
            checkerror['reAccountNumberError'] = "Account Number should be Same";
            checkValidate = false;
        }
        if (ifsc === '' || ifsc === undefined) {
            checkerror['IfscError'] = "Please enter IFSC";
            checkValidate = false;
        }
        if (mode == '' || mode === undefined) {
            checkValidate = false;
            toast.error('Bank is not valid', {
                position: toast.POSITION.TOP_CENTER
            });
        }
        setValidationError({ ...validation, ...checkerror });
        if (checkValidate) {
            const payload = {
                bank_code: bankCode,
                account_type: accountType,
                account_holder_name: accountHolder,
                account_number: accountNumber,
                account_number_confirmation: reAccountNumber,
                ifsc: ifsc,
                bank_detail_consent: 'Y',
                mode: mode,
            }
            const encrypted = encryptData(payload);
            const headers = {
                'Authorization': `Bearer ${usertoken}`,
                'latlong' : `${latitude},${longitude}`,
            }
            document.getElementById('waitingLoader').style.display = 'block';
            axios.post(api_url + `/save-bank-information`, {encrypted : encrypted}, { headers })
                .then((response) => {
                    console.log("response is",response);
                    document.getElementById('waitingLoader').style.display = 'none';
                    if (response.data.status === 'success') {
                        toast.success(response.data.message, {
                            position: toast.POSITION.TOP_CENTER
                        });
                        navigate('/summary');
                    } else if (response.data.status === "expired") {
                        document.getElementById('waitingLoader').style.display = 'none';
                        setPopUpData({...popupData, is_bureau_checked : true});
                    } else if (response.data.status === "hold") {
                        document.getElementById('waitingLoader').style.display = 'none';
                        setPopUpData({...popupData, is_bank_hold: true});
                    } else if (response.data.status === "token") {
                        toast.error(response.data.errors);
                        sessionStorage.clear();
                        navigate('/login');
                    }else if (response.data.status === 'fail' && response.data.is_rejected === true) {
                        document.getElementById('waitingLoader').style.display = 'none';
                        if(response.data.hasOwnProperty('data')){
                            sessionStorage.setItem('status_flag', response.data.data.appStatus.comment)
                        }
                        navigate("/regrate");
                    } else {
                        document.getElementById('waitingLoader').style.display = 'none';
                        toast.error(response.data.errors, {
                            position: toast.POSITION.TOP_CENTER
                        });
                    }
                })
                .catch(error => {
                    document.getElementById('waitingLoader').style.display = 'none';
                    toast.error(error.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                });
        }
    }

    const changeAccountHolderName = (e) => {
        const value = e.target.value.replace(/[^A-Za-z\s]/ig, '')
        const limit = 50;
        let checkerror = {}
        setAccountHolder(value.slice(0, limit));
        if (e.target.value.length > 0) {
            checkerror['accountHolderError'] = "";
            setValidationError({ ...validation, ...checkerror });
        }
    }
    const changesAccountNumber = (e, blr) => {
        const value = e.target.value.replace(/\D/g, "");
        if (blr) {
            if (reAccountNumber !== '' && reAccountNumber !== null && parseInt(value) !== parseInt(reAccountNumber)) {
                setValidationError({ misMatchError: "Account number mismatched." });
            } else if (reAccountNumber !== '' && reAccountNumber !== null) {
                setValidationError({ accountNumberError: '' });
                setValidationError({ misMatchError: '' });
                setValidationError({ misMatchSuccess: " " });
            } else {
                setValidationError({ accountNumberError: '' });
                setValidationError({ misMatchError: '' });
                setValidationError({ misMatchSuccess: '' });
            }
            //setValidationError({accountError : ''});
        }
        const limit = 25;
        setAccountNumber(value.slice(0, limit));
    }
    const changesReAccountNumber = (e, blr) => {
        const value = e.target.value.replace(/\D/g, "");
        if (accountNumber === '' || accountNumber === null) {
            setValidationError({ ...validation, ['accountError']: "Account number required." });
            return false;
        }
        setValidationError({ misMatchSuccess: '' });
        setValidationError({ misMatchError: '' });
        setValidationError({ reAccountNumberError: '' });
        if (blr) {
            if (accountNumber !== '' && accountNumber !== null && value !== accountNumber) {
                setValidationError({ ...validation, ['misMatchError']: "Account number mismatched." });
            } else {
                setValidationError({ ...validation, ['misMatchError']: '' });
                setValidationError({ ...validation, ['misMatchSuccess']: '' });
            }
        }
        const limit = 25;
        setReAccountNumber(value.slice(0, limit));
    }
    const changeIfsc = (e) => {
        let checkerror = {}
        const value = e.target.value.toUpperCase();
        const limit = 11;
        setIfsc(value.slice(0, limit));
        if (e.target.value.length > 0) {
            checkerror['IfscError'] = "";
            setValidationError({ ...validation, ...checkerror });
        }
    }
    return (
        <>
            <ToastContainer />
            <div className="pt-3">
                <Heading Description="Let us help you build a future-ready company" BadgeDescription="Let us know about the Bank you will repay us from" user />
            </div>
            <Layout ImageSide="Address">
                <h4 className="text-center mb-4">Please enter your Banking details</h4>
                <form autoComplete="off">
                    <div className="row">
                        <div className="col-md-6">
                            <SelectBank preSelect={preSelectBank} LabelName="Bank Name" isAstrix SelectData={BankNameList} selected={(e) => onChangeHandlerBank(e)} disabled={isInputDisabled} />
                            {(validation.bankNameError !== '') ?
                                <span className="text-danger fontsize-13">{validation.bankNameError}</span>
                                : null
                            }
                        </div>
                        <div className="col-md-6">
                            <Input isAstrix changeInput={(e) => changeAccountHolderName(e)} LabelName="Account Holder’s Name" type="text" Id="Account_Holder_Name" inputval={accountHolder} placeholder="Account Holder’s Name" disabled={isInputDisabled} />
                            {(validation.accountHolderError !== '') ?
                                <span className="text-danger fontsize-13">{validation.accountHolderError}</span>
                                : null
                            }
                        </div>
                        <div className="col-md-6">
                            <Input LabelName="Account Number" isAstrix type="password" Id="Account_Number" placeholder="Account Number" inputval={accountNumber} changeInput={(e) => changesAccountNumber(e, false)} blurEvent={(e) => changesAccountNumber(e, true)} autoComplete="off" disabled={isInputDisabled} />
                            {(validation.accountNumberError !== '') ?
                                <span className="text-danger fontsize-13">{validation.accountNumberError}</span>
                                : null
                            }
                        </div>
                        <div className="col-md-6">
                            <Input LabelName="Re-enter Account Number " isAstrix type="text" Id="Reenter_Account_number " placeholder="Re-enter Account Number " inputval={reAccountNumber} changeInput={(e) => changesReAccountNumber(e, false)} blurEvent={(e) => changesReAccountNumber(e, true)} disabled={isInputDisabled} />
                            {(validation.misMatchSuccess !== '') ?
                                <span className="text-success fontsize-13">{validation.misMatchSuccess}</span>
                                : null
                            }

                            {(validation.accountError !== '') ?
                                <span className="text-danger fontsize-13">{validation.accountError}</span>
                                : null
                            }

                            {(validation.misMatchError !== '') ?
                                <span className="text-danger fontsize-13">{validation.misMatchError}</span>
                                : null
                            }

                            {(validation.reAccountNumberError !== '') ?
                                <span className="text-danger fontsize-13">{validation.reAccountNumberError}</span>
                                : null
                            }

                        </div>
                        <div className="col-md-6">
                            <Input LabelName="IFSC" isAstrix type="text" Id="IFSC" inputval={ifsc} placeholder="IFSC" changeInput={(e) => changeIfsc(e)} disabled={isInputDisabled}/>
                            {(validation.IfscError !== '') ?
                                <span className="text-danger fontsize-13">{validation.IfscError}</span>
                                : null
                            }
                        </div>
                        <div className="col-md-6">
                            <Select LabelName="Account Type " preSelect={preSelectAccoutType} isAstrix SelectData={AccountType} selected={(e) => onChangeHandlerType(e)} disabled={isInputDisabled} />
                            {(validation.accountTypeError !== '') ?
                                <span className="text-danger fontsize-13">{validation.accountTypeError}</span>
                                : null
                            }
                        </div>
                    </div>
                    <CheckBoxInput setHide={isHide} ChangeCheck={(e) => onChangeChecked(e)}>I confirm that the above account details belong to me</CheckBoxInput>
                    <PageFooter setDisabled={isDisabled} BtnName="I SUBMIT" TermsCondition="By confirming, I declare that these are my Bank details" ButtonClick={(e) => save_bank_details(e)} />
                </form>
            </Layout>
        </>
    );
}
export default BankDetail;