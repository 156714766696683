import React from "react";
import Waiting from "../Waiting";
 const AllowLocation = () => {
    const accessLocation = () => {
        var timeoutVal = 10 * 1000 * 1000;
        navigator.geolocation.watchPosition(displayPosition, displayError, { enableHighAccuracy: true, timeout: timeoutVal, maximumAge: 0 });
        window.location.reload();
    }

    function displayPosition(position) {
        sessionStorage.setItem("latitude" , position.coords.latitude);
        sessionStorage.setItem("longitude" , position.coords.longitude);
      }
    
      function displayError(error) {
        var errors = {
          1: 'Permission denied',
          2: 'Position unavailable',
          3: 'Request timeout'
        };
      }
    return (
        <>
         <div className="text-center" id="waitingLoader">
        </div>
        <div className="modal-center">
            <div className={`modal modal-background fade show`} id="exampleModal" tabIndex={-1} style={{ display: "block" }}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body pt-4">
                               <div className="text-center col-md-10 mx-auto">Please Allow Your Device Location.</div>
                            </div><br />
                            <div className="modal-footer justify-content-center border-0 pt-0">
                                <button type="button" className="btn btn-primary" onClick={() => accessLocation()}>CLOSE</button></div>
                        </div>
                    </div>
                </div>
            </div> 
        </>
    )
}
export default AllowLocation;