import React, { useState, useEffect, useRef, useContext, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import ChooseInput from "../common-components/ChooseInput";
import Heading from "../common-components/Heading";
import Layout from "../common-components/Layout";
import PageFooter from "../common-components/PageFooter";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from "../../config.json";
import axios from "axios";
import imageThumb from "../../images/image-thumb.png";
import { BureauContext } from "../Context/BureauContext";
import Webcam from "react-webcam";
import { encryptData } from "../../encrypt";

const KycSelfie = () => {
    const latitude = sessionStorage.getItem("latitude");
    const longitude = sessionStorage.getItem("longitude");
    const camera = sessionStorage.getItem("camera");
    const [locate_permission, setLocatePermission] = useState('denied');
    const [camera_permission, setcameraPermission] = useState('denied');

    useEffect( () => {
        navigator.geolocation.getCurrentPosition(function(position) {
         sessionStorage.setItem("latitude", position.coords.latitude);
         sessionStorage.setItem("longitude", position.coords.latitude);
        });
    
        navigator.permissions
        .query({ name: "camera" })
        .then((permissionStatus) => {
          if(permissionStatus.state === "granted"){
            sessionStorage.setItem("camera", 'granted');
          }else{
            sessionStorage.setItem("camera", 'denied');
          }
          permissionStatus.onchange = () => {
            if(permissionStatus.state === "granted"){
                sessionStorage.setItem("camera", 'granted');
            }else{
                sessionStorage.setItem("camera", 'denied');
            }
            window.location.reload();
          };
        });
    
      }, [camera_permission]);
    
    const navigate = useNavigate();
    const api_url = config.API_URL;
    const [disablebtn, setDisableBtn] = useState(false);
    const [panSrc, setPanImage] = useState("");
    const jwtToken = sessionStorage.getItem('token');
    const { popupData, setPopUpData, checkStateByMiddileware} = useContext(BureauContext);
    const webcamRef = useRef(null);

    const videoConstraints = {
        width: { min: 480 },
        height: { min: 720 },
        aspectRatio: 0.6666666667,
        facingMode: "user"
      };

    const capture = useCallback(() => {        
        const imageSrc = webcamRef.current.getScreenshot();
        const finalImages = resizeImage(imageSrc);
        //setPanImage(imageSrc);
      }, [webcamRef]);


      const resizeImage = function(base64Str) {
        var img = new Image();
        img.src = base64Str;
        var MIN_WIDTH = 350;
        var MIN_HEIGHT = 350;
        var content;
        img.onload = function(canvas,ctx) {
                var canvas = document.createElement('canvas');
                var ctx=canvas.getContext("2d");
                var width = this.width;
                var height = this.height;
                if (width < MIN_WIDTH) {
                    //alert(width);
                
                    height = MIN_HEIGHT;
                    width = MIN_WIDTH;
                    canvas.width = width;
                    canvas.height = height;
                    ctx.drawImage(img, 0, 0, width, height);
                    setPanImage(canvas.toDataURL("image/jpeg",0.5));
            
                }else if(height < MIN_HEIGHT){
                    //alert(height+'hh');
                
                    height = MIN_HEIGHT;
                    width = MIN_WIDTH;
                    canvas.width = width;
                    canvas.height = height;
                    ctx.drawImage(img, 0, 0, width, height);
                    setPanImage(canvas.toDataURL("image/jpeg",0.5));
                }else{
                    setPanImage(base64Str);
                }
            }          
    }

    const proceedSelfie = (e) => {
        checkStateByMiddileware();
        const img_arr = panSrc.split(";base64,");
        const selfieImage = img_arr[1];
        if(selfieImage !== '' && selfieImage !== null && selfieImage !== undefined){
            const request_data = {
                'selfie_photo' : selfieImage,
                'latitude' : latitude,
                'longitude' : longitude,
                'selfie_captured' : 'Y',
                'lat_long' : `${latitude},${longitude}`,
                'selfie_doc_type' : 'jpg'
            }
            
            const encrypted = encryptData(request_data);
            const headers = {
                'Authorization': `Bearer ${jwtToken}`,
                'Access-Control-Allow-Origin': '*',
                'latlong' : `${latitude},${longitude}`,
            }
            document.getElementById('waitingLoader').style.display = 'block';
            axios.post(api_url + `/update-selfie`, {encrypted : encrypted}, { headers })
                .then((response) => {
                    document.getElementById('waitingLoader').style.display = 'none';
                    if (response.data.status === 'success') {
                        toast.success(response.data.message, {
                            position: toast.POSITION.TOP_CENTER
                        });
                        navigate("/be-proof")
                    } else if (response.data.status === "expired") {
                        document.getElementById('waitingLoader').style.display = 'none';
                        setPopUpData({...popupData,is_bureau_checked : true});
                    }else if (response.data.status === 'hold') {
                        setPopUpData({...popupData,is_bank_hold : true});
                    }else if (response.data.status === "token") {
                        toast.error(response.data.errors, {
                            position: toast.POSITION.TOP_CENTER,
                        });
                        sessionStorage.clear();
                        navigate('/login')
                    }else if (response.data.status === 'fail' && response.data.is_rejected === true) {
                        document.getElementById('waitingLoader').style.display = 'none';
                        if(response.data.hasOwnProperty('data')){
                    sessionStorage.setItem('status_flag', response.data.data.appStatus.comment)
                }
                        navigate("/regrate");
                    }else {
                        document.getElementById('waitingLoader').style.display = 'none';
                        toast.error(response.data.errors, {
                            position: toast.POSITION.TOP_CENTER
                        });
                    }
                })
                .catch(error => {
                    document.getElementById('waitingLoader').style.display = 'none';
                    toast.error(error.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                });
        }else{
            toast.error('Please check and Allow your camera.', {
                position: toast.POSITION.TOP_CENTER
            });
        }
    }

    function reTake(){
        console.log("webcamRef",webcamRef);
        webcamRef.current = null;
        setPanImage('');
    }

    return (
        <>
            <ToastContainer />
            <div className="pt-3">
                <Heading Description="Let us help you build a future-ready company" BadgeDescription="Let us complete your KYC" user />
            </div>
            <Layout ImageSide="Product-intro">
                <h4 className="text-center mb-4">Please capture your clear Photo</h4>
                <div className="col-md-8 col-sm-12 mx-auto">
                { panSrc === null || panSrc === '' ?
                <>
                     <Webcam
                     audio={false}
                     mirrored={true}
                     height={400}
                     width={400}
                     ref={webcamRef}
                     screenshotFormat="image/jpeg"
                     videoConstraints={videoConstraints}
                 />
                <PageFooter BtnName="Capture" ButtonClick={() => { capture() }} />
                 </> : <img src={panSrc} height={400}
                     width={400}/>
                }
               
                </div>
                { panSrc !== null && panSrc !== '' ?
                <>
                <PageFooter setDisabled={disablebtn} BtnName="I SUBMIT" TermsCondition="I acknowledge that by submitting, U GRO may access the location, camera and the file uploaded from this device." ButtonClick={(e) => { proceedSelfie(e) }} />
                <div className="text-center">
                    <a href="javascript:;" onClick={() => reTake()} className="text-center fontsize-12 fw-bold">Re-Take</a>
                </div>
                </>
                : null}

            </Layout>
        </>
    );
}
export default KycSelfie;