import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { CheckBoxInput } from "../common-components/CheckInput";
import Heading from "../common-components/Heading";
import Input from "../common-components/Input";
import Layout from "../common-components/Layout";
import PageFooter from "../common-components/PageFooter";
import Select from "../common-components/Select";
import { PaymentMethod } from "../general-questions/SelectStaticData";
import { ToastContainer, toast } from 'react-toastify';
import config from "../../config.json";
import axios from "axios";
import { format } from 'date-fns';
import { BureauContext } from "../Context/BureauContext";
import { encryptData } from "../../encrypt";
import ErrorIcon from "../../images/error-icon.png";

const SetMandate = () => {
    const navigate = useNavigate();
    const dataFetchedRef = useRef(false);
    const api_url = config.API_URL;
    const usertoken = sessionStorage.getItem('token');
    const [maximumAmount, setMaximumAmount] = useState('');
    const [debitFrequency, setdebitFrequency] = useState('');
    const [purpose, setPurpose] = useState('');
    const startDate = format(new Date(),'dd-MM-yyyy');
    const [endDate, setEnd] = useState('');
    const [checkMode, setCheckMode] = useState(false);
    const [checkModeMessage, setModeMessage] = useState('');
    const [Getmode, setGetmode] = useState([]);
    const [payment_method, setPaymentMethod] = useState('');
    const [preSelectMode, setPreSelectMode] = useState('');
    const [isHide, setHide] = useState(true);
    const [isDisabled, setDisabled] = useState(true);
    const [agree_concent, setAgreeConcent] = useState(0);
    const {popupData, setPopUpData, checkStateByMiddileware, checkLocationPermission} = useContext(BureauContext);
    const latitude = sessionStorage.getItem("latitude");
    const longitude = sessionStorage.getItem("longitude");

    const getMandateData = async () => {
        const headers = {
            'Authorization': `Bearer ${usertoken}`,
            'latlong' : `${latitude},${longitude}`,
        }
        document.getElementById('waitingLoader').style.display = 'block';
       await fetch(api_url + `/set-mandate`, { headers }).then((res) => {
        return res.json();
       }).then((response) => {
                document.getElementById('waitingLoader').style.display = 'none';
                if (response.status === 'success') {
                    setMaximumAmount(response.data.loanSanction.loan_amount);
                    setdebitFrequency(response.data.loanSanction.frequency);
                    setPurpose(response.data.loanSanction.purpose_of_loan);
                    setEnd(response.data.loanSanction.purpose_of_loan);
                    setGetmode(response.data.mode);
                    const length = Object.keys(response.data.mode).length;
                    if(length==1)
                    {
                        const id =  response.data.mode[0].id;
                        setPaymentMethod(id);
                        if (id === 'all') {
                            setHide(false);
                        } else if(id === 'aadhar') {
                            setHide(false);
                        }else{
                            setHide(true);
                        }
                    }
                    if(response.data.checkMode === false){
                        setModeMessage(response.data.modeMessage);
                        setCheckMode(true);
                    }
                }else if(response.status === "token") {
                    toast.error(response.errors);
                    sessionStorage.clear();
                    navigate('/login');
                } else if(response.status === "expired") {
                    document.getElementById('waitingLoader').style.display = 'none';
                    setPopUpData({...popupData,is_bureau_checked : true});
                }else if(response.status === "hold") {
                    document.getElementById('waitingLoader').style.display = 'none';
                    setPopUpData({...popupData,is_bank_hold : true});
                }else if (response.status === 'fail' && response.is_rejected === true) {
                    document.getElementById('waitingLoader').style.display = 'none';
                    if(response.hasOwnProperty('data')){
                        sessionStorage.setItem('status_flag', response.data.appStatus.comment)
                    }
                    navigate("/regrate");
                } else {
                    document.getElementById('waitingLoader').style.display = 'none';
                    toast.error(response.errors,{
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            })
            .catch(error => {
                document.getElementById('waitingLoader').style.display = 'none';
                toast.error(error.message,{
                    position: toast.POSITION.TOP_CENTER
                });
            });
    }

    useEffect(() => {
        if(dataFetchedRef.current){
            return;
        }
        dataFetchedRef.current = true;
        getMandateData();
    }, [])

    function onChangeHandlerType(e) {
        setPaymentMethod(e.target.value);
        setPreSelectMode(e.target.value);
        if (e.target.value === 'all') {
            setHide(false);
        } else if(e.target.value === 'aadhar') {
            setHide(false);
        }else{
            setHide(true);
        }
    }

    function onChangeChecked(e) {
        setDisabled(true);
        setAgreeConcent(0);
        if (e.target.checked) {
            setDisabled(false);
            setAgreeConcent(1);
        }
    }

    function processMandate(e) {
        e.preventDefault();
        if(checkLocationPermission()){
            checkStateByMiddileware();
            let checkValidate = true;
            if (payment_method === '' || payment_method === undefined) {
                toast.error('Please Select payment method');
                checkValidate = false;
            }
            if (agree_concent !== 1) {
                toast.error('Please Select mandate confirm');
                checkValidate = false;
            }
            if (checkValidate) {
                const payload = {
                    payment_method: payment_method,
                    mandate_confirm: agree_concent,
                }
                const encrypted = encryptData(payload);
                const headers = {
                    'Authorization': `Bearer ${usertoken}`,
                    'latlong' : `${latitude},${longitude}`,
                }
                document.getElementById('waitingLoader').style.display = 'block';
                axios.post(api_url + `/process-mandate`, {encrypted : encrypted}, { headers })
                    .then((response) => {
                        document.getElementById('waitingLoader').style.display = 'none';
                        if (response.data.status === 'success') {
                            window.location.href = response.data.data.nachRegUrl;
                        }else if(response.data.status === "expired") {
                            document.getElementById('waitingLoader').style.display = 'none';
                            setPopUpData({...popupData,is_bureau_checked : true});
                        }else if(response.data.status === "hold") {
                            document.getElementById('waitingLoader').style.display = 'none';
                            setPopUpData({...popupData,is_bank_hold : true});
                        }else if(response.data.status === "token") {
                            toast.error(response.data.errors);
                            sessionStorage.clear();
                            navigate('/login');
                        }else if (response.data.status === 'fail' && response.data.is_rejected === true) {
                            document.getElementById('waitingLoader').style.display = 'none';
                            if(response.data.hasOwnProperty('data')){
                                sessionStorage.setItem('status_flag', response.data.data.appStatus.comment)
                            }
                            navigate("/regrate");
                        } else {
                            document.getElementById('waitingLoader').style.display = 'none';
                            toast.error(response.data.errors,{
                                position: toast.POSITION.TOP_CENTER
                            });
                        }
                    })
                    .catch(error => {
                        document.getElementById('waitingLoader').style.display = 'none';
                        toast.error(error.message,{
                            position: toast.POSITION.TOP_CENTER
                        });
                    });
            }else{
                toast.error("Please allow your device location", {
                    position: toast.POSITION.TOP_CENTER
                });
            }
        }
    }

    const closeErrorModal = async () => {
        setCheckMode(false);
    }
    return (
        <>
            <ToastContainer/>
            <div className="pt-3">
                <Heading Description="You have the support from U GRO in prospering your business" BadgeDescription="Let us set up your mandate for hassle free repayment" user />
            </div>
            <Layout ImageSide="Product-intro">
                <form onSubmit={(e) => processMandate(e)}>
                    <p>I hereby authorize U GRO CAPITAL Limited to debit my bank account as per the below mentioned and bank account details.</p>
                    <p>I understand that the bank where I have authorized the debit may levy mandate processing charges as mentioned in the bank's latest schedule of charges</p>
                    <div className="row">
                        <div className="col-md-6 col-sm-12">                           
                            <Input LabelName="Maximum Amount (In INR)" type="text" Id="Maximum_Amount" inputval={maximumAmount} placeholder="Maximum Amount (In INR)" disabled /> 
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <Input LabelName="Debit Frequency" type="text" Id="Debit_Frequency" inputval={debitFrequency} placeholder="Debit Frequency" disabled />
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <Input LabelName="Purpose" type="text" Id="Purpose" inputval={purpose} placeholder="Purpose" disabled />
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <Input LabelName="Start Date" type="text" Id="Start_Date" inputval={startDate} placeholder="Start Date" disabled />
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <Input LabelName="End Date" type="text" Id="End_Date" inputval="Until Cancelled" placeholder="End Date" disabled />
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <Select LabelName="Verify Using" preSelect={preSelectMode} selected={(e) => onChangeHandlerType(e)} SelectData = { Getmode } />
                            <p className="fontsize-12">Please select Debit card or Net banking option for a quick response</p>
                        </div>
                            {
                                checkModeMessage !== '' ? <p className="fontsize-12 text-danger">{checkModeMessage}</p> : null
                            }
                    </div>
                    <CheckBoxInput setHide={isHide} ChangeCheck={(e) => onChangeChecked(e)}>This is to confirm that the declaration has been carefully read, understood and prepared by me. I understand that I am authorized to cancel/amend this mandate by appropriately communicating the cancellation/amendment to UGRO CAPITAL</CheckBoxInput>
                    <PageFooter type="submit" setDisabled={isDisabled} BtnName="I SUBMIT" TermsCondition="By submitting, I set up the account I will repay from" ButtonClick={(e) => processMandate(e)}/>
                </form>
            </Layout>
            {
                checkMode === true ?
                    <div className="modal-center">
                    <div className={`modal modal-background fade show`} id="exampleModal" tabIndex={-1} style={{ display: "block" }}>
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                    <span className="text-center pt-4">
                                    <h3 className="text-danger">ERROR</h3>
                                    </span>
                                <div className="modal-body">
                                    <p className="text-justify">{checkModeMessage}</p>
                                    </div>
                                    <div className="modal-footer justify-content-center border-0 pt-0">
                                        <button type="button" className="btn btn-primary" onClick={() => {closeErrorModal(); }}>CLOSE</button></div>
                                </div>
                            </div>
                        </div>
                    </div> : null
            }
        </>
    );
}
export default SetMandate;