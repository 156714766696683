/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect } from "react";
import Logo from "../../images/dtocweb_images/delivered/urgo_Logo.png";
import { useState } from "react";
import config from "../../config.json";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Waiting from "../common-components/Waiting";
import DownImg from "../../images/down.gif";
import { GrFormClose } from "react-icons/gr";
import { BureauContext } from "../Context/BureauContext";
import { Language } from "../general-questions/SelectStaticData";
import ApprovedModal from "../common-components/modal/ApprovedModal";

const Heading = ({ Description, BadgeDescription, user }) => {
    const navigate = useNavigate();
    const api_url = config.API_URL;
    const currentURL = window.location.pathname;
    const hostname = window.location.origin;
    const [logined, setLogined] = useState('Guest');
    const user_data = sessionStorage.getItem('userData');
    const usertoken = sessionStorage.getItem('token');
    const [stepData, setStepData] = useState({});
    let userData = JSON.parse(user_data);
    const [isUser, setUser] = useState(user);
    const [isLogined, setisLogined] = useState(true);
    const [scroll, setScroll] = useState(5000);
    const termContent = sessionStorage.getItem('termContent');
    const lafId = sessionStorage.getItem('lafId');
    const locate_permission = sessionStorage.getItem("locate_permission");
    const latitude = sessionStorage.getItem('latitude');
    const longitude = sessionStorage.getItem('longitude');
    const [termHTML, setHtml] = useState({ __html: termContent });
    const { sanctionHtml, setsanctionHtml, logout, popupData, setPopUpData, checkStateByMiddileware} = useContext(BureauContext);
    useEffect(() => {
        let cururl =window.location.pathname;
        if(cururl=='/' || cururl=='/login'){
          setisLogined(false); 
        }
        if (usertoken !== '' && usertoken !== null) {
            checkStateByMiddileware();
        }
        if (userData) {
            if(userData.f_name !== null) {
                setUser(true);
                setLogined(userData.f_name);
            }
           else {
                setUser(true);
                setLogined('Guest');
            }
        }
    }, []);

    const recheckBureau = async () => {
        const headers = {
            'Authorization': `Bearer ${usertoken}`,
            'latlong' : `${latitude},${longitude}`
        };

        const requestOptions = {
            method : "post",
            headers:headers
        };
        document.getElementById('waitingLoader').style.display = 'block';
        await fetch(api_url+`/recheck-bureau`, requestOptions).then((res) => {
            return res.json();
        }).then((response) => {
            document.getElementById('waitingLoader').style.display = 'none';
            if(response.status === 'success'){
                sessionStorage.setItem('approve_amount', response.data.PreQualifiedAmount)
                setsanctionHtml({__html : response.data.sanction})
                setPopUpData({...popupData, is_bureau_checked : false})
                navigate(response.data.stage.lastActiveStage);
            }else if(response.status === 'fail' && response.is_rejected === true){
                navigate('/regrate');
            }else if(response.status === 'fail'){
                toast.error(response.errors);
            }else if(response.status === 'token'){
                sessionStorage.clear();
                navigate('/login');
            }else if(response.status === 'expired'){
                setPopUpData({...popupData, is_bureau_checked : true})
            }
        }).catch((err) => {
            document.getElementById('waitingLoader').style.display = 'none';
        })
    }

    const goToDown = (val) => {
        const scrollmodal = document.querySelector('.termsconditions');
        scrollmodal.scrollTo({
            top: val,
            left: 0,
            behavior: 'smooth'
        })
        setScroll(val + 2000);
    };

    const allowLocation = () => {
        var timeoutVal = 10 * 1000 * 1000;
        navigator.geolocation.watchPosition(displayPosition, displayError, { enableHighAccuracy: true, timeout: timeoutVal, maximumAge: 0 });
        window.location.reload(true);
    }

    function displayPosition(position) {
        sessionStorage.setItem("latitude" , position.coords.latitude);
        sessionStorage.setItem("longitude" , position.coords.longitude);
      }
    
      function displayError(error) {
        var errors = {
          1: 'Permission denied',
          2: 'Position unavailable',
          3: 'Request timeout'
        };
      }

      const changeLanguage = (e) => {
         const langUrl =  e.target.value;
         window.location.href = langUrl;
      }
    

    return (
        <>
            <ToastContainer />
            <div className="container-fluid">
                <div className="row justify-content-between align-items-center">
                    <div className="col-auto"><a href="#"><img src={Logo} alt="Logo" height={50} className="" /></a></div>
                    {isUser &&
                        <>
                            <div className="col-md-8 col-sm-12 steps2 mx-auto">
                                <ol className="steps2 col-md-12 col-sm-12  mx-auto" id="progress-desktop">
                                    {Object.keys(popupData.stepData).length > 0 && popupData.stepData.map((key, index) => {
                                        return (
                                            <li className={`step2 top-8 ${key.status} ${popupData.stepData.every(val => val.status === 'is-complete') && 'top-8'}`} data-step={index + 1} key={index}>
                                                <span id="content-progress" className={`progress-tag  ${(key.status !== 'is-active') ? "d-none" : " mt-2 d-block"}`}>{key.stageName}</span>
                                            </li>
                                        );
                                    })}
                                </ol>
                            </div>
                            <div className="col-auto">
                                <div className="dropdown">
                                    {isLogined && 
                                     <a className="btn logout dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                        Hello <span className="text-orange fw-semibold">{logined}</span>
                                     </a>
                                    }
                                    <ul className="dropdown-menu">
                                        <li><Link className="dropdown-item">{lafId}</Link></li>
                                        <li><Link className="dropdown-item" to="" onClick={() => logout()}>Logout</Link></li>
                                    </ul>
                                </div>
                                <div style={{'text-align': 'right', 'margin-right' : '24px' }}>{popupData.laf}</div>
                            </div>
                            </>
                            }
                {/* <div className="col-auto">
                    <select onChange={(e) => { changeLanguage(e);}}>
                        {
                            Language.map((lang)=>{
                                return ( <option value={lang.id} selected={hostname == lang.id}>{lang.Value}</option> )
                            })
                        }
                        
                    </select>
                </div> */}
                </div>
            </div>
            <h4 className="text-center my-3">{Description}</h4>
            <h5 className="leveltitle">{BadgeDescription}</h5>
            <div className="text-center" id="waitingLoader">
                <Waiting />
            </div>
            {currentURL !== '/regrate' && popupData.modal ?
                <div className="modal-center">
                    <div className={`modal modal-background fade show`} id="exampleModal" tabIndex={-1} style={{ display: "block" }}>
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-body pt-4">
                                        {popupData.isApiType === 'rejected' && <div className="text-center col-md-10 mx-auto">{popupData.stageSMS}</div>}
                                    </div><br />
                                    {popupData.isApiType === 'approved' &&
                                    <div>
                                        <div className="text-center col-md-10 mx-auto"><b>{popupData.stageSMS} {popupData.mobileapp}</b></div>
                                        <div className="col-md-10 mx-auto lh-lg">
                                            <div>LAN - {popupData.lan}</div>
                                            <div>CRN - {popupData.crn}</div>
                                            <p>Loan Amount - {popupData.loanAmount}</p>
                                        </div>
                                        </div>
                                    }
                                    <div className="modal-footer justify-content-center border-0 pt-0">
                                        <button type="button" className="btn btn-primary" onClick={() => logout()}>CLOSE</button>                                </div>
                                </div>
                            </div>
                        </div>
                    </div> : null
            }

            {currentURL !== '/regrate' && popupData.is_bureau_checked ?
               <div className={`modal modal-background  ${popupData.is_bureau_checked && 'fade show'}`} id="exampleModal" tabIndex={-1} style={{ display: "block" }}>
               <div className="modal-dialog modal-xl">
                   <div className="modal-content">
                       <div className="modal-header border-0">
                           <h1 className="modal-title fs-5 cursor text-center col-md-12" id="exampleModalLabel">Terms and Conditions</h1>
                           <div className="d-flex align-items-center" style={{ right: '35px', position: 'relative' }}>
                               <a onClick={() => goToDown(scroll)} > <img src={DownImg} alt="Down arrow" width={25} className="cursor" /></a>
                               <p className="cursor mb-0 d-flex" >
                                   <GrFormClose className="fs-5" />
                               </p>
                           </div>
                       </div>
                       <div className="modal-body termsconditions">
                           <div dangerouslySetInnerHTML={termHTML}></div>
                           {termHTML !== '' && 
                            <div className="py-3 text-center">
                                <button type="button" className="btn btn-primary" onClick={() => { recheckBureau() }}>Accept</button>
                            </div>
                           }
                       </div>

                   </div>

               </div>
           </div> :null
            }

            {currentURL !== '/regrate' && popupData.is_bank_hold ?
                <div className="modal-center">
                    <div className={`modal modal-background fade show`} id="exampleModal" tabIndex={-1} style={{ display: "block" }}>
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-body pt-4">
                                       <div className="text-center col-md-10 mx-auto">We are reviewing your application, please allow us two working days.</div>
                                    </div><br />
                                    <div className="modal-footer justify-content-center border-0 pt-0">
                                        <button type="button" className="btn btn-primary" onClick={() => logout()}>CLOSE</button></div>
                                </div>
                            </div>
                        </div>
                    </div> : null
            }
        </>
    );
}
export default Heading;