/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import Tooltip from "../common-components/Tooltip";
import { CustomButton } from "../common-components/Buttons";
import Heading from "../common-components/Heading";
import Layout from "../common-components/Layout";
import { toast } from "react-toastify";
import config from "../../config.json";
import { BureauContext } from "../Context/BureauContext";

const KycDocument = () => {
    const navigate = useNavigate();
    const token = sessionStorage.getItem('token');
    const api_url = config.API_URL;
    const {popupData, setPopUpData, checkStateByMiddileware, checkLocationPermission} = useContext(BureauContext);
    const latitude = sessionStorage.getItem("latitude");
    const longitude = sessionStorage.getItem("longitude");

    const headers = {
            "Authorization": `Bearer ${token}`,
            'latlong' : `${latitude},${longitude}`,
    }
    const proceedKycDocument = () => {
        if(checkLocationPermission()){
            checkStateByMiddileware();
            const requestOptions = {
                method : "POST",
                headers : headers,
            };
            document.getElementById('waitingLoader').style.display = 'block';
            fetch(api_url+`/store-kyc-document`, requestOptions).then((res) => {
                return res.json();
            }).then((result) => {
                document.getElementById('waitingLoader').style.display = 'none';
                if (result.status === "success") {
                    toast.success(result.message);
                    navigate('/kyc-detail');
                }else if(result.status === "fail"){
                    toast.error(result.errors);
                }else if(result.status === "expired") {
                    document.getElementById('waitingLoader').style.display = 'none';
                    setPopUpData({...popupData,is_bureau_checked : true});
                }else if(result.status === "hold") {
                    document.getElementById('waitingLoader').style.display = 'none';
                    setPopUpData({...popupData,is_bank_hold : true});
                }else if(result.status === "token"){
                    toast.error(result.errors);
                    sessionStorage.clear();
                    navigate('/login')
                }
            }).catch((err) => {
                document.getElementById('waitingLoader').style.display = 'none';
            })
        }else{
            toast.error("Please allow your device location", {
                position: toast.POSITION.TOP_CENTER
            });
        }
    }
    return (
        <>
            <div className="pt-3">
                <Heading Description="U GRO Capital can help you with your business finance" BadgeDescription="Request you to please take a note" user />
            </div>
            <Layout ImageSide="Product-intro">
            <h4 className="text-center mb-4">Product Features</h4>
                <ol className="col-md-11 mx-auto">
                   
                    {
                    Product.map((key, index) => {
                        return (
                            <>
                                <li key={index}>{key}</li>
                            </>
                        );
                    })}
                </ol>
                <h4 className="text-center mb-4">Documents Required</h4>
                <ol className="col-md-11 mx-auto">
                    {Document.map((key,index)=>{
                        return(
                            <li key={index}>{key}</li>
                        );
                    })}
                    <li>Business Entity Proof <Tooltip type="KYC" /> </li>
                </ol>
                <p className="col-md-11 mx-auto lh-lg fontsize-12 fw-bold" >Note: Please use JPG,JPEG or PNG format with a maximum file size of 4MB</p>
                <div className="text-center mt-4">
                    <CustomButton btnname="I UNDERSTAND" btn_col="col-md-4" clickBtn={() => { proceedKycDocument() }} />
                </div>

            </Layout>
        </>
    );
}
export default KycDocument;

export const Document = [
    "Clear copy of PAN Card", "Clear copy of unmasked AADHAAR card (Front and Back of it)", "Clear and latest photograph of proprietor", "Clear and latest photograph of Entity with visible business name", "UDYAM Number"
];

export const Product = ["Instant and On-tap credit solution", "End to End digital journey with no need of physical paper", "A dedicated GRO X App to help you make and receive Real time, Secure and Safe payments via digital modes like UPI (QR, VPA) and Bank transfers (IMPS, NEFT, RTGS)", "Interest will be charged basis utilisation", "Option to convert the outstanding into affordable EMIs"
];