/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Heading from "../common-components/Heading";
import Layout from "../common-components/Layout";
import PageFooter from "../common-components/PageFooter";
import AddressCard from "./components/AddressCard";
import { Link } from 'react-router-dom';
import config from "../../config.json";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import { BureauContext } from "../Context/BureauContext";

const Address = () => {

    const navigate = useNavigate();
    const [address, setAddress] = useState(false);
    const [isDisabled, setDisabled] = useState(true);
    const [isInput, setIsInput] = useState('checked');
    const [address1, setAddress1] = useState('');
    const [address2, setAddress2] = useState('');
    const [address3, setAddress3] = useState('');
    const [pincode, setPincode] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [peraddress1, setPerAddress1] = useState('');
    const [peraddress2, setPerAddress2] = useState('');
    const [peraddress3, setPerAddress3] = useState('');
    const [perpincode, setPerPincode] = useState('');
    const [percity, setPerCity] = useState('');
    const [perstate, setPerState] = useState('');
    const [custaddress1, setcustAddress1] = useState('');
    const [custaddress2, setcustAddress2] = useState('');
    const [custaddress3, setcustAddress3] = useState('');
    const [custpincode, setcustPincode] = useState('');
    const [isRequired, setisRequired] = useState(false);
    const [isaddr2Required, setisaddr2Required] = useState(false);
    const [isaddr3Required, setisaddr3Required] = useState(false);
    const [iscityRequired, setiscityRequired] = useState(false);
    const [isstateRequired, setisstateRequired] = useState(false);
    const [ispinRequired, setispinRequired] = useState(false);
    const [custcity, setcustCity] = useState('');
    const [custstate, setcustState] = useState('');
    const usertoken = sessionStorage.getItem('token');
    const [disabled, setdisabled] = useState(true);
    const [redioAttr, setRedioAttr] = useState(0);
    const [isRead, setIsRead] = useState(false);
    const api_url = config.API_URL;
    const [validation, setValidationError] = useState({ custAddress1Error: '', custAddress2Error: '', custAddress3Error: '', custcityError: '', custstateError: '', custpinError: "" });
    const {popupData, setPopUpData, checkStateByMiddileware} = useContext(BureauContext);
    useEffect(() => {
        checkStateByMiddileware();
        const headers = {
            'Authorization': `Bearer ${usertoken}`
        }
        document.getElementById('waitingLoader').style.display = 'block';
        axios.get(api_url + `/address`, { headers })
            .then((response) => {
                document.getElementById('waitingLoader').style.display = 'none';
                if (response.data.status == 'success') {
                    setAddress1(response.data.data.businessAddress.address.address_1);
                    setAddress2(response.data.data.businessAddress.address.address_2);
                    setAddress3(response.data.data.businessAddress.address.address_3);
                    setState(response.data.data.businessAddress.state);
                    setCity(response.data.data.businessAddress.city);
                    setPincode(response.data.data.businessAddress.pincode);
                    setPerAddress1(response.data.data.perAddress.address.address_1);
                    setPerAddress2(response.data.data.perAddress.address.address_2);
                    setPerAddress3(response.data.data.perAddress.address.address_3);
                    setPerState(response.data.data.perAddress.state);
                    setPerCity(response.data.data.perAddress.city);
                    setPerPincode(response.data.data.perAddress.pincode);
                }else if (response.data.status === 'hold') {
                    setPopUpData({...popupData,is_bank_hold : true});
                }else if (response.data.status === 'fail' && response.data.is_rejected === true) {
                    document.getElementById('waitingLoader').style.display = 'none';
                    if(response.data.hasOwnProperty('data')){
                        sessionStorage.setItem('status_flag', response.data.data.appStatus.comment)
                    }
                    navigate("/regrate");
                } else {
                    document.getElementById('waitingLoader').style.display = 'none';
                    toast.error(response.data.errors, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            })
            .catch(error => {
                document.getElementById('waitingLoader').style.display = 'none';
                toast.error(error.message, {
                    position: toast.POSITION.TOP_CENTER
                });
            });
    }, []);

    function setNewAddress() {
        if (address) {
            setAddress(false);
            setDisabled(true);
            setIsInput('');
        } else {
            setAddress(true);
            setDisabled(false);
            setIsInput('checked');
            setRedioAttr("3");
            setcustCity('');
            setcustState('');
            setcustPincode('');
        }
    }

    function redioClickBtn(e) {
        setDisabled(false);
        setRedioAttr(e.target.attributes.getNamedItem('data_id').value);
        let rediobtn = e.target.attributes.getNamedItem('data_id').value;
        if (rediobtn !== "3") {
            setAddress(false);
            setcustAddress1('');
            setcustAddress2('');
            setcustAddress3('');
            setcustPincode('');
            setcustCity('');
            setcustState('');
        }
    }

    function addressSubmit(e) {
        let checkValidate = true;
        switch (redioAttr) {
            case "1":
                if (address1 === '' || address1 === undefined) {
                    toast.error('Enter address 1');
                    checkValidate = false;
                }
                if (address2 === '' || address2 === undefined) {
                    toast.error('Enter address 2');
                    checkValidate = false;
                }
                if (address3 === '' || address3 === undefined) {
                    toast.error('Enter address 3');
                    checkValidate = false;
                }
                if (city === "" || city === undefined) {
                    toast.error('Enter City');
                    checkValidate = false;
                }
                if (state === "" || state === undefined) {
                    toast.error('Enter State');
                    checkValidate = false;
                }
                if (pincode === "" || pincode === undefined) {
                    toast.error('Enter Pin Code');
                    checkValidate = false;
                }
                if (checkValidate) {
                    submitbusinessAddress(address1, address2, address3, pincode, city, state);
                }
                break;
            case "2":
                if (peraddress1 === '' || peraddress1 === undefined) {
                    toast.error('Enter address 1');
                    checkValidate = false;
                }
                if (peraddress2 === '' || peraddress2 === undefined) {
                    toast.error('Enter address 2');
                    checkValidate = false;
                }
                if (peraddress3 === '' || peraddress3 === undefined) {
                    toast.error('Enter address 3');
                    checkValidate = false;
                }
                if (percity === '' || percity === undefined) {
                    toast.error('Enter City');
                    checkValidate = false;
                }
                if (perstate === '' || perstate === undefined) {
                    toast.error('Enter State');
                    checkValidate = false;
                }
                if (perpincode === '' || perpincode === undefined) {
                    toast.error('Enter Pin Code');
                    checkValidate = false;
                }
                if (checkValidate) {
                    submitbusinessAddress(peraddress1, peraddress2, peraddress3, perpincode, percity, perstate);
                }
                break;
            case "3":
                let checkerror = {}
                if (custaddress1 === '' || custaddress1 === undefined) {
                    checkerror['custAddress1Error'] = "Please enter address 1";
                    checkValidate = false;
                }
                if (custaddress2 === '' || custaddress2 === undefined) {
                    checkerror['custAddress2Error'] = "Please enter address 2";
                    checkValidate = false;
                }
                if (custaddress3 === '' || custaddress3 === undefined) {
                    checkerror['custAddress3Error'] = "Please enter address 3";
                    checkValidate = false;
                }
                if (custcity === '' || custcity === undefined) {
                    checkerror['custcityError'] = "Please enter city";
                    checkValidate = false;
                }
                if (custstate === '' || custstate === undefined) {
                    checkerror['custstateError'] = "Please enter State";
                    checkValidate = false;
                }
                if (custpincode === '' || custpincode === undefined) {
                    checkerror['custpinError'] = "Please enter Pincode";
                    checkValidate = false;
                }
                setValidationError({ ...validation, ...checkerror });

                if (validation.custAddress1Error !== '') {
                    setisRequired(true);
                }
                if (validation.custAddress2Error !== '') {
                    setisaddr2Required(true);
                }
                if (validation.custAddress3Error !== '') {
                    setisaddr3Required(true);
                }
                if (validation.custcityError !== '') {
                    setiscityRequired(true);
                }
                if (validation.custstateError !== '') {
                    setisstateRequired(true);
                }
                if (validation.custpinError !== '') {
                    setispinRequired(true);
                }

                if (checkValidate) {
                    submitbusinessAddress(custaddress1, custaddress2, custaddress3, custpincode, custcity, custstate);
                }
                break;
            default:
            // return 'foo';
        }
    }

    function submitbusinessAddress(address1, address2, address3, pin, city, state) {
        checkStateByMiddileware();
        const payload = {
            line_1: address1,
            line_2: address2,
            line_3: address3,
            city: city,
            state: state,
            pincode: pin,
        }
        const headers = {
            'Authorization': `Bearer ${usertoken}`,
            'Content-Type': 'application/json',
        }
        document.getElementById('waitingLoader').style.display = 'block';
        axios.post(api_url + `/add-user-addr`, payload, { headers }).then((response) => {
            document.getElementById('waitingLoader').style.display = 'none';
            if (response.data.status == 'success') {
                toast.success(response.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
                navigate("/bank-detail")
            }else if (response.data.status === 'hold') {
                setPopUpData({...popupData,is_bank_hold : true});
            }else if(response.data.status == 'regrate'){
                document.getElementById('waitingLoader').style.display = 'none';
                if(response.data.hasOwnProperty('data')){
                    sessionStorage.setItem('status_flag', response.data.data.appStatus.comment)
                }
                navigate('/regrate');
            } else if(response.data.status === "expired") {
                document.getElementById('waitingLoader').style.display = 'none';
                setPopUpData({...popupData,is_bureau_checked : true}); 
            }
            else{
                document.getElementById('waitingLoader').style.display = 'none';
                toast.error(response.data.errors, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
        }).catch(error => {
                document.getElementById('waitingLoader').style.display = 'none';
                toast.error(error.message,
                    {
                        position: toast.POSITION.TOP_CENTER
                    });
            });
    }

    const onChangeCustPin = (e) => {
        const value = e.target.value.replace(/\D/g, "");
        const limit = 6;
        setcustPincode(value.slice(0, limit));
        if (e.target.value.length == 6) {
            removeErrorSms(e, "custpinError");
        }
    }

    const updatePincode = (e) => {
        checkStateByMiddileware();
        if (e.target.value.length === 6) {
            const payload = {
                pincode: custpincode,
            }
            const headers = {
                'Authorization': `Bearer ${usertoken}`,
                'Content-Type': 'application/json',
            }
            document.getElementById('waitingLoader').style.display = 'block';
            axios.post(api_url + `/pincode`, payload, { headers }).then((response) => {
                document.getElementById('waitingLoader').style.display = 'none';
                if (response.data.status === 'success') {
                    let checkerror = {}
                    setcustCity(response.data.data.city);
                    setcustState(response.data.data.state);
                    checkerror['custcityError'] = "";
                    checkerror['custstateError'] = "";
                    setiscityRequired(false);
                    setisstateRequired(false);
                    setIsRead(true);
                }else if (response.data.status === 'hold') {
                    setPopUpData({...popupData,is_bank_hold : true});
                }
                else if(response.data.status === "token") {
                    toast.error(response.data.errors);
                    sessionStorage.clear();
                    navigate('/login');
                } else {
                    document.getElementById('waitingLoader').style.display = 'none';
                    toast.error(response.data.errors, {
                        position: toast.POSITION.TOP_CENTER
                    });
                    setcustCity('');
                    setcustState('');
                    setIsRead(false);
                }
            })
                .catch(error => {
                    document.getElementById('waitingLoader').style.display = 'none';
                    setcustCity('');
                    setcustState('');
                    toast.error(error.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                    setIsRead(false);
                });
        } else {
            setcustCity('');
            setcustState('');
            setIsRead(false);
        }
    }

    function updatecustAddress1(e) {
        setcustAddress1(e.target.value);
        removeErrorSms(e, "custAddress1Error");
    }

    function updatecustAddress2(e) {
        setcustAddress2(e.target.value);
        removeErrorSms(e, "custAddress2Error");
    }

    function updatecustAddress3(e) {
        setcustAddress3(e.target.value);
        removeErrorSms(e, "custAddress3Error");
    }
    function updatecustState(e){
        setcustState(e.target.value);
        removeErrorSms(e, "custstateError");  
    }
    function updatecustcity(e){
        setcustCity(e.target.value);
        removeErrorSms(e, "custcityError");  
    }
    function removeErrorSms(e, fielName) {
        let checkerror = {}
        if (e.target.value.length > 0) {
            checkerror[fielName] = "";
            setisRequired(false);
            setisaddr2Required(false);
            setisaddr3Required(false);
            setiscityRequired(false);
            setisstateRequired(false);
            setispinRequired(false);
            setValidationError({ ...validation, ...checkerror });
        }
    }
    return (
        <>
            <ToastContainer />
            <div className="pt-3">
                <Heading Description="Let us help you build a future-ready company" BadgeDescription="Let us know about your Addresses" user />
            </div>
            <Layout ImageSide="Address">
                <div className="col-md-10 mx-auto col-sm-12">
                    <AddressCard AddressType="Business Address" id="Business" address1={address1} disabled={disabled} address2={address2} address3={address3} pincode={pincode} city={city} state={state} radioname="is_communication" redioClick={(e) => redioClickBtn(e)} setRedioAttr={"1"} RadioLabel="This is also my communication address" />
                    <AddressCard AddressType="Permanent Residence Address " address1={peraddress1} disabled={disabled} address2={peraddress2} address3={peraddress3} id="Permanent" pincode={perpincode} city={percity} state={perstate} redioClick={(e) => redioClickBtn(e)} setRedioAttr={"2"} radioname="is_communication" RadioLabel="This is also my communication address" />
                    <a onClick={() => setNewAddress()} className="text-decoration-none cursor">I want to add my communication address</a>
                    {address && <AddressCard AddressType="Permanent Residence Address" upPincode={(e) => updatePincode(e)} changeInputAddress1={(e) => { updatecustAddress1(e) }} isRequired={isRequired} changeInputAddress2={(e) => { updatecustAddress2(e) }} isaddr2Required={isaddr2Required} changeInputAddress3={(e) => { updatecustAddress3(e) }} isaddr3Required={isaddr3Required} pincode={custpincode} isRead={isRead} changePincode={(e) => onChangeCustPin(e)} ispinRequired={ispinRequired} city={custcity} iscityRequired={iscityRequired} state={custstate} isstateRequired={isstateRequired} changeState={(e) => { updatecustState(e) }} changeCity={(e) => { updatecustcity(e) }} id="custom" redioClick={(e) => redioClickBtn(e)} radioname="is_communication" isInput={isInput} setRedioAttr={"3"} RadioLabel="This is also my communication address" />}
                </div>
                <PageFooter setDisabled={isDisabled} BtnName="I CONFIRM" TermsCondition="By Confirming, I declare my address" ButtonClick={(e) => { addressSubmit(e) }} />
            </Layout>
        </>
    );
}
export default Address;