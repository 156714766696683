import React from "react";

export const CheckBoxInput = ({children,ChangeCheck, setHide, isChecked, inputVal}) => {
    return (
        <>
            <div className="form-check mt-1">
                <input className="form-check-input cursor" checked={isChecked} disabled={setHide} onChange={ChangeCheck} type="checkbox" value="" id="flexCheckDefault" />
                <label className="form-check-label" htmlFor="flexCheckDefault">
                    {children}
                </label>
            </div>
        </>
    );
}

export const RadioInput = ({children, radioname, redioClick, setRedioAttr, inputVal, id, isChecked}) =>{
    return(
        <>
        <div className="form-check mt-1">
                <input className="form-check-input cursor" checked={isChecked} data_id={setRedioAttr} onClick={redioClick} name={radioname} type="radio" value={inputVal} id={id} />
                <label className="form-check-label" htmlFor="flexCheckDefault">
                    {children}
                </label>
            </div>
        </>
    );
}