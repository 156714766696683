import React, { useState, useEffect } from "react";
import { AiFillStar } from "react-icons/ai";
import config from "../../../config.json";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { encryptData } from "../../../encrypt";

const Rating = ({ratting,hovers}) => {
    
    const [rating, setRating] = useState(5);
    const [hover, setHover] = useState(5);
    const api_url = config.API_URL;
    const navigate = useNavigate();
    const usertokenk = sessionStorage.getItem('token');
    const latitude = sessionStorage.getItem("latitude");
    const longitude = sessionStorage.getItem("longitude");

    const updateRating = (index) => {
        setRating(index);
        const headers = {
            'Authorization': `Bearer ${usertokenk}`,
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
           'latlong' : `${latitude},${longitude}`,
        }
        const payload = {
            rating: index,
        }
        const encrypted = encryptData(payload);
        document.getElementById('waitingLoader').style.display = 'block';
        axios.post(api_url + `/submit_rating`, {encrypted : encrypted}, { headers })
            .then((response) => {
                if (response.data.status === 'success') {
                    document.getElementById('waitingLoader').style.display = 'none';
                    toast.success(response.data.message,{
                        position: toast.POSITION.TOP_CENTER
                    });
                }
                else if(response.data.status === "token") {
                    document.getElementById('waitingLoader').style.display = 'none';
                    toast.error(response.data.errors);
                    sessionStorage.clear();
                    navigate('/login');
                }
                else {
                    document.getElementById('waitingLoader').style.display = 'none';
                    toast.error(response.data.errors,{
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            })
            .catch(error => {
                document.getElementById('waitingLoader').style.display = 'none';
                toast.error(error.message,{
                    position: toast.POSITION.TOP_CENTER
                });
            });
    }
    return (
        <>
            <ToastContainer />
            <div className="star-rating text-center">
                {[...Array(5)].map((star, index) => {
                    index += 1;
                    return (
                        <i
                            type="button"
                            key={index}
                            className={index <= (hover || rating) ? "on" : "off"}
                            onClick={(e) => updateRating(index)}
                            onMouseEnter={() => setHover(index)}
                            onMouseLeave={() => setHover(rating)}

                        ><AiFillStar className="fs-1 star" /></i>
                    );
                })}
            </div>
        </>
    );
}
export default Rating;