import React from "react";
import register_Image from "../../images/dtocweb_images/delivered/Level__Level-1.png";
import  { AddressImg, LoginImg,ProductionImg, SummaryImg, Img404 } from "./StaticData";

const Layout = ({ children, ImageSide }) => {
    return (
        <>
            <div className="container my-5">
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        {ImageSide === "Register" && <img src={register_Image} alt="" className="img-fluid" />}

                        {ImageSide === "Login" &&
                           <LoginImg />
                        }
                        {ImageSide === "Product-intro" &&
                            <ProductionImg />
                        }
                        {ImageSide === "Address" && 
                            <AddressImg />
                        }
                        {
                            ImageSide === "Summary" && <SummaryImg />
                        }
                        {
                            ImageSide === "404" && <Img404 />
                        }
                    </div>
                    <div className="col-md-6 col-sm-12 my-auto">
                        {children}
                    </div>
                </div>
            </div>
        </>
    );
}
export default Layout;