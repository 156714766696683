import React from "react";
import CryptoJS from 'crypto-js';
import hexToBinary  from 'hex-to-binary';
import config from './config.json';
const Key = config.Key; 
const IV = config.IV;
export function encryptData(data) {
    var val = CryptoJS.enc.Utf8.parse(JSON.stringify(data));
    var encrypted = CryptoJS.AES.encrypt(val, CryptoJS.enc.Utf8.parse(Key), { iv: CryptoJS.enc.Utf8.parse(IV)}).toString();
    var b64 = CryptoJS.enc.Base64.parse(encrypted).toString();
    return encrypted;
}

export function decryptData(cipher) {
    const bytes = CryptoJS.AES.decrypt({
        ciphertext: CryptoJS.enc.Hex.parse(cipher)
    }, CryptoJS.enc.Utf8.parse(Key), {iv: CryptoJS.enc.Utf8.parse(IV), format: CryptoJS.format.Hex});
    var plainText = bytes.toString(CryptoJS.enc.Utf8);
    return plainText;
}