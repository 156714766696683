import React, { useState, useRef, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import ChooseInput from "../common-components/ChooseInput";
import Heading from "../common-components/Heading";
import Layout from "../common-components/Layout";
import Input from "../common-components/Input";
import PageFooter from "../common-components/PageFooter";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from "../../config.json";
import axios from "axios";
import imageThumb from "../../images/image-thumb.png";
import { BureauContext } from '../Context/BureauContext';
import { encryptData } from '../../encrypt';

const AadhaarUpload = () => {
    const navigate = useNavigate();
    const [disablebtn, setDisableBtn] = useState(true);
    const api_url = config.API_URL;
    const [aadhaar_no, setAadhaarNo] = useState('');
    const [aadhaar_name, setAadhaarName] = useState('');
    const [aadhaar_dob, setAadhaarDob] = useState('');
    const [aadhaar_address, setAadhaarAddress] = useState('');
    const [ocr_type, setOcrType] = useState('');
    const [ishide, setHide] = useState('hidden');
    const [isReUpload, setReUpload] = useState(false);
    const [src, setSrc] = useState(imageThumb);
    const [srcback, setSrcBack] = useState(imageThumb);
    const inputRef = useRef(null);
    const inputBackRef = useRef(null);
    const [isSelectedImg, setSelectedImg] = useState(false);
    const [isSelectedBackImg, setSelectedBackImg] = useState(false);
    const jwtToken = sessionStorage.getItem('token');
    const [frontpdf, setFrontPDF] = useState('');
    const [backpdf, setBACKPDF] = useState('');
    const [checkBack, setcheckBack] = useState(false);
    const [aadhaarFrontImage, setBase64] = useState('');
    const [aadhaarBackImage, setBackBase64] = useState('');
    const {popupData, setPopUpData, checkStateByMiddileware, checkLocationPermission} = useContext(BureauContext);
    const locate_permission = sessionStorage.getItem('locate_permission');
    const latitude = sessionStorage.getItem("latitude");
    const longitude = sessionStorage.getItem("longitude");
    
    const onFileChangeHandler = (e) => {
        var reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onloadend = function(){
            const img_arr = reader.result.split(";base64,");
            const image = img_arr[1];
            setBase64(image);
            setSrc(URL.createObjectURL(e.target.files[0]));
            var value = e.target.files[0].size;
            var extenstion = e.target.files[0].type;
            var file_size = value / 1024;
            let result = true;
            if (extenstion == "application/pdf" && file_size > 1024 * 2) {
                toast.error("Please try with an acceptable file size", {
                    position: toast.POSITION.TOP_CENTER
                });
                setReUpload(true);
                result = false;
            } else if (file_size > 1024 * 4) {
                toast.error("Please try with an acceptable file size", {
                    position: toast.POSITION.TOP_CENTER,
                })
                setReUpload(true);
                result = false;
            }
    
            if(locate_permission === "denied"){
                toast.error("Please allow your device location", {
                    position: toast.POSITION.TOP_CENTER,
                })
                setReUpload(true);
                result = false;
            }
            var extenstion = e.target.files[0].name.split('.').pop();
            const request_data = {'name_of_ocr': 'AADHAAR_OCR', 'ocr_doc' : image, 'doc_type' : extenstion};
            if(result){
                checkStateByMiddileware();
                document.getElementById('waitingLoader').style.display = 'block';
                ajaxAadhaarFront(request_data, extenstion); 
            }
        }
        
    }

function ajaxAadhaarFront (request_data, extenstion){
        const encrypted = encryptData(request_data);
        const headers = {
            'Authorization': `Bearer ${jwtToken}`,
            'latlong' : `${latitude},${longitude}`,
        }
        axios.post(api_url + `/doc-ocr`, {encrypted : encrypted}, { headers })
        .then((response) => {
            document.getElementById('waitingLoader').style.display = 'none';
            if (response.data.status == 'success') {
                setSelectedImg(true);
                if (typeof response.data.data.aadhaar_name === "undefined" || typeof response.data.data.aadhaar_name === "") {
                    toast.error('Please upload valid Aadhaar Front Image', {
                        position: toast.POSITION.TOP_CENTER,
                    });
                    setSelectedImg(false);
                }else {
                    toast.success(response.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                    setAadhaarNo(response.data.data.aadhaar_no);
                    setAadhaarName(response.data.data.aadhaar_name);
                    setAadhaarDob(response.data.data.aadhaar_dob);
                    sessionStorage.setItem('aadhaar_number', response.data.data.aadhaar_no)
                    setReUpload(true);
                    setSelectedImg(true);
                    if(isSelectedBackImg){
                        setDisableBtn(false);
                    }
                    if (extenstion == "application/pdf") {
                        setcheckBack(false);
                        setFrontPDF(response.data.data.aadhaar_name);
                    }
                }
            }else if (response.data.status === 'hold') {
                reupload();
                setPopUpData({...popupData,is_bank_hold : true});
            }else if (response.data.status === "expired") {
                document.getElementById('waitingLoader').style.display = 'none';
                setPopUpData({...popupData,is_bureau_checked : true});
                reupload();
            }else if (response.data.status === "token") {
                toast.error(response.data.errors, {
                    position: toast.POSITION.TOP_CENTER,
                });
                sessionStorage.clear();
                navigate('/login')
            }else if(response.data.status === 'fail' && response.data.is_rejected === true) {
                document.getElementById('waitingLoader').style.display = 'none';
                if(response.data.hasOwnProperty('data')){
                    sessionStorage.setItem('status_flag', response.data.data.appStatus.comment)
                }
                navigate("/regrate");
            } else{
                reupload();
                document.getElementById('waitingLoader').style.display = 'none';
                toast.error(response.data.errors,{
                position: toast.POSITION.TOP_CENTER
                });
            }
    }).catch(error => {
        reupload();
        document.getElementById('waitingLoader').style.display = 'none';
        toast.error(error.message,{
            position: toast.POSITION.TOP_CENTER
        });
    });
}

    const onFileChangeHandlerBack = (e) => {
        checkStateByMiddileware();
        var reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onloadend = function(){
            const img_arr = reader.result.split(";base64,");
            const image = img_arr[1];
            setBackBase64(image)
            setSrcBack(URL.createObjectURL(e.target.files[0]));
            var value = e.target.files[0].size;
            var extenstion = e.target.files[0].type;
            var file_size = value / 1024;
            let result = true;
            if (extenstion == "application/pdf" && file_size > 1024 * 2) {
                toast.error("Please try with an acceptable file size", {
                    position: toast.POSITION.TOP_CENTER
                });
                setReUpload(true);
                result = false;
            } else if (file_size > 1024 * 4) {
                toast.error("Please try with an acceptable file size", {
                    position: toast.POSITION.TOP_CENTER,
                })
                setReUpload(true);
                result = false;
            }
            var extenstion = e.target.files[0].name.split('.').pop();
            const request_data = {'name_of_ocr': 'AADHAAR_BACK_OCR', 'ocr_doc' : image, 'doc_type' : extenstion};
            if(result){
                checkStateByMiddileware();
                document.getElementById('waitingLoader').style.display = 'block';
                ajaxAadhaarBack(request_data, extenstion);
            }
        }
    }

    function ajaxAadhaarBack (request_data, extenstion){
        const headers = {
            'Authorization': `Bearer ${jwtToken}`,
            'latlong' : `${latitude},${longitude}`,
        }
        const encrypted = encryptData(request_data);
        axios.post(api_url + `/doc-ocr`, {encrypted : encrypted}, { headers })
        .then((response) => {
            document.getElementById('waitingLoader').style.display = 'none';
            if (response.data.status == 'success') {
                setSelectedBackImg(true);
                if (typeof response.data.data.aadhaar_address === "undefined" || typeof response.data.data.aadhaar_address === "") {
                    toast.error('Please upload valid Aadhaar Back Image', {
                        position: toast.POSITION.TOP_CENTER
                    });
                  setSelectedBackImg(false);
                }else {
                    toast.success(response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                    setAadhaarAddress(response.data.data.aadhaar_address);
                    setOcrType(response.data.data.ocr_type);
                    setSelectedBackImg(true);
                    setReUpload(true);
                    if(isSelectedImg){
                        setDisableBtn(false);
                    }
                    if(extenstion == "application/pdf") {
                      setcheckBack(true);
                      setBACKPDF(frontpdf);                         
                    } else {
                        setBACKPDF('');
                    }
                }
            }else if (response.data.status === "token") {
                toast.error(response.data.errors, {
                    position: toast.POSITION.TOP_CENTER,
                });
                sessionStorage.clear();
                navigate('/login')
            }else if (response.data.status === 'hold') {
                reupload();
                setPopUpData({...popupData,is_bank_hold : true});
            }else if (response.data.status === "expired") {
                document.getElementById('waitingLoader').style.display = 'none';
                setPopUpData({...popupData,is_bureau_checked : true});
            }else if (response.data.status === 'fail' && response.data.is_rejected === true) {
                document.getElementById('waitingLoader').style.display = 'none';
                if(response.data.hasOwnProperty('data')){
                    sessionStorage.setItem('status_flag', response.data.data.appStatus.comment)
                }
                navigate("/regrate");
            }else {
                reupload();
                document.getElementById('waitingLoader').style.display = 'none';
                toast.error(response.data.errors,{
                    position: toast.POSITION.TOP_CENTER
                });
            }
        }).catch(error => {
            reupload();
            document.getElementById('waitingLoader').style.display = 'none';
            toast.error(error.message,{
                position: toast.POSITION.TOP_CENTER
            });
        });
    }

    const Send_aadhar_otp = () => {
        let checkAadhaar = true;
        if(aadhaar_no === '' || aadhaar_no === null || aadhaar_no === undefined || aadhaar_no === 'undefinded'){
            checkAadhaar = false;
        }
        checkStateByMiddileware();
        if(checkAadhaar){
            const payload = {
                aadhaar_no: aadhaar_no,
            }
            const encrypted = encryptData(payload);
            const headers = {
                'Authorization': `Bearer ${jwtToken}`,
                'latlong' : `${latitude},${longitude}`,
            }
            document.getElementById('waitingLoader').style.display = 'block';
            axios.post(api_url + `/send_aadhar_otp`, {encrypted : encrypted}, { headers })
                .then((response) => {
                    document.getElementById('waitingLoader').style.display = 'none';
                    if (response.data.status == 'success') {
                        navigate('/aadhar-otp', { aadhaar_no: [aadhaar_no] });
                    }
                    else if (response.data.status === "token") {
                        toast.error(response.data.errors, {
                            position: toast.POSITION.TOP_CENTER,
                        });
                        sessionStorage.clear();
                        navigate('/login')
                    }else if (response.data.status === 'hold') {
                        setPopUpData({...popupData,is_bank_hold : true});
                    }else if (response.data.status === "expired") {
                        document.getElementById('waitingLoader').style.display = 'none';
                        setPopUpData({...popupData,is_bureau_checked : true});
                    }else if (response.data.status === 'fail' && response.data.is_rejected === true) {
                        document.getElementById('waitingLoader').style.display = 'none';
                        if(response.data.hasOwnProperty('data')){
                    sessionStorage.setItem('status_flag', response.data.data.appStatus.comment)
                }
                        navigate("/regrate");
                    } else {
                        document.getElementById('waitingLoader').style.display = 'none';
                        toast.error(response.data.errors,{
                            position: toast.POSITION.TOP_CENTER
                        });
                    }
                })
                .catch(error => {
                    document.getElementById('waitingLoader').style.display = 'none';
                    toast.error(error.message,{
                        position: toast.POSITION.TOP_CENTER
                    });
                });
        }else{
            toast.error("Please reupload your aadhaar data.",{
                position: toast.POSITION.TOP_CENTER
            });
        }
    }
    
    const reupload = () => {
        inputRef.current.value = null;
        inputBackRef.current.value = null;
        setAadhaarNo('');
        setAadhaarName('');
        setAadhaarDob('');
        setAadhaarAddress('');
        setOcrType('');
        setSrc(imageThumb);
        setSrcBack(imageThumb);
        setDisableBtn(true);
        setReUpload(false);
        setSelectedBackImg(false);
        setSelectedImg(false);
        setBACKPDF('');
        setFrontPDF('');
    }

    return (
        <>
            <ToastContainer />
            <div className="pt-3">
                <Heading Description="Let us help you build a future-ready company" BadgeDescription="Let us complete your KYC" user />
            </div>
            <Layout ImageSide="Product-intro">
                <h4 className="text-center mb-4">Please upload your unmasked AADHAAR</h4>
                <div className="col-md-12 mx-auto">
                    <div className="row">
                        <div className="col-md-6">
                            <ChooseInput isDisabled={isSelectedImg} ispdf={frontpdf} src={src} inputRef={inputRef} title="Front" isAccept={'.png, .jpg, .jpeg, .pdf'} selectFile={onFileChangeHandler} />
                        </div>
                        <div className="col-md-6">
                            <ChooseInput isDisabled={isSelectedBackImg} ispdf={(checkBack==true?backpdf:'')} src={srcback} inputRef={inputBackRef} title="Back" isAccept={'.png, .jpg, .jpeg, .pdf'} selectFile={onFileChangeHandlerBack} />
                        </div>
                    </div>
                    <div className='isDisplay'>
                        <Input LabelName="Aadhaar No" isAstrix type={ishide} id="aadhaar_no" placeholder="aadhaar no" inputval={aadhaar_no} changeInput={(e) => { setAadhaarNo(e.target.value) }} />
                        <Input LabelName="Aadhaar Name" name="aadhaar_name" isAstrix type={ishide} Id="aadhaar_name" placeholder="Aadhaar Name" inputval={aadhaar_name} changeInput={(e) => { setAadhaarName(e.target.value) }} />
                        <Input LabelName="Aadhaar DOB" name="aadhaar_dob" isAstrix type={ishide} Id="aadhaar_dob" placeholder="Aadhaar DOB" inputval={aadhaar_dob} changeInput={(e) => { setAadhaarDob(e.target.value) }} />
                        <Input LabelName="Aadhaar Address" name="aadhaar_address" isAstrix type={ishide} Id="aadhaar_address" placeholder="Aadhaar DOB" inputval={aadhaar_address} changeInput={(e) => { setAadhaarAddress(e.target.value) }} />
                        <Input LabelName="OCR Type" name="ocr_type" isAstrix type={ishide} Id="ocr_type" placeholder="ocr type" inputval={ocr_type} changeInput={(e) => { setOcrType(e.target.value) }} />
                    </div>
                    <PageFooter BtnName="I SUBMIT" setDisabled={disablebtn} TermsCondition="I acknowledge that by submitting, U GRO may access the location, camera and the file uploaded from this device." ButtonClick={() => { Send_aadhar_otp() }} />
                    {isReUpload &&
                        <div className="text-center">
                            <a className='mt-0 text-center fontsize-12 fw-bold' href='javascript:;' onClick={() => { reupload(); }}>Re Upload</a>
                        </div>
                    }
                </div>
            </Layout>
        </>
    );
}
export default AadhaarUpload;